<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        class="sidebar_nav app-drawer"
        :class="{ 'app-drawer__active': rail }"
        width="300"
        id="navigationDrawer"
      >
        <v-btn
          :icon="!rail ? 'mdi-menu-left' : 'mdi-menu-right'"
          variant="text"
          @click.stop="onClickExpand"
          v-if="!isMobileView"
          class="app-drawer__button"
          :class="[{ side_btn_open: !rail, side_btn_close: rail }]"
        ></v-btn>
        <v-icon
          icon="mdi-close"
          class="tw-absolute tw-right-4 tw-top-4 rmd:tw-hidden"
        ></v-icon>
        <v-skeleton-loader
          class="tw-my-10"
          v-if="isWorkstationLoading"
          type="list-item-avatar"
        ></v-skeleton-loader>
        <div
          v-else
          class="dashboard-verify-profile__info common-profile-view sidebar_profile md:!tw-py-4"
        >
          <div class="img-wrap">
            <img
              :src="userImage"
              v-if="userImage"
              width="150"
              height="150"
              alt=""
            />
            <UserProfileLogo
              v-else
              :userName="`${user?.firstName} ${user?.lastName}`"
            />
          </div>

          <v-list-item
            :title="setUserName"
            nav
            class="profile_wrapper"
            v-if="!rail"
          >
          </v-list-item>
        </div>

        <v-list
          density="compact"
          nav
          class="side_nav app-drawer__list no-scroll"
          v-if="sideBarTabList.length"
        >
          <template v-for="(tabItem, index) in sideBarTabList" :key="index">
            <v-list-item
              class="app-drawer__list-item"
              color="#FFA500"
              :title="tabItem.name"
              :value="tabItem.name"
              :active="tabItem.route === selectedModule"
              @click="onClickModule(tabItem.route, tabItem.name)"
              :class="{
                active: changeSidebarIcon.includes(tabItem.name),
                disable: checkIsModuleAllowedUser(tabItem.name),
              }"
            >
              <template v-slot:prepend>
                <!-- <v-icon :icon="tabItem.icon"></v-icon> -->
                <img
                  v-if="tabItem.icon"
                  :src="
                    require('@/assets/icons/sidebar-icons/' +
                      tabItem.icon +
                      '.svg')
                  "
                  alt="icon"
                /> </template
            ></v-list-item>
          </template>
        </v-list>
        <div class="side_footer app-drawer__footer">
          <div class="logout_wrapper">
            <hr />
            <v-list density="compact" nav class="app-drawer__list logout-list">
              <v-list-item
                @click="onClickLogout"
                class="app-drawer__list-item"
                prepend-icon="mdi-logout"
                title="Logout"
                value="logout"
              ></v-list-item>
            </v-list>
            <hr />
          </div>
          <div class="footer-logo">
            <img
              :width="160"
              aspect-ratio="16/9"
              cover
              src="../../assets/images/logo/iknowa-logo-v4.svg"
              style="margin: 0 auto"
            />
          </div>
        </div>
        <h1 v-if="createWorkstationRestrictionModal">hello world</h1>
        <PageRestrictionNoticeModal
          v-if="createWorkstationRestrictionModal"
          @on-close="toggleRestrictionModal"
        />
        <CreateWorkstationModal
          v-if="isShowCreateWorkstationModal"
          @on-close="toggleCreateWorkstationModal"
        />
        <CommingSoonNoticeModal
          v-if="isShowComingSoonModal"
          @on-close="toggleComingSoonModal"
          @on-route-to-dashboard="routeToDashboard"
          @on-tutorial="toggleComingSoonModal"
        />
        <SubmitVerificationNoticeModal
          v-if="isShowingVerificationRequiredModal"
          @on-close="toggleSubmitVerificationModal"
          @on-route-to-dashboard="routeToDashboard"
        />
      </v-navigation-drawer>
    </v-layout>
  </v-card>
  <PropertyOwnerWorkStationModal
    v-if="isShowPropertyOwnerWorkStationModal"
    @onClickCloseTab="onClickClosePOWorkstationModal"
  />
</template>

<script lang="ts">
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import { useStore } from "vuex";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { deleteAllCookies } from "../utils/common";
import { TRADE_NETWORK_ROUTE } from "@/modules/trade-network/routes";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { inject, onMounted } from "vue";
import PageRestrictionNoticeModal from "./modals/PageRestrictionNoticeModal.vue";
import CreateWorkstationModal from "@/core/components/modals/CreateWorkstationModal.vue";
import CommingSoonNoticeModal from "./modals/CommingSoonNoticeModal.vue";
import SubmitVerificationNoticeModal from "./modals/SubmitVerificationNoticeModal.vue";
import { CERTIFICATE_STORE } from "@/store/modules/certificates";
import { EMERGENCY_OUT_OF_HOURS } from "@/modules/emergency-out-of-hours/routes";
import PropertyOwnerWorkStationModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerWorkStationModal.vue";
import { CHAT_ROUTE } from "@/modules/chat/routes";
import { CLIENTS_ROUTE } from "@/modules/clients/routes";
import insurancesService from "../services/insurances.service";
import { INSURANCE_STORE } from "@/store/modules/insurance";

export default {
  components: {
    UserProfileLogo,
    PageRestrictionNoticeModal,
    CommingSoonNoticeModal,
    SubmitVerificationNoticeModal,
    PropertyOwnerWorkStationModal,
    CreateWorkstationModal,
  },
  setup(props: any, ctx: any) {
    const vuetify = useDisplay();
    const store = useStore();
    const rail = ref(false);
    const drawer = ref(true);
    const activeIndex = ref(0);
    const isShowPropertyOwnerWorkStationModal = ref(false);

    const pusher = inject("pusher") as any;

    const preOpenSideBarTabsInTradePerson = ref([
      "Dashboard",
      // "Jobs",
      // "Documents",
    ]);

    const preOpenSideBarTabsInPropertyOwner = ref(["Dashboard"]);

    const createWorkstationRestrictionModal = ref(false);
    const isShowComingSoonModal = ref(false);
    const isShowCreateWorkstationModal = ref(false);
    const isShowingVerificationRequiredModal = ref(false);
    const isShowWorkStationModal = ref(false);
    // const userInsurenceExperiedStatus = ref(false);

    const changeSidebarIcon = ref(["Trade Network", "Courses"]);
    const route = useRoute();
    const router = useRouter();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const selectedModule = computed(() => route.name);
    const isMobileView = computed(() => vuetify.smAndDown.value);
    const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
    const userWorkStationImage = computed(
      () => store.getters[`${USER_STORE}/userWorkStationImage`]
    );

    const collapsed = ref(false);

    const toggleCollapse = () => {
      collapsed.value = !collapsed.value;
    };

    const handleFileUpload = (event: any) => {
      const target = event.target;
      const file = target.files[0];
    };
    const onClickLogout = () => {
      deleteAllCookies();
      store.commit(`${USER_STORE}/resetStore`);
      store.commit(`${WORKSTATION}/setActiveUserWorkstation`, null);
      window.localStorage.clear();
      router.push({ name: LOGIN_ROUTE });
    };

    const toggleRestrictionModal = () => {
      createWorkstationRestrictionModal.value =
        !createWorkstationRestrictionModal.value;
    };
    const toggleComingSoonModal = () => {
      isShowComingSoonModal.value = !isShowComingSoonModal.value;
    };
    const toggleCreateWorkstationModal = () => {
      isShowCreateWorkstationModal.value = !isShowCreateWorkstationModal.value;
    };
    const toggleSubmitVerificationModal = () => {
      isShowingVerificationRequiredModal.value =
        !isShowingVerificationRequiredModal.value;
    };
    const onClickModule = (path: string, name: string) => {
      if (isWorkStationVerified.value) {
        isInsurenceExpired(user.value.id);
      }
      const isModuleAllowed = checkIsModuleAllowedUser(name);
      if (isModuleAllowed) {
        createWorkstationRestrictionModal.value = true;
      } else {
        if (name === "Chat") {
          // toggleCreateWorkstationModal();
          toggleComingSoonModal();
          return;
        }
        router.push({ name: path });
      }
    };

    const onClickExpand = () => {
      rail.value = !rail.value;
      ctx.emit("expandNavBar", rail.value);
    };

    const userName = computed(
      () =>
        `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
    );

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    // console.log("work staion", activeUserWorkstation.value);

    const isWorkstationLoading = computed(
      () => store.getters[`${WORKSTATION}/loadingWorkstation`]
    );

    const userWorkStationStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );
    // if (userWorkStationStatus.value) {
    //   console.log("user works station status", userWorkStationStatus.value);
    // }

    const isWorkStationVerified = computed(() => {
      return (
        userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED
      );
    });

    const userInsurenceExperiedStatus = computed(
      () => store.getters[`${INSURANCE_STORE}/getIsInsuranceExpired`]
    );

    watch(isWorkStationVerified, (newVal, oldVal) => {
      if (newVal) {
        if (isWorkStationVerified.value) {
          isInsurenceExpired(user.value.id);
        }
      }
    });
    const isInsurenceExpired = async (id: number) => {
      const responce: any = await insurancesService.isInsuranceExpired(id);
      if (responce.isFreezed) {
        // userInsurenceExperiedStatus.value = true;
        store.commit(`${INSURANCE_STORE}/setInsuranceExpired`, true);
      }
    };

    const workstationStatusNotAdded = computed(() => {
      return (
        userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED
      );
    });

    const isUserProfileVerified = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );

    const certificateList = computed(
      () => store.getters[`${CERTIFICATE_STORE}/certificates`]
    );

    const userIsDefault = computed(() => {
      return activeUserWorkstation.value?.name === "Default";
    });

    const setUserName = computed(() => {
      return userName.value;
      // if (userIsDefault.value) {
      // }
      // return activeUserWorkstation.value?.name;
    });

    const onClickClosePOWorkstationModal = () => {
      isShowPropertyOwnerWorkStationModal.value = false;
    };

    const routeToDashboard = () => {
      router.push({ name: DASHBOARD_ROUTE });
      isShowComingSoonModal.value = false;
      isShowingVerificationRequiredModal.value = false;
      isShowCreateWorkstationModal.value = false;
    };

    const checkIsModuleAllowedUser = (value: string) => {
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        if (!preOpenSideBarTabsInTradePerson.value.includes(value)) {
          if (activeUserWorkstation.value?.name === "Default") {
            return false;
          }
          if (userInsurenceExperiedStatus.value) {
            if (
              value === "Trade Passport" ||
              value === "Calendar" ||
              value === "Documents" ||
              value === "Trade Network"
            ) {
              return false;
            }
            return true;
          }
          if (
            value === "Documents" ||
            value === "Chat" ||
            value === "Calendar"
          ) {
            return false;
          }
          if (
            activeUserWorkstation.value?.verificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
          ) {
            return true;
          }
          if (
            value !== "Trade Passport" &&
            value !== "Calendar" &&
            value !== "Documents" &&
            value !== "Trade Network" &&
            activeUserWorkstation.value?.verificationStatus !==
              UserVerificationStatusEnum.VERIFIED
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (
          (value === "Trade Passport" ||
            value === "Calendar" ||
            value === "Documents" ||
            value === "Trade Network") &&
          activeUserWorkstation.value?.verificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
        ) {
          return true;
        }
        return false;
      } else {
        if (!preOpenSideBarTabsInPropertyOwner.value.includes(value)) {
          if (activeUserWorkstation.value?.name === "Default") {
            return false;
          }
          return false;
        }
      }
    };
    const sideBarTabList = computed(() => {
      if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
        return [
          {
            name: "Dashboard",
            icon: "dashboard",
            route: DASHBOARD_ROUTE,
          },
          {
            name: "Properties",
            icon: "property",
            route: "properties",
          },
          { name: "Projects", icon: "projects", route: "project" },
          { name: "Calendar", icon: "calendar", route: "calendar" },
          { name: "Documents", icon: "documents", route: "documents" },
          {
            name: "Preferred Contractors",
            icon: "members",
            route: "preferred-contractors",
          },
          {
            name: "Raise An Issue",
            icon: "issue",
            route: "raise-issue",
          },
          {
            name: "Out Of Hours",
            icon: "clock_circle",
            route: EMERGENCY_OUT_OF_HOURS,
          },

          // { name: "E-Wallet", icon: "eWallet", route: "e-wallet" },
          {
            name: "Connections",
            icon: "connections",
            route: "connections",
          },
          { name: "Chat", icon: "chat", route: "chat" },
        ];
      } else if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        return [
          {
            name: "Dashboard",
            icon: "dashboard",
            route: DASHBOARD_ROUTE,
          },
          {
            name: "Trade Passport",
            icon: "trade-passport",
            route: "trade-passport",
          },
          {
            name: "Jobs",
            icon: "jobs",
            route: "jobs",
          },
          {
            name: "Trade Network",
            icon: "members",
            route: TRADE_NETWORK_ROUTE,
          },
          { name: "Courses", icon: "courses", route: "courses" },
          { name: "Documents", icon: "documents", route: "documents" },
          { name: "E-Wallet", icon: "eWallet", route: "e-wallet" },
          { name: "Calendar", icon: "calendar", route: "calendar" },
          { name: "Chat", icon: "chat", route: CHAT_ROUTE },
          { name: "Client", icon: "client", route: CLIENTS_ROUTE },
        ];
      } else {
        return [];
      }
    });

    const isTabActive = (routeName: string) => {
      return `/${routeName}` === selectedModule.value;
    };

    // const isSubmitVerificationStatus = () => {
    //   if (
    //     !userIsDefault.value &&
    //     (isUserProfileVerified.value ||
    //       userWorkStationStatus.value ===
    //         UserVerificationStatusEnum.PENDING_VERIFICATION) &&
    //     userWorkStationStatus.value !== UserVerificationStatusEnum.VERIFIED
    //   ) {
    //     toggleSubmitVerificationModal();
    //   }
    // };

    // watch(
    //   () => certificateList.value,
    //   async (newValue, oldValue) => {
    //     if (newValue) {
    //       isSubmitVerificationStatus();
    //     }
    //   },
    //   { deep: true, immediate: true }
    // );

    // watch(
    //   () => activeUserWorkstation.value,
    //   async (newValue, oldValue) => {
    //     isSubmitVerificationStatus();
    //   },
    //   { deep: true, immediate: true }
    // );

    // onMounted(() => {
    //   isSubmitVerificationStatus();
    // });

    onMounted(() => {
      if (user.value?.userPublicProfile?.profileImage) {
        store.dispatch(
          `${USER_STORE}/setUserProfileImage`,
          user.value.userPublicProfile.profileImage
        );
      }
    });

    return {
      collapsed,
      userName,
      toggleCollapse,
      handleFileUpload,
      sideBarTabList,
      onClickLogout,
      rail,
      drawer,
      onClickExpand,
      user,
      activeIndex,
      selectedModule,
      onClickModule,
      isMobileView,
      userImage,
      checkIsModuleAllowedUser,
      changeSidebarIcon,
      setUserName,
      createWorkstationRestrictionModal,
      toggleRestrictionModal,
      userWorkStationImage,
      isShowComingSoonModal,
      toggleComingSoonModal,
      routeToDashboard,
      toggleSubmitVerificationModal,
      isShowingVerificationRequiredModal,
      workstationStatusNotAdded,
      isWorkstationLoading,
      isShowPropertyOwnerWorkStationModal,
      onClickClosePOWorkstationModal,
      isShowCreateWorkstationModal,
      toggleCreateWorkstationModal,
    };
  },
};
</script>

<style lang="scss">
/* Styles for the bottom drawer */

.slider-menu-active {
  pointer-events: all !important;
  z-index: 9999999 !important;
  @include respond(md) {
    background-color: rgba(rgba($blueDark, 1), 0.2);
  }
  & > div {
    @include respond(md) {
      contain: content;
      width: 100% !important;
      max-width: 400px !important;
    }
    @include respond(sm) {
      padding: 0 !important;
      height: auto !important;
      max-width: 100% !important;
      left: 0 !important;
      top: unset !important;
      bottom: 0 !important;
      .v-card {
        background-color: transparent !important;
      }
    }
  }
}
.app-drawer {
  border-radius: 0 16px 16px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 0 !important;

  @include respond(md) {
    width: 100% !important;
    border-radius: 16px;
    padding: 0 16px 30px !important;
    position: unset !important;
  }
  @include respond(sm) {
    border-radius: 16px 16px 0 0;
  }

  &__button {
    &.v-btn {
      position: absolute;
      z-index: 1;
      background-color: rgba(rgba($blueDark, 1), 0.5);
      color: rgba($white, 1);
      border-radius: 100% 0 0 100%;
      width: 20px !important;
      top: 42px;
      right: 0;
      transition: all 0.3s;
    }
    &.side_btn_close {
      right: -20px;
      border-radius: 0 100% 100% 0;
      background-color: rgba($PrimaryBlue, 1);
    }
  }
  .v-navigation-drawer__content {
    max-width: 210px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: hidden;
    width: 100%;

    @include respond(md) {
      max-width: 100% !important;
      width: 100% !important;
    }

    .v-icon {
      font-size: calc(100% + 4px);
    }

    .profile_wrapper {
      // padding-top: 3rem;
      // padding-bottom: 2.25rem;
      width: calc(100% - 3rem);

      .v-list-item-title {
        text-align: left;
        text-transform: capitalize;
        white-space: pre-wrap;
        line-height: 24px !important;
        @include fluidFont(18, 18, 1.5);
      }
    }
  }
  &__list {
    padding: 0 !important;
    &:not(.logout-list) {
      height: calc(100dvh - 300px);
      overflow: auto !important;
      // @include respond(md) {
      //   max-height: calc(100dvh - 300px);
      //   height: auto;
      // }
    }
    &.logout-list {
      padding: 5px 0 !important;
    }
    &-item {
      &.v-list-item {
        min-height: 48px !important;
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: left;
        color: rgba(rgba($blueDark, 1), 1);
        border-radius: 8px;
        gap: 16px;
        display: flex;
        align-items: center;
        .v-list-item__prepend {
          width: 16px;
          max-width: 100%;
          display: block;
          img {
            width: 100%;
            object-fit: cover;
            filter: invert(15%) sepia(30%) saturate(2487%) hue-rotate(211deg)
              brightness(80%) contrast(116%);
          }
        }

        .v-list-item__spacer {
          display: none;
        }
        .v-list-item__content {
          width: calc(100% - 32px);
          .v-list-item-title {
            @include fluidFont(14, 14, 1.5);
            font-weight: 400;
            letter-spacing: 0.25px;
          }
        }
      }
      &.v-list-item--active {
        .v-list-item__prepend {
          img {
            filter: invert(75%) sepia(86%) saturate(3141%) hue-rotate(359deg)
              brightness(101%) contrast(105%);
          }
        }
        .v-list-item__content {
          .v-list-item-title {
            color: rgba($orange, 1);
          }
        }
      }
      &.active {
        .v-list-item__prepend {
          width: 20px;
        }
      }
      &.disable {
        color: rgba(12, 15, 74, 0.5);
        &.v-list-item {
          .v-list-item__prepend {
            img {
              filter: invert(10%) sepia(10%) saturate(1087%) hue-rotate(211deg)
                brightness(50%) contrast(50%);
            }
          }
        }
      }
    }
  }
  &__footer {
    .footer-logo {
      padding-top: 50px;
      @include respond(xlg) {
        padding-top: 30px;
      }
    }
  }

  // While collpase The Drawer
  &__active {
    width: 96px !important;
    .v-list-item__content {
      display: none;
    }
    .app-drawer__list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      .v-icon {
        font-size: calc(100% + 6px);
      }
    }
    .v-navigation-drawer__content {
      padding: 0 20px;
    }
    // .app-drawer__list:not(.logout-list) {
    //   height: calc(100dvh - 250px);
    //   @include respond(xlg) {
    //     height: calc(100dvh - 430px);
    //   }
    // }
    .profile_wrapper {
      .v-avatar {
        width: 3rem;
        height: 3rem;
      }
      .v-list-item__spacer {
        display: none;
      }
    }
    .app-drawer__footer {
      .logout_wrapper {
        hr {
          display: none;
        }
      }
      .footer-logo {
        display: none;
      }
    }
  }
}

// header

.header {
  width: 100%;
  background-color: transparent !important;
  box-shadow: none !important;
  padding-right: 110px;
  padding-left: 0;
  position: relative;
  margin-left: 50px;
  @include respond(xlg) {
    padding: 0 30px;
    margin: 0;
  }
  @include respond(sm) {
    padding: 0 15px;
  }

  .main-header {
    padding: 18px 0 17px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-block {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-end;
      @include respond(sm) {
        width: 50%;
        max-width: 100%;
      }
      .v-card {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: $white;
        box-shadow: 0px 0px 20px 0px #0c0f4a1a;
        .v-icon {
          vertical-align: -webkit-baseline-middle;
        }
      }
      .button {
        @include respond(sm) {
          width: 24px;
          height: 24px;
        }
      }
    }

    &::before {
      position: absolute;
      content: "";
      border: 1px solid rgba(rgba($blueDark, 1), 0.1);
      width: 100%;
      margin: 0 auto;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
.main-header .right-block .selector {
  width: 162px !important;
  border-radius: 48px !important;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
}
.right-block .selector img {
  border-radius: 100%;
  width: 32px;
}
.right-block .selector .v-field {
  box-shadow: none;
  padding: 0;
  .v-field__input {
    padding: 0;
  }
}
.right-block .v-field--center-affix .v-label.v-field-label {
  top: 50%;
  transform: translate(0);
  width: 100%;
  margin: 0;
  max-width: 100%;
}
.right-block .v-field--center-affix .v-field__append-inner i {
  margin: 0;
}

.v-navigation-drawer {
  border: none !important;
}

.right-block {
  .header-profile-select {
    @include respond(sm) {
      // width: 50px;
      width: 113px;
      max-width: 100%;
    }
    .v-btn__content {
      gap: 8px;
    }
  }
}

.main_block_right {
  height: 100%;
  .left-block {
    text-align: left;
    @include respond(sm) {
      width: 50%;
      max-width: 100%;

      span {
        font-size: 17px !important;
      }
    }
    span {
      color: rgba($blueDark, 1);
      @include fluidFont(22, 18, 1.4);
      letter-spacing: 0.15px;
    }
  }
}
.sidebar_profile {
  padding-top: 3rem;
  padding-bottom: 2.25rem;
}

.disable {
  .v-list-item__prepend {
    img {
      filter: invert(55%) sepia(9%) saturate(932%) hue-rotate(198deg)
        brightness(93%) contrast(86%);
    }
  }
  .v-list-item__content {
    .v-list-item-title {
      color: rgba($buttonText, 1);
    }
  }
}
</style>
