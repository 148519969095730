<template>
  <v-dialog
    width="450px"
    max-width="100%"
    height="auto"
    v-model="isShowModel"
    class="modal_box action-modal payment__modal_box"
    persistent
  >
    <div class="modal_wrapper">
      <div class="main" v-if="!paymentStatus || paymentStatus === ''">
        <CommonLoader :loading="true" class="loader-container" />
        <v-btn class="button button-orange w-full" @click="onContinue"
          >continue</v-btn
        >
      </div>
      <div v-if="paymentStatus === 'paid'" class="main">
        <div class="modal-img">
          <img src="../../assets/images/success-payment.png" />
        </div>
        <h2 class="modal-title">Payment Successful!</h2>
        <p class="modal-desc">
          Congrats! Your payment has been successful. Take a look around and
          enjoy your iknowa
          <strong> {{ plan }}</strong> plan.
        </p>
        <v-btn class="button button-orange w-full" @click="onContinue"
          >continue</v-btn
        >
      </div>
      <div v-if="paymentStatus === 'pending'" class="main">
        <div class="action-icon">
          <PendingStatus :className="`icon-50`" />
        </div>
        <h2 class="modal-title">Payment pending</h2>
        <p class="modal-desc">
          We are yet to receive confirmation about your payment for to the
          iknowa {{ plan }} Plan. We will update you as soon as we get the
          confirmation
        </p>
        <v-btn class="button button-orange w-full" @click="onContinue"
          >continue</v-btn
        >
      </div>
      <div v-if="paymentStatus === 'failed'" class="main">
        <div class="action-icon">
          <CancelIcon />
        </div>
        <h2 class="modal-title">Payment unsuccessful</h2>
        <p class="modal-desc">
          Your payment for the subscription of iknowa {{ plan }} Plan was
          unsuccessful. Please try again or later or if there is any issue,
          contact us hello@iknowa.com
        </p>
        <v-btn class="button button-orange w-full" @click="onContinue"
          >continue</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
.payment__modal_box {
  :deep(.v-overlay__content) {
    padding: 0 20px;
    @include respond(s720) {
      width: 350px !important;
    }
    .modal_wrapper {
      width: 100%;
      max-width: 100%;
      .main {
        border-radius: 8px;
        padding: 36px 26px;
        .action-icon {
          width: 54px;
          height: 54px;
          margin: 0 auto;
          @include respond(s720) {
            width: 50px;
            height: 50px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .modal-title {
          @include fluidFont(24, 24, 1.2);
          font-weight: 700;
          color: rgba($blueDark, 1);
          margin: 55px 21px 38px;
        }
        .modal-img {
          img {
            width: 100%;
          }
        }
        p {
          // margin-top: 36px;
          margin-bottom: 0;
          strong {
            color: rgba($blueDark, 1);
          }
        }
      }
    }
  }
}
</style>

<script setup>
import { computed } from "vue";

import CheckGreen from "./icons/CheckGreen.vue";
import PendingStatus from "./icons/PendingCheck.vue";
import CancelIcon from "./icons/CancelIcon.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";

const props = defineProps({
  paymentStatus: {
    type: String,
    default: "",
  },
  onClickBackButton: {
    type: Function,
  },
  onContinue: {
    type: Function,
  },
  planType: Number,
});

const isShowModel = true;

const plan = computed(() => {
  return props.planType === 1 ? "Monthly" : "Yearly";
});
</script>
