<template>
  <GeneralWidgetLayoutSlot
    class="!tw-min-h-[396px]"
    title="Projects Overview"
    buttonLabel="Manage Projects"
    :borderBottom="true"
    @on-action-click="onClickManageProject"
  >
    <template #content>
      <main class="tw-w-full tw-h-full tw-flex tw-justify-between tw-flex-col">
        <div
          class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-4 tw-box-border"
          :class="{ 'tw-border-b-solid-custom': !item?.noBorder }"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <component :is="item.icon" />
            <Text variant="p" textWeight="500">{{ item.title }}</Text>
          </div>
          <Text class="!tw-text-[20px]" textWeight="600">{{
            getProjectCountBaseOnStatus(item.statusId)
          }}</Text>
        </div>
      </main>
    </template>
  </GeneralWidgetLayoutSlot>
</template>
<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";

import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InProgressIcon from "@/core/components/icons/InProgressIcon.vue";
import EstimateReceivedIcon from "@/core/components/icons/EstimateReceivedIcon.vue";
import NotStartedIcon from "@/core/components/icons/NotStartedIcon.vue";
import IssueRaisedIcon from "@/core/components/icons/IssueRaisedIcon.vue";
import ProjectPostedIcon from "@/core/components/icons/ProjectPostedIcon.vue";
import DraftJobsIcon from "@/core/components/icons/DraftJobsIcon.vue";
import { PROJECT_ROUTE } from "@/modules/project/routes";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import { PROJECT_STORE } from "@/store/modules/project";
import { computed } from "vue";
import { useStore } from "vuex";
import AwaitingEstimatesIcon from "@/core/components/icons/AwaitingEstimatesIcon.vue";

const inProgress = InProgressIcon;
const estimateReceived = EstimateReceivedIcon;
const notStarted = NotStartedIcon;
const draftJobs = DraftJobsIcon;
const issueRaised = IssueRaisedIcon;
const awaitingEstimates = AwaitingEstimatesIcon;
const ProjectPosted = ProjectPostedIcon;
const router = useRouter();

const items = reactive([
  {
    icon: inProgress,
    title: "In progress",
    statusId: [ProjectJobStatusEnum.IN_PROGRESS],
    count: "0",
  },
  {
    icon: issueRaised,
    title: "Issues Raised",
    count: "0",
    statusId: ["issue"],
  },
  {
    icon: awaitingEstimates,
    title: "Awaiting estimates",
    statusId: [ProjectJobStatusEnum.AWAITING_QUOTES],
    count: "0",
  },
  {
    icon: estimateReceived,
    title: "Estimates received",
    statusId: [ProjectJobStatusEnum.QUOTES_RECEIVED],
    count: "0",
  },
  {
    icon: notStarted,
    title: "Not Started",

    count: "0",
    statusId: ["all"],
  },
  {
    icon: draftJobs,
    title: "Draft Projects",
    count: "0",
    statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
  },
  {
    icon: ProjectPosted,
    title: "Project Posted",
    count: "0",
    noBorder: true,

    statusId: [
      "all",
      ProjectJobStatusEnum.AWAITING_QUOTES,
      ProjectJobStatusEnum.IN_PROGRESS,
      ProjectJobStatusEnum.QUOTES_RECEIVED,
    ],
  },
]);
const store = useStore();
const getProjectStatusCountsList = computed(
  () => store.getters[`${PROJECT_STORE}/getProjectStatusCountsList`]
);
const getProjectCountBaseOnStatus = (statusId) => {
  try {
    const total = statusId.reduce((count, id) => {
      count += getProjectStatusCountsList.value[id] || 0;
      return count;
    }, 0);
    return String(total).padStart(2, "0");
  } catch (error) {
    return "00";
  }
};

onMounted(async () => {
  // if (!Object.keys(getProjectStatusCountsList.value)?.length) {
  await store.dispatch(`${PROJECT_STORE}/setProjectStatusCounts`);
  // }
});

const onClickManageProject = () => {
  router.push({ name: PROJECT_ROUTE });
};
</script>
<style lang="scss" scoped></style>
