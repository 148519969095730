<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['property-service-plan-modal']"
    width="590px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <v-btn variant="plain" icon="mdi-close" @click="closeModal"></v-btn>
        </div>
        <div class="property-service-plan-modal__content">
          <h4>Does Your Property have a heat pump?</h4>
          <p>
            Get your annual service, from <strong>£30.00</strong> per month.
          </p>
          <div class="property-service-plan-modal__pay">
            <p>You’ll Pay,</p>
            <p><strong>£30.00</strong></p>
          </div>
        </div>

        <div class="property-service-plan-modal__footer">
          <v-btn
            @click="onSubscribeHeatPumpSubscription"
            class="button button-orange"
            :loading="loading"
            block
            >PAY NOW !</v-btn
          >
        </div>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { USER_STORE } from "@/store/modules/user";
import { ref, defineEmits, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const emits = defineEmits(["on-close"]);

const loading = ref(false);
const store = useStore();
const closeModal = () => {
  emits("on-close");
};
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);
const componentInstance = getCurrentInstance();
const displayToast = (message, type) => {
  if (
    componentInstance &&
    componentInstance.appContext.config.globalProperties.$notify
  ) {
    componentInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};
const onSubscribeHeatPumpSubscription = async () => {
  try {
    loading.value = true;
    await store.dispatch(`${PROPERTY_STORE}/getHeatPumpSubscription`, {
      userId: user.value.id,
      propertyId: propertyDetails.value.id,
    });
    await store.dispatch(
      `${PROPERTY_STORE}/fetchPropertyDetails`,
      propertyDetails.value.id
    );
    displayToast("Your subscription plan changed successfully.", "success");
  } catch (error) {
    if (error.response.status === 402) {
      return displayToast("Please upgrade your workstation !", "error");
    }
    displayToast(
      "something went wrong in subscription of heat pump service plan.",
      "error"
    );
    console.log("get heat pump subscription error", error);
  } finally {
    loading.value = false;
    closeModal();
  }
};
</script>
