export default {
  jobs: [],
  myJobs: [],
  draftJobs: [],
  rejectedJobs: [],
  jobDetails: {},
  historicalJobs: [],
  activeHistoricalJob: null,
  videoScheduleTime: {
    startTime: "",
    endTime: "",
  },

  workFlowType: null,
  jobStatusCountsList: {},
};
