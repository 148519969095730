<template>
  <div class="calendar_event_modal shadow">
    <div class="todo_list">
      <h6>{{ projectData?.project?.name }}</h6>
      <p>
        {{ getMonthData(projectData?.suggestedStartDate) }} -
        {{ getMonthData(projectData?.projectEstimateEndDate) }}
      </p>
      <div class="progress_bar">
        <v-progress-linear
          color="#40AE42"
          :model-value="getProjectProgress"
          height="8"
          rounded
        />
        {{ getProjectProgress }}
      </div>
    </div>
    <div class="member__card__users" v-if="getProjectMemberImages?.length">
      <ul class="member__card__users__img">
        <li
          v-for="(profile, index) in getProjectMemberImages.slice(0, 3)"
          :key="index"
        >
          <img v-if="profile?.imageUrl" :src="profile?.imageUrl" alt="" />
          <UserProfileLogo v-else :userName="profile?.workStationName" />
        </li>
        <li v-if="getProjectMemberImages.length > 3">
          <span>+{{ getProjectMemberImages.length - 3 }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import moment from "moment";
import { defineProps, computed } from "vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
const props = defineProps({ projectData: Object });
const getMonthData = (date) => {
  const formateData = moment(date, "YYYY-MM-DD");
  return formateData.format("MMM D");
};
const getPhaseProgressData = (phase) => {
  if (!phase?.estimationPhaseTasks?.length) {
    return phase?.phaseCompletionStatus ? 100 : 0;
  } else {
    const completedSubTask = phase?.estimationPhaseTasks.filter(
      (task) => task.taskCompletionStatus
    );
    return (completedSubTask.length * 100) / phase.estimationPhaseTasks.length;
  }
};

const getProjectProgress = computed(() => {
  if (!props?.projectData?.estimationPhase?.length) return "0%";
  const totalPhaseCompletion = props.projectData.estimationPhase.reduce(
    (total, phase) => {
      return (total += getPhaseProgressData(phase));
    },
    0
  );
  return `${(
    totalPhaseCompletion / props.projectData?.estimationPhase?.length
  )?.toFixed(2)}%`;
});

const getProjectMemberImages = computed(() =>
  props?.projectData?.estimationPhase?.length
    ? props.projectData.estimationPhase
        .flatMap((phase) => phase?.memberProfileImages)
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.imageUrl === value.imageUrl &&
                t.workStationName === value.workStationName
            )
        )
    : []
);
</script>

<style lang="scss" scoped>
.calendar_event_modal.shadow {
  text-align: left;
  position: relative;
  width: 185px;
  box-shadow: 0px 4px 20px 0px #2a2a2a1a;
  background-color: rgba($white, 1);
  border-radius: 8px;
  .todo_list {
    padding: 15px;
    border-bottom: 1px solid rgba($blueDark, 0.2);
    h6 {
      @include fluidFont(14, 14, 1.2);
      font-weight: 600;
      color: rgba($blueDark, 1);
    }
    p {
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 0.5);
      margin-top: 8px;
    }
    .progress_bar {
      margin-top: 12px;
      display: flex;
      align-items: center;
      gap: 6px;
      @include fluidFont(10, 10, 1.2);
      font-weight: 500;
      color: rgba($blueDark, 1);
    }
  }
  .member__card__users {
    padding: 15px;
    .member__card__users__img {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      li {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        overflow: hidden;
        margin-left: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e6e6e6;
        &:first-child {
          margin: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        span {
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
      }
    }
  }
  .calendar_time {
    @include fluidFont(12, 12, 1.2);
    font-weight: 400;
    color: rgba($PrimaryPurple, 1);
    margin-top: 15px;
    display: inline-block;
    margin-right: 20px;
  }
  .calendar_event_modal_footer {
    display: inline-flex;

    .calendar_edit_wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      .edit_modal {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($blueDark, 0.1);
      }
      .delete_modal {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($PrimaryRed, 0.1);
      }
    }
  }
}
</style>
