<template>
  <main
    class="dashboard-sec !tw-flex !tw-flex-col tw-gap-4 rmd:tw-gap-6 tw-relative md:!tw-pb-16 md:!tw-box-border"
  >
    <!-- KYC/KYB NOTICE -->
    <div
      v-if="
        getVerificationStatus.statusClass === 'info' &&
        !isUserWorkstationIsDefault &&
        !isWorkstationLoading &&
        activeUserWorkstation?.verificationStatus !==
          UserVerificationStatusEnum.VERIFIED &&
        accountStatuses?.forms?.insurance !== 0 &&
        accountStatuses?.forms?.certification !== 0 &&
        accountStatuses?.forms?.skills !== 0 &&
        getUserWorkStationFieldAccess
      "
      class="tw-w-full tw-flex tw-gap-3 tw-rounded-[10px] tw-px-6 tw-py-4 tw-box-border tw-bg-[rgba(250,75,0,0.09)] tw-border-solid tw-border tw-border-[rgba(250,75,0,0.5)]"
    >
      <NoticeIcon />
      <Text
        v-if="
          !activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode &&
          userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Add Business Address.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickAddBusinessAddressModal"
          >Manage Address</Text
        ></Text
      >
      <Text
        v-if="
          !activeUserWorkstation?.user?.userBusinessDetails
            ?.residencyPostcode &&
          userBusinessType === BusinessDetailTypeEnum.SOLETRADER
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Add Business Address.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickAddBusinessAddressModal"
          >Manage Address</Text
        ></Text
      >
      <Text
        v-if="
          activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          activeUserWorkstation?.user?.userBusinessDetails?.residencyPostcode &&
          userBusinessType === BusinessDetailTypeEnum.SOLETRADER
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete ID Verification.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickIDVerificationModal"
          >Get Verified</Text
        ></Text
      >
      <Text
        v-if="
          activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode &&
          userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete ID & Business Verification.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickIDVerificationModal"
          >Get Verified</Text
        ></Text
      >
      <Text
        v-if="
          (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
            ? activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode
            : activeUserWorkstation?.user?.userBusinessDetails
                ?.residencyPostcode) &&
          (activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION ||
            activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.VERIFIED) &&
          !isUserProfileVerified
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete Your Trade Passport.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickManageTradePassportPreviewModal"
          >Manage Trade Passport</Text
        ></Text
      >
      <Text
        v-if="
          !isUserWorkstationIsDefault &&
          activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.VERIFIED &&
          isUserProfileVerified &&
          (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
            ? activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode
            : activeUserWorkstation?.user?.userBusinessDetails
                ?.residencyPostcode) &&
          userWorkStationStatus === UserVerificationStatusEnum.NOT_ADDED
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete Workstation Verification.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickSubmitVerificationPreviewModal"
          >Get Verified</Text
        ></Text
      >
      <Text
        v-if="
          !isUserWorkstationIsDefault &&
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION &&
          isUserProfileVerified &&
          (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
            ? activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode
            : activeUserWorkstation?.user?.userBusinessDetails
                ?.residencyPostcode)
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Verification Pending.
      </Text>
    </div>

    <!-- LOADER -->
    <div
      v-if="isWorkstationLoading"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[600px] md:!tw-mt-[301px]"
    >
      <Loader :show="isWorkstationLoading" />
    </div>

    <!-- BANNER -->
    <Card
      v-else
      class="!tw-max-h-[unset] tw-w-full !tw-py-6 !tw-px-4 rmd:!tw-py-8 rmd:!tw-px-6 tw-box-border"
    >
      <template #content>
        <!-- <v-skeleton-loader
          v-if="initialiazing"
          type="list-item-two-line"
        ></v-skeleton-loader> -->
        <main class="tw-w-full">
          <!-- WELCOME BANNER -->
          <section
            v-if="isUserWorkstationIsDefault"
            class="tw-w-full tw-flex tw-flex-col tw-gap-2 rmd:tw-flex-row rmd:tw-items-center rmd:tw-justify-between"
          >
            <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
              <Text class="rlg:!tw-text-[28px]" variant="h6" textWeight="400"
                >Welcome to your iknowa HQ,
                <Text class="rlg:!tw-text-[28px]" variant="h6">{{
                  userName
                }}</Text></Text
              >
              <Text
                class="rlg:!tw-text-sm"
                variant="span"
                textWeight="400"
                textColor="rgba(12, 15, 74, 0.5)"
                >This is your personal profile. Create a workstation to get
                started with the best suited option for you.</Text
              >
            </div>
            <Button
              class="!tw-w-[auto]"
              variant="accent"
              label="CREATE A Trade WORKSTATION"
              @click="onClickToggleTradesWorkStation"
            ></Button>
          </section>

          <!-- PROFILE DETAILS BANNER -->
          <section
            v-else
            class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-4 tw-justify-start rmd:tw-flex-row rmd:tw-justify-between"
          >
            <div
              class="tw-w-full tw-flex tw-items-center tw-gap-3 rmd:tw-gap-2"
            >
              <ProfileThumbnail
                class="ws-profile-logo"
                v-if="userWorkStationImage"
                :src="userWorkStationImage"
                :isVerified="
                  activeUserWorkstation?.verificationStatus ===
                  UserVerificationStatusEnum.VERIFIED
                "
                :showVerificationIcon="true"
              />
              <div
                v-else
                class="tw-rounded-full tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
              >
                <ChipState
                  bgColor="#FFF"
                  :hasShadow="true"
                  class="tw-absolute tw-top-[-.3rem] tw-right-[-.4rem] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
                >
                  <template #prefix>
                    <div>
                      <CheckGreenShield
                        v-if="
                          activeUserWorkstation?.verificationStatus ===
                          UserVerificationStatusEnum.VERIFIED
                        "
                      />
                      <ErrorRedIcon v-else />
                    </div>
                  </template>
                </ChipState>
                <UserProfileLogo class="ws-profile-logo" />
              </div>
              <div class="tw-flex tw-flex-col tw-items-start">
                <div class="tw-flex tw-items-center tw-gap-2">
                  <Text class="rmd:!tw-text-2xl" variant="h6">{{
                    activeUserWorkstation?.name
                  }}</Text>
                  <ChipState
                    class="tw-border-solid tw-border-[1px] tw-border-[#0C0F4A] tw-cursor-pointer tw-h-[25px] md:tw-hidden"
                    label="VIEW TRADE PASSPORT"
                    textColor="#0C0F4A"
                    :isDense="true"
                    @click="onClickManageTradePassport"
                  ></ChipState>
                </div>
                <div
                  class="tw-flex tw-items-start tw-gap-0 rmd:tw-gap-1"
                  v-if="
                    getWorkstationAddress(
                      activeUserWorkstation?.user?.userBusinessDetails?.type
                    ) &&
                    getWorkstationPostcode(
                      activeUserWorkstation?.user?.userBusinessDetails?.type
                    )
                  "
                >
                  <Text
                    class="!tw-text-[10px] !tw-leading-4 !tw-mt-1.5"
                    textWeight="400"
                    textColor="#0C0F4A"
                    whiteSpace="pre-wrap"
                    >{{
                      getWorkstationAddress(
                        activeUserWorkstation?.user?.userBusinessDetails?.type
                      )
                    }}</Text
                  >
                  <Postcode
                    :value="
                      getWorkstationPostcode(
                        activeUserWorkstation?.user?.userBusinessDetails?.type
                      )
                    "
                  />
                </div>
                <div
                  v-else-if="getUserWorkStationFieldAccess"
                  @click="onClickAddress"
                  class="tw-flex tw-gap-1 tw-items-center tw-cursor-pointer"
                >
                  <Text textColor="#FFA500" variant="p">+</Text>
                  <Text textColor="#FFA500" variant="span">Add Address</Text>
                </div>
              </div>
            </div>

            <ChipState
              class="tw-border-solid tw-border-[1px] tw-border-[#0C0F4A] tw-cursor-pointer tw-h-[25px] tw-hidden md:tw-block md:tw-ml-auto"
              label="VIEW TRADE PASSPORT"
              textColor="#0C0F4A"
              :isDense="true"
              @click="onClickManageTradePassport"
            ></ChipState>
            <div
              class="tw-flex tw-flex-col tw-w-full tw-gap-2 tw-items-center tw-justify-center tw-relative rsm:tw-items-end rsm:tw-w-[auto] md:tw-hidden"
            >
              <SiltPendingChip
                v-if="
                  WorkStationSiltVerificationStatus &&
                  getVerificationStatus.statusClass !== 'success'
                "
                :label="getVerificationStatus.label"
              />
              <SiltVerifiedChip
                v-if="
                  WorkStationSiltVerificationStatus &&
                  getVerificationStatus.statusClass === 'success'
                "
                :label="getVerificationStatus.label"
              />
              <ProfileVerificationStatusChip
                v-if="
                  activeUserWorkstation?.verificationStatus !==
                  UserVerificationStatusEnum.VERIFIED
                "
                class="tw-hidden rmd:tw-flex"
                :variant="userWorkstationStatusByWord"
              />
              <!-- <TradePassportActionChip
                class="!tw-w-full"
                @click="onClickManageTradePassport"
              />
              <Text
                v-if="
                  userWorkStationStatus === UserVerificationStatusEnum.VERIFIED
                "
                class="!tw-text-[10px] tw-cursor-pointer rmd:tw-absolute rmd:tw-right-0 rmd:tw-bottom-[-1.8rem]"
                textColor="#000"
                whiteSpace="nowrap"
                >Looking To Upskill?
                <Text
                  @click="navigateToCourse"
                  class="!tw-text-[10px]"
                  textColor="#FFA500"
                  whiteSpace="nowrap"
                  >Explore Our Courses</Text
                ></Text
              > -->
            </div>
          </section>
        </main>
      </template>
    </Card>

    <!-- FREE/DEFAULT DASHBOARD -->
    <section
      v-if="
        isUserWorkstationIsDefault &&
        activeUserWorkstation &&
        !isWorkstationLoading
      "
      class="dashboard-setting-up__custom tw-w-full tw-flex tw-flex-col tw-gap-6 rxl:tw-flex-row"
    >
      <!-- LEFT CONTAINER -->
      <div
        class="tw-w-full tw-flex tw-flex-col tw-h-full tw-max-w-[300] rxl:tw-max-w-[453px] rxl:tw-min-w-[453px]"
      >
        <!-- TRADE PASSPORT STATUS -->

        <v-card class="custom-card c-trade-card">
          <div class="c-trade-card__header">
            <div class="c-trade-card__logo">
              <img
                src="../../../assets/images/iknowa_logo.svg"
                width="106"
                height="24"
              />
              <span class="underline" />
            </div>
            <div class="c-trade-card__author">
              <div class="c-trade-card__image">
                <div class="c-trade-card__image-inner">
                  <img
                    src="../../../assets/images/contractor-profile.png"
                    width="52"
                    height="52"
                  />
                </div>
                <div class="c-trade-card__status-indicator"></div>
              </div>

              <div class="c-trade-card__author-name">
                <h2>Toby Sparks</h2>
              </div>

              <div class="c-trade-card__address">
                <p>44 Treeline Court, Birmingham</p>
                <p><v-icon icon="mdi-map-marker" /> W12 9RE</p>
              </div>
            </div>
            <div class="c-trade-card__tags">
              <div class="c-trade-card__tag"><p>TRADE PASSPORT PREVIEW</p></div>
              <div class="c-trade-card__status">
                <v-icon icon="mdi-check-circle" color="#1FB27A"></v-icon>
                <p>KYC Verified</p>
              </div>
            </div>
          </div>
          <div class="c-trade-card__info">
            <div class="c-trade-card__info-title">
              <p>Specialisms</p>
            </div>

            <div class="c-trade-card__icon-text-list">
              <div class="c-trade-card__icon-text">
                <div class="c-trade-card__icon-text-icon">
                  <img
                    src="@/assets/images/electrician.svg"
                    width="12"
                    height="18"
                  />
                </div>
                <p>Electrician</p>
              </div>
              <div class="c-trade-card__icon-text">
                <div class="c-trade-card__icon-text-icon">
                  <img
                    src="@/assets/images/plumber.svg"
                    width="12"
                    height="18"
                  />
                </div>
                <p>Plumber</p>
              </div>
              <div class="c-trade-card__icon-text">
                <div class="c-trade-card__icon-text-icon">
                  <img src="@/assets/images/gas.svg" width="12" height="18" />
                </div>
                <p>Gas & Heating Engineer</p>
              </div>
              <div class="c-trade-card__icon-text">
                <div class="c-trade-card__icon-text-icon">
                  <img
                    src="@/assets/images/builder.svg"
                    width="12"
                    height="18"
                  />
                </div>
                <p>Builder</p>
              </div>
            </div>
          </div>
          <div class="c-trade-card__info">
            <div class="c-trade-card__info-title">
              <p>Certifications</p>
            </div>
            <div class="c-trade-card__info-inner">
              <div class="c-trade-card__info-item">
                <p><strong>Plumbing & Heating</strong></p>
                <p>Level 3 (BPEC)</p>
              </div>
              <div class="c-trade-card__info-item">
                <p><strong>Electrical Installation</strong></p>
                <p>NVQ Level 3</p>
              </div>
            </div>
            <!-- <div class="c-trade-card__info-inner">
              <div class="c-trade-card__info-item">
                <p><strong>Trust Mark</strong></p>
                <p>NVQ Level 3</p>
              </div>
              <div class="c-trade-card__info-item">
                <p><strong>Gas Safe</strong></p>
                <p>Level 3 (CENWAT)</p>
              </div>
            </div> -->
          </div>
          <div class="c-trade-card__info">
            <div class="c-trade-card__info-title">
              <p>Insurances</p>
            </div>
            <div class="c-trade-card__info-inner">
              <div class="c-trade-card__info-item">
                <p><strong>Public Liability</strong></p>
                <p>Coverage: £5,000,000.00</p>
                <p>Expiry: 15 Oct 2024</p>
              </div>
              <div class="c-trade-card__info-item">
                <p><strong>Professional Indemnity</strong></p>
                <p>Coverage: £10,000,000.00</p>
                <p>Expiry: 31 Dec 2024</p>
              </div>
            </div>
            <!-- <div class="c-trade-card__info-inner">
              <div class="c-trade-card__info-item">
                <p><strong>Employer Liability</strong></p>
                <p>Coverage: £5,000,000.00</p>
                <p>Expiry: 15 Oct 2024</p>
              </div>
              <div class="c-trade-card__info-item">
                <p><strong>Structural Warranty</strong></p>
                <p>Coverage: £10,000,000.00</p>
                <p>Expiry: 31 Dec 2024</p>
              </div>
            </div> -->
          </div>
          <!-- <div class="c-trade-card__info">
            <div class="c-trade-card__info-title">
              <p>Tax Details</p>
            </div>
            <div class="c-trade-card__info-inner">
              <div class="c-trade-card__info-item">
                <p>
                  <strong>VAT</strong>
                  <v-icon icon="mdi-check-circle" color="#1FB27A"></v-icon>
                </p>
                <p>GB982675</p>
              </div>
              <div class="c-trade-card__info-item">
                <p>
                  <strong>National Insurance Number</strong>
                  <v-icon icon="mdi-check-circle" color="#1FB27A"></v-icon>
                </p>
                <p>QQ123456B</p>
              </div>
            </div>
          </div> -->
          <v-btn
            @click="onClickBenefitsButton"
            class="button button-orange c-trade-card__button"
            >Benefits</v-btn
          >
          <div class="c-trade-card__footer">
            <p>Powered by</p>
            <div class="c-trade-card__footer-image">
              <img
                src="../../../assets/images/iknowa_logo.svg"
                width="106"
                height="24"
              />
            </div>
          </div>
        </v-card>

        <!-- <v-skeleton-loader
          v-if="initialiazing"
          class="tw-w-full !tw-shadow-none"
          elevation="2"
          type="card-avatar, article"
          boilerplate
        ></v-skeleton-loader> -->
        <!-- <v-card class="custom-card"> -->
        <!-- <div class="card-logo-header">
            <div class="card-logo-header__wrap">
              <img
                class="site-logo-img"
                src="../../../assets/images/iknowa_logo.svg"
                width="106"
                height="24"
              />
              <span class="under-line" />
            </div>
            <div class="card-logo-header__tags">
              <label class="tag-item">TRADE PASSPORT</label>
              <label
                v-if="activeUserWorkstation?.name !== 'Default'"
                :class="[
                  'kyc-item',
                  `kyc-item__${getVerificationStatus.statusClass}`,
                ]"
              >
                <v-icon :icon="getVerificationStatus.icon"></v-icon>
                {{ getVerificationStatus.label }}
              </label>
            </div>
          </div>
          <div class="profile-wrap common-profile-view card-part">
            <div class="profile-content">
              <div class="img-wrap">
                <img
                  v-if="userWorkStationImage"
                  :src="userWorkStationImage"
                  width="150"
                  height="150"
                  alt="userWorkStationImage"
                />
                <UserProfileLogo v-else />
              </div>
              <div class="right__wrapper">
                <div class="content">
                  <h3 class="dashboard-verify-profile__name">
                    {{ showTradeName }}
                  </h3>
                  <v-btn
                    class="button button-orange"
                    v-if="
                      !WorkStationSiltVerificationStatus &&
                      activeUserWorkstation?.name !== 'Default'
                    "
                    @click="onClickOpenModel(modelName.VERIFYMODAL)"
                    >Verify</v-btn
                  >
                </div>
                <div class="verify__btn">
                  <div
                    class="dashboard-address"
                    v-if="
                      workstationAddress(user?.userBusinessDetails?.type) !==
                      'null null null'
                    "
                  >
                    <span>{{
                      workstationAddress(user?.userBusinessDetails?.type)
                    }}</span>
                  </div> -->
        <!-- <div
                          class="dashboard-address edit__address"
                          v-if="activeUserWorkstation?.name !== 'Default'"
                          @click="onClickAddress"
                        >
                          <v-icon icon="mdi-map-marker" />
                          <span>Add Address</span>
                        </div> -->
        <!-- <div class="area_zipcode" v-if="userAddressPostCode">
                    <v-icon icon="mdi-map-marker" />
                    <span>{{ userAddressPostCode }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template
            v-if="userWorkStationStatus !== UserVerificationStatusEnum.VERIFIED"
          >
            <div class="card-part insurance_wrapper">
              <p class="card-part__title common-title">Insurances</p>
              <div class="insurances-list" v-if="userInsurances.length">
                <template v-for="(insurance, index) in userInsurances">
                  <ItemListCard
                    v-if="index < 2"
                    :key="insurance.id"
                    :isShowEditButton="true"
                    :isShowDeleteButton="true"
                    :onClickDelete="() => onDeleteInsurance(insurance.id)"
                    :onClickEdit="() => onEditInsurance(insurance)"
                  >
                    <template v-slot:listItemCardTitle>
                      {{
                        insurance?.metadata?.insuranceName ||
                        insurance?.insurance?.name
                      }}
                      <div
                        class="prefix"
                        v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                      >
                        <v-icon>
                          <img src="../../../assets/icons/exclaimation.svg" />
                        </v-icon>
                        Expired
                      </div>
                    </template>
                    <template v-slot:listItemCardLabel
                      >Coverage: £{{ insurance?.metadata?.sum }}</template
                    >
                    <template v-slot:listItemCardText
                      >Expiry: {{ insurance?.metadata?.expiryDate }}</template
                    >
                  </ItemListCard>
                </template>
                <div v-if="userInsurances.length > 2" class="more__data">
                  {{ `+${userInsurances.length - 2} More` }}
                </div>
              </div>
              <label
                class="yellow-text btn-yellow-hover"
                @click="onClickOpenModel(modelName.INSURANCE)"
              >
                Add Insurance Details<v-icon icon="mdi-chevron-right"
              /></label>
            </div>
            <div class="card-part specialism_wrapper">
              <p class="card-part__title common-title">Specialisms</p>
              <div
                class="card-service-icon-list custom-border"
                v-if="filterUserSpecialisms.length"
              >
                <template v-for="(skill, index) in filterUserSpecialisms">
                  <SpecialismItem
                    class="!tw-max-w-[55px]"
                    :key="skill.id"
                    v-if="index < 6"
                    :iconName="skill.iconName"
                    :label="skill.label"
                  />
                </template>
              </div>
              <div v-if="filterUserSpecialisms.length > 6" class="more__data">
                {{ `+${filterUserSpecialisms.length - 6} More` }}
              </div>
              <label
                class="yellow-text btn-yellow-hover"
                @click="onClickOpenModel(modelName.SPECIALISM)"
              >
                {{ filterUserSpecialisms.length ? "Update" : "Add" }}
                Specialisms<v-icon icon="mdi-chevron-right"
              /></label>
            </div>

            <div class="card-part certificate_wrapper">
              <p class="card-part__title common-title">Certifications</p>
              <div class="insurances-list">
                <template v-for="(certificate, index) in userCertificates">
                  <ItemListCard
                    v-if="index < 2"
                    :key="certificate.id"
                    :isShowLogo="true"
                    :isShowEditButton="true"
                    :onClickDelete="() => deleteCertificate(certificate.id)"
                    :onClickEdit="() => onEditCertificate(certificate)"
                  >
                    <template v-slot:listItemLogo>
                      <div class="logo_wrapper">
                        <img src="../../../assets/icons/tools.svg" alt="" />
                      </div>
                    </template>
                    <template v-slot:listItemCardTitle>{{
                      certificate?.certificate?.name
                    }}</template>
                    <template v-slot:listItemCardLabel>{{
                      certificate?.metadata?.registrationNumber
                    }}</template>
                    <template v-slot:listItemCardText
                      >{{ certificate?.metadata?.url }}
                      <div v-if="certificate?.attachment.length">
                        {{ certificate?.attachment[0]?.originalName }}
                      </div>
                    </template>
                  </ItemListCard>
                </template>
                <div
                  v-if="userCertificates.length > 2"
                  class="more_certificate_wrapper"
                >
                  {{ `+${userCertificates.length - 2} More` }}
                </div>
              </div>
              <label
                class="yellow-text btn-yellow-hover"
                @click="onClickOpenModel(modelName.CERTIFICATION)"
              >
                Add Certifications<v-icon icon="mdi-chevron-right"
              /></label>
            </div>
            <div class="card-part tax_wrapper">
              <p class="card-part__title common-title">Tax Details</p>
              <div class="insurances-list">
                <div
                  class="insurances-list__item"
                  v-if="user?.userBusinessDetails?.ukVatNumber"
                >
                  <div class="insurances-list__item-name">
                    <div class="title">
                      <h5>VAT</h5>
                      <v-icon icon="mdi-check-circle" />
                    </div>

                    <span> {{ user?.userBusinessDetails?.ukVatNumber }}</span>
                  </div>
                </div>
                <div
                  class="insurances-list__item"
                  v-if="user?.userBusinessDetails?.nationalInsuranceNumber"
                >
                  <div class="insurances-list__item-name">
                    <div class="title">
                      <h5>National Insurance Number</h5>
                      <v-icon icon="mdi-check-circle" />
                    </div>

                    <span class="nin_number">
                      <input
                        :type="isShowPassword ? 'text' : 'password'"
                        v-model="
                          user.userBusinessDetails.nationalInsuranceNumber
                        "
                      />
                      <div class="insurances-list__item-action">
                        <v-btn
                          icon="mdi-eye"
                          variant="text"
                          color="#0c0f4a80"
                          v-if="isShowPassword"
                          @click="isShowPassword = !isShowPassword"
                        />
                        <v-btn
                          icon="mdi-eye-off"
                          variant="text"
                          color="#0c0f4a80"
                          v-if="!isShowPassword"
                          @click="isShowPassword = !isShowPassword"
                        />
                      </div>
                    </span>
                  </div> -->
        <!-- <div class="insurances-list__item-action">
                          <v-btn
                            icon="mdi-eye"
                            variant="text"
                            color="#0c0f4a80"
                          />
                        </div> -->
        <!-- </div>
              </div>
              <label
                class="yellow-text btn-yellow-hover"
                @click="onClickOpenModel(modelName.TAXANDVAT, 'Edit')"
              >
                Add Tax & VAT details<v-icon icon="mdi-chevron-right"
              /></label>
            </div>
          </template>
          <template
            v-if="userWorkStationStatus === UserVerificationStatusEnum.VERIFIED"
          >
            <div class="dashboard-card-service-icon-list">
              <p class="card-part__title common-title">Specialisms</p>
              <div
                class="card-service-icon-list custom-border"
                v-if="filterUserSpecialisms.length"
              >
                <template v-for="(skill, i) in filterUserSpecialisms">
                  <div
                    class="card-service-icon-list__item"
                    v-if="i < 6"
                    :key="skill.id"
                  >
                    <div class="servive-btn">
                      <v-btn class="ma-2">
                        <v-icon v-if="skill.iconName" class="list_content_btn">
                          <img
                            class="service-img"
                            :src="
                              require('@/assets/category-icons/' +
                                skill.iconName +
                                '.svg')
                            "
                            alt="icon"
                          />
                        </v-icon>
                      </v-btn>
                    </div>
                    <span>{{ skill.label }}</span>
                  </div>
                </template>
              </div>
              <div v-if="filterUserSpecialisms.length > 6" class="more__data">
                {{ `+${filterUserSpecialisms.length - 6} More` }}
              </div>
            </div>
            <div class="sole_accreditations-card">
              <p class="card-part__title common-title">Certifications</p>
              <div
                class="accreditations-card__list dashboard-accreditations-card__list"
                v-if="userCertificates.length"
              >
                <template v-for="(certificate, i) in userCertificates">
                  <div class="accreditations-card__item" v-if="i < 1" :key="i">
                    <div class="accreditations-card__img">
                      <v-icon
                        ><img src="../../../assets/icons/tools.svg" alt=""
                      /></v-icon>
                    </div>
                    <div class="accreditations-card__text">
                      <div class="accreditations-card__title">
                        <h5 v-if="certificate?.certificate?.name">
                          {{ certificate?.certificate?.name }}
                        </h5>
                        <p v-if="certificate?.metadata?.registrationNumber">
                          {{ certificate?.metadata?.registrationNumber }}
                        </p>
                        <span v-if="certificate?.metadata?.url">{{
                          certificate?.metadata?.url
                        }}</span>
                      </div>
                      <div
                        v-if="certificate?.attachment.length"
                        class="accreditations-card__subtext"
                      >
                        <span>{{
                          certificate?.attachment[0]?.originalName
                        }}</span>
                      </div>
                      <div class="accreditations-card__item-action">
                        <v-btn
                          icon="mdi-pencil"
                          variant="text"
                          color="#80829F"
                          @click="onEditCertificate(certificate)"
                        />
                        <v-btn
                          icon="mdi-delete"
                          variant="text"
                          color="#80829F"
                          class="delete__btn"
                          @click="deleteCertificate(certificate.id)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="access__dashboard-accreditations-card__list"
                v-if="userCertificates.length > 1"
              >
                + {{ userCertificates.length - 1 }} More Certificates
              </div>
            </div>

            <div class="blue-text" @click="onClickTradePassport()">
              Explore Your Trade Passport<v-icon icon="mdi-chevron-right" />
            </div>
          </template>
        </v-card> -->
      </div>
      <!-- RIGHT CONTAINER -->
      <div class="tw-flex tw-flex-col tw-gap-6 tw-w-full tw-h-full">
        <!-- PROGRESS DETAILS -->
        <!-- <v-skeleton-loader
          v-if="initialiazing"
          class="tw-w-full !tw-shadow-none"
          elevation="2"
          type="article"
          boilerplate
        ></v-skeleton-loader> -->
        <!-- v-if="
            !(
              userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
              userWorkStationStatus === UserVerificationStatusEnum.VERIFIED
            ) &&
            userAllWorkStation &&
            userAllWorkStation.length < 2
          " -->
        <v-col
          v-if="userAllWorkStation && userAllWorkStation.length < 2"
          class="dashboard-setting-up__right tw-w-full !tw-p-0"
        >
          <v-card
            class="custom-card"
            :class="{
              'fixed-height':
                userWorkStationStatus === UserVerificationStatusEnum.VERIFIED,
            }"
          >
            <div class="icon-title">
              <div class="icon-title__inner">
                <v-icon icon="mdi-star" />
                <p class="common-title">Setting Up Your iknowa Workstation</p>
              </div>
            </div>
            <!-- <p class="body-text">
              Welcome, it&apos;s great to see you - here&apos;s the best things
              on iknowa right now!
            </p> -->
            <div
              class="progress-list !tw-m-0"
              v-if="
                userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
                userWorkStationStatus !== UserVerificationStatusEnum.VERIFIED
              "
            >
              <div
                class="progress-list__item !tw-mt-0"
                :class="{
                  completed: !isUserWorkstationIsDefault,
                }"
              >
                <div class="progress-list__counter">
                  <span>1</span>
                </div>
                <div class="progress-list__text">
                  <p
                    @click="onClickToggleTradesWorkStation"
                    class="common-title"
                  >
                    Create Workstation
                  </p>
                  <span>Create a Workstation, to access courses and jobs.</span>
                </div>
              </div>
            </div>
            <!-- <div
              class="progress-list"
              v-if="
                userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
                userWorkStationStatus === UserVerificationStatusEnum.VERIFIED
              "
            >
              <div class="progress-list__item completed">
                <div class="progress-list__counter">
                  <span>1</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Explore Your Trade Passport</p>
                  <span>Everything under one roof</span>
                </div>
              </div>
              <div
                class="progress-list__item"
                :class="{
                  completed: !isUserWorkstationIsDefault,
                }"
              >
                <div class="progress-list__counter">
                  <span>2</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Create a workstation</p>
                  <span>xxx</span>
                </div>
              </div>
              <div class="progress-list__item">
                <div class="progress-list__counter">
                  <span>3</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Check out new opportunities</p>
                  <span>Earn a new accredited qualification</span>
                </div>
              </div>
              <div class="progress-list__item">
                <div class="progress-list__counter">
                  <span>4</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Find new jobs</p>
                  <span>xxx</span>
                </div>
              </div>
              <div class="progress-list__item">
                <div class="progress-list__counter">
                  <span>5</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Take a tour of the dashboard</p>
                  <span>xxx</span>
                </div>
              </div>
            </div> -->
            <!-- <div
              v-if="
                userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
                userWorkStationStatus !== UserVerificationStatusEnum.VERIFIED
              "
            >
              <div
                class="progress-list__item"
                :class="{
                  completed: !isUserWorkstationIsDefault,
                }"
              >
                <div class="progress-list__counter">
                  <span>1</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Create Workstation</p>
                  <span>Create a Workstation, to access courses and jobs.</span>
                </div>
              </div>
              <div
                class="progress-list__item"
                :class="{
                  completed: isUserProfileVerified && isUserTaxDetailsAdded,
                }"
              >
                <div class="progress-list__counter">
                  <span>2</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Assign Directors</p>
                  <span>Everything under one roof</span>
                </div>
              </div>
              <div
                class="progress-list__item"
                :class="{
                  completed: isUserProfileVerified && isUserTaxDetailsAdded,
                }"
              >
                <div class="progress-list__counter">
                  <span>3</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Invite Teammates</p>
                  <span>Everything under one roof</span>
                </div>
              </div>
              <div
                class="progress-list__item"
                :class="{
                  completed: isUserProfileVerified && isU
                  serTaxDetailsAdded,
                }"
              >
                <div class="progress-list__counter">
                  <span>4</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Add Company Information</p>
                  <span>Everything under one roof</span>
                </div>
              </div>
              <div
                class="progress-list__item"
                :class="{
                  completed: isUserProfileVerified && isUserTaxDetailsAdded,
                }"
              >
                <div class="progress-list__counter">
                  <span>5</span>
                </div>
                <div class="progress-list__text">
                  <p class="common-title">Submit Company for Verificaton</p>
                  <span>Everything under one roof</span>
                </div>
              </div>
            </div> -->
          </v-card>
        </v-col>

        <!-- MANAGE WORKSTATIONS -->
        <!-- <v-skeleton-loader
          v-if="initialiazing"
          class="tw-w-full !tw-shadow-none"
          elevation="2"
          type="article"
          boilerplate
        ></v-skeleton-loader> -->
        <Card
          class="!tw-max-h-[unset] !tw-min-h-[336px] tw-w-full tw-box-border"
        >
          <template #content>
            <main
              class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
            >
              <section
                class="tw-w-full tw-flex tw-items-center tw-justify-between tw-py-5 tw-px-4 tw-box-border tw-border-b-solid-custom"
              >
                <div class="tw-flex tw-items-center tw-gap-4">
                  <Text variant="h6">Workstations</Text>
                </div>
              </section>

              <!-- WORKSTATION FILLED STATE -->
              <section
                class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-5 tw-px-4 tw-box-border tw-overflow-auto !tw-max-h-[300px] !tw-min-h-[300px]"
                v-if="
                  filteredUserAllWorkStation.length > 0 ||
                  WorkStationInvitation.length > 0
                "
              >
                <div
                  v-if="WorkStationInvitation.length > 0"
                  class="tw-flex tw-flex-col tw-gap-4 tw-border-b tw-border-0 tw-border-dashed tw-border-[#C5D2F2] tw-pb-5"
                >
                  <div
                    class="tw-w-full tw-flex tw-justify-between tw-items-center"
                    v-for="(member, index) in WorkStationInvitation"
                    :key="index"
                  >
                    <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
                      <!-- profile -->
                      <img
                        class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                        v-if="InviteMemberImage"
                        :src="InviteMemberImage"
                      />
                      <WorkStationProfileLogo
                        class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                        v-else
                        :WSName="member?.userWorkstation?.name"
                      />
                      <!-- details -->
                      <div
                        class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap tw-w-[calc(100%_-_50px)] tw-max-w-full"
                      >
                        <div class="tw-flex tw-flex-col tw-item-start">
                          <div
                            class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                          >
                            <Text variant="h6">{{
                              member?.userWorkstation?.name
                            }}</Text>
                            <div
                              class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                            >
                              {{ member?.role }}
                            </div>
                            <div
                              class="tw-flex"
                              v-for="tag in parseJSON(member?.tags)"
                              :key="tag"
                            >
                              <div
                                class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                              >
                                {{ tag }}
                              </div>
                            </div>
                            <!-- <SignInChip /> -->
                          </div>
                          <div class="tw-flex tw-items-start tw-flex-col">
                            <div
                              class="tw-flex tw-items-start tw-flex-col tw-gap-2"
                            >
                              <Text
                                variant="span"
                                textWeight="600"
                                textColor="rgba(12, 15, 74, 0.5)"
                                >{{ member?.user?.email }}</Text
                              >
                              <!-- <Text
                          variant="span"
                          textWeight="600"
                          textColor="#4B4BFF"
                          >+2 more</Text
                        > -->
                            </div>
                            <Text
                              v-if="member?.message"
                              variant="span"
                              textWeight="600"
                              textColor="rgba(12, 15, 74, 0.5)"
                              >{{ member?.message }}</Text
                            >

                            <Text
                              class="!tw-text-[10px] !tw-leading-none !tw-text-darkBlue/50"
                              textWeight="400"
                              >Last signed in:
                              {{
                                member?.user?.lastLoginAt
                                  ? formatDate(
                                      member?.user?.lastLoginAt,
                                      "fullDate"
                                    )
                                  : "NA"
                              }}</Text
                            >
                          </div>
                        </div>

                        <div
                          class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                          v-if="
                            member?.invitationStatus ===
                            MemberInvitationStatusEnum.PENDING
                          "
                        >
                          <v-btn
                            :loading="
                              deleteInvitedWorkstationLoader &&
                              deleteInvitedWorkstationId === member?.id
                            "
                            class="tw-px-[8px] tw-py-[6px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                            @click="onClickRejectWSInvitation(member?.id)"
                          >
                            <InfoIconWhite />
                            <span class="tw-ml-[3px]">Reject</span>
                          </v-btn>
                          <v-btn
                            :loading="
                              acceptInvitationLoader &&
                              acceptInvitationWorkstationId === member?.token
                            "
                            class="tw-px-[8px] tw-py-[6px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#1FB27A] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                            @click="onClickAcceptWSInvitation(member?.token)"
                          >
                            <CheckIconWhite />
                            <span class="tw-ml-[3px]">Accept</span>
                          </v-btn>
                          <!-- <AcceptChip /> -->
                          <!-- <MyWorkstationChip /> -->
                        </div>
                        <!-- <div
                          class="tw-flex tw-items-center tw-gap-2"
                          v-if="
                            member?.invitationStatus ===
                            MemberInvitationStatusEnum.ACCEPTED
                          "
                        >
                          <v-btn
                            class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                            @click="
                              onWorkStationClick(member?.userWorkstation?.id)
                            "
                          >
                            launch <LunchIcon class="tw-ml-2" />
                          </v-btn>
                          <v-btn
                            class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                            @click="onClickRejectWSInvitation(member?.id)"
                          >
                            Delete
                            <TrashIcon
                              class="tw-ml-2"
                              fill="#FFF"
                              fillOpacity="1"
                            />
                          </v-btn> -->
                        <!-- <MyWorkstationChip /> -->
                        <!-- </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- User Workstation -->
                <div
                  class="tw-max-h-[350px] tw-w-full tw-flex tw-flex-col tw-gap-3 tw-justify-between"
                >
                  <div
                    class="tw-w-full tw-flex tw-items-center md:!tw-pb-4 md:!tw-box-border"
                    v-for="(member, index) in filteredUserAllWorkStation"
                    :key="index"
                  >
                    <div
                      class="tw-w-full tw-flex tw-items-start rmd:!tw-items-center tw-gap-2"
                    >
                      <!-- profile -->
                      <img
                        v-if="member?.profileImage"
                        :src="`https://storage.googleapis.com/idgc-b10/${member?.profileImage}`"
                        class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                      />
                      <WorkStationProfileLogo
                        class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                        v-else
                        :WSName="member?.name"
                      />
                      <!-- details -->
                      <div
                        class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap tw-w-[calc(100%_-_50px)] tw-max-w-full"
                      >
                        <div class="tw-flex tw-flex-col tw-item-start">
                          <div
                            class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                          >
                            <Text variant="h6">{{ member?.name }}</Text>
                            <div
                              v-if="member?.role"
                              class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                            >
                              {{ member?.role }}
                            </div>
                            <div
                              v-else
                              class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                            >
                              Admin
                            </div>
                            <div
                              class="tw-flex"
                              v-for="tag in parseJSON(member?.tags)"
                              :key="tag"
                            >
                              <div
                                class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                              >
                                {{ tag }}
                              </div>
                            </div>
                            <!-- <SignInChip /> -->
                          </div>
                          <div class="tw-flex tw-items-start tw-flex-col">
                            <div
                              class="tw-flex tw-items-start tw-flex-col tw-gap-2"
                            >
                              <Text
                                variant="span"
                                textWeight="600"
                                textColor="rgba(12, 15, 74, 0.5)"
                                >{{ member?.user?.email }}</Text
                              >
                              <!-- <Text
                          variant="span"
                          textWeight="600"
                          textColor="#4B4BFF"
                          >+2 more</Text
                        > -->
                            </div>
                            <Text
                              v-if="member?.message"
                              variant="span"
                              textWeight="600"
                              textColor="rgba(12, 15, 74, 0.5)"
                              >{{ member?.message }}</Text
                            >

                            <Text
                              class="!tw-text-[10px] !tw-leading-none !tw-text-darkBlue/50"
                              textWeight="400"
                              >Last signed in:
                              {{
                                member?.user?.lastLoginAt
                                  ? formatDate(
                                      member?.user?.lastLoginAt,
                                      "fullDate"
                                    )
                                  : "NA"
                              }}</Text
                            >
                          </div>
                        </div>

                        <div
                          class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                        >
                          <v-btn
                            class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                            @click="onWorkStationClick(member?.id)"
                          >
                            launch <LunchIcon class="tw-ml-2" />
                          </v-btn>
                          <v-btn
                            :loading="
                              deleteInvitedWorkstationLoader &&
                              deleteInvitedWorkstationId === member?.memberId
                            "
                            v-if="member?.isInvited"
                            class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                            @click="onClickRejectWSInvitation(member?.memberId)"
                          >
                            Delete
                            <TrashIcon
                              class="tw-ml-2"
                              fill="#FFF"
                              fillOpacity="1"
                            />
                          </v-btn>
                          <!-- <AcceptChip /> -->
                          <!-- <MyWorkstationChip /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- WORKSTATION EMPTY STATE -->
              <section
                class="tw-w-full tw-h-60 !tw-max-h-[300px] !tw-min-h-[245px]"
                v-else
              >
                <div
                  class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4"
                >
                  <h3 class="tw-text-center tw-max-w-[90%] tw-text-[#0C0F4A]">
                    Welcome to iknowa!
                  </h3>
                  <p class="tw-text-center tw-max-w-[90%] tw-text-[#0c0f4a80]">
                    Get started by estimating live jobs and enhancing your
                    skills to unlock access to courses. Create a workstation to
                    begin trading.
                  </p>
                  <Button
                    class="!tw-w-[auto]"
                    variant="accent"
                    label=" CREATE A Trade WORKSTATION "
                    @click="onClickToggleTradesWorkStation"
                  ></Button>
                </div>
              </section>
            </main>
          </template>
        </Card>

        <!-- MANAGE EMAILS -->
        <!-- <v-skeleton-loader
          v-if="initialiazing"
          class="tw-w-full !tw-shadow-none"
          elevation="2"
          type="article"
          boilerplate
        ></v-skeleton-loader> -->
        <Card
          class="!tw-max-h-[unset] !tw-min-h-[260px] tw-w-full tw-box-border"
        >
          <template #content>
            <main
              class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
            >
              <section
                class="tw-w-full tw-flex tw-items-center tw-justify-between tw-py-5 tw-px-4 tw-box-border tw-border-b-solid-custom"
              >
                <div class="tw-flex tw-items-center tw-gap-4">
                  <Text variant="h6">Manage Emails</Text>
                </div>
                <AddEmailActionChip @click="toggleAddEmailModal(true)" />
              </section>
              <section
                class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-5 tw-px-4 tw-box-border !tw-max-h-[300px] !tw-min-h-[300px]"
              >
                <div
                  class="tw-w-full tw-flex tw-items-start tw-justify-between tw-border-0 tw-border-b-[#C5D2F2] tw-border-b tw-border-dashed tw-pb-2"
                >
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <Text variant="h6">{{ userEmail }}</Text>
                  </div>
                </div>
                <div
                  class="tw-w-full tw-flex tw-items-start tw-justify-between tw-border-0 tw-border-b-[#C5D2F2] tw-border-b tw-border-dashed tw-pb-2"
                  v-for="email in manageEmailList"
                  :key="email.id"
                >
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <Text variant="h6">{{ email?.aliasEmail }}</Text>
                    <ApproveChip
                      v-if="email?.invitationStatus === 'accepted'"
                    />
                    <PendingChip v-if="email?.invitationStatus === 'pending'" />
                  </div>
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <DeleteActionChip @click="onClickDeleteEmail(email)" />
                  </div>
                </div>
              </section>
            </main>
          </template>
        </Card>
      </div>
    </section>

    <!-- SOLETRADER OR COMPANY DASHBOARD -->
    <section
      v-if="
        !isUserWorkstationIsDefault &&
        activeUserWorkstation &&
        !isWorkstationLoading
      "
      class="workstation-dashboard tw-flex tw-flex-col tw-gap-6 tw-w-full tw-h-full"
    >
      <!-- WORKSTATION DETAILS -->
      <!-- <div class="tw-relative">
        <WorkStationVerificationLock
          v-if="
            userWorkStationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
          "
        />
        <v-expansion-panels v-model="tradePassportPanel">
          <v-expansion-panel
            class="workstation-details-card !tw-rounded-[10px]"
          >
            <v-expansion-panel-title
              ><Text variant="p">Workstation Trade Passport</Text>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="tw-border-t-2">
              <div
                class="tw-grid tw-grid-cols-1 tw-w-full tw-gap-4 rmd:tw-grid-cols-2 r2xl:!tw-grid-cols-4"
              >
                <SpecialismsCard
                  :specialisms="filterUserSpecialisms"
                  @on-add="onClickOpenModel(modelName.SPECIALISM)"
                  :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
                />
                <InsuranceCard
                  :userInsurances="userInsurances"
                  :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
                  @on-edit="onEditInsurance"
                  @on-delete="
                    onDeleteItem(
                      $event,
                      confirmDeleteInsuranceData.type,
                      confirmDeleteInsuranceData.label
                    )
                  "
                  @on-add="onClickOpenModel(modelName.INSURANCE)"
                />
                <CertificateCard
                  :userCertificates="userCertificates"
                  :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
                  @on-edit="onEditCertificate"
                  @on-delete="
                    onDeleteItem(
                      $event,
                      confirmDeleteCertificateData.type,
                      confirmDeleteCertificateData.label
                    )
                  "
                  @on-add="onClickOpenModel(modelName.CERTIFICATION)"
                />
                <RatesCard
                  :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
                  :user="user"
                  @on-open-modal="onClickOpenModel(modelName.RATEMODAL, 'Edit')"
                />
              </div>
              <v-divider class="tw-mt-8 tw-mb-2"></v-divider>
              <v-card-actions class="tw-border-t-2">
                <div
                  class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center rmd:tw-flex-row rmd:tw-justify-end"
                >
                  <Button
                    @click="onClickManageTradePassport"
                    class="!tw-w-auto !tw-px-3 tw-box-border !tw-text-[#4B4BFF]"
                    variant="text"
                    label="Manage Trade Passport"
                  >
                    <template #postfix>
                      <v-icon icon="mdi-chevron-right"></v-icon>
                    </template>
                  </Button>
                </div>
              </v-card-actions>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div> -->

      <div
        class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-gap-6 tw-box-border rlg:tw-grid-cols-2"
      >
        <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
          <div
            v-if="
              activeUserWorkstation?.verificationStatus !==
                UserVerificationStatusEnum.VERIFIED &&
              accountStatuses?.forms?.insurance !== 0 &&
              accountStatuses?.forms?.certification !== 0 &&
              accountStatuses?.forms?.skills !== 0
            "
            @click.capture="handleClick"
          >
            <v-col class="dashboard-setting-up__right tw-w-full !tw-p-0">
              <v-card
                class="custom-card"
                :class="{
                  'fixed-height':
                    userWorkStationStatus ===
                    UserVerificationStatusEnum.VERIFIED,
                }"
              >
                <div class="icon-title">
                  <div class="icon-title__inner">
                    <v-icon icon="mdi-star" />
                    <p class="common-title">
                      Setting Up Your iknowa Workstation
                    </p>
                  </div>
                </div>

                <div class="progress-wrap">
                  <p class="common-title">Your iknowa account setup</p>
                  <v-progress-linear
                    color="#40AE42"
                    :model-value="workstationSetupProgressNew"
                    height="8"
                    rounded
                  />
                </div>

                <div class="progress-list">
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: !isUserWorkstationIsDefault,
                    }"
                  >
                    <div class="progress-list__counter">
                      <span>1</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Create a Trade Workstation</p>
                      <span
                        >Access document storage, create calendar events &
                        invite others.</span
                      >
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: isUserAddBusinessAddress,
                    }"
                    @click="onClickAddBusinessAddressModal"
                  >
                    <div class="progress-list__counter">
                      <span>2</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Add Business Address</p>
                      <span>Build trust and legitimacy for your business.</span>
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed:
                        activeUserWorkstation?.user
                          ?.userKYCVerificationStatus ===
                          UserVerificationStatusEnum.VERIFIED ||
                        activeUserWorkstation?.user
                          ?.userKYCVerificationStatus ===
                          UserVerificationStatusEnum.PENDING_VERIFICATION,
                    }"
                    @click="onClickIDVerificationModal"
                    v-if="
                      userBusinessType ===
                      BusinessDetailTypeEnum.LIMITED_COMPANY
                    "
                  >
                    <div class="progress-list__counter">
                      <span>3</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">
                        Complete ID & Business Verification
                      </p>
                      <span>Increase clients trust & win more Jobs</span>
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed:
                        activeUserWorkstation?.user
                          ?.userKYCVerificationStatus ===
                          UserVerificationStatusEnum.VERIFIED ||
                        activeUserWorkstation?.user
                          ?.userKYCVerificationStatus ===
                          UserVerificationStatusEnum.PENDING_VERIFICATION,
                    }"
                    @click="onClickIDVerificationModal"
                    v-else
                  >
                    <div class="progress-list__counter">
                      <span>3</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Complete ID Verification</p>
                      <span>Increase clients trust & win more Jobs</span>
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: isUserProfileVerified,
                    }"
                    @click="onClickManageTradePassportPreviewModal"
                  >
                    <div class="progress-list__counter">
                      <span>4 </span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Complete Your Trade Passport</p>
                      <span
                        >Add Specialisms, Certificates, Insurances and other
                        details.</span
                      >
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed:
                        userWorkStationStatus ===
                          UserVerificationStatusEnum.PENDING_VERIFICATION ||
                        userWorkStationStatus ===
                          UserVerificationStatusEnum.VERIFIED,
                    }"
                    @click="onClickSubmitVerificationPreviewModal"
                  >
                    <div class="progress-list__counter">
                      <span>5</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">
                        Submit Your Workstation for Verification
                      </p>
                      <span>Access courses, jobs & trade network.</span>
                    </div>
                  </div>
                  <div
                    class="tw-w-full tw-mt-4 tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center rmd:tw-flex-row rmd:tw-justify-between"
                  >
                    <Button
                      v-if="
                        userWorkStationStatus !==
                        UserVerificationStatusEnum.VERIFIED
                      "
                      :isActive="
                        isUserProfileVerified &&
                        userWorkStationStatus !==
                          UserVerificationStatusEnum.PENDING_VERIFICATION
                      "
                      :label="
                        userWorkStationStatus ===
                        UserVerificationStatusEnum.PENDING_VERIFICATION
                          ? 'profile verification submitted'
                          : 'submit for verification'
                      "
                      class="!tw-w-[auto] !tw-max-w-[auto]"
                      @click="onClickSubmitVerification"
                    ></Button>
                  </div>
                </div>
              </v-card>
            </v-col>
          </div>
          <JobsOverviewWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-if="
              !(
                activeUserWorkstation?.verificationStatus !==
                  UserVerificationStatusEnum.VERIFIED &&
                accountStatuses?.forms?.insurance !== 0 &&
                accountStatuses?.forms?.certification !== 0 &&
                accountStatuses?.forms?.skills !== 0
              )
            "
          />
          <TeamWidget
            className="!tw-min-h-[229px]"
            :getUserWorkStationFieldAccess="isUserWorkStationAdmin"
          />
          <TradeNetworkWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
          />
        </div>
        <div
          class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 no-scroll tw-max-h-[1100px]"
        >
          <JobsOverviewWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-if="
              activeUserWorkstation?.verificationStatus !==
                UserVerificationStatusEnum.VERIFIED &&
              accountStatuses?.forms?.insurance !== 0 &&
              accountStatuses?.forms?.certification !== 0 &&
              accountStatuses?.forms?.skills !== 0
            "
          />
          <CalendarEventWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-if="
              activeUserWorkstation?.verificationStatus !==
                UserVerificationStatusEnum.VERIFIED &&
              accountStatuses?.forms?.insurance !== 0 &&
              accountStatuses?.forms?.certification !== 0 &&
              accountStatuses?.forms?.skills !== 0
            "
            :userWorkStationImage="userWorkStationImage"
            :calenderAttribute="calenderAttribute"
            className="tw-max-h-[727px] tw-overflow-auto tw-rounded-lg"
          />
          <CalendarEventWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-else
            :userWorkStationImage="userWorkStationImage"
            :calenderAttribute="calenderAttribute"
            className="!tw-h-full"
          />
        </div>
      </div>
    </section>
    <WorkStationVerificationLock
      v-if="
        userWorkStationStatus ===
          UserVerificationStatusEnum.PENDING_VERIFICATION &&
        !isWorkstationLoading
      "
    />
  </main>

  <AddEmailModal
    v-if="isShowAddEmailModal"
    @on-close="toggleAddEmailModal($event)"
    @onClickAdd="onClickAddEmail"
  />
  <EmailConformModal
    v-if="isShowAddEmailConformModal"
    :modalType="emailConfirmModalType"
    :manageEmail="manageEmail"
    @on-close="toggleAddEmailConfirmModal"
  />

  <v-dialog
    v-model="isShowWorkStationModal"
    width="968px"
    class="custom__modal__box soletrader__modal__box"
    height="836px"
    max-width="100%"
    persistent
  >
    <div class="previous_btn">
      <v-btn
        variant="text"
        aria-label="Refresh"
        class="back_btn"
        prepend-icon="mdi-chevron-left"
        @click="onClickToggleTradesWorkStation"
        >back</v-btn
      >
    </div>
    <main class="main">
      <header class="custom__modal__header">
        <img
          class="open-logo"
          src="../../../assets/images/logo/iknowa-logo-v4.svg"
        />
        <h4>Business Setup</h4>
      </header>
      <div class="custom__modal__content">
        <div
          class="custom__modal__heading tw-flex tw-flex-col tw-gap-3 rmd:tw-gap-4"
        >
          <div class="custom__modal__head">
            Great job, <span>{{ userName }}</span> <br />
            You are a step closer in creating your Trade Workstation!
          </div>
          <Text
            variant="h6"
            textWeight="400"
            textColor="rgba(12, 15, 74, 0.5)"
            lineHeight="18px"
            >So we can personalise your Workstation, please tell us what type of
            business do you have.</Text
          >
        </div>
        <div class="custom__modal__card">
          <v-card @click="onClickSoleTrader()" class="modal_card">
            <div class="icon_btn">
              <v-icon
                ><img src="../../../assets/icons/connections.svg"
              /></v-icon>
            </div>
            <div class="modal_content">
              <h4>Sole Trader</h4>
              <p>Trading as Individual</p>
            </div>
          </v-card>
          <v-card @click="onClickLimitedCompany()" class="modal_card">
            <div class="icon_btn">
              <v-icon
                ><img src="../../../assets/icons/building-filled-icon.svg"
              /></v-icon>
            </div>
            <div class="modal_content">
              <h4>Limited Company</h4>
              <p>Registered on Companies House</p>
            </div>
          </v-card>
        </div>
        <!-- </main> -->
      </div>
    </main>
  </v-dialog>
  <SoleTraderWorkStationComponent
    v-if="isShowSoleTraderWorkStation"
    @onClickCloseTab="onClickCloseSoloTrader"
  />
  <LimitedCompanyWorkStationComponent
    v-if="isShowLimitedCompanyWorkStation"
    @onClickCloseTab="onClickCloseLimitedCompany"
  />
  <InsuranceModel
    v-if="openedModel === modelName.INSURANCE"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditInsurance="isOuterEditInsurance"
    :userInsuranceData="userInsuranceData"
    @clearUserInsuranceData="clearData"
  />

  <EditWorkStationDetailsPopup
    v-if="openedModel === modelName.ADDRESSMODAL"
    @closeEditWorkStationModal="onClickCloseModel"
  />
  <CertificationModal
    v-if="openedModel === modelName.CERTIFICATION"
    :userCertificateData="userCertificateData"
    @onClickCloseTab="onClickCloseModel"
    @clearUserCertificateData="clearData"
  />
  <TaxAndVatModel
    v-if="openedModel === modelName.TAXANDVAT"
    :mode="taxModalMode"
    @onClickCloseTab="onClickCloseModel"
  />
  <InviteMemberForm
    v-if="openedModel === modelName.INVITEFORM"
    :onCloseForm="onClickCloseModel"
  />
  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :onClickCloseTab="onClickCloseModel"
  />

  <AddAddressModal
    v-if="openedModel === modelName.ADDRESSMODAL"
    @on-close="onClickCloseModel"
  />
  <AddUserRatesModal
    v-if="openedModel === modelName.RATEMODAL"
    @closeUpdateUserRatesModal="onClickCloseModel"
  />
  <!-- <SpecialismModel
    v-if="openedModel === modelName.SPECIALISM"
    @onClickCloseTab="onClickCloseModel"
  /> -->

  <SpecialismMultiSelectModel
    v-if="openedModel === modelName.SPECIALISM"
    @onClickCancel="onClickCloseModel"
  />
  <CreateTradeWSModal
    v-if="openedModel === 'CreateTradeWSModal'"
    @onClickCloseTab="onClickCloseModel"
  />
  <WelcomeTradePersonPopup
    v-if="user.loginAttempt === 1 && isShowWelcomeTradePersonPopup"
    @onClickExploreButton="onClickExploreButton"
  ></WelcomeTradePersonPopup>
  <!-- <KYCStatusModal
    v-if="statusModal.KYC === currentStatusModalName"
    :onContinue="onClickCloseModel"
    :kycStatus="currentModalStatus"
  /> -->

  <TradePersonBenefitsModal
    v-if="isShowTradePersonBenefitsModal"
    @onClickClosePreviewModalButton="onClickClosePreviewModalButton"
  />

  <AddBusinessAddressPreviewModal
    v-if="openedModel === modelName.ADDBUSINESSADDRESSMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <CompleteIDVerificationPreviewModal
    v-if="openedModel === modelName.VERIFYPREVIEWMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <TradePassportPreviewModal
    v-if="openedModel === modelName.TRADEPASSPORTPREVIEWMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <WorkstationForVerificationPreviewModal
    v-if="openedModel === modelName.WORKSTATIONVERIFYPREVIEWMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    :type="confirmDeleteType"
    :label="confirmDeleteLabel"
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
  ></ConfirmDeleteModal>
</template>

<script lang="ts">
import { computed, onBeforeMount, ref, onUnmounted, watch } from "vue";
import PendingChip from "./Chips/PendingChip.vue";
import {
  MemberInvitationStatusEnum,
  UserWorkstationVerificationType,
} from "@/core/enums/RolesEnum";

import SoleTraderWorkStationComponent from "@/modules/workstation/soletrader/components/SoleTraderWorkStationComponent.vue";
import LimitedCompanyWorkStationComponent from "@/modules/workstation/limitedcompany/components/LimitedCompanyWorkStationComponent.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
import CertificationModal from "@/modules/certification/components/CertificationModel.vue";
import TaxAndVatModel from "@/modules/tax-and-vat/components/TaxAndVatModel.vue";
// import DashBoardWelcome from "@/core/components/DashBoardWelcome.vue";
import ItemListCard from "@/core/components/ItemListCard.vue";
import InviteMemberForm from "@/core/components/InviteMemberForm.vue";
import WorkStationVerificationLock from "@/core/components/WorkStationVerificationLock.vue";
import { CRYPTO_SECRET_KEY } from "@/core/constants";
import CryptoJS from "crypto-js";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
// import AddressDetailsComponentModal from "@/modules/profile/components/AddressDetailsComponent.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { JOBS_ROUTE } from "@/modules/jobs/routes";
import { COURSE_ROUTE } from "@/modules/courses/routes";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
// import SpecialismModel from "@/modules/Specialism/components/SpecialismModel.vue";
import SpecialismMultiSelectModel from "@/modules/Specialism/components/SpecialismMultiSelectModel.vue";
import { decryptModalData } from "@/core/helpers/workstation.helper";
import WelcomeTradePersonPopup from "@/modules/dashboard/components/WelcomeTradePersonPopup.vue";
import { isExpiredDate } from "@/core/utils/common";
import { parseJSON } from "@/core/utils/common";
import CreateTradeWSModal from "@/modules/dashboard/components/CreateTradeWSModal.vue";

import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
// import MyWorkstationChip from "./Chips/MyWorkstationChip.vue";
// import SignInChip from "./Chips/SignInChip.vue";
// import MemberChip from "./Chips/MemberChip.vue";
// import DirectorChip from "./Chips/DirectorChip.vue";
import LunchIcon from "@/core/components/icons/LunchIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
// import InfoIconWhite from "@/core/components/icons/InfoIconWhite.vue";
// import CheckIconWhite from "@/core/components/icons/CheckIconWhite.vue";
// import AcceptChip from "./Chips/AcceptChip.vue";
// import RejectChip from "./Chips/RejectChip.vue";
import ApproveChip from "./Chips/ApproveChip.vue";
import AddEmailActionChip from "./Chips/AddEmailActionChip.vue";
import DeleteActionChip from "./Chips/DeleteActionChip.vue";
import AddEmailModal from "./Modals/AddEmailModal.vue";
import EmailConformModal from "./Modals/EmailConformModal.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import ProfileVerificationStatusChip from "./Chips/ProfileVerificationStatusChip.vue";
// import TradePassportActionChip from "./Chips/TradePassportActionChip.vue";
import SpecialismItem from "@/core/components/common/SpecialismItem.vue";

// WIDGETS
import JobsOverviewWidget from "./Widgets/JobsOverviewWidget.vue";
import TeamWidget from "./Widgets/TeamWidget.vue";
import TradeNetworkWidget from "./Widgets/TradeNetworkWidget.vue";
import CalendarEventWidget from "./Widgets/CalendarEventWidget.vue";

// Cards
// import SpecialismsCard from "./Cards/SpecialismsCard.vue";
// import InsuranceCard from "./Cards/InsuranceCard.vue";
// import TaxDetailsCard from "./Cards/TaxDetailsCard.vue";
// import CertificateCard from "./Cards/CertificateCard.vue";
// import RatesCard from "./Cards/RatesCard.vue";

// Modals
import AddAddressModal from "@/modules/dashboard/components/Modals/AddAddressModal.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";

import Loader from "@/core/components/common/Loader.vue";
import NoticeIcon from "@/core/components/icons/NoticeIcon.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CheckGreenShield from "@/core/components/icons/CheckGreenShield.vue";
import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
import SiltPendingChip from "./Chips/SiltPendingChip.vue";
import SiltVerifiedChip from "./Chips/SiltVerifiedChip.vue";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
// import KYCStatusModal from "@/core/components/KYCStatusModal.vue";
import manageEmailService from "@/core/services/manage-email.service";
import TradePersonBenefitsModal from "@/modules/dashboard/components/TradePersonBenefitsModal.vue";
import AddUserRatesModal from "@/modules/trade-passport/components/AddUserRatesModal.vue";
import EditWorkStationDetailsPopup from "@/modules/trade-passport/components/EditWorkStationDetailsPopup.vue";

import AddBusinessAddressPreviewModal from "@/modules/dashboard/components/Modals/AddBusinessAddressPreviewModal.vue";
import CompleteIDVerificationPreviewModal from "@/modules/dashboard/components/Modals/CompleteIDVerificationPreviewModal.vue";
import TradePassportPreviewModal from "@/modules/dashboard/components/Modals/TradePassportPreviewModal.vue";
import WorkstationForVerificationPreviewModal from "@/modules/dashboard/components/Modals/WorkstationForVerificationPreviewModal.vue";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
import { UserNotificationType } from "@/core/enums";

export default {
  components: {
    SoleTraderWorkStationComponent,
    LimitedCompanyWorkStationComponent,
    InsuranceModel,
    CertificationModal,
    TaxAndVatModel,
    // DashBoardWelcome,
    // ItemListCard,
    InviteMemberForm,
    WorkStationVerificationLock,
    WorkstationVerificationModal,
    // AddressDetailsComponentModal,
    UserProfileLogo,
    // SpecialismModel,
    SpecialismMultiSelectModel,
    WelcomeTradePersonPopup,
    CreateTradeWSModal,
    TradePersonBenefitsModal,
    Card,
    Button,
    Text,
    ProfileThumbnail,
    // MyWorkstationChip,
    // SignInChip,
    // MemberChip,
    // DirectorChip,
    LunchIcon,
    TrashIcon,
    // InfoIconWhite,
    // CheckIconWhite,
    // AcceptChip,
    // RejectChip,
    DeleteActionChip,
    AddEmailActionChip,
    ApproveChip,
    PendingChip,
    AddEmailModal,
    EmailConformModal,
    Postcode,
    ProfileVerificationStatusChip,
    // TradePassportActionChip,
    JobsOverviewWidget,
    TeamWidget,
    TradeNetworkWidget,
    // SpecialismsCard,
    // InsuranceCard,
    // RatesCard,
    // CertificateCard,
    // SpecialismItem,
    CalendarEventWidget,
    // KYCStatusModal,
    Loader,
    AddAddressModal,
    NoticeIcon,
    SiltPendingChip,
    SiltVerifiedChip,
    ErrorRedIcon,
    CheckGreenShield,
    ChipState,
    ConfirmDeleteModal,
    AddUserRatesModal,
    EditWorkStationDetailsPopup,
    AddBusinessAddressPreviewModal,
    CompleteIDVerificationPreviewModal,
    TradePassportPreviewModal,
    WorkstationForVerificationPreviewModal,
    WorkStationProfileLogo,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const InviteMemberImage = ref();

    const statusModal = { PAYMENT: "payment", KYC: "kyc" };
    const currentStatusModalName = ref("");
    const currentModalStatus = ref("");

    const siltVerificationModal = ref(false);
    const isShowTradePersonBenefitsModal = ref(false);

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteLabel = ref("");
    const confirmDeleteType = ref("");
    const confirmDeleteId = ref(null) as any;
    const confirmDeleteCertificateData = ref({
      type: "certificate",
      label: "You're about to delete your certification.",
    });
    const confirmDeleteInsuranceData = ref({
      type: "insurance",
      label: "You're about to delete your insurance.",
    });

    const isShowWelcomeTradePersonPopup = ref(false);
    const isShowPassword = ref(false);
    const modelName = {
      INSURANCE: "insurance",
      CERTIFICATION: "certification",
      TAXANDVAT: "tax&vat",
      INVITEFORM: "inviteForm",
      VERIFYMODAL: "verifyModal",
      ADDRESSMODAL: "addressModal",
      SPECIALISM: "SpecialismsModal",
      RATEMODAL: "Hourly&RateModal",
      EDITWORKSTATION: "editWorkstation",
      ADDBUSINESSADDRESSMODAL: "addBusinessAddress",
      VERIFYPREVIEWMODAL: "verifyPreviewModal",
      TRADEPASSPORTPREVIEWMODAL: "tradePassportPreview",
      WORKSTATIONVERIFYPREVIEWMODAL: "workstationVerifyPreview",
    };
    const memberPermissions = ref([
      { id: 1, label: "Director", name: "Admin" },
      { id: 2, label: "Admin", name: "Admin" },
      { id: 3, label: "Edit", name: "Edit" },
      { id: 4, label: "View", name: "View" },
    ]);
    const userWorkStationImage = computed(
      () => store.getters[`${USER_STORE}/userWorkStationImage`]
    );
    const isWorkstationLoading = computed(
      () => store.getters[`${WORKSTATION}/loadingWorkstation`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    const isUserWorkStationAdmin = computed(
      () => store.getters[`${WORKSTATION}/isUserWorkStationAdmin`]
    );
    const openedModel = ref("");
    const taxModalMode = ref("Add");

    const tradePassportPanel = ref([0]);
    const WorkStationInvitation = ref([]) as any;
    const isWorkstationEmpty = ref(false);
    const isShowAddEmailModal = ref(false);
    const isShowAddEmailConformModal = ref(false);
    const manageEmailList: any = ref([]);
    const emailConfirmModalType = ref("");
    const manageEmail = ref() as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userFullName = computed(() => {
      return `${user.value?.firstName} ${user.value?.lastName}`;
    });
    const userName = computed(() => {
      return `${user.value?.firstName}`;
    });
    const userEmail = computed(() => {
      return `${user.value?.email}`;
    });

    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );

    const toggleAddEmailModal = (event: boolean) => {
      isShowAddEmailModal.value = event;
    };
    const toggleAddEmailConfirmModal = (status = false) => {
      isShowAddEmailConformModal.value = !isShowAddEmailConformModal.value;
      if (status) {
        manageEmailList.value = manageEmailList.value.filter(
          (data: any) => data.id !== manageEmail.value?.id
        );
        manageEmail.value = "";
      }
    };

    const setManageEmailList = async () => {
      try {
        const response: any = await manageEmailService.getEmailList(
          user.value?.id
        );
        manageEmailList.value = response?.aliases;
      } catch (error) {
        console.error("get email list error", error);
      }
    };
    const onClickDeleteEmail = (data: any) => {
      manageEmail.value = data;
      emailConfirmModalType.value = "delete";
      toggleAddEmailConfirmModal();
    };
    const onClickAddEmail = (addEmailData: object) => {
      manageEmailList.value.push(addEmailData);
      isShowAddEmailModal.value = false;
      emailConfirmModalType.value = "success";
      toggleAddEmailConfirmModal();
    };

    const assignMyselfDirector = () => {
      console.log("assignMyselfDirector");
    };
    const onChangeMemberPermission = async (role: any, member: any) => {
      try {
        await store.dispatch(`${WORKSTATION}/updateUserWorkstationMembers`, {
          id: member.id,
          userId: user.value.id,
          role: role.value,
        });
        store.dispatch(`${WORKSTATION}/setUserWorkstationMembers`, {
          userId: user.value.id,
        });
      } catch (error) {
        console.log();
      }
    };

    const userWorkstationMembers = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstationMembers`]
    );

    const openSiltVerification = () => {
      siltVerificationModal.value = true;
    };
    const closeSiltVerification = () => {
      siltVerificationModal.value = false;
    };
    const checkIfHasWorkstationInvitation = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.wsmt) {
        try {
          const cleanToken = params.wsmt.replace("wsmt=", "");
          const token = CryptoJS.AES.decrypt(
            cleanToken,
            CRYPTO_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8);

          await store.dispatch(`${WORKSTATION}/acceptUserWorkstationMember`, {
            userId: user.value.id,
            key: token,
          });
        } catch (error) {
          console.log();
        }
      }
    };

    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trade WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;

    const onClickToggleTradesWorkStation = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: !isShowWorkStationModal.value,
      });
    };

    const onClickSoleTrader = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: false,
        isShowSoleTraderWorkStation: true,
      });
    };
    const onClickLimitedCompany = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: false,
        isShowLimitedCompanyWorkStation: true,
      });
    };

    const onClickCloseSoloTrader = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: true,
        isShowSoleTraderWorkStation: false,
      });
    };

    const onClickCloseLimitedCompany = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: true,
        isShowLimitedCompanyWorkStation: false,
      });
    };

    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );

    const filteredUserAllWorkStation = computed(() =>
      userAllWorkStation.value.filter(
        (workstation: any) => workstation.name !== "Default"
      )
    );

    const isShowWorkStationModal = computed(
      () => store.getters[`${WORKSTATION}/getWorkStationModelState`]
    );
    const isShowSoleTraderWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getSoleTraderWorkStationState`]
    );
    const isShowLimitedCompanyWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getLimitedCompanyWorkStationState`]
    );
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const accountStatuses = computed(
      () => store.getters[`${USER_STORE}/accountStatuses`]
    );
    const onClickOpenModel = (modalName: string, mode = "") => {
      if (activeUserWorkstation.value?.name !== "Default") {
        if (modalName === modelName.TAXANDVAT && mode !== "") {
          taxModalMode.value = mode;
        }
        openedModel.value = modalName;
        isOuterEditInsurance.value = false;
        userCertificateData.value = null;
      } else {
        openedModel.value = "CreateTradeWSModal";
      }
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    };

    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );
    const isOuterEditInsurance = ref(false);
    const userInsuranceData = ref(null) as any;
    const userCertificateData = ref(null) as any;
    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const onEditInsurance = async (insurance: object) => {
      userInsuranceData.value = insurance;
      isOuterEditInsurance.value = true;
      openedModel.value = modelName.INSURANCE;
    };
    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    const userWorkStationStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const userVerifyStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.user
          ?.userKYCVerificationStatus
    );

    const userWorkstationStatusByWord = computed(() => {
      if (userVerifyStatus.value === UserVerificationStatusEnum.NOT_ADDED) {
        return "required";
      }
      if (userVerifyStatus.value === UserVerificationStatusEnum.VERIFIED) {
        return "verified";
      }
      if (
        userVerifyStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        return "pending";
      }
      return "";
    });

    const isUserWorkstationIsDefault = computed(
      () =>
        activeUserWorkstation.value?.name === "Default" &&
        activeUserWorkstation.value?.status === "active"
    );

    const isUserAddedCompanyInformation = computed(
      () => userInsurances.value?.length
    );

    const workstationSetupProgress = computed(() => {
      if (
        !isUserWorkstationIsDefault.value &&
        isUserProfileVerified.value &&
        isUserTaxDetailsAdded.value
      ) {
        return 66;
      }
      if (!isUserWorkstationIsDefault.value) {
        return 33;
      }
      return 0;
    });

    const workstationSetupProgressNew = computed(() => {
      if (userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED) {
        return 100;
      }
      if (!isUserWorkstationIsDefault.value && isUserProfileVerified.value) {
        return 60;
      }
      if (!isUserWorkstationIsDefault.value && isUserAddBusinessAddress.value) {
        return 40;
      }
      if (!isUserWorkstationIsDefault.value) {
        return 20;
      }
      return 0;
    });

    watch(
      () => isUserWorkstationIsDefault.value,
      (newVal) => {
        if (newVal) {
          getWorkStationInvitation();
        }
      }
    );
    const onDeleteItem = (id: any, type: string, label: string) => {
      confirmDeleteId.value = id;
      confirmDeleteLabel.value = label;
      confirmDeleteType.value = type;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteId.value = null;
      confirmDeleteLabel.value = "";
      confirmDeleteType.value = "";
      isOpenConfirmDelete.value = false;
    };

    const deleteCertificate = async (certificateId: number) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificateId,
        });
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async (e: any) => {
      if (e === confirmDeleteCertificateData.value.type) {
        await deleteCertificate(confirmDeleteId.value);
        await onCloseConfirmDelete();
      }
      if (e === confirmDeleteInsuranceData.value.type) {
        await onDeleteInsurance(confirmDeleteId.value);
        await onCloseConfirmDelete();
      }
    };

    const onClickSubmitVerification = async () => {
      const data = {
        userId: user.value?.id,
        userWorkstationId: activeUserWorkstation.value?.id,
        formData: {
          verificationStatus: UserVerificationStatusEnum.PENDING_VERIFICATION,
        },
      };
      await store.dispatch(`${WORKSTATION}/updateUserWorkstationStatus`, data);
    };

    // NOTE: Must be isUserProfileVerifiable
    const isUserProfileVerified = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );

    const isUserAddBusinessAddress = computed(() => {
      if (
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
      ) {
        return user.value.userBusinessDetails.businessPostcode;
      } else {
        return user.value.userBusinessDetails.residencyPostcode;
      }
    });

    const userBusinessType = computed(
      () => user.value?.userBusinessDetails?.type
    );

    //calenderData
    const masks = {
      weekdays: "WWW",
    };
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const calenderAttribute = [
      {
        key: 1,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Lunch with mom.",
          class: "bg-red-600 text-white",
        },
        dot: true,
        popover: {
          label: "Lunch with mom.",
          class: "bg-red-600 text-white",
        },
        dates: new Date(year, month, 1),
      },
      {
        key: 2,
        dot: true,
        highlight: true,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        popover: {
          label: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 2),
      },
      {
        key: 3,
        dot: true,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        popover: {
          label: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 13),
      },
      {
        key: 4,
        dot: true,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        popover: {
          label: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 23),
      },
    ];

    const isUserTaxDetailsAdded = computed(
      () =>
        user.value?.userBusinessDetails?.taxReferenceNumber &&
        user.value?.userBusinessDetails?.ukVatNumber
    );

    const WorkStationSiltVerificationStatus = computed(
      () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
    );
    const getVerificationStatus = computed(() => {
      const statusString =
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
          ? "KYB"
          : "KYC";

      const verificationStatus: any = {
        ERROR: {
          label: `${statusString} Not Verified`,
          icon: "mdi-information",
          statusClass: "info",
        },
        PENDING: {
          label: `${statusString} Verification Pending`,
          icon: "mdi-clock-time-eight",
          statusClass: "pending",
        },
        SUCCESS: {
          label: `${statusString} Verified`,
          icon: "mdi-check-circle",
          statusClass: "success",
        },
      };
      if (WorkStationSiltVerificationStatus.value) {
        const status = WorkStationSiltVerificationStatus?.value?.status;
        return verificationStatus[status] || verificationStatus.ERROR;
      } else {
        return verificationStatus.ERROR;
      }
    });

    const getWorkstationPostcode = (type: string) => {
      if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
        return user.value?.userBusinessDetails?.businessPostcode;
      }
      return user.value?.userBusinessDetails?.residencyPostcode;
    };

    const getWorkstationAddress = (type: string) => {
      if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
        return user.value?.userBusinessDetails?.businessAddress;
      }
      return user.value?.userBusinessDetails?.residencyAddressLine;
    };

    const onClickTaxVatDeleteButton = async (filedKey: string) => {
      try {
        await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
          userId: user.value.id,
          formData: {
            taxReferenceNumber:
              user.value?.userBusinessDetails?.taxReferenceNumber,
            type: user.value?.userBusinessDetails?.type,
            userVerificationStatus:
              user.value?.userBusinessDetails?.userVerificationStatus,
            ukVatNumber: user.value?.userBusinessDetails?.ukVatNumber,
            ...{ [`${filedKey}`]: null },
          },
        });
      } catch (error) {
        console.log();
      } finally {
        await store.dispatch(`${USER_STORE}/initializeUserState`);
      }
    };

    const onClickUpdateProfile = () => {
      router.push("/profile");
    };

    const showTradeName = computed(() => {
      if (
        activeUserWorkstation.value &&
        activeUserWorkstation.value?.name === "Default"
      ) {
        return userFullName.value;
      } else {
        return activeUserWorkstation.value?.name;
      }
    });

    const onClickAddress = () => {
      // if (user.value?.address) return;
      openedModel.value = modelName.ADDRESSMODAL;
    };

    const onClickTradePassport = () => {
      router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };

    const navigateToJobs = () => {
      router.push({ name: JOBS_ROUTE });
    };

    const navigateToCourse = () => {
      router.push({ name: COURSE_ROUTE });
    };

    const onClickExploreButton = () => {
      isShowWelcomeTradePersonPopup.value = false;
      localStorage.setItem("isShowWelcomePopup", JSON.stringify(true));
    };

    const onEditCertificate = (certificate: any) => {
      openedModel.value = modelName.CERTIFICATION;
      userCertificateData.value = certificate;
    };

    const userAddressPostCode = computed(() => {
      if (
        activeUserWorkstation.value?.workstationAccountType?.code ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
      ) {
        return user.value?.userBusinessDetails?.businessPostcode;
      } else {
        return user.value?.userBusinessDetails?.residencyPostcode;
      }
    });
    const workstationAddress = (type: string) => {
      return type === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? user.value?.userBusinessDetails?.businessAddress
        : user.value?.userBusinessDetails?.residencyAddressLookup
        ? user.value?.userBusinessDetails?.residencyAddressLookup
        : `${user.value?.userBusinessDetails?.residencyAddressLine} ${user.value?.userBusinessDetails?.residencyTown} ${user.value?.userBusinessDetails?.residencyPostcode}`;
    };

    const acceptInvitationLoader = ref(false);
    const acceptInvitationWorkstationId = ref<null | string>(null);
    const onClickAcceptWSInvitation = async (token: string) => {
      if (
        user.value?.userKYCVerificationStatus ===
        UserVerificationStatusEnum.VERIFIED
      ) {
        try {
          acceptInvitationLoader.value = true;
          acceptInvitationWorkstationId.value = token;
          await store.dispatch(`${USER_STORE}/acceptWorkStationInvitation`, {
            userId: user.value?.id,
            key: token,
          });
          getWorkStationInvitation();
          await store.dispatch(
            `${WORKSTATION}/getUserWorkstations`,
            user.value?.id
          );
        } catch (err) {
          console.log();
        } finally {
          acceptInvitationLoader.value = false;
          acceptInvitationWorkstationId.value = null;
        }
      } else {
        openedModel.value = "verifyModal";
      }
    };

    const deleteInvitedWorkstationLoader = ref(false);
    const deleteInvitedWorkstationId = ref<null | number>(null);
    const onClickRejectWSInvitation = async (id: number) => {
      try {
        deleteInvitedWorkstationLoader.value = true;
        deleteInvitedWorkstationId.value = id;
        const response = await store.dispatch(
          `${MEMBER_STORE}/deleteMemberDetails`,
          {
            userId: user.value?.id,
            userMemberId: id,
          }
        );
        getWorkStationInvitation();
        await store.dispatch(
          `${WORKSTATION}/getUserWorkstations`,
          user.value?.id
        );
      } catch (err) {
        console.log();
      } finally {
        deleteInvitedWorkstationLoader.value = false;
        deleteInvitedWorkstationId.value = null;
      }
    };

    const getWorkStationInvitation = async () => {
      try {
        const response = await store.dispatch(
          `${USER_STORE}/getWorkStationInvitation`,
          user.value?.id
        );
        WorkStationInvitation.value = response;
        const image = getImageApiUrl(
          response[0]?.userWorkstation?.profileImage,
          true
        );
        const encoded: any = await $axios.get(image);
        InviteMemberImage.value = encoded?.publicUrl;
      } catch (err) {
        console.log();
      }
    };

    const onWorkStationClick = async (
      workstationId: number,
      resetData = true
    ) => {
      try {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, true);
        if (!workstationId) return;
        if (resetData) {
          await router.push({ name: DASHBOARD_ROUTE });
          await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
            userId: user.value?.id,
            userWorkstationId: workstationId,
          });
        }
        const workstationVerification = await store.dispatch(
          `${WORKSTATION}/getWorkstationVerificationData`,
          workstationId
        );
        if (
          workstationVerification &&
          workstationVerification?.response?.id &&
          ["KYC", "KYB"].includes(workstationVerification?.verificationType)
        ) {
          await store.dispatch(
            `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
            {
              id: workstationVerification.response.id,
              verificationType: workstationVerification?.verificationType,
            }
          );
        } else {
          await store.dispatch(
            `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
            null
          );
        }
      } catch (error) {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
        console.log();
      } finally {
        if (resetData) {
          await store.dispatch(`${USER_STORE}/initializeUserState`);
          await store.dispatch(`${USER_STORE}/getAccountStatuses`);
          await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

          await store.dispatch(
            `${USER_STORE}/setUserCertificates`,
            user.value?.id
          );
          await store.dispatch(
            `${USER_STORE}/setUserInsurances`,
            user.value?.id
          );
          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
          await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
        }
      }
    };

    const getCheckoutUserKYC = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.silt_user_id) return;
        const soloTraderVerificationData = await store.dispatch(
          `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
          {
            id: params.silt_user_id,
            verificationType: UserWorkstationVerificationType.KYC,
          }
        );
        await store.dispatch(
          `${WORKSTATION}/saveWorkstationVerificationData`,
          soloTraderVerificationData
        );
        currentStatusModalName.value = statusModal.KYC;
        currentModalStatus.value = soloTraderVerificationData?.status;
        openedModel.value = "";
        await store.dispatch(`${USER_STORE}/initializeUserState`);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          currentStatusModalName.value = statusModal.KYC;
          openedModel.value = "";
          currentModalStatus.value = "ERROR";
          return;
        }
      }
    };

    const onClickManageTradePassport = () => {
      if (
        userWorkStationStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        console.log("Workstation Locked");
      } else {
        router.push({ name: TRADE_PASSPOSRT_ROUTE });
      }
    };

    const clearData = () => {
      userCertificateData.value = null;
      userInsuranceData.value = null;
    };

    const formatDate = (dateString: any, format: string) => {
      const date = new Date(dateString);

      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      switch (format) {
        case "fullDate": {
          const day = date.getUTCDate();
          const month = date.getUTCMonth();
          const year = date.getUTCFullYear();
          return `${day} ${monthsOfYear[month]}, ${year}`;
        }
        default: {
          throw new Error("Invalid format type");
        }
      }
    };

    const onClickBenefitsButton = () => {
      isShowTradePersonBenefitsModal.value = true;
    };

    const onClickClosePreviewModalButton = () => {
      isShowTradePersonBenefitsModal.value = false;
    };
    const onClickAddBusinessAddressModal = () => {
      if (!isUserAddBusinessAddress.value) {
        openedModel.value = modelName.ADDBUSINESSADDRESSMODAL;
      }
    };

    const onClickIDVerificationModal = () => {
      if (
        activeUserWorkstation.value?.user?.userKYCVerificationStatus ===
        UserVerificationStatusEnum.NOT_ADDED
      ) {
        openedModel.value = modelName.VERIFYPREVIEWMODAL;
      }
    };

    const onClickManageTradePassportPreviewModal = () => {
      if (
        userWorkStationStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        console.log("Workstation Locked");
      } else {
        openedModel.value = modelName.TRADEPASSPORTPREVIEWMODAL;
      }
    };

    const onClickSubmitVerificationPreviewModal = () => {
      if (
        isUserProfileVerified.value &&
        activeUserWorkstation.value?.verificationStatus ===
          UserVerificationStatusEnum.NOT_ADDED &&
        (activeUserWorkstation.value?.user?.userKYCVerificationStatus ===
          UserVerificationStatusEnum.VERIFIED ||
          activeUserWorkstation.value?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION)
      ) {
        openedModel.value = modelName.WORKSTATIONVERIFYPREVIEWMODAL;
      }
    };

    const checkWelcomePopup = () => {
      isShowWelcomeTradePersonPopup.value = parseJSON(
        localStorage.getItem("isShowWelcomePopup")
      )
        ? false
        : true;
    };

    onBeforeMount(async () => {
      await checkWelcomePopup();
      getCheckoutUserKYC();
      try {
        checkIfHasWorkstationInvitation();
        getWorkStationInvitation();

        if (user.value?.id) {
          await store.dispatch(`${USER_STORE}/getAccountStatuses`);

          if (!userInsurances.value?.length) {
            await store.dispatch(
              `${USER_STORE}/setUserInsurances`,
              user.value?.id
            );
          }
          if (!userCertificates.value?.length) {
            await store.dispatch(
              `${USER_STORE}/setUserCertificates`,
              user.value?.id
            );
          }

          if (!userSpecialisms.value?.length) {
            await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
          }
          await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

          await store.dispatch(`${WORKSTATION}/setUserWorkstationMembers`, {
            userId: user.value.id,
          });
          setManageEmailList();
        }

        const data = decryptModalData();
        if (data) {
          store.dispatch(`${WORKSTATION}/setWsModal`, data);
        }
      } catch (error) {
        console.log("error", error);
      }
      store.dispatch(`${TRADE_NETWORK}/getUserNetWorkContractor`, {
        activeUserWorkstationId: activeUserWorkstation.value?.id,
      });
    });

    const handleClick = (event: any) => {
      if (!getUserWorkStationFieldAccess.value) {
        event?.preventDefault();
        event?.stopPropagation();
      }
    };
    onUnmounted(() => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: false,
        isShowSoleTraderWorkStation: false,
        isShowLimitedCompanyWorkStation: false,
      });
    });
    return {
      onClickToggleTradesWorkStation,
      isShowWorkStationModal,
      isShowSoleTraderWorkStation,
      onClickSoleTrader,
      onClickCloseSoloTrader,
      isShowLimitedCompanyWorkStation,
      onClickLimitedCompany,
      user,
      openedModel,
      modelName,
      onClickOpenModel,
      onClickCloseModel,
      userWorkStationImage,
      userFullName,
      userName,
      userEmail,
      dashBoardWelcomeButton,
      onClickCloseLimitedCompany,
      userInsurances,
      onDeleteInsurance,
      onEditInsurance,
      isOuterEditInsurance,
      userInsuranceData,
      isUserProfileVerified,
      userBusinessType,
      isUserWorkstationIsDefault,
      userWorkStationStatus,
      UserVerificationStatusEnum,
      onClickSubmitVerification,
      userCertificates,
      deleteCertificate,
      BusinessDetailTypeEnum,
      isUserAddedCompanyInformation,
      workstationSetupProgress,
      workstationSetupProgressNew,
      activeUserWorkstation,
      memberPermissions,
      assignMyselfDirector,
      onChangeMemberPermission,
      userWorkstationMembers,
      calenderAttribute,
      masks,
      isUserTaxDetailsAdded,
      WorkStationSiltVerificationStatus,
      getVerificationStatus,
      onClickTaxVatDeleteButton,
      onClickUpdateProfile,
      showTradeName,
      onClickAddress,
      onClickTradePassport,
      navigateToJobs,
      navigateToCourse,
      isShowWelcomeTradePersonPopup,
      onClickExploreButton,
      isExpiredDate,
      onEditCertificate,
      userCertificateData,
      isShowPassword,
      userAddressPostCode,
      workstationAddress,
      isWorkstationEmpty,
      WorkStationInvitation,
      toggleAddEmailModal,
      isShowAddEmailModal,
      userWorkstationStatusByWord,
      tradePassportPanel,
      getWorkstationAddress,
      getWorkstationPostcode,
      onClickAcceptWSInvitation,
      InviteMemberImage,
      onClickRejectWSInvitation,
      MemberInvitationStatusEnum,
      onWorkStationClick,
      statusModal,
      currentStatusModalName,
      currentModalStatus,
      taxModalMode,
      onClickManageTradePassport,
      isWorkstationLoading,
      clearData,
      openSiltVerification,
      closeSiltVerification,
      siltVerificationModal,
      userAllWorkStation,
      onClickAddEmail,
      toggleAddEmailConfirmModal,
      isShowAddEmailConformModal,
      emailConfirmModalType,
      manageEmail,
      manageEmailList,
      onClickDeleteEmail,
      isOpenConfirmDelete,
      onDeleteItem,
      confirmDeleteInsuranceData,
      confirmDeleteCertificateData,
      confirmDeleteType,
      confirmDeleteLabel,
      onCloseConfirmDelete,
      deleteItem,
      formatDate,
      onClickBenefitsButton,
      isShowTradePersonBenefitsModal,
      onClickClosePreviewModalButton,
      accountStatuses,
      isUserAddBusinessAddress,
      onClickAddBusinessAddressModal,
      onClickIDVerificationModal,
      onClickManageTradePassportPreviewModal,
      onClickSubmitVerificationPreviewModal,
      filteredUserAllWorkStation,
      getUserWorkStationFieldAccess,
      isUserWorkStationAdmin,
      handleClick,
      parseJSON,
      acceptInvitationLoader,
      deleteInvitedWorkstationLoader,
      deleteInvitedWorkstationId,
      acceptInvitationWorkstationId,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/dashboard/styles/SoleTraderDashboard.scss";
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/courses/styles/courses.scss";

.workstation-details-card {
  :deep(.v-expansion-panel__shadow) {
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  }
}

.workstation-dashboard {
  &__expansion-panel {
    :deep(.v-expansion-panel__shadow) {
      box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
    }
  }
}
.profile-logo {
  border-radius: 10px;
  background-color: #4a92ef;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ws-profile-logo {
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
