import { JobDetails, JobOverview, JobsState } from "@/core/models/jobs";

export default {
  setJobs(state: JobsState, payload: JobOverview[]) {
    state.jobs = payload;
  },
  setMyJobs(state: JobsState, payload: JobOverview[]) {
    state.myJobs = payload;
  },
  setDraftJobs(state: JobsState, payload: JobOverview[]) {
    state.draftJobs = payload;
  },
  setRejectedJobs(state: JobsState, payload: JobOverview[]) {
    state.rejectedJobs = payload;
  },
  setJobDetails(state: JobsState, payload: JobDetails) {
    state.jobDetails = payload;
  },
  setHistoricalJobs(state: JobsState, payload: any) {
    state.historicalJobs = payload;
  },
  setActiveHistoricalJob(state: JobsState, payload: any) {
    state.activeHistoricalJob = payload;
  },
  setVideoScheduleTime(state: JobsState, payload: any) {
    state.videoScheduleTime = payload;
  },
  setWorkFlowType(state: any, payload: any) {
    state.workFlowType = payload;
  },
  setJobStatusCountsList(state: any, payload: any) {
    state.jobStatusCountsList = payload;
  },
};
