<template>
  <div id="gantt-chart">
    <v-btn
      class="button button-orange desktop_wrapper"
      @click="onToggleEventEditModal"
      :disabled="isLoading"
    >
      Add Event
    </v-btn>
    <section
      class="schedule-page__content md:!tw-mt-[74px]"
      :class="{
        'mobile-view': isMobileView,
        'month-view-layout': currentCalendarView === calendarView.MONTH,
      }"
    >
      <div class="calendar__header">
        <div class="custom__calendar">
          <v-text-field
            density="compact"
            variant="outlined"
            v-model="datePickerValue"
            class="selectprop_dropdown"
            type="date"
            @change="onChangeDatePicker(true)"
          />

          <span class="current-month"> {{ currentMonth }}</span>
        </div>
        <div class="calendar__tabs">
          <ul>
            <li>
              <v-btn
                :class="{ active: currentCalendarView === calendarView.DAY }"
                variant="text"
                @click="onChangeCalendarView(calendarView.DAY)"
                >Day</v-btn
              >
            </li>
            <li>
              <v-btn
                :class="{ active: currentCalendarView === calendarView.WEEK }"
                variant="text"
                @click="onChangeCalendarView(calendarView.WEEK)"
                >Week</v-btn
              >
            </li>
            <li>
              <v-btn
                :class="{ active: currentCalendarView === calendarView.MONTH }"
                variant="text"
                @click="onChangeCalendarView(calendarView.MONTH)"
                >Month</v-btn
              >
            </li>
          </ul>
        </div>
      </div>
      <div ref="ganttChartContainerRef" class="task-content-wrapper">
        <div class="calendar__inner">
          <div
            class="custom-class tw-w-[200px] tw-sticky tw-left-0 tw-z-10 tw-top-0 llg:tw-w-[140px]"
          >
            <div class="schedule-page__list-container lg:tw-bg-[#FAFCFF]">
              <div class="schedule-page__title md:!tw-py-0 md:!tw-px-2">
                <h4
                  class="md:!tw-text-left"
                  :class="{ 'team-calendar-label': calendarType === 'team' }"
                >
                  {{ calendarType === "project" ? "Projects" : "Team Members" }}
                  <span>{{ getTeamMemberCounts }}</span>
                </h4>
              </div>
              <div class="schedule-page__list_item" ref="sideTaskRef">
                <projectCalendarSideList
                  v-if="projectList.length"
                  :chartClickTask="chartClickTask"
                  :projectList="projectList"
                  :calendarType="calendarType"
                  @on-click-task="onClickSideBarProfile"
                />
              </div>
            </div>
          </div>
          <div
            ref="chartScrollContainerRef"
            class="work_bars lg:tw-order-2 lg:tw-w-full lg:tw-max-w-full"
          >
            <div
              class="chart-container-header tw-sticky tw-w-full"
              :style="{ width: `${getChartContainerWidth}px` }"
            >
              <ul
                v-if="currentCalendarView === calendarView.DAY"
                class="chart-values lg:tw-mt-0"
              >
                <li
                  v-for="hour in dayViewGrids"
                  :key="hour"
                  class="lg:tw-bg-white week_wrapper"
                >
                  <p class="date_wrapper">
                    {{ hour }}
                  </p>
                </li>
              </ul>
              <ul
                v-if="currentCalendarView === calendarView.WEEK"
                class="chart-values lg:tw-mt-0"
              >
                <li
                  v-for="grid in grids"
                  :key="grid.id"
                  class="lg:tw-bg-white week_wrapper"
                >
                  <p
                    class="date_wrapper"
                    :class="{
                      'select-date': grid?.displayMonth === datePickerValue,
                    }"
                  >
                    {{ grid.day }}&nbsp;{{ grid.date }}
                  </p>
                </li>
              </ul>
              <ul
                v-if="currentCalendarView === calendarView.MONTH"
                class="chart-values lg:tw-mt-0"
              >
                <li
                  v-for="month in monthGrids"
                  :key="month?.id"
                  class="lg:tw-bg-white week_wrapper"
                >
                  <p class="date_wrapper">
                    {{ month.label }}
                  </p>
                </li>
              </ul>
            </div>

            <div
              class="chart-container"
              :style="{ width: `${getChartContainerWidth}px` }"
              @mousedown="onMouseDown($event)"
              @touchstart="onTouchStart($event)"
              ref="chartBoxRef"
            >
              <draggable
                v-if="finalProjectCalendarData.length"
                v-model="finalProjectCalendarData"
                group="tasks"
                :animation="400"
                itemKey="id"
                handle=".drag-handle"
                :force-fallback="true"
                :fallback-on-body="true"
                fallback-class="fallback"
                :draggable="'.draggable-item'"
              >
                <template
                  #item="{ element: projectData, index: mainTaskIndex }"
                >
                  <div
                    :class="{
                      'draggable-item': !projectData?.isLock,
                    }"
                    class="task-container"
                  >
                    <div
                      class="task-wrapper"
                      v-if="!projectData?.isDefaultEvent"
                      :id="`task-box-${mainTaskIndex}`"
                      :data-mainindex="mainTaskIndex"
                    >
                      <div
                        :id="`maintask-${mainTaskIndex}`"
                        class="phase-task tw-text-white tw-text-xs tw-font-bold"
                        :style="{
                          'background-color':
                            currentCalendarView !== calendarView.MONTH
                              ? projectData?.color?.lightColor
                              : projectData?.color?.darkColor,
                        }"
                        :data-color="projectData?.color?.lightColor"
                        :data-mainindex="mainTaskIndex"
                        :data-startdate="projectData?.suggestedStartDate"
                        :data-enddate="projectData?.projectEstimateEndDate"
                        :data-isevent="projectData?.isEvent"
                        :data-starttime="projectData.startTime"
                        :data-endtime="projectData.endTime"
                      >
                        <v-menu
                          target="cursor"
                          transition="slide-y-reverse-transition"
                          :activator="`#maintask-${mainTaskIndex}`"
                          width="185px"
                          min-width="185px"
                        >
                          <CalendarInfoDialog
                            v-if="!projectData?.isEvent"
                            :projectData="projectData"
                          />
                          <CalendarEventInfoDialog
                            v-else
                            :event="projectData"
                            @removeEvent="
                              deleteEvent(projectData, mainTaskIndex)
                            "
                            @editEvent="editEvent"
                          />
                        </v-menu>

                        <div class="taskbar_inner_content">
                          <div class="left_block">
                            <div
                              class="phase_task_text"
                              :data-mainindex="mainTaskIndex"
                              :data-isevent="projectData?.isEvent"
                            >
                              <div
                                v-if="
                                  currentCalendarView !== calendarView.MONTH
                                "
                                class="left__space"
                                :class="{
                                  'cursor-resize ':
                                    projectData?.isEvent &&
                                    currentCalendarView === calendarView.DAY,
                                }"
                                :style="{
                                  'background-color':
                                    projectData?.color?.darkColor,
                                }"
                                :data-mainindex="mainTaskIndex"
                                :data-isevent="projectData?.isEvent"
                              ></div>
                              <div
                                v-if="
                                  currentCalendarView !== calendarView.MONTH
                                "
                                class="text__content"
                                :data-isevent="projectData?.isEvent"
                                :data-mainindex="mainTaskIndex"
                              >
                                <p
                                  :data-isevent="projectData?.isEvent"
                                  :data-mainindex="mainTaskIndex"
                                >
                                  {{
                                    projectData?.isEvent
                                      ? projectData.eventLabel
                                      : projectData?.project?.name
                                  }}
                                </p>
                                <span
                                  :data-isevent="projectData?.isEvent"
                                  :data-mainindex="mainTaskIndex"
                                >
                                  {{
                                    getMonthData(
                                      projectData?.suggestedStartDate
                                    )
                                  }}
                                  -
                                  {{
                                    getMonthData(
                                      projectData?.projectEstimateEndDate
                                    )
                                  }}
                                </span>
                              </div>
                              <div
                                v-if="
                                  projectData?.isEvent &&
                                  currentCalendarView === calendarView.DAY
                                "
                                :data-mainindex="mainTaskIndex"
                                class="right__space cursor-resize"
                                :style="{
                                  'background-color':
                                    projectData?.color?.darkColor,
                                }"
                                :data-isevent="projectData?.isEvent"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
            <CommonLoader
              v-if="isLoading"
              :loading="isLoading"
              class="chart-loader-container"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
  <EventModal
    v-if="isShowCreateEventModal"
    @closeModal="onToggleEventEditModal"
    @action="editEvent"
    modalType="create"
  />

  <PageRestrictionNoticeModal
    v-if="isShowWorkstationRestrictionModal"
    @on-close="toggleRestrictionModal"
  />
</template>
<script setup>
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import projectCalendarSideList from "@/modules/calendar/components/projectCalendarSideList.vue";
import draggable from "vuedraggable";
import moment from "moment";
import { onBeforeUnmount, onMounted } from "vue";
import { onBeforeMount } from "vue";
import { computed } from "vue";
import { ref } from "vue";
import { useDisplay } from "vuetify";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  getCurrentConTractorPhases,
  getImageStringToImageURL,
  getParentContractorPhases,
  getSubContractorPhases,
  parseJSON,
} from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import CommonLoader from "@/core/components/CommonLoader.vue";
import calendarService from "@/core/services/calendar.service";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { USER_STORE } from "@/store/modules/user";
import CalendarInfoDialog from "@/modules/calendar/components/CalendarInfoDialog.vue";
import CalendarEventInfoDialog from "@/modules/calendar/components/CalendarEventInfoDialog.vue";
import { ProjectPhaseType } from "@/core/enums/ProjectsEnum";
import projectEstimationService from "@/core/services/project-estimation.service";
import { CALENDAR_STORE } from "@/store/modules/calendar";
import EventModal from "@/modules/calendar/components/EventModal.vue";
import dummyUserIcon1 from "@/assets/images/sample-avatar.jpg";
import dummyUserIcon2 from "@/assets/images/sample-avatar-2.png";
import dummyUserIcon3 from "@/assets/images/contractor-profile.png";
import dummyUserIcon4 from "@/assets/images/ronald-richards.png";
import dummyUserIcon5 from "@/assets/images/sample-avatar-4.png";
import dummyUserIcon6 from "@/assets/images/sample-avatar-5.png";
import dummyUserIcon7 from "@/assets/images/sample-avatar-6.png";
import dummyUserIcon8 from "@/assets/images/sample-avatar-7.png";
import dummyUserIcon9 from "@/assets/images/sample-avatar-8.png";
import manageMemberService from "@/core/services/manage-member.service";
import { EstimatePhaseAssignMemberStatusEnum } from "@/core/enums/estimateEnum";

const props = defineProps({
  estimateData: Object,
  isEditable: {
    type: Boolean,
    default: true,
  },
  calendarType: {
    type: String,
    default: "project",
  },
});
moment.updateLocale("en", {
  week: {
    dow: 1,
    doy: 6,
  },
});

const store = useStore();

const vuetify = useDisplay();
const isMobileView = computed(() => vuetify.smAndDown.value);
const chartBoxRef = ref(null);
const sideTaskRef = ref(null);
const ganttChartContainerRef = ref(null);
const chartScrollContainerRef = ref(null);
const chartClickTask = ref(null);
const isLoading = ref(false);
const datePickerValue = ref();
const isOpenCalendarView = ref(false);
const isShowCreateEventModal = ref(false);
const currentMonth = ref("");
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const projectCalendarData = ref([]);
const teamCalenderEventData = ref([]);
const finalProjectCalendarData = ref([]);
const projectList = ref([]);
const calendarBoxSize = ref(80);
const calendarView = { DAY: "day", WEEK: "week", MONTH: "month" };
const currentCalendarView = ref("day");
const oldScrollLeftValue = ref(0);
const dummyUsers = {
  team: [
    {
      id: 1,
      isEvent: true,
      eventId: 24,
      name: "Giovanni",
      userId: 1204,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon1,
        name: "Giovanni",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().format("YYYY-MM-DD"),
    },
    {
      id: 2,
      isEvent: false,
      name: "Alejandro",
      userId: 1190,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon2,
        name: "Alejandro",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().add(10, "days").format("YYYY-MM-DD"),
    },
    {
      id: 3,
      isEvent: true,
      eventId: 24,
      name: "Toby Sparks",
      userId: 1204,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon3,
        name: "Toby Sparks",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().format("YYYY-MM-DD"),
    },
    {
      id: 4,
      isEvent: false,
      name: "Bruce Burner",
      userId: 1190,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon4,
        name: "Bruce Burner",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().add(2, "days").format("YYYY-MM-DD"),
    },
    {
      id: 5,
      isEvent: true,
      eventId: 24,
      name: "Rogers & Sons",
      userId: 1204,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon5,
        name: "Rogers & Sons",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().format("YYYY-MM-DD"),
    },
    {
      id: 6,
      isEvent: false,
      name: "Peter Parquet",
      userId: 1190,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon6,
        name: "Peter Parquet",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().add(10, "days").format("YYYY-MM-DD"),
    },
    {
      id: 7,
      isEvent: true,
      eventId: 24,
      name: "Nick Curry",
      userId: 1204,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon7,
        name: "Nick Curry",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().format("YYYY-MM-DD"),
    },
    {
      id: 8,
      isEvent: false,
      name: "Tron Oddies",
      userId: 1190,
      role: "Tradesperson",
      profileImage: {
        imageUrl: dummyUserIcon8,
        name: "Tron Oddies",
      },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().add(10, "days").format("YYYY-MM-DD"),
    },
  ],
  project: [
    {
      id: 1171,
      name: "Painting House",
      phaseCount: 3,
      color: { lightColor: "#E5FAF1", darkColor: "#00CA72" },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().add(10, "days").format("YYYY-MM-DD"),
    },
    {
      id: 1172,
      name: "Solar PV Installation",
      phaseCount: 4,
      color: { lightColor: "#E1EBFF", darkColor: "#4B4BFF" },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().add(5, "days").format("YYYY-MM-DD"),
    },
    {
      id: 1173,
      name: "Underfloor Heating...",
      phaseCount: 5,
      color: { lightColor: "#E5FAF1", darkColor: "#00CA72" },
      projectStartDate: moment().format("YYYY-MM-DD"),
      projectEndDate: moment().add(2, "days").format("YYYY-MM-DD"),
    },
  ],
};
const dummyCalenderData = {
  team: [
    {
      user: {
        id: 1204,
        firstName: "devid",
        lastName: "yalgar",
        email: "mailto:devidyalgar123@mail.com",
        role: "Tradesperson",
        userPublicProfile: {
          profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
        },
        profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
      },
      eventId: 24,
      isEvent: true,
      color: { lightColor: "#FBEBD2", darkColor: "#FFA922" },
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().format("YYYY-MM-DD"),
      startTime: "02:45 PM",
      eventLabel: "project event",
      location: "landon",
      description: "event description",
      endTime: "09:00 PM",
      invitePeople: ["mailto:devidyalgar123@mail.com"],
    },
    {
      estimateId: 69,
      project: { id: 1179, name: "house roof change" },
      estimationPhase: [
        {
          phaseCompletionStatus: false,
          color: "#051D19",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon1,
              workStationName: "limited ltd",
            },
          ],
        },
        {
          phaseCompletionStatus: true,
          color: "#2D993F",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon2,
              workStationName: "limited ltd",
            },
          ],
        },
      ],
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().add(10, "days").format("YYYY-MM-DD"),
      color: { lightColor: "#E5FAF1", darkColor: "#00CA72" },
      isEvent: false,
    },
    {
      user: {
        id: 1212,
        firstName: "devid",
        lastName: "yalgar",
        email: "mailto:devidyalgar123@mail.com",
        role: "Tradesperson",
        userPublicProfile: {
          profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
        },
        profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
      },
      eventId: 24,
      isEvent: true,
      color: { lightColor: "#E1EBFF", darkColor: "#4B4BFF" },
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().format("YYYY-MM-DD"),
      startTime: "03:45 PM",
      eventLabel: "project event #1",
      location: "landon",
      description: "event description",
      endTime: "06:00 PM",
      invitePeople: ["mailto:devidyalgar123@mail.com"],
    },
    {
      estimateId: 69,
      project: { id: 1179, name: "wall painting" },
      estimationPhase: [
        {
          phaseCompletionStatus: false,
          color: "#051D19",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon1,
              workStationName: "limited ltd",
            },
          ],
        },
        {
          phaseCompletionStatus: true,
          color: "#2D993F",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon2,
              workStationName: "limited ltd",
            },
          ],
        },
      ],
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().add(7, "days").format("YYYY-MM-DD"),
      color: { lightColor: "#E5FAF1", darkColor: "#00CA72" },
      isEvent: false,
    },
    {
      user: {
        id: 1212,
        firstName: "devid",
        lastName: "yalgar",
        email: "mailto:devidyalgar123@mail.com",
        role: "Tradesperson",
        userPublicProfile: {
          profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
        },
        profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
      },
      eventId: 24,
      isEvent: true,
      color: { lightColor: "#F6EBFB", darkColor: "#A338D6" },
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().format("YYYY-MM-DD"),
      startTime: "1:45 PM",
      eventLabel: "project event #11",
      location: "landon",
      description: "event description",
      endTime: "3:00 PM",
      invitePeople: ["mailto:devidyalgar123@mail.com"],
    },
    {
      estimateId: 69,
      project: { id: 1179, name: "house roof change" },
      estimationPhase: [
        {
          phaseCompletionStatus: false,
          color: "#051D19",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon1,
              workStationName: "limited ltd",
            },
          ],
        },
        {
          phaseCompletionStatus: true,
          color: "#2D993F",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon2,
              workStationName: "limited ltd",
            },
          ],
        },
      ],
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().add(12, "days").format("YYYY-MM-DD"),
      color: { lightColor: "#E5FAF1", darkColor: "#00CA72" },
      isEvent: false,
    },
    {
      user: {
        id: 1212,
        firstName: "devid",
        lastName: "yalgar",
        email: "mailto:devidyalgar123@mail.com",
        role: "Tradesperson",
        userPublicProfile: {
          profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
        },
        profileImage: "1204/profiles/1352/1720597537131_cFPzZ5mPI64Js.png",
      },
      eventId: 24,
      isEvent: true,
      color: { lightColor: "#E1EBFF", darkColor: "#4B4BFF" },
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().format("YYYY-MM-DD"),
      startTime: "02:45 PM",
      eventLabel: "project event",
      location: "landon",
      description: "event description",
      endTime: "09:00 PM",
      invitePeople: ["mailto:devidyalgar123@mail.com"],
    },
    {
      estimateId: 69,
      project: { id: 1179, name: "house roof change" },
      estimationPhase: [
        {
          phaseCompletionStatus: false,
          color: "#051D19",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon1,
              workStationName: "limited ltd",
            },
          ],
        },
        {
          phaseCompletionStatus: true,
          color: "#2D993F",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon2,
              workStationName: "limited ltd",
            },
          ],
        },
      ],
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().add(5, "days").format("YYYY-MM-DD"),
      color: { lightColor: "#E5FAF1", darkColor: "#00CA72" },
      isEvent: false,
    },
  ],
  project: [
    {
      estimateId: 69,
      project: { id: 1179, name: "Painting house" },
      estimationPhase: [
        {
          phaseCompletionStatus: false,
          color: "#051D19",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon1,
              workStationName: "limited ltd",
            },
          ],
        },
        {
          phaseCompletionStatus: true,
          color: "#2D993F",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon2,
              workStationName: "limited ltd",
            },
          ],
        },
      ],
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().add(10, "days").format("YYYY-MM-DD"),
      color: { lightColor: "#F6EBFB", darkColor: "#A338D6" },
      isEvent: false,
    },

    {
      estimateId: 69,
      project: { id: 1180, name: "Solar PV Installation" },
      estimationPhase: [
        {
          phaseCompletionStatus: false,
          color: "#051D19",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon1,
              workStationName: "limited ltd",
            },
          ],
        },
        {
          phaseCompletionStatus: true,
          color: "#2D993F",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon2,
              workStationName: "limited ltd",
            },
          ],
        },
      ],
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().add(3, "days").format("YYYY-MM-DD"),
      color: { lightColor: "#E1EBFF", darkColor: "#4B4BFF" },
      isEvent: false,
    },

    {
      estimateId: 69,
      project: { id: 1181, name: "Underfloor Heating" },
      estimationPhase: [
        {
          phaseCompletionStatus: false,
          color: "#051D19",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon1,
              workStationName: "limited ltd",
            },
          ],
        },
        {
          phaseCompletionStatus: true,
          color: "#2D993F",
          memberProfileImages: [
            {
              imageUrl: dummyUserIcon2,
              workStationName: "limited ltd",
            },
          ],
        },
      ],
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().add(1, "days").format("YYYY-MM-DD"),
      color: { lightColor: "#E5FAF1", darkColor: "#00CA72" },
      isEvent: false,
    },
  ],
};
const phaseColors = [
  {
    lightColor: "#F6EBFB",
    darkColor: "#A338D6",
  },
  {
    lightColor: "#E5FAF1",
    darkColor: "#00CA72",
  },
  {
    lightColor: "#E1EBFF",
    darkColor: "#4B4BFF",
  },
  {
    lightColor: "#FBEBD2",
    darkColor: "#FFA922",
  },
  {
    lightColor: "#fbd2d2",
    darkColor: "#cc0808",
  },
  {
    lightColor: "#f7f5cd",
    darkColor: "#c4bc0c",
  },
  {
    lightColor: "#e1f5b0",
    darkColor: "#75a10a",
  },
  {
    lightColor: "#ddc6f7",
    darkColor: "#6a09d9",
  },
  {
    lightColor: "#edd0f5",
    darkColor: "#af0fdb",
  },
];
const getRandomColorPair = (idx) => {
  return phaseColors[idx % (phaseColors.length + 1)];
};

const currentDragElement = ref(null);
const phaseTaskLeftValue = ref(0);
const initialLeft = ref(0);
const initialMouseX = ref(0);
const scrollLeft = ref(0);
const scrollDirection = ref(null);
const scrollInterval = ref(null);
const setPositionTimeout = ref(null);
const widthIncreaseDirection = ref(null);
const getNumericValue = (elementSizeInPx) => {
  let match = elementSizeInPx.match(/-?\d+/);

  if (match) {
    return parseInt(match[0], 10);
  } else {
    return NaN;
  }
};

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const monthGrids = [
  { id: 1, label: "Jan" },
  { id: 2, label: "Feb" },
  { id: 3, label: "Mar" },
  { id: 4, label: "Apr" },
  { id: 5, label: "May" },
  { id: 6, label: "Jun" },
  { id: 7, label: "Jul" },
  { id: 8, label: "Aug" },
  { id: 9, label: "Sep" },
  { id: 10, label: "Oct" },
  { id: 11, label: "Nov" },
  { id: 12, label: "Dec" },
];
const dayViewGrids = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
];

const grids = computed(() => {
  if (getBaseStartDate.value) {
    const gridStartDate = moment(
      new Date(getBaseStartDate.value),
      "YYYY-MM-DD"
    ).toDate();

    const gridEndDate = moment(
      new Date(getBaseEndDate.value),
      "YYYY-MM-DD"
    ).toDate();
    const date = new Date(gridStartDate.getTime());
    date.setDate(date.getDate());
    const dates = [];
    let id = 1;
    while (date <= gridEndDate) {
      const dateMoment = moment(date);
      dates.push({
        id,
        date: dateMoment.format("DD"),
        displayMonth: dateMoment.format("YYYY-MM-DD"),
        day: dateMoment.format("ddd"),
        month: dateMoment.month() + 1,
      });
      date.setDate(date.getDate() + 1);
      id++;
    }

    return dates;
  }
  return [];
});

const getChartContainerWidth = computed(() =>
  currentCalendarView.value === calendarView.DAY
    ? dayViewGrids.length * calendarBoxSize.value
    : currentCalendarView.value === calendarView.WEEK
    ? grids.value.length * calendarBoxSize.value
    : monthGrids.length * calendarBoxSize.value
);
const getTeamMemberCounts = computed(() => {
  const uniqueIds = new Set(projectList.value.map((item) => item?.userId));
  return uniqueIds.size;
});
const currentYear = ref(moment().year());

const getBaseStartDate = computed(() => {
  return moment(`${currentYear.value}-01-01`, "YYYY-MM-DD").format(
    "YYYY-MM-DD"
  );
});
const getBaseEndDate = computed(() => {
  const weekDateRange = getStartAndEndDateOfWeek(`${currentYear.value}-12-31`);
  return weekDateRange.endOfWeek;
});

const calculateTimePosition = (startTime, endTime) => {
  const times = [
    "12:00 am",
    "1:00 am",
    "2:00 am",
    "3:00 am",
    "4:00 am",
    "5:00 am",
    "6:00 am",
    "7:00 am",
    "8:00 am",
    "9:00 am",
    "10:00 am",
    "11:00 am",
    "12:00 pm",
    "1:00 pm",
    "2:00 pm",
    "3:00 pm",
    "4:00 pm",
    "5:00 pm",
    "6:00 pm",
    "7:00 pm",
    "8:00 pm",
    "9:00 pm",
    "10:00 pm",
    "11:00 pm",
  ];

  const quarters = {
    "00": 0,
    15: 0.25,
    30: 0.5,
    45: 0.75,
    59: 1,
  };

  const getHourAndMinute = (timeStr) => {
    const [hourStr, minuteStr, period] = timeStr.split(/[: ]/);
    let hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    if (period.toLowerCase() === "pm" && hour !== 12) {
      hour += 12;
    }
    return { hour, minute, period: period.toLowerCase() };
  };

  const {
    hour: startHour,
    minute: startMinute,
    period: startPeriod,
  } = getHourAndMinute(startTime);
  const {
    hour: endHour,
    minute: endMinute,
    period: endPeriod,
  } = getHourAndMinute(endTime);
  const startIndex = times.findIndex((time) => {
    const startTime = getHourAndMinute(time);
    return startTime.hour === startHour && startTime.period === startPeriod;
  });
  const endIndex = times.findIndex((time) => {
    const endTime = getHourAndMinute(time);
    return endTime.hour === endHour && endTime.period === endPeriod;
  });

  if (startIndex === -1 || endIndex === -1) {
    return null;
  }
  // const startPart = quarters[startMinute.toString().padStart(2, "0")];
  // const endPart = quarters[endMinute.toString().padStart(2, "0")];
  const startPart = Math.round((startMinute / 60) * 100) / 100;
  const endPart = Math.round((endMinute / 60) * 100) / 100;
  const left = startIndex + startPart;
  const width = endIndex - startIndex + endPart - startPart;

  return { left, width };
};

const getDayViewLayoutData = (startDate, endDate, startTime, endTime) => {
  const checkDate = moment(datePickerValue.value);
  const start = moment(startDate);
  const end = moment(endDate);
  const inRange = checkDate.isBetween(start, end, null, "[]");
  const isSameDate = checkDate.isSame(start, "day");
  const dayDiff = end.diff(start, "days");
  if (startTime && endTime && isSameDate && dayDiff === 0) {
    const layoutValue = calculateTimePosition(startTime, endTime);

    return {
      left: layoutValue.left * calendarBoxSize.value,
      width: layoutValue.width * calendarBoxSize.value,
    };
  } else if (startTime && endTime && inRange && dayDiff) {
    const diffDaysWithStartDateOfCalendar = start.diff(checkDate, "days");
    const firstDateCoordinate = calculateTimePosition(startTime, "11:59 PM");
    const lastDateCoordinate = calculateTimePosition("12:00 AM", endTime);
    const totalWidth =
      (dayDiff - 1) * 24 + firstDateCoordinate.width + lastDateCoordinate.width;
    const leftValue =
      diffDaysWithStartDateOfCalendar * 24 + firstDateCoordinate.left;
    return {
      left: leftValue * calendarBoxSize.value,
      width: totalWidth * calendarBoxSize.value,
    };
  }
  return {
    left: isSameDate ? 0 : inRange ? -5 : -2240,
    width: isSameDate ? 1920 : inRange ? 1925 : 1920,
  };
};
const getWeekViewLayoutData = (startDate, endDate) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const diffDays = endDateMoment.diff(startDateMoment, "days");

  const diffDaysWithStartDateOfCalendar = startDateMoment.diff(
    moment(getBaseStartDate.value),
    "days"
  );
  return {
    left: diffDaysWithStartDateOfCalendar * calendarBoxSize.value,
    width: (diffDays + 1) * calendarBoxSize.value,
  };
};

const getMonthViewLayoutData = (startDate, endDate) => {
  const firstDateOfYear = moment(getBaseStartDate.value);
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const startMonthDays = startDateMoment.date();
  const startMonthTotalDays = startDateMoment.daysInMonth();
  const endMonthDays = endDateMoment.date();
  const endMonthTotalDays = endDateMoment.daysInMonth();
  const startMonthDiff = startDateMoment
    .startOf("month")
    .diff(firstDateOfYear, "months");
  const fullMonthCount =
    endDateMoment
      .startOf("month")
      .diff(startDateMoment.startOf("month"), "months") - 1;
  let width;
  if (fullMonthCount === -1) {
    width = (endMonthDays - startMonthDays) / startMonthTotalDays;
  } else {
    width =
      fullMonthCount +
      (startMonthTotalDays - startMonthDays) / startMonthTotalDays +
      endMonthDays / endMonthTotalDays;
  }

  return {
    left:
      (startMonthDiff + (startMonthDays - 1) / startMonthTotalDays) *
      calendarBoxSize.value,
    width: width ? width * calendarBoxSize.value : 2,
  };
};

const createChart = () => {
  try {
    closeCalenderInfoDialog();
    const tasks = document.querySelectorAll(".phase-task");
    tasks.forEach((el, i) => {
      if (!el.dataset.startdate && !el.dataset.enddate) return;
      const currentViewKLayoutData =
        currentCalendarView.value === calendarView.DAY
          ? getDayViewLayoutData(
              el.dataset.startdate,
              el.dataset.enddate,
              el.dataset.starttime,
              el.dataset.endtime
            )
          : currentCalendarView.value === calendarView.WEEK
          ? getWeekViewLayoutData(el.dataset.startdate, el.dataset.enddate)
          : getMonthViewLayoutData(el.dataset.startdate, el.dataset.enddate);

      el.style.width = `${currentViewKLayoutData.width}px`;

      const taskBox = document.getElementById(
        `task-box-${el?.dataset?.mainindex}`
      );
      if (taskBox) {
        taskBox.style.left = `${currentViewKLayoutData.left}px`;
        taskBox.style.width = `${currentViewKLayoutData.width}px`;
      }
    });
  } catch (error) {
    console.log("error create chart", error);
  }
};

const getUpdateDate = (date, diffDays) => {
  return moment(new Date(date), "YYYY-MM-DD")
    .add(diffDays, "days")
    .format("YYYY-MM-DD");
};

const handleScrollChart = () => {
  const chartBoxElement = chartBoxRef.value;
  const sideTaskElement = sideTaskRef.value;
  sideTaskElement.scrollTop = chartBoxElement.scrollTop;
};
const handleSideScroll = () => {
  const chartBoxElement = chartBoxRef.value;
  const sideTaskElement = sideTaskRef.value;
  chartBoxElement.scrollTop = sideTaskElement.scrollTop;
};
let setTimeOut;
const handleHorizontalScrollChart = () => {
  closeCalenderInfoDialog();
  if (currentCalendarView.value !== calendarView.WEEK) return;
  clearTimeout(setTimeOut);
  setTimeOut = setTimeout(() => {
    const chartScrollContainer = chartScrollContainerRef.value;
    if (!chartScrollContainer) return;
    const scrollDateNumber = Math.round(
      chartScrollContainer.scrollLeft / calendarBoxSize.value
    );
    const currentFirstDateOfCalendar = getUpdateDate(
      getBaseStartDate.value,
      scrollDateNumber
    );
    setCurrentMonthDate(currentFirstDateOfCalendar);
  }, 200);
};

const setProfileImageURL = async (estimatePhase) => {
  const updatePhase = [];

  for (const [idx, phase] of estimatePhase.entries()) {
    const memberProfileImages = [];

    if (phase?.workStation) {
      const userImage = await getImageStringToImageURL(
        phase?.workStation?.profileImage
      );
      memberProfileImages.push({
        imageUrl: userImage,
        workStationName: phase.workStation.name,
      });
    }

    if (phase?.assignPhaseWorkStation?.id) {
      const userImage = await getImageStringToImageURL(
        phase?.assignPhaseWorkStation?.profileImage
      );
      memberProfileImages.push({
        imageUrl: userImage,
        workStationName: phase.assignPhaseWorkStation.name,
      });
    }

    if (phase?.assignTeamMembers?.length) {
      for (const assignMember of phase?.assignTeamMembers) {
        if (
          assignMember.status === EstimatePhaseAssignMemberStatusEnum.ACCEPTED
        ) {
          const teamUser = assignMember?.userWorkstationMember?.toUser;
          const userImage = await getImageStringToImageURL(
            teamUser?.userPublicProfile?.profileImage
          );
          memberProfileImages.push({
            imageUrl: userImage,
            workStationName: `${teamUser.firstName} ${teamUser.lastName}`,
          });
        }
      }
    }
    updatePhase.push({ ...phase, memberProfileImages });
  }

  return updatePhase;
};

const getProjectEstimateEndDate = (phases) => {
  return phases.reduce((endDate, phase) => {
    return (endDate = moment(endDate).isAfter(moment(phase?.phaseEndDate))
      ? moment(endDate).format("YYYY-MM-DD")
      : moment(phase?.phaseEndDate).format("YYYY-MM-DD"));
  }, "");
};

const getProjectEstimateStartDate = (phases) => {
  return phases.reduce((startDate, phase) => {
    return (startDate = moment(startDate).isBefore(
      moment(phase?.phaseStartDate)
    )
      ? moment(startDate).format("YYYY-MM-DD")
      : moment(phase?.phaseStartDate).format("YYYY-MM-DD"));
  }, "");
};

const isMemberPhase = (phaseData, contractor) => {
  return contractor.isTeamMember
    ? contractor.estimateIds.includes(phaseData.id)
    : phaseData?.assignPhaseWorkStation?.id
    ? phaseData?.assignPhaseWorkStation?.id == contractor.toContractorId
    : phaseData?.workStation?.id === contractor.toContractorId;
};
const getAllFilterPhases = (contractor, response) => {
  if (contractor.isTeamMember) {
    return response.estimationPhase;
  } else {
    const subContractorPhases = getSubContractorPhases(
      contractor.toContractorId,
      response?.inviteContractors,
      response?.estimationPhase
    );
    const parentContractorPhases = getParentContractorPhases(
      contractor.toContractorId,
      response?.inviteContractors,
      response?.estimationPhase
    );
    const currentConTractorPhases = getCurrentConTractorPhases(
      contractor.toContractorId,
      response?.estimationPhase
    );

    const finalArray = [
      ...subContractorPhases,
      ...parentContractorPhases,
      ...currentConTractorPhases,
    ];
    return finalArray;
  }
};
const filterProjectData = (response, contractor) => {
  try {
    const contractorWorkStationId = contractor.toContractorId;
    if (!response && !contractorWorkStationId) return null;
    const estimateForm = {
      estimateId: response.id,
      isTeamMember: contractor.isTeamMember === true,
      project: {
        id: response?.project?.id,
        name: response?.project?.name,
      },
      workStation: {
        id: contractor.isTeamMember
          ? response?.workStation?.id
          : contractor?.toContractorWorkstation?.id,
        name: contractor.isTeamMember
          ? response?.workStation?.name
          : contractor?.toContractorWorkstation?.name,
        type: contractor.isTeamMember
          ? parseJSON(response?.workStation?.subscription)?.metadata
              ?.businessType
          : parseJSON(contractor?.toContractorWorkstation?.subscription)
              ?.metadata?.businessType,
        profileImage: contractor.isTeamMember
          ? response?.workStation?.profileImage
          : contractor?.toContractorWorkstation?.profileImage,
      },
      user: {
        id: contractor?.toUser?.id,
        firstName: contractor?.toUser?.firstName,
        lastName: contractor?.toUser?.lastName,
        role: contractor?.toUser?.role?.name,
        profileImage: contractor?.toUser?.userPublicProfile?.profileImage,
      },

      estimationPhase: [],
    };

    const estimationPhase = getAllFilterPhases(contractor, response);
    estimationPhase.forEach((phase) => {
      if (!isMemberPhase(phase, contractor)) return;
      const estimatePhaseRecord = {
        id: phase?.id,
        stageType:
          phase.phaseType === ProjectPhaseType.LABOUR ? "labour" : "materials",
        subTotal: phase?.subTotal,
        name: phase?.name,
        phaseStatus: phase?.phaseStatus,
        phaseStartDate: phase?.phaseStartDate,
        phaseEndDate: phase?.phaseEndDate,
        isCompleted: phase?.phaseCompletionStatus,
        projectStageTasks: [],
        workStation: phase.workStation,
        assignPhaseWorkStation: phase.assignPhaseWorkStation,
        assignTeamMembers: phase.assignTeamMembers,
        color: phase?.color,
      };
      phase?.estimationPhaseTasks.forEach((subTask) => {
        const estimatePhaseSubTaskRecords = {
          id: subTask?.id,
          name: subTask?.name,
          taskCost: subTask?.taskCost,
          isCompleted: subTask?.taskCompletionStatus,
          startDate: subTask?.phaseTaskStartDate,
          endDate: subTask?.phaseTaskEndDate,
        };
        estimatePhaseRecord.projectStageTasks.push(estimatePhaseSubTaskRecords);
      });
      estimateForm.estimationPhase.push(estimatePhaseRecord);
    });
    return estimateForm;
  } catch (error) {
    console.log("filterProjectData error", error);
    return null;
  }
};
const getProjectEstimateDataByEstimateId = async (estimateId) => {
  try {
    const projectEstimateData =
      await projectEstimationService.getProjectEstimateByEstimationId(
        estimateId
      );
    return projectEstimateData;
  } catch (error) {
    return [];
  }
};

const getStoreCalendarData = computed(() =>
  props.calendarType === "project"
    ? store.getters[`${CALENDAR_STORE}/getProjectCalendarData`]
    : props.calendarType === "team"
    ? store.getters[`${CALENDAR_STORE}/getTeamCalendarData`]
    : []
);
const generateUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomString = Math.random().toString(36).substr(2, 10);
  return timestamp + randomString;
};
const setSidePanelDataList = async () => {
  projectList.value = [];
  if (props.calendarType === "project") {
    projectList.value = [
      ...teamCalenderEventData.value,
      ...projectCalendarData.value,
    ].map((estimateData) => ({
      id: estimateData.project.id,
      name: estimateData.project.name,
      phaseCount: estimateData.estimationPhase.length,
      color: estimateData?.color,
      projectStartDate: estimateData?.suggestedStartDate,
      projectEndDate: estimateData?.projectEstimateEndDate,
    }));
  } else if (props.calendarType === "team") {
    projectList.value = await Promise.all(
      [...teamCalenderEventData.value, ...projectCalendarData.value].map(
        async (estimateData, index) => ({
          id: generateUniqueId(),
          isEvent: estimateData?.isEvent,
          eventId: estimateData?.eventId,
          name: `${estimateData.user.firstName} ${estimateData.user.lastName}`,
          userId: estimateData.user.id,
          role: estimateData?.user?.role,
          profileImage: projectList.value[index]?.profileImage || {
            imageUrl: await getImageStringToImageURL(
              estimateData?.isEvent || estimateData.isTeamMember
                ? estimateData.user?.profileImage
                : estimateData?.workStation?.profileImage
            ),
            name:
              estimateData?.isEvent || estimateData.isTeamMember
                ? `${estimateData.user.firstName} ${estimateData.user.lastName}`
                : estimateData?.workStation?.name,
          },
          projectStartDate: estimateData?.suggestedStartDate,
          projectEndDate: estimateData?.projectEstimateEndDate,
        })
      )
    );
  }
};
const arrangeEstimateDataBaseOnTeamMember = (projectEstimate) => {
  if (!projectEstimate?.assignTeamMembers?.length) return [];
  const arrangeData = projectEstimate?.assignTeamMembers.reduce(
    (acc, teamMember) => {
      const userId = teamMember?.userWorkstationMember?.toUser?.id;
      if (!userId) return acc;
      if (!acc[userId]) {
        acc[userId] = {
          estimateIds: [],
          toUser: teamMember.userWorkstationMember.toUser,
          isTeamMember: true,
        };
      }
      acc[userId].estimateIds.push(teamMember.estimatePhase.id);
      return acc;
    },
    {}
  );
  return Object.values(arrangeData);
};
const getProjectCalendarData = async () => {
  try {
    if (
      getStoreCalendarData.value?.calendarData?.length &&
      getStoreCalendarData.value?.calendarSideList?.length
    ) {
      projectCalendarData.value =
        getStoreCalendarData.value.calendarData.filter(
          (data) => !data?.isEvent
        );
      teamCalenderEventData.value =
        getStoreCalendarData.value.calendarData.filter((data) => data?.isEvent);
      projectList.value = getStoreCalendarData.value.calendarSideList;
      return getStoreCalendarData.value.calendarData;
    }
    let allProjectData;
    if (props.calendarType === "project") {
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        const response =
          await calendarService.getProjectTradeSideCalendarData();
        allProjectData = response?.AllProjectData || [];
      } else {
        const response =
          await calendarService.getProjectPropertySideCalendarData();
        allProjectData = response?.AllProjectData?.length
          ? response.AllProjectData.map((project) => ({
              ...project,
              project: { id: project.id, name: project.name },
              suggestedStartDate: project?.projectEstimation.length
                ? project?.projectEstimation[0]?.suggestedStartDate
                : null,
              agreeTermsConsumerDisclosure: project?.projectEstimation.length
                ? project?.projectEstimation[0]?.agreeTermsConsumerDisclosure
                : null,
              estimationPhase: project?.projectEstimation.length
                ? project?.projectEstimation[0]?.estimationPhase
                : [],
            }))
          : [];
      }
      projectCalendarData.value = allProjectData
        .map((project, index) => ({
          ...project,
          projectEstimateEndDate: getProjectEstimateEndDate(
            project?.estimationPhase
          ),
          color: getRandomColorPair(index),
        }))
        .filter(
          (project) =>
            project.suggestedStartDate && project.projectEstimateEndDate
        );
      await setSidePanelDataList();

      for (const project of projectCalendarData.value) {
        project.estimationPhase = await setProfileImageURL(
          project.estimationPhase
        );
      }
      await store.dispatch(`${CALENDAR_STORE}/setProjectCalendarData`, {
        calendarData: {
          calendarData: projectCalendarData.value,
          calendarSideList: projectList.value,
        },
        type: "project",
      });
      return projectCalendarData.value;
    } else if (props.calendarType === "team") {
      if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
        projectCalendarData.value = [];
      } else {
        const response =
          await calendarService.getProjectTradeSideCalendarData();

        const filterEstimateRecords = response.AllProjectData.map(
          (projectEstimate) => ({
            estimateId: projectEstimate.id,

            teamMembers: arrangeEstimateDataBaseOnTeamMember(projectEstimate),
          })
        );
        const teamEstimateData = await Promise.all(
          filterEstimateRecords.map(async (estimate) => {
            if (!estimate?.estimateId) return [];
            const projectEstimateData =
              await getProjectEstimateDataByEstimateId(estimate.estimateId);
            if (!projectEstimateData) return [];
            let userContractorList = [];
            // projectEstimateData?.inviteContractors?.filter(
            //   (contractor) =>
            //     contractor?.fromContractorId ===
            //     activeUserWorkstation.value?.id
            // );
            if (estimate?.teamMembers?.length) {
              userContractorList = [
                ...userContractorList,
                ...estimate.teamMembers,
              ];
            }
            const filterEstimateData = userContractorList?.map((contractor) =>
              filterProjectData(projectEstimateData, contractor)
            );
            return filterEstimateData.filter((estimate) => estimate);
          })
        );

        projectCalendarData.value = teamEstimateData
          .flatMap((estimate) => estimate)
          .map((project, index) => ({
            ...project,
            suggestedStartDate: getProjectEstimateStartDate(
              project?.estimationPhase
            ),
            projectEstimateEndDate: getProjectEstimateEndDate(
              project?.estimationPhase
            ),
            color: getRandomColorPair(index),
            isEvent: false,
          }));
      }

      teamCalenderEventData.value = await getTeamCalenderEvents();

      await setSidePanelDataList();
      for (const project of projectCalendarData.value) {
        if (!project?.isEvent)
          project.estimationPhase = await setProfileImageURL(
            project.estimationPhase
          );
      }
      await store.dispatch(`${CALENDAR_STORE}/setProjectCalendarData`, {
        calendarData: {
          calendarData: [
            ...teamCalenderEventData.value,
            ...projectCalendarData.value,
          ],
          calendarSideList: projectList.value,
        },
        type: "team",
      });

      return [...teamCalenderEventData.value, ...projectCalendarData.value];
    }
  } catch (error) {
    console.log("get project data error", error);
  }
};

const getStartAndEndDateOfWeek = (date) => {
  let momentDate = moment(date);
  let startOfWeek = momentDate.clone().startOf("week");

  let endOfWeek = momentDate.clone().endOf("week");

  return {
    startOfWeek: startOfWeek.format("YYYY-MM-DD"),
    endOfWeek: endOfWeek.format("YYYY-MM-DD"),
  };
};
const setWeekBoxPosition = (date) => {
  const weekDateRange = getStartAndEndDateOfWeek(date);
  const diffDaysWithStartDateOfCalendar = moment(
    weekDateRange.startOfWeek
  ).diff(moment(getBaseStartDate.value), "days");

  const changeScrollLeftValue =
    diffDaysWithStartDateOfCalendar * calendarBoxSize.value;

  const weekViewBoxElement = document.querySelector(".week-view-box");
  if (weekViewBoxElement)
    weekViewBoxElement?.style?.setProperty(
      "--before-left",
      `${changeScrollLeftValue}px`
    );
};
const scrollToSelectedEventData = (changeScrollLeftValue) => {
  const scrollAreaWidth = Math.abs(
    changeScrollLeftValue - oldScrollLeftValue.value
  );
  const baseRate = 30;
  const scaleFactor = 50;
  let scrollSpeedRate = baseRate + scrollAreaWidth / scaleFactor;
  scrollSpeedRate = Math.min(scrollSpeedRate, 300);

  oldScrollLeftValue.value = changeScrollLeftValue;
  const chartScrollContainer = chartScrollContainerRef.value;
  if (!chartScrollContainer) return;
  let scrollLeftValue = chartScrollContainer.scrollLeft;
  const setPositionInterVal = setInterval(() => {
    if (scrollLeftValue < changeScrollLeftValue) {
      scrollLeftValue = Math.min(
        scrollLeftValue + scrollSpeedRate,
        changeScrollLeftValue
      );
    } else if (scrollLeftValue > changeScrollLeftValue) {
      scrollLeftValue = Math.max(
        scrollLeftValue - scrollSpeedRate,
        changeScrollLeftValue
      );
    }
    chartScrollContainer.scrollLeft = scrollLeftValue;
    if (scrollLeftValue === changeScrollLeftValue) {
      clearInterval(setPositionInterVal);
    }
  }, 1);
};

const onChangeDatePicker = (isCalledByDatePicker) => {
  if (isCalledByDatePicker) toggleCalendarView();

  setCurrentMonthDate(datePickerValue.value);

  const selectedDateYear = moment(datePickerValue.value).year();
  if (selectedDateYear !== currentYear.value) {
    currentYear.value = selectedDateYear;
    createChart();
  }

  if (currentCalendarView.value === calendarView.DAY) {
    createChart();
  } else if (currentCalendarView.value === calendarView.WEEK) {
    setWeekBoxPosition(datePickerValue.value);
    const diffDaysWithStartDateOfCalendar = moment(datePickerValue.value).diff(
      moment(getBaseStartDate.value),
      "days"
    );
    scrollToSelectedEventData(
      diffDaysWithStartDateOfCalendar * calendarBoxSize.value
    );
  } else if (currentCalendarView.value === calendarView.MONTH) {
    const firstDateOfYear = moment(getBaseStartDate.value);
    const startDateMoment = moment(datePickerValue.value);
    const startMonthDays = startDateMoment.date();
    const startMonthDiff = startDateMoment
      .startOf("month")
      .diff(firstDateOfYear, "months");
    const startMonthTotalDays = startDateMoment.daysInMonth();
    const diffDaysWithStartDateOfCalendar =
      startMonthDiff + (startMonthDays - 1) / startMonthTotalDays;

    scrollToSelectedEventData(
      diffDaysWithStartDateOfCalendar * calendarBoxSize.value
    );
  }
};
const toggleCalendarView = () => {
  isOpenCalendarView.value = !isOpenCalendarView.value;
};
const getMonthData = (date) => {
  const formateData = moment(date, "YYYY-MM-DD");
  return formateData.format("D MMM YYYY");
};
const setCurrentMonthDate = (date) => {
  if (currentCalendarView.value === calendarView.DAY)
    currentMonth.value = moment(date).format("D MMM YYYY");
  if (currentCalendarView.value === calendarView.WEEK)
    currentMonth.value = moment(date).format("MMMM YYYY");
  if (currentCalendarView.value === calendarView.MONTH)
    currentMonth.value = moment(date).format("YYYY");
};
const scrollDayViewToStartDate = (eventId) => {
  const event = finalProjectCalendarData.value.find(
    (event) => event?.isEvent && event?.eventId === eventId
  );
  const layoutData = getDayViewLayoutData(
    event.suggestedStartDate,
    event.projectEstimateEndDate,
    event.startTime,
    event.endTime
  );
  scrollToSelectedEventData(layoutData.left);
};
const onClickSideBarProfile = (projectDate) => {
  if (isLoading.value) return;
  datePickerValue.value = projectDate?.projectStartDate;

  onChangeDatePicker();
  if (currentCalendarView.value === calendarView.DAY) {
    if (projectDate.eventId) {
      scrollDayViewToStartDate(projectDate.eventId);
    } else {
      scrollToSelectedEventData(0);
    }
  }
};
const onChangeCalendarView = async (tabName) => {
  currentCalendarView.value = tabName;
  const headerEl = document.querySelector(".chart-container-header");
  headerEl?.classList?.remove("week-view-box");
  calendarBoxSize.value = 80;
  if (tabName === calendarView.WEEK) {
    headerEl?.classList?.add("week-view-box");
  }
  if (tabName === calendarView.MONTH) {
    calendarBoxSize.value = 110;
  }
  onChangeDatePicker();
  createChart();
};
const allTeamMemberDefaultValueList = ref([]);
const setAllTeamMemberList = async () => {
  try {
    const teamMemberRes = await manageMemberService.getAllTeamMemberList(
      user.value.id
    );

    allTeamMemberDefaultValueList.value = teamMemberRes.map((member) => ({
      user: {
        id: member?.inviteUser?.id || generateUniqueId(),
        firstName: member?.inviteUser?.firstName || member.email,
        lastName: member?.inviteUser?.lastName,
        email: member?.inviteUser?.email || member.email,
        role: member?.inviteUser?.role?.name || "not set",
        userPublicProfile: {
          profileImage:
            member.workstationOwner && member?.inviteUser?.id === user.value.id
              ? user.value?.userPublicProfile?.profileImage
              : member?.inviteUser?.userPublicProfile?.profileImage,
        },
        profileImage:
          member.workstationOwner && member?.inviteUser?.id === user.value.id
            ? user.value?.userPublicProfile?.profileImage
            : member?.inviteUser?.userPublicProfile?.profileImage,
      },
      isEvent: true,
      isDefaultEvent: true,
      suggestedStartDate: moment().format("YYYY-MM-DD"),
      projectEstimateEndDate: moment().format("YYYY-MM-DD"),
    }));
  } catch (error) {
    console.log("set all team member error : ", error);
  }
};

const getTeamCalenderEvents = async () => {
  try {
    const response = await calendarService.getCalenderMemberEvent();
    let filterRes = response.flatMap((event) => {
      const inviteAcceptUserEmails = event.inviteUsers.map(
        (user) => user.email
      );
      const invitationNotAcceptUserEmails = event.invitePeople.filter(
        (email) => !inviteAcceptUserEmails.includes(email)
      );

      const invitationAcceptedUserEvent = event.inviteUsers.map(
        (user, index) => ({
          user: {
            ...user,
            role: user.role.name,
            profileImage: user?.userPublicProfile?.profileImage,
          },
          eventId: event.id,
          isEvent: true,

          color: getRandomColorPair(index),
          suggestedStartDate: event.startDate,
          projectEstimateEndDate: event.endDate,
          startTime: event?.startTime,
          eventLabel: event.name,
          location: event?.location,
          description: event?.description,
          endTime: event?.endTime,
          invitePeople: event?.invitePeople,
        })
      );

      const invitationNotAcceptedUserEvent = invitationNotAcceptUserEmails.map(
        (email, index) => ({
          user: {
            id: generateUniqueId(),
            firstName: email,
            lastName: "",
            email: email,
            role: "not set",
            profileImage: null,
          },
          eventId: event.id,
          isEvent: true,

          color: getRandomColorPair(index),
          suggestedStartDate: event.startDate,
          projectEstimateEndDate: event.endDate,
          startTime: event?.startTime,
          eventLabel: event.name,
          location: event?.location,
          description: event?.description,
          endTime: event?.endTime,
          invitePeople: event?.invitePeople,
        })
      );

      return [
        ...invitationAcceptedUserEvent,
        ...invitationNotAcceptedUserEvent,
      ];
    });
    filterRes = setDefaultCurrentUserEvent(
      JSON.parse(JSON.stringify(filterRes))
    );
    return filterRes;
  } catch (error) {
    console.log("get team event error", error);
    return [];
  }
};

const setDefaultCurrentUserEvent = (filterRes) => {
  const phaseAssignTeamMembers = projectCalendarData.value
    .filter((project) => project.isTeamMember)
    .map((project) => project.user.id);
  allTeamMemberDefaultValueList.value.forEach((defaultValue) => {
    const findCurrentUserDataIndex = filterRes.findIndex((data) => {
      return data?.user?.email === defaultValue.user.email;
    });
    if (
      (!filterRes.length || findCurrentUserDataIndex === -1) &&
      !phaseAssignTeamMembers.includes(defaultValue.user.id)
    ) {
      if (filterRes.length && filterRes[0].user.id === user.value.id) {
        filterRes.splice(1, 0, defaultValue);
      } else {
        filterRes.unshift(defaultValue);
      }
    }
  });
  return filterRes;
};

const deleteEvent = async (projectData, index) => {
  closeCalenderInfoDialog();
  finalProjectCalendarData.value.splice(index, 1);

  finalProjectCalendarData.value.forEach((data) => {
    if (data.eventId === projectData.eventId) {
      data.invitePeople = data.invitePeople.filter(
        (email) => email !== projectData.user.email
      );
    }
  });
  teamCalenderEventData.value = finalProjectCalendarData.value.filter(
    (data) => data?.isEvent
  );

  teamCalenderEventData.value = setDefaultCurrentUserEvent(
    JSON.parse(JSON.stringify(teamCalenderEventData.value))
  );
  finalProjectCalendarData.value = [
    ...teamCalenderEventData.value,
    ...projectCalendarData.value,
  ];
  await setSidePanelDataList();
  store.dispatch(`${CALENDAR_STORE}/setProjectCalendarData`, {
    calendarData: {
      calendarData: finalProjectCalendarData.value,
      calendarSideList: projectList.value,
    },
    type: "team",
  });

  setTimeout(() => {
    createChart();
  }, 200);
};
const editEvent = async () => {
  try {
    isShowCreateEventModal.value = false;
    if (props.calendarType === "project") return;
    isLoading.value = true;
    closeCalenderInfoDialog();
    teamCalenderEventData.value = await getTeamCalenderEvents();

    finalProjectCalendarData.value = [
      ...teamCalenderEventData.value,
      ...projectCalendarData.value,
    ];
    await setSidePanelDataList();
    store.dispatch(`${CALENDAR_STORE}/setProjectCalendarData`, {
      calendarData: {
        calendarData: finalProjectCalendarData.value,
        calendarSideList: projectList.value,
      },
      type: "team",
    });

    setTimeout(() => {
      createChart();
    }, 200);
  } catch (error) {
    console.log("edit event error", error);
  } finally {
    isLoading.value = false;
  }
};

const scrollContainer = (currentSelectElement, event) => {
  const chartScrollContainer = chartScrollContainerRef.value;

  clearInterval(scrollInterval.value);
  const chartContainer = ganttChartContainerRef.value;
  const containerRect = chartContainer.getBoundingClientRect();
  const speedRate = 4;
  const smoothScroll = () => {
    initialLeft.value = getNumericValue(currentSelectElement.style.left);
    initialMouseX.value = event.clientX;
    if (scrollDirection.value === "right") {
      scrollLeft.value += speedRate;
      updateElementPosition(
        currentSelectElement,
        initialLeft.value + speedRate
      );
    } else if (scrollDirection.value === "left") {
      scrollLeft.value -= speedRate;
      updateElementPosition(
        currentSelectElement,
        initialLeft.value - speedRate
      );
    }
    scrollLeft.value =
      scrollLeft.value <= 0
        ? 0
        : scrollLeft.value >=
          getChartContainerWidth.value - containerRect.width + 200
        ? getChartContainerWidth.value - containerRect.width + 200
        : scrollLeft.value;
    chartScrollContainer.scrollLeft = scrollLeft.value;
    if (
      scrollLeft.value <= 0 ||
      scrollLeft.value >=
        getChartContainerWidth.value - containerRect.width + 200
    ) {
      clearInterval(scrollInterval.value);
    }
  };
  scrollInterval.value = setInterval(smoothScroll, 1);
};

const setTaskPosition = (currentSelectElement) => {
  clearTimeout(setPositionTimeout.value);
  setPositionTimeout.value = setTimeout(() => {
    const mainTaskLeftValue = getNumericValue(currentSelectElement.style.left);
    let currentValue = getNumericValue(currentSelectElement.style.left);
    const divider =
      currentCalendarView.value === calendarView.DAY
        ? calendarBoxSize.value / 4
        : currentCalendarView.value === calendarView.WEEK
        ? calendarBoxSize.value
        : calendarBoxSize.value / 30;
    const perfectPositionLeftValue =
      Math.round(mainTaskLeftValue / divider) * divider;

    const setPositionInterVal = setInterval(() => {
      let positionValue;
      if (mainTaskLeftValue < perfectPositionLeftValue) {
        positionValue = Math.min(currentValue + 1, perfectPositionLeftValue);
        currentValue += 1;
      } else {
        positionValue = Math.max(currentValue - 1, perfectPositionLeftValue);
        currentValue -= 1;
      }

      currentSelectElement.style.left = `${Math.round(positionValue)}px`;

      if (positionValue === perfectPositionLeftValue) {
        clearInterval(setPositionInterVal);
      }
    }, 1);
  }, 400);
};

const getElementCoordinate = (mainTaskIndex) => {
  const taskBoxElement = document.getElementById(`task-box-${mainTaskIndex}`);
  const mainTaskElement = document.getElementById(`maintask-${mainTaskIndex}`);
  const leftValue = getNumericValue(taskBoxElement.style.left);

  return taskBoxElement && mainTaskElement
    ? {
        startPoint: leftValue,
        endPoint: leftValue + getNumericValue(mainTaskElement.style.width),
      }
    : null;
};
const setElementWidth = (element, currentSelectElement, direction) => {
  clearTimeout(setPositionTimeout.value);
  setPositionTimeout.value = setTimeout(() => {
    let elementWidth = getNumericValue(element.style.width);
    let taskLeft = getNumericValue(currentSelectElement.style.left);
    const divider =
      currentCalendarView.value === calendarView.DAY
        ? calendarBoxSize.value / 4
        : calendarBoxSize.value;
    const changeWidth = Math.round(elementWidth / divider) * divider || divider;

    const setPositionInterVal = setInterval(() => {
      elementWidth = elementWidth + (elementWidth < changeWidth ? 1 : -1);
      taskLeft = taskLeft + (elementWidth < changeWidth ? -1 : 1);

      if (elementWidth === changeWidth) {
        taskLeft = Math.round(taskLeft / divider) * divider;
      }

      element.style.width = `${Math.round(elementWidth)}px`;
      currentSelectElement.style.width = `${Math.round(elementWidth)}px`;
      if (direction === "left") {
        currentSelectElement.style.left = `${Math.round(taskLeft)}px`;
      }

      if (elementWidth === changeWidth) {
        clearInterval(setPositionInterVal);
      }
    }, 1);
  }, 400);
};

const updateElementWidth = (
  currentSelectElement,
  mainTaskElement,
  mainTaskLeftValue
) => {
  const mainTaskElementWidth = getNumericValue(
    currentSelectElement.style.width
  );
  const elementPoint = getElementCoordinate(
    currentSelectElement.dataset.mainindex
  );
  const diff =
    (widthIncreaseDirection.value === "left" && mainTaskLeftValue < 0
      ? 0
      : widthIncreaseDirection.value === "right" &&
        elementPoint.endPoint > getChartContainerWidth.value
      ? initialLeft.value
      : mainTaskLeftValue) - initialLeft.value;

  const minWidth =
    currentCalendarView.value === calendarView.DAY
      ? calendarBoxSize.value / 4
      : calendarBoxSize.value;

  let widthDiff =
    widthIncreaseDirection.value === "left"
      ? mainTaskElementWidth - diff
      : mainTaskElementWidth + diff;

  widthDiff = widthDiff <= minWidth ? minWidth : widthDiff;

  const leftValue =
    mainTaskLeftValue < 0
      ? 0
      : mainTaskLeftValue >= elementPoint.endPoint - minWidth
      ? elementPoint.endPoint - minWidth
      : mainTaskLeftValue;

  mainTaskElement.style.width = `${Math.round(widthDiff)}px`;
  currentSelectElement.style.width = `${Math.round(widthDiff)}px`;
  if (widthIncreaseDirection.value === "left") {
    currentSelectElement.style.left = `${Math.round(leftValue)}px`;
  }
  if (currentCalendarView.value === calendarView.WEEK) {
    setElementWidth(
      mainTaskElement,
      currentSelectElement,
      widthIncreaseDirection.value
    );
  }
  if (widthDiff === minWidth) {
    clearInterval(scrollInterval.value);
    scrollDirection.value = null;
    onMouseUp();
  }
};

const updateElementPosition = (currentSelectElement, changeLeftValue) => {
  try {
    const mainTaskElement = document.getElementById(
      `maintask-${currentSelectElement.dataset.mainindex}`
    );
    const elementPoint = getElementCoordinate(
      currentSelectElement?.dataset?.mainindex
    );

    const mainTaskElementWidth = getNumericValue(mainTaskElement.style.width);

    const mainTaskLeftValue =
      elementPoint.startPoint < 0 && changeLeftValue < elementPoint.startPoint
        ? elementPoint.startPoint
        : elementPoint.startPoint >= 0 && changeLeftValue < 0
        ? 0
        : changeLeftValue >
            Math.abs(getChartContainerWidth.value - mainTaskElementWidth) &&
          elementPoint.endPoint <= getChartContainerWidth.value
        ? Math.abs(getChartContainerWidth.value - mainTaskElementWidth)
        : elementPoint.endPoint > getChartContainerWidth.value &&
          changeLeftValue >= elementPoint.startPoint
        ? elementPoint.startPoint
        : changeLeftValue;

    if (widthIncreaseDirection.value) {
      updateElementWidth(
        currentSelectElement,
        mainTaskElement,
        changeLeftValue
      );
      return;
    }
    currentSelectElement.style.left = `${mainTaskLeftValue}px`;
    if (currentCalendarView.value === calendarView.WEEK) {
      setTaskPosition(currentSelectElement);
    }
  } catch (error) {
    console.log("update element position error", error);
  }
};
const formatTime = (hourString, minute) => {
  let [hour, period] = hourString.split(" ");
  const formattedHour = parseInt(hour).toString().padStart(2, "0");
  const formattedMinute = minute.toString().padStart(2, "0");
  return `${formattedHour}:${formattedMinute} ${period}`;
};
const updateEstimateDates = async (element) => {
  try {
    if (!element) return;
    isLoading.value = true;
    let updatedEventData = null;
    const divider =
      currentCalendarView.value === calendarView.DAY
        ? calendarBoxSize.value / 4
        : currentCalendarView.value === calendarView.WEEK
        ? calendarBoxSize.value
        : calendarBoxSize.value / 30;
    const eventIndex = element.dataset.mainindex;

    const eventData = JSON.parse(
      JSON.stringify(finalProjectCalendarData.value[eventIndex])
    );

    const elementPoint = getElementCoordinate(eventIndex);
    elementPoint.startPoint =
      Math.round(elementPoint.startPoint / divider) * divider;
    elementPoint.endPoint =
      Math.round(elementPoint.endPoint / divider) * divider;

    if (currentCalendarView.value === calendarView.DAY) {
      const elStartPoint = Math.floor(
        elementPoint.startPoint / getChartContainerWidth.value
      );
      const elEndPoint = Math.ceil(
        elementPoint.endPoint / getChartContainerWidth.value
      );

      const newStartDate = getUpdateDate(datePickerValue.value, elStartPoint);

      const newEndDate = getUpdateDate(
        newStartDate,
        elEndPoint - elStartPoint - 1
      );

      if (elementPoint.startPoint < 0) {
        elementPoint.startPoint =
          Math.ceil(
            Math.abs(elementPoint.startPoint / getChartContainerWidth.value)
          ) *
            getChartContainerWidth.value +
          elementPoint.startPoint;

        elementPoint.endPoint +=
          moment(datePickerValue.value).diff(
            moment(eventData.suggestedStartDate),
            "days"
          ) * getChartContainerWidth.value;
      }
      const elementPointWithoutChange = getElementCoordinate(eventIndex);
      const elementStartPointDistance =
        elementPointWithoutChange.startPoint / calendarBoxSize.value;

      const elementEndPointDistance =
        elementPointWithoutChange.endPoint / calendarBoxSize.value;

      const startIndex = Math.floor(elementStartPointDistance);
      const endIndex = Math.floor(elementEndPointDistance);

      const startMinute = Math.round(
        60 * (elementStartPointDistance - startIndex)
      );
      const endMinute = Math.round(60 * (elementEndPointDistance - endIndex));

      const time = {
        startTime: formatTime(
          dayViewGrids[startIndex % dayViewGrids.length],
          startMinute
        ),
        endTime: formatTime(
          dayViewGrids[
            endIndex && !endMinute && endIndex % dayViewGrids.length === 0
              ? dayViewGrids.length - 1
              : endIndex % dayViewGrids.length
          ],
          endIndex && !endMinute && endIndex % dayViewGrids.length === 0
            ? 59
            : endMinute
        ),
      };

      if (!(time.startTime && time.endTime && newStartDate && newEndDate))
        return;
      updatedEventData = {
        name: eventData.eventLabel,
        startDate: newStartDate,
        endDate: newEndDate,
        location: eventData.location,
        startTime: time.startTime,
        endTime: time.endTime,
        description: eventData.description,
        invitePeople: eventData.invitePeople,
      };
    }

    if (currentCalendarView.value === calendarView.WEEK) {
      const elementStartPointDistance =
        elementPoint.startPoint / calendarBoxSize.value;

      const elementEndPointDistance =
        elementPoint.endPoint / calendarBoxSize.value;

      const startIndex = Math.floor(elementStartPointDistance);
      const endIndex = Math.floor(elementEndPointDistance);

      const newStartDate = getUpdateDate(
        grids.value[0]?.displayMonth,
        startIndex
      );

      const newEndDate = getUpdateDate(newStartDate, endIndex - startIndex - 1);

      if (!newStartDate && !newEndDate) return;
      updatedEventData = {
        name: eventData.eventLabel,
        startDate: newStartDate,
        endDate: newEndDate,
        location: eventData.location,
        startTime: eventData.startTime,
        endTime: eventData.endTime,
        description: eventData.description,
        invitePeople: eventData.invitePeople,
      };
    }

    if (
      updatedEventData.startTime === eventData.startTime &&
      updatedEventData.endTime === eventData.endTime &&
      updatedEventData.startDate === eventData.suggestedStartDate &&
      updatedEventData.endDate === eventData.projectEstimateEndDate
    ) {
      return;
    }

    await store.dispatch(`${CALENDAR_STORE}/updateCalendarEvent`, {
      TraderCalenderId: eventData.eventId,
      updateData: updatedEventData,
    });

    teamCalenderEventData.value.forEach((calendarData) => {
      if (calendarData.eventId === eventData.eventId) {
        calendarData.suggestedStartDate = updatedEventData.startDate;
        calendarData.projectEstimateEndDate = updatedEventData.endDate;
        calendarData.startTime = updatedEventData.startTime;
        calendarData.endTime = updatedEventData.endTime;
      }
    });
    finalProjectCalendarData.value = [
      ...teamCalenderEventData.value,
      ...projectCalendarData.value,
    ];
    await setSidePanelDataList();
    store.dispatch(`${CALENDAR_STORE}/setProjectCalendarData`, {
      calendarData: {
        calendarData: finalProjectCalendarData.value,
        calendarSideList: projectList.value,
      },
      type: "team",
    });
    closeCalenderInfoDialog();
    setTimeout(() => {
      createChart();
    }, 200);
  } catch (error) {
    console.log("update date error", error);
  } finally {
    isLoading.value = false;
  }
};
const getCurrentSelectElement = (element) => {
  const currentSelectElement =
    element?.dataset?.isevent == "true"
      ? document.getElementById(`task-box-${element?.dataset?.mainindex}`)
      : null;
  widthIncreaseDirection.value = null;
  if (element.classList.contains("left__space")) {
    widthIncreaseDirection.value = "left";
  }
  if (element.classList.contains("right__space")) {
    widthIncreaseDirection.value = "right";
  }
  return currentSelectElement;
};
const onMouseDown = (event) => {
  try {
    if (
      currentCalendarView.value === calendarView.MONTH ||
      props.calendarType === "project" ||
      isPersonalAccountWorkStation.value
    )
      return;
    const currentSelectElement = getCurrentSelectElement(event?.target);

    if (
      !currentSelectElement ||
      (currentCalendarView.value === calendarView.WEEK &&
        widthIncreaseDirection.value)
    )
      return;
    const elementPoint = getElementCoordinate(
      currentSelectElement?.dataset?.mainindex
    );

    if (
      elementPoint.startPoint < 0 &&
      elementPoint.endPoint > getChartContainerWidth.value
    )
      return;
    currentDragElement.value = currentSelectElement;
    phaseTaskLeftValue.value = getNumericValue(currentSelectElement.style.left);
    initialLeft.value = getNumericValue(currentSelectElement.style.left);
    initialMouseX.value = event.clientX;
    scrollLeft.value = chartScrollContainerRef.value.scrollLeft;
    const chartContainerElement = chartBoxRef.value;

    closeCalenderInfoDialog();

    chartContainerElement.addEventListener("mousemove", onMouseMove);
    chartContainerElement.addEventListener("mouseleave", onMouseUp);
    chartContainerElement.addEventListener("mouseup", onMouseUp);

    chartContainerElement.addEventListener("touchmove", onTouchMove);
    chartContainerElement.addEventListener("touchend", onTouchEnd);
    chartContainerElement.addEventListener("touchcancel", onTouchEnd);
  } catch (error) {
    console.log(" onMouseDown-error", error);
  }
};
const onMouseMove = (event) => {
  try {
    const currentSelectElement = currentDragElement.value;
    if (!currentSelectElement) return;

    const dx = event.clientX - initialMouseX.value;
    const changeLeftValue = initialLeft.value + dx;

    const chartContainer = ganttChartContainerRef.value;
    const containerRect = chartContainer.getBoundingClientRect();

    const currentElement =
      currentSelectElement.getElementsByClassName("phase-task");

    const elementRect = currentElement[0].getBoundingClientRect();
    const elementPoint = getElementCoordinate(
      currentSelectElement?.dataset?.mainindex
    );

    if (
      ((["right", null].includes(widthIncreaseDirection.value)
        ? elementRect.right
        : elementRect.left) >
        containerRect.right - 40 ||
        (scrollDirection.value === "right" &&
          initialMouseX.value <= event.clientX)) &&
      dx >= 0 &&
      (elementPoint.endPoint < getChartContainerWidth.value ||
        widthIncreaseDirection.value)
    ) {
      scrollDirection.value = "right";
      scrollContainer(currentSelectElement, event);
    } else if (
      ((["left", null].includes(widthIncreaseDirection.value)
        ? elementRect.left
        : elementRect.right) <
        containerRect.left + 200 + 40 ||
        (scrollDirection.value === "left" &&
          initialMouseX.value >= event.clientX)) &&
      dx < 1 &&
      (elementPoint.startPoint > 0 || widthIncreaseDirection.value)
    ) {
      scrollDirection.value = "left";
      scrollContainer(currentSelectElement, event);
    } else {
      clearInterval(scrollInterval.value);
      scrollDirection.value = null;
      updateElementPosition(currentSelectElement, changeLeftValue);
    }
    initialLeft.value = getNumericValue(currentSelectElement.style.left);
    initialMouseX.value = event.clientX;
  } catch (error) {
    console.log("onMouseMove error", error);
  }
};

const onMouseUp = (event) => {
  clearInterval(scrollInterval.value);
  scrollDirection.value = null;
  widthIncreaseDirection.value = null;

  if (currentDragElement.value) {
    updateEstimateDates(currentDragElement.value);
    currentDragElement.value = null;
  }
  const chartContainerElement = chartBoxRef.value;
  chartContainerElement.removeEventListener("mousemove", onMouseMove);
  chartContainerElement.removeEventListener("mouseleave", onMouseUp);
  chartContainerElement.removeEventListener("mouseup", onMouseUp);
  chartContainerElement.removeEventListener("touchmove", onTouchMove);
  chartContainerElement.removeEventListener("touchend", onTouchEnd);
  chartContainerElement.removeEventListener("touchcancel", onTouchEnd);
};

const onTouchStart = (event) => {
  onMouseDown(event.touches[0]);
};
const onTouchMove = (event) => {
  onMouseMove(event.touches[0]);
  if (getCurrentSelectElement(event.target)) {
    event.preventDefault();
  }
};

const onTouchEnd = (event) => {
  onMouseUp();
};
const closeCalenderInfoDialog = () => {
  const overLay = document.querySelector(".v-overlay__content");
  if (overLay) overLay.click();
};
const onToggleEventEditModal = () => {
  if (isPersonalAccountWorkStation.value) return toggleRestrictionModal();
  isShowCreateEventModal.value = !isShowCreateEventModal.value;
};

const isShowWorkstationRestrictionModal = ref(false);
const isPersonalAccountWorkStation = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name === "Default"
);

const toggleRestrictionModal = () => {
  isShowWorkstationRestrictionModal.value =
    !isShowWorkstationRestrictionModal.value;
};

onMounted(() => {
  const chartScrollContainer = chartScrollContainerRef.value;
  const sideTaskElement = sideTaskRef.value;
  if (sideTaskElement)
    sideTaskElement.addEventListener("scroll", handleSideScroll);
  const chartBoxElement = chartBoxRef.value;
  if (chartBoxElement)
    chartBoxElement.addEventListener("scroll", handleScrollChart);
  if (chartScrollContainer)
    chartScrollContainer.addEventListener(
      "scroll",
      handleHorizontalScrollChart
    );
});

onBeforeMount(async () => {
  try {
    isLoading.value = true;
    await setAllTeamMemberList();
    datePickerValue.value = moment().format("YYYY-MM-DD");
    if (isPersonalAccountWorkStation.value) {
      projectList.value =
        props.calendarType === "project" ? dummyUsers.project : dummyUsers.team;
      finalProjectCalendarData.value =
        props.calendarType === "project"
          ? dummyCalenderData.project
          : dummyCalenderData.team;
    } else {
      finalProjectCalendarData.value = await getProjectCalendarData();
    }

    setTimeout(() => {
      createChart();
    }, 200);
    onChangeDatePicker();
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
});

onBeforeUnmount(() => {
  const chartScrollContainer = chartScrollContainerRef.value;
  const chartBoxElement = chartBoxRef.value;
  if (chartBoxElement)
    chartBoxElement.removeEventListener("scroll", handleScrollChart);
  const sideTaskElement = sideTaskRef.value;
  if (sideTaskElement)
    sideTaskElement.removeEventListener("scroll", handleSideScroll);
  if (chartScrollContainer)
    chartScrollContainer.addEventListener(
      "scroll",
      handleHorizontalScrollChart
    );
});
</script>
<style lang="scss" scoped>
.button.desktop_wrapper {
  @include respond(s720) {
    position: absolute;
    left: 0;
    top: 0;
    margin: 24px 0 0;
    width: 100%;
    max-width: 100%;
  }
}
.schedule-page__content {
  @include respond(s720) {
    margin-top: 94px !important;
  }
}
.chart-container {
  padding: 0;
  padding-bottom: 0;
  list-style: none;
  position: relative;
  width: 18250px;
  overflow-x: hidden;
  background-color: transparent;
  z-index: 10;
  height: 610px;
  max-height: 540px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  :deep(.task-container) {
    padding: 0%;
    margin: 0%;
    border-radius: 0;
    height: 60px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: rgba(12, 15, 74, 0.1);
      left: 0;
      bottom: 0;
    }
    .task-wrapper {
      position: relative;
      .phase-task {
        cursor: pointer;
        max-width: 100%;
        width: 50px;
        background-color: #4a92e5;
        border-radius: 0;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 99;
        padding: 0;
        min-height: 60px;
      }
    }
  }

  .chart-bars-mobile {
    height: auto;
    overflow: auto;
  }
}

.sub-task {
  transition: 0.3s ease-in-out all;
  position: relative;
  z-index: 10;
}

.hide {
  display: none;
  transition: 0.3s ease-in-out all;
}

.month_wrapper {
  list-style: none;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.month_wrapper_item {
  position: absolute;
  top: 10px;
  width: 100px;
  text-align: left;
  @include fluidFont(18, 18, 1.2);
  color: rgba($blueDark, 1);
  font-weight: 700;
  letter-spacing: 0.25px;
}

.sub-task-item {
  margin: 17px 0;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  .subtask-label {
    position: relative;
    border-radius: 6px;
    padding: 8px 12px;
    background-color: #4a92e5;
    display: flex;
    gap: 5px;
    justify-content: space-between;

    .subtask-label-text {
      @include fluidFont(12, 12, 1.2);
      color: white;
      font-weight: 700;
      text-align: left;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: block;
      text-transform: capitalize;
    }
    .task_images {
      margin-right: 010;
    }
  }
}

.custom-border {
  width: 100%;
  height: 50px;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  position: absolute;
  left: 0;
  top: -33px;
  z-index: -2;
}
#app {
  line-height: 1 !important;
}

.chart-values {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid rgba(12, 15, 74, 0.1);
  border-right: 1px solid rgba(12, 15, 74, 0.1);
  border-left: 0;
  border-bottom: 1px solid rgba(12, 15, 74, 0.1);
  li {
    min-height: 60px;
    max-height: 60px;
    position: relative;
    min-width: 80px;
    max-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      position: absolute;
      content: "";
      width: 1px;
      left: 0;
      right: 0;
      top: 0;
      background-color: transparent;
      height: 100%;
    }
    &::after {
      position: absolute;
      content: "";
      width: 1px;
      right: 0;
      top: 0px;
      background-color: #0c0f4a1a;
      height: 667px;
    }
    .date_wrapper {
      @include fluidFont(12, 12, 1.3);
      font-weight: 500;
      color: rgba($blueDark, 1);
      text-transform: uppercase;
    }
  }
  .weekend {
    &::before {
      background-color: orange;
    }
    .date_wrapper {
      color: orange;
    }
    .day_wrapper {
      color: orange;
    }
  }
}
.work_bars {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  overflow-x: auto;
  overflow-y: hidden;
  @include respond(md) {
    width: calc(100% - 165px);
  }
}
.work_bars::-webkit-scrollbar {
  display: none;
}
.chart-container-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba($white, 1);
  width: 18950px;
}
.week-view-box {
  position: relative;
  &:before {
    content: "";
    left: var(--before-left, 0);
    position: absolute;
    width: 560px;
    height: 615px;
    background-color: #f6faff;
    z-index: -1;
    border: 1px solid #b1d2fc;
  }
}

.schedule-page {
  &__content {
    padding: 24px 0 0;
    box-sizing: border-box;
    border-bottom-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: rgba($white, 1);
    height: 750px;
    max-height: 700px;
    overflow-y: hidden;
    margin-top: 24px;
    border-radius: 12px;
    z-index: 100;
    position: relative;
    @include respond(s720) {
      max-height: 730px;
    }
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.mobile-view {
  height: 100%;
}

.full-screen-chart-container {
  height: auto;
  overflow: hidden;
}

.full-screen-chart-container .work_bars {
  overflow-y: auto;
  height: 100dvh;
}
.full-screen-chart-container .custom-class {
  display: none;
}
.full-screen-chart-container .chart-values li::before {
  height: calc(100dvh - 150px);
}
.full-screen-chart-container .chart-container {
  padding-bottom: 80px;
}
.drag-handle {
  cursor: grab;
  display: flex;
  gap: 0;
  margin-left: 5px;
  .v-icon {
    width: 6px;
  }
}
.fallback {
  display: none;
}

.taskbar_inner_content {
  display: flex;
  align-items: center;
  width: 100%;

  height: 100%;
  max-width: 100%;
  overflow: hidden;
  .phase_bar_btn {
    height: 38px;
    width: 5px;
    position: absolute;
    background-color: rgb(143 144 148 / 34%);
    cursor: col-resize;
  }

  .left_block {
    display: flex;
    align-items: center;
    gap: 0;
    width: 100%;
    .phase_task_text {
      text-align: left;
      padding: 10px;
      position: relative;
      width: 100%;
      .text__content {
        p {
          @include fluidFont(12, 12, 1.5);
          font-weight: 400;
          color: rgba($blueDark, 1);
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          @include fluidFont(12, 12, 1.5);
          font-weight: 400;
          color: rgba($blueDark, 0.6);
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
  }
  .right_block {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
.bar_btn_left {
  left: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.bar_btn_right {
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.task_images {
  display: flex;
  align-items: center;
  margin-right: 8px;
  .task_images_list {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
    margin: -5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &:first-child {
    margin-left: 0;
  }
}

.subtask__left-btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  z-index: 21;
  width: 5px;
  height: 30px;
  background-color: rgb(143 144 148 / 34%);
  left: 0px;
  cursor: col-resize;
}
.subtask__right-btn {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  z-index: 21;
  width: 5px;
  height: 30px;
  background-color: rgb(143 144 148 / 34%);
  right: 0;
  top: 0;
  cursor: col-resize;
}
.task_images {
  display: flex;
  align-items: center;
  .task_images_list {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    overflow: hidden;
    margin: -5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &:first-child {
    margin-left: 0;
  }
}
.tooltip_wrapper {
  :deep(.v-overlay__content) {
    opacity: 1;
  }
}
.chart-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.selectprop_dropdown {
  padding: 0 24px;
  :deep(.v-input__control) {
    .v-field {
      cursor: pointer;
      padding: 0;
      border: 1px solid rgba(232, 236, 238, 1);
      .v-field__field {
        .v-field__input {
          @include fluidFont(12, 12, 1.2);
          color: rgba($blueDark, 1);
          font-weight: 400;
          min-height: auto;
          padding: 7px 10px;
        }
      }
      .v-field__outline {
        display: none;
        &:focus-within {
          display: none;
        }
      }
      .v-field__append-inner {
        @include fluidFont(6, 6, 1);
        margin-right: 7px;
        width: auto;
      }
    }
  }
}
.v-field:hover .v-field__outline {
  --v-field-border-opacity: 0;
}
v-field--focused {
  display: none;
}
:deep(.v-field:hover) .v-field__outline {
  --v-field-border-opacity: 0;
}
.custom__calendar {
  position: relative !important;
  display: flex;
  align-items: center;
  gap: 30px;
}
.schedule-page__list-container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(12, 15, 74, 0.1);
  .schedule-page__title {
    height: 60px;
    padding: 0 0 0 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 60px;
    position: relative;
    border-top: 1px solid rgba(12, 15, 74, 0.1);
    box-shadow: none;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      background-color: rgba(12, 15, 74, 0.1);
      bottom: -2px;
      box-shadow: none;
    }

    h4 {
      @include fluidFont(18, 18, 1.3);
      font-weight: 700;
      color: rgba($blueDark, 1);
      display: flex;
      gap: 16px;
      align-items: center;
      span {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include fluidFont(15, 15, 1.3);
        font-weight: 700;
        color: rgba($blueDark, 1);
        border: 2px solid rgba($orange, 1);
        border-radius: 6px;
      }
    }
    .team-calendar-label {
      font-size: 16px !important;
      gap: 8px !important;
    }
  }
}

.week_wrapper {
  @include fluidFont(12, 12, 1.3);
  font-weight: 500;
  color: rgba($blueDark, 1);
}

.calendar__inner {
  display: flex;
  margin-top: 24px;
}
.current-month {
  @include fluidFont(18, 18, 1.3);
  font-weight: 700;
  color: rgba($blueDark, 1);
  text-align: left;
}

.schedule-page__list_item {
  height: 610px;
  max-height: 540px;
  overflow: auto;
  margin-top: 2px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.schedule-page__list_item::-webkit-scrollbar {
  display: none;
}

.calendar__tabs {
  margin-right: 30px;
  @include respond(s720) {
    margin-left: 30px;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    li {
      .v-btn {
        padding: 0;
        border-radius: 5px;
        :deep(.v-btn__content) {
          @include fluidFont(12, 12, 1.4);
          font-weight: 400;
          color: rgba($blueDark, 1);
          text-transform: capitalize;
        }
      }
      .active {
        background-color: rgba($PrimaryPurple, 1);
        :deep(.v-btn__content) {
          color: rgba($white, 1);
        }
      }
    }
  }
}
.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include respond(s720) {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 12px;
  }
}
.custom-class {
  background-color: #fafcff;
}

.select-date {
  padding: 8px 15px;
  border-radius: 30px;
  background-color: rgba($PrimaryPurple, 1);
  color: rgba($white, 1) !important;
}

/* Month view layout*/
.month-view-layout {
  .task-content-wrapper {
    .calendar__inner {
      .work_bars {
        .chart-container-header {
          .chart-values {
            .week_wrapper {
              min-width: 110px;
              max-width: 110px;
            }
          }
        }
        .chart-container {
          .task-container {
            display: flex;
            align-items: center;
            .task-wrapper {
              .phase-task {
                min-height: 30px;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}

.phase_task_text .left__space {
  width: 3px;
  height: 60px;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0;
}

.phase_task_text:hover .right__space {
  width: 3px;
  height: 60px;
  background-color: #000;
  position: absolute;
  right: 0;
  top: 0;
}
.cursor-resize {
  cursor: ew-resize;
}
</style>
