<template>
  <div class="form_wrapper" v-if="verified === false">
    <div class="form_wrapper_content">
      <div class="title">
        <h3 class="logo-description default-text">
          Time to check your emails. Please enter the code we have just sent to
          you.
        </h3>
      </div>
      <p>
        iknowa has just sent a code validation email to
        <strong>{{ userEmail }}</strong>
      </p>
      <label class="yellow-text" @click="resendEmail()"
        >Resend email<v-icon icon="mdi-chevron-right"
      /></label>
    </div>
    <v-form key="" class="">
      <v-otp-input
        v-model="otp"
        length="4"
        variant="solo-filled"
        class=""
        bg-color="rgba(12, 15, 74, 0.0509803922)"
      ></v-otp-input>
      <p class="error_msg" v-if="emailErrorMessage">
        {{ emailErrorMessage }}
      </p>
      <v-btn
        :disabled="otp.length < 4"
        class="button button-orange"
        size="large"
        rounded="lg"
        block
        @click.enter="redirectToSuccess"
      >
        verify email & continue
      </v-btn>
    </v-form>
    <div class="card-part">
      <p class="common-title">Already have an account</p>
      <label class="yellow-text" @click="returnToLogin">
        Log In<v-icon icon="mdi-chevron-right" />
      </label>
    </div>
    <div class="benefit_wrapper llg:!tw-mb-[20px]">
      <div class="title">
        <h6><v-icon icon="mdi-star" />iknowa Benefits</h6>
      </div>
      <h5>Take a look at all the amazing benefits iknowa offers</h5>
      <label @click="openOverview" class="yellow-text"
        >Open iknowa Overview <v-icon icon="mdi-chevron-right"
      /></label>
    </div>
  </div>

  <div v-else class="success_wrapper">
    <div class="title">
      <img
        class="open-logo"
        src="../../../assets/icons/yellow-success-icon.svg"
      />

      <h3>Success!</h3>
    </div>
    <div class="description">
      <p>Your email has been verified successfully.</p>
      <p>Please login.</p>
    </div>
    <v-btn
      class="button button-orange"
      size="large"
      block
      @click.enter="returnToLogin"
    >
      continue
    </v-btn>

    <div class="card-part tw-mb-4">
      <p class="common-title">Already have an account</p>
      <label class="yellow-text" @click="returnToLogin">
        Log In<v-icon icon="mdi-chevron-right" />
      </label>
    </div>
  </div>
  <AuthIknowaInfoMobileModal
    v-if="isShowAuthIknowaInfoMobile"
    @onCloseButtonClick="onCloseButtonClick"
  />
</template>

<script lang="ts">
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import AuthIknowaInfoMobileModal from "@/core/components/AuthIknowaInfoMobileModal.vue";

export default {
  components: {
    AuthIknowaInfoMobileModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const internalInstance = getCurrentInstance();
    const verified = ref(false);
    const userEmail = ref();
    const otp = ref("");
    const emailErrorMessage = ref("");

    const isShowAuthIknowaInfoMobile = ref(false);

    const returnToLogin = () => {
      router.push("/");
    };

    onMounted(() => {
      userEmail.value = router.currentRoute?.value?.query?.email;
    });

    const redirectToSuccess = async () => {
      const data = {
        email: userEmail.value,
        code: Object.values(otp.value).join().replaceAll(",", ""),
      };
      try {
        const response = await store.dispatch(
          `${AUTHENTICATION_STORE}/verifyCode`,
          data
        );
        if (response) {
          verified.value = true;
        }
      } catch (error) {
        emailErrorMessage.value = "Invalid Code";
        console.log("error", error);
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const resendEmail = async () => {
      try {
        await store.dispatch(
          `${AUTHENTICATION_STORE}/resendEmailOTP`,
          userEmail.value
        );
        displayToast("Resend Mail Successfully ", "success");
      } catch (error) {
        console.log("error", error);
      }
    };

    const openOverview = () => {
      isShowAuthIknowaInfoMobile.value = true;
      document.body.classList.add("noScrollModal");
    };

    const onCloseButtonClick = () => {
      isShowAuthIknowaInfoMobile.value = false;
      document.body.classList.remove("noScrollModal");
    };

    return {
      returnToLogin,
      redirectToSuccess,
      resendEmail,
      userEmail,
      verified,
      otp,
      openOverview,
      emailErrorMessage,
      onCloseButtonClick,
      isShowAuthIknowaInfoMobile,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/registration/styles/verify.scss";
</style>
