<template>
  <main
    v-if="!isLoading"
    class="tw-h-full tw-w-full tw-flex tw-items-start tw-gap-8 lg:tw-flex-col lg:tw-gap-4"
  >
    <section
      class="tw-flex tw-flex-col tw-col-span-2 md:tw-py-4 md:tw-border-b md:tw-border-b-[#C5D2F2] md:tw-border-solid md:tw-border-x-0 md:tw-border-t-0 tw-w-full"
    >
      <div
        class="tw-flex tw-flex-col tw-gap-4 tw-pb-4 tw-border-b tw-border-b-[#C5D2F2] tw-border-solid tw-border-x-0 tw-border-t-0 tw-items-start"
      >
        <Text variant="h4">{{ historicalProjectForm?.title }}</Text>
        <div class="tw-flex tw-items-center tw-gap-4">
          <StandardChip />
          <CommercialChip />
          <Postcode :value="propertyDetails?.postcode" />
        </div>
      </div>

      <HistoricalEstimateFormScreen
        :estimateForm="estimateForm"
        :showTimeLineButton="true"
        @on-toggle-gantt="onTogglegantt"
      />
    </section>
    <section
      v-if="state.screenType === ESTIMATE_FORM && !isShowGantt"
      class="tw-box-border lg:tw-pl-0 md:tw-pt-4 tw-w-[350px] md:tw-w-full"
    >
      <EstimateSummaryCard
        :isProperty="true"
        :project="currentJobDetails"
        :projectEstimateId="formData.id"
        @on-save-estimation="debouncedSaveEstimation"
        @onFinalSave="onFinalSave"
      />
    </section>

    <Button
      label="next"
      class="!tw-max-w-full !tw-hidden md:!tw-block"
      @click="$emit('onNext')"
      :isActive="isFormValid"
    />
    <!-- MODALS -->
    <!-- <section class="estimate_summary">
      <div class="estimate_summary__header">
        <div class="estimate_summary__header__title">
          <h5>Your Estimate Summary</h5>
          <v-icon icon="mdi-information-variant-circle-outline"></v-icon>
        </div>
        <div class="estimate_summary__header__info">
          <div class="custom__card">
            <h6>DD/MM/YY</h6>
            <p>Start Date</p>
          </div>
          <div class="custom__card">
            <h6>N/A</h6>
            <p>Total phases</p>
          </div>
          <div class="custom__card">
            <h6>N/A</h6>
            <p>Duration</p>
          </div>
        </div>
      </div>
      <div class="estimate_summary__phase">
        <div class="estimate_summary__phase__tag">
          <span>Phase 1</span>
        </div>
        <div class="estimate_summary__phase__tasks">
          <span>0 tasks</span>
          <span>|</span>
          <span>0 days</span>
          <span>|</span>
          <span>£0</span>
        </div>
      </div>
      <div class="estimate_summary__phase__grant__button">
        <v-btn class="button button-purple-border" width="100%" max-width="100%"
          >Add grant
        </v-btn>
      </div>
      <div class="estimate_summary__phase__cost">
        <div class="estimate_summary__phase__cost__details">
          <h6>Grant</h6>
          <p>£0.00</p>
        </div>
        <div class="estimate_summary__phase__total__cost__details">
          <h6>Total Cost</h6>
          <p>£0.00</p>
        </div>
      </div>
      <div class="estimate_summary__phase__grant__button">
        <v-btn class="button button-purple-border" width="100%" max-width="100%"
          >clear
        </v-btn>
      </div>
    </section> -->
  </main>

  <div v-else class="tw-h-full tw-w-full">
    <CommonLoader :loading="isLoading" />
  </div>
  <GanttChart
    v-if="isShowGantt && getGanttChartEstimateFormData"
    :isEditable="false"
    :estimateData="getGanttChartEstimateFormData"
    :isShowAllPhase="true"
  />
</template>
<script setup>
import { computed, onBeforeMount, reactive } from "vue";
import HistoricalEstimateFormScreen from "@/modules/jobs/components/screens/HistoricalEstimateFormScreen.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import Button from "@/core/components/ui/general/Button.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { ref, onMounted } from "vue";
import {
  AgreeTermsConsumerDisclosure,
  ContractorRequestStatusEnum,
} from "@/core/enums/estimateEnum";
import { JOBS_STORE } from "@/store/modules/jobs";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import CommonLoader from "@/core/components/CommonLoader.vue";
import EstimateSummaryCard from "@/modules/jobs/components/estimates/EstimateSummaryCard.vue";
import GanttChart from "@/modules/jobs/components/estimates/GanttChart.vue";
import moment from "moment";
import { subtract } from "lodash";

const props = defineProps({
  historicalProjectForm: {
    type: Object,
  },
  propertyDetails: {
    type: Object,
  },
});
const emits = defineEmits(["onNext"]);
const ESTIMATE_FORM = "estimate-form";

const store = useStore();
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const state = reactive({
  hideBanner: false,
  saveTemplateModal: false,
  contractorInvitationModal: false,
  // TEMPORARY STATUS
  screenType: ESTIMATE_FORM,
});
const isLoading = ref(false);
const receiveInvitationList = ref([]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isShowGantt = ref(false);

const onTogglegantt = (event) => {
  isShowGantt.value = event;
};

const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];
  if (
    estimates &&
    estimates.projectStages.length &&
    !estimates.projectStages[0].user
  ) {
    estimates.projectStages[0].user = user.value;
  }
  if (
    estimates &&
    estimates.projectStages.length &&
    !estimates.projectStages[0].workStation
  ) {
    estimates.projectStages[0].workStation = activeUserWorkstation.value;
  }

  return estimates;
});
const getGanttChartEstimateFormData = computed(() => {
  const estimationPhase = estimateForm.value.projectStages.map(
    (phase, index) => ({
      ...phase,
      phaseStartDate: phase?.startDate,
      phaseEndDate: phase?.endDate,
      id: phase.id || index + 1,
      phaseType: phase.stageType === "labour" ? 1 : 2,
      estimationPhaseTasks: phase.projectStageTasks.map((subTask, idx) => ({
        ...subTask,
        id: subTask.id || idx + 1,
        phaseTaskStartDate: subTask.startDate,
        phaseTaskEndDate: subTask.endDate,
      })),
    })
  );
  return {
    estimationPhase,
    projectEstimateEndDate: !isNaN(new Date(projectEstimateEndDate.value))
      ? projectEstimateEndDate.value
      : null,
    ...formData.value,
  };
});
const projectEstimateEndDate = computed(() =>
  estimateForm.value.projectStages
    .filter((phase) => phase.stageType === "labour")
    .reduce((endDate, phase) => {
      return (endDate = moment(endDate).isAfter(moment(phase?.endDate))
        ? moment(endDate).format("YYYY-MM-DD")
        : moment(phase?.endDate).format("YYYY-MM-DD"));
    }, "")
);
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const debouncedSaveEstimation = () => {
  return;
};
</script>
<script>
export default {
  name: "",
};
</script>
<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}

/* Estiamte Summary */
.estimate_summary {
  width: 350px;
  max-width: 100%;
  background-color: rgba($white, 1);
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  padding: 16px 24px;
  border-radius: 8px;
  @include respond(s1024) {
    width: 100%;
  }
  .estimate_summary__header {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
    .estimate_summary__header__title {
      display: flex;
      gap: 4px;
      align-items: flex-start;
      h5 {
        @include fluidFont(18, 18, 1.3);
        font-weight: 700;
        color: rgba($blueDark, 1);
        letter-spacing: 0.25px;
        text-align: left;
      }
      .v-icon {
        @include fluidFont(14, 14, 1);
        color: rgba($buttonText, 1);
      }
    }
    .estimate_summary__header__info {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: 16px 0 0;
      .custom__card {
        background-color: rgba(243, 243, 246, 1);
        border-radius: 8px;
        padding: 8px;
        width: 33.33%;
        max-width: 100%;
        h6 {
          @include fluidFont(12, 12, 1.3);
          font-weight: 700;
          color: rgba($blueDark, 1);
          letter-spacing: 0.25px;
        }
        p {
          @include fluidFont(10, 10, 1.3);
          font-weight: 400;
          color: rgba($blueDark, 0.7);
          letter-spacing: 0.25px;
        }
      }
    }
  }
  .estimate_summary__phase {
    text-align: left;
    margin: 32px 0;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    border-radius: 8px;
    padding: 8px;
    .estimate_summary__phase__tag {
      span {
        padding: 5px;
        border-radius: 5px;
        background-color: rgba($info, 0.1);
        color: rgba($info, 1);
        @include fluidFont(10, 10, 1.2);
        letter-spacing: 0.25px;
        font-weight: 600;
      }
    }
    .estimate_summary__phase__tasks {
      margin-top: 10px;
      max-width: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: rgba($blueDark, 0.5);
        @include fluidFont(10, 10, 1.2);
        letter-spacing: 0.25px;
        font-weight: 600;
      }
    }
  }
  .estimate_summary__phase__grant__button {
    padding-top: 32px;
    border-top: 1px solid rgba($PrimaryBlueLight, 1);
  }
  .estimate_summary__phase__cost {
    margin-top: 32px;
    .estimate_summary__phase__cost__details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      h6 {
        color: rgba($blueDark, 0.5);
        @include fluidFont(14, 14, 1.2);
        letter-spacing: 0.25px;
        font-weight: 400;
      }
      p {
        color: rgba($blueDark, 1);
        @include fluidFont(14, 14, 1.2);
        letter-spacing: 0.25px;
        font-weight: 600;
      }
    }
    .estimate_summary__phase__total__cost__details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      border-top: 1px solid rgba($PrimaryBlueLight, 1);
      padding-top: 8px;
      h6 {
        color: rgba($blueDark, 1);
        @include fluidFont(14, 14, 1.2);
        letter-spacing: 0.25px;
        font-weight: 700;
      }
      p {
        color: rgba($blueDark, 1);
        @include fluidFont(14, 14, 1.2);
        letter-spacing: 0.25px;
        font-weight: 600;
      }
    }
  }
  .estimate_summary__phase__grant__button {
    border: none;
  }
}
</style>
