export enum ProjectStatusType {
  DRAFT_PROJECT = "0",
  ACTIVE_PROJECT = "1",
  COMPLETED_PROJECT = "2",
}

export enum ProjectSubTypesEnum {
  WEEKLY = "weekly",
  FORTNIGHTLY = "fortnightly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
  FLEXIBLE = "flexible",
  SCHEDULE = "schedule",
}

export enum ProjectTypesEnum {
  STANDARD = "standard",
  EMERGENCY = "emergency",
  ROUTINE = "routine",
  VIDEO_CONSULTATION = "video consultation",
}

export enum ProjectPhaseType {
  LABOUR = 1,
  MATERIAL = 2,
}

export enum ProjectListStatusType {
  DRAFT_PROJECT = "0",
  AWAITING_ESTIMATES = "1",
  NOT_STARTED = "2",
  IN_PROGRESS = "3",
  COMPLETED = "4",
  CANCELLED = "5",
  ALL = "6",
  ESTIMATES_RECEIVED = "7",
}

export enum ProjectJobStatusEnum {
  CANCELLED = "-1",
  DRAFT_PROJECT = "0",
  AWAITING_QUOTES = "1",
  QUOTES_RECEIVED = "2",
  QUOTE_ACCEPTED = "3",
  QUOTE_CONFIRMED = "4",
  IN_PROGRESS = "5",
  COMPLETED = "6",
}

export enum PropertyCategoryEnum {
  COMMERCIAL = "Commercial",
  RESIDENTIAL = "Residential",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}
export enum HistoricProjectStatusEnum {
  DRAFT = "0",
  VERIFIED = "1",
  PENDING = "2",
  ACCEPTED = "3",
  REJECTED = "4",
}

export enum HistoricalProjectTypeEnum {
  TRADE_VERIFY = 1,
  DIY_PROJECT = 2,
}

export enum HistoricalWorkFlowTypeEnum {
  CLIENT = "client workflow",
  PROPERTY = "property workflow",
}

export enum ProjectSpecialismGeneratedEnum {
  KRYSTAL = "krystal",
  PROPERTY = "property",
  TRADE = "trade",
}

export enum ProjectRatingTypeEnum {
  TRADER_OWNER = "trade-owner",
  PROPERTY_OWNER = "property-owner",
}

export const RatingStatusData = [
  { label: " Excellent", rating: 5 },
  { label: "Good", rating: 4 },
  { label: "Fair", rating: 3 },
  { label: "Poor", rating: 2 },
  { label: "Very Poor", rating: 1 },
];

export const TradeSideRatingTags = [
  { label: "Communication & Responsiveness", id: 1 },
  { label: "Respect Towards Trade Contractors", id: 2 },
  { label: "Payment Management & Release", id: 3 },
];
export const PropertySideRatingTags = [
  { label: "Overall Satisfaction", id: 1 },
  { label: "Quality of Workmanship", id: 2 },
  { label: "Communication", id: 3 },
  { label: "Cleanliness of Worksite", id: 4 },
  { label: "Budget Management", id: 5 },
];
export enum PropertySubscriptionTypeEnum {
  HEAT_PUMP_SERVICE = "heat pump service",
}

export enum ProjectFrom {
  REGULAR = "regular",
  RECOMMENDATION = "recommendation",
}
