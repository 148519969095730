<template>
  <GeneralDialog
    class="tw-w-full tw-max-w-[860px] tw-min-w-[300px] rmd:!tw-min-w-[860px]"
    @on-close="onCloseModal"
    width="auto"
  >
    <template #body>
      <main
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center rmd:!tw-w-[600px]"
      >
        <Text
          variant="h4"
          class="rmd:!tw-text-[32px] rmd:!tw-leading-[40px]"
          textWeight="400"
          textAlign="center"
          lineHeight="30px"
          >You are exploring this area<br />
          as
          <Text variant="h4" class="rmd:!tw-text-[32px]"
            >Toby Sparks</Text
          ></Text
        >
        <div
          class="tw-flex tw-w-full tw-items-center tw-justify-center tw-h-auto"
        >
          <img
            :src="require('@/assets/images/toby-sparks.jpg')"
            alt="toby-sparks iknowa"
          />
        </div>

        <Text
          textAlign="center"
          variant="p"
          class="rmd:!tw-text-base"
          textColor="rgba(12, 15, 74, 0.5)"
          textWeight="400"
          >Learn more on how iknowa platform<br />
          can help your business grow.</Text
        >
      </main>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-4 tw-items-center tw-px-0 rmd:!tw-px-20"
      >
        <!-- <Button variant="tertiary" label="Tutorial" @click="onTutorial" /> -->
        <Button label="Explore as toby sparks 🚀" @click="onCloseModal" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();

const emits = defineEmits(["on-close"]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isDefault = computed(() => {
  return activeUserWorkstation.value.name === "Default";
});

const onCloseModal = () => {
  emits("on-close");
};
</script>
