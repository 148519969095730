import { getRandomColor } from "@/core/utils/common";

export default {
  setEstimateForm(
    state: {
      estimateForm: { projectStages: any[] };
      defaultProjectStageForm: any;
    },
    value: any
  ) {
    if (value) {
      state.estimateForm = JSON.parse(JSON.stringify(value));
    } else {
      state.estimateForm.projectStages = [
        JSON.parse(JSON.stringify(state.defaultProjectStageForm)),
      ];
    }
  },

  setSelectedPhaseIndex(state: { selectedPhaseIndex: any }, value: any) {
    state.selectedPhaseIndex = value;
  },

  setTemplateName(state: { templateName: any }, value: any) {
    state.templateName = value;
  },

  addProjectStageMutation(
    state: { estimateForm: { projectStages: any[] } },
    newProjectStage: any
  ) {
    state.estimateForm.projectStages.push({
      ...newProjectStage,
      phaseOrder: state.estimateForm.projectStages.length,
    });
  },

  setShowPhaseUploadAttachmentModal(
    state: { showPhaseUploadAttachmentModal: any },
    value: any
  ) {
    state.showPhaseUploadAttachmentModal = value;
  },

  setShowTaskUploadAttachmentModal(
    state: { showTaskUploadAttachmentModal: any },
    value: any
  ) {
    state.showTaskUploadAttachmentModal = value;
  },

  setShowCreateTemplateConfirmationModal(
    state: { showCreateTemplateConfirmationModal: any },
    value: any
  ) {
    state.showCreateTemplateConfirmationModal = value;
  },

  setShowUseTemplateModal(state: { showUseTemplateModal: any }, value: any) {
    state.showUseTemplateModal = value;
  },

  setShowSubmitEstimateConfirmationModal(
    state: { showSubmitEstimateConfirmationModal: any },
    value: any
  ) {
    state.showSubmitEstimateConfirmationModal = value;
  },

  setSelectedJobTemplate(state: { selectedJobTemplate: any }, value: any) {
    state.selectedJobTemplate = value;
  },

  setIsOnEstimateForm(state: { isOnEstimateForm: any }, value: any) {
    state.isOnEstimateForm = value;
  },

  setConfirmDeleteModal(state: { confirmDeleteModal: any }, value: any) {
    state.confirmDeleteModal = value;
  },

  setIsFormReady(state: { isFormReady: any }, value: any) {
    state.isFormReady = value;
  },

  setConfirmLoadTemplateModal(
    state: { confirmLoadTemplateModal: any },
    value: any
  ) {
    state.confirmLoadTemplateModal = value;
  },

  setSelectedTemplateId(state: { selectedTemplateId: any }, value: any) {
    state.selectedTemplateId = value;
  },

  setFormData(state: { formData: any }, value: any) {
    state.formData = value;
  },

  setSelectDepositModal(state: { selectDepositModal: any }, value: any) {
    state.selectDepositModal = value;
  },

  setIsTemplateCreate(state: { isTemplateCreate: any }, value: any) {
    state.isTemplateCreate = value;
  },

  setIsEstimateSubmit(state: { isEstimateSubmit: any }, value: any) {
    state.isEstimateSubmit = value;
  },

  setIsEstimateLoadedModified(
    state: { isEstimateLoadedModified: any },
    value: any
  ) {
    state.isEstimateLoadedModified = value;
  },

  setHasLoadedTemplate(state: { hasLoadedTemplate: any }, value: any) {
    state.hasLoadedTemplate = value;
  },

  setLoadedEstimate(state: { loadedEstimate: any }, value: any) {
    state.loadedEstimate = value;
  },

  setIsFormReadOnly(state: { isFormReadOnly: any }, value: any) {
    state.isFormReadOnly = value;
  },

  setLockTemplateModifiedChecker(
    state: { lockTemplateModifiedChecker: any },
    value: any
  ) {
    state.lockTemplateModifiedChecker = value;
  },

  setSelectedAssignMembers(state: { selectedAssignMembers: any }, value: any) {
    state.selectedAssignMembers = value;
  },

  setAssignMembersModal(state: { assignMembersModal: any }, value: any) {
    state.assignMembersModal = value;
  },

  setAssignMembers(state: { assignMembers: any }, value: any) {
    state.assignMembers = value;
  },

  setInvitedContractorsList(
    state: { invitedContractorsList: any },
    value: any
  ) {
    state.invitedContractorsList = value;
  },

  setSubContractorsPhases(state: any, value: any) {
    state.subContractorsPhases = value;
  },

  setparentContractorPhases(state: any, value: any) {
    state.parentContractorPhases = value;
  },

  setShowUpdateEstimateConfirmationModal(
    state: { showUpdateEstimateConfirmationModal: any },
    value: any
  ) {
    state.showUpdateEstimateConfirmationModal = value;
  },

  setTotalPhases(state: any, value: any) {
    state.totalPhases = value;
  },

  setEstimatePhaseCost(state: { estimateForm: any }, value: any) {
    const { phaseIndex, newValue } = value;
    state.estimateForm.projectStages[phaseIndex].stageCost =
      parseFloat(newValue);
  },

  setEstimatePhaseSubTotal(state: { estimateForm: any }, value: any) {
    const { phaseIndex, newValue } = value;
    state.estimateForm.projectStages[phaseIndex].subTotal = newValue.toString();
  },
  setEstimatePhaseDate(state: { estimateForm: any }, value: any) {
    state.estimateForm.projectStages = state.estimateForm.projectStages.map(
      (phase: any) => {
        return {
          ...phase,
          startDate:
            value && phase.startDate
              ? new Date(value) <= new Date(phase.startDate)
                ? phase.startDate
                : null
              : null,
        };
      }
    );
  },
  setEstimatePhase(state: { estimateForm: any }, value: any) {
    const { phaseIndex, field, newValue } = value;

    state.estimateForm.projectStages[phaseIndex][field] = newValue;
  },
  setPhaseTaskProperties(state: { estimateForm: any }, value: any) {
    const { phaseIndex, itemIndex, taskProperties } = value;

    state.estimateForm.projectStages[phaseIndex].projectStageTasks[
      itemIndex
    ].estimationPhaseTasksProperties = taskProperties;
  },

  setPhaseProperties(state: { estimateForm: any }, value: any) {
    const { phaseIndex, phaseProperties } = value;
    state.estimateForm.projectStages[phaseIndex].estimationPhaseProperties =
      phaseProperties;
  },
  setPhaseAttachments(state: { estimateForm: any }, value: any) {
    const { phaseIndex, attachments } = value;
    state.estimateForm.projectStages[phaseIndex].attachments = attachments;
  },

  setProjectStage(state: any, value: any) {
    const { phaseIndex, defaultProjectStageFormValue } = value;
    state.estimateForm.projectStages[phaseIndex] = defaultProjectStageFormValue;
  },
  setSubContractor(state: { isSubContractor: boolean }, value: any) {
    state.isSubContractor = value;
  },

  setProjectEstimationInvitation(
    state: { projectEstimationInvitation: any },
    value: any
  ) {
    state.projectEstimationInvitation = value;
  },
  setSubmitProjectEstimation(
    state: { isSubmitProjectEstimation: any },
    value: any
  ) {
    state.isSubmitProjectEstimation = value;
  },

  setError(
    state: any,
    {
      phaseIndex,
      subTaskIndex,
      field,
      message,
    }: {
      phaseIndex: number;
      subTaskIndex: number;
      field: string;
      message: string;
    }
  ) {
    if (!state.errors[phaseIndex]) {
      state.errors[phaseIndex] = { subErrors: {} };
    }
    if (typeof subTaskIndex === "undefined")
      state.errors[phaseIndex][field] = message;
    else {
      if (!state.errors[phaseIndex].subErrors[subTaskIndex]) {
        state.errors[phaseIndex].subErrors[subTaskIndex] = {};
      }
      state.errors[phaseIndex].subErrors[subTaskIndex][field] = message;
    }
  },
  clearErrors(state: any, { phaseIndex, subTaskIndex, field }: any) {
    if (typeof subTaskIndex === "undefined") {
      if (state.errors[phaseIndex]) {
        delete state.errors[phaseIndex][field];
      }
    } else {
      if (
        state.errors[phaseIndex] &&
        state.errors[phaseIndex].subErrors[subTaskIndex]
      ) {
        delete state.errors[phaseIndex].subErrors[subTaskIndex][field];
      }
    }
  },

  clearAllErrors(state: any) {
    state.errors = { subErrors: {} };
    state.isFormErrors = {
      startDate: false,
      validationError: false,
      projectStageDate: false,
    };
  },

  setFormError(state: { isFormErrors: any }, { field, value }: any) {
    state.isFormErrors[field] = value;
  },
  setEstimateResponse(state: any, value: any) {
    state.estimateResponse = value;
  },
  setJobsTemplates(state: any, payload: any) {
    state.jobTemplates = payload;
  },
  setEstimatePhaseNotes(state: any, payload: { phaseId: number; data: any }) {
    state.estimatePhaseNote[payload.phaseId] = payload.data;
  },
  setPhaseTasks(state: any, payload: any) {
    state.estimateForm.projectStages[payload.phaseIndex].projectStageTasks =
      payload.projectStageTasks;
  },
  setHoldEstimateAddTaskApi(state: any, payload: any) {
    state.holdEstimateAddTaskApi = payload;
  },
  clearAllEstimateData(state: any) {
    state.estimateForm = {
      projectStages: [
        {
          id: null,
          isNew: true,
          name: "",
          stageType: "labour",
          totalDuration: 0,
          durationType: 1,
          stageCost: 0,
          projectStageTasks: [],
          requiredDeposit: false,
          vat: 0,
          subTotal: "0",
          subTotalDisplay: "",
          note: "",
          startDate: null,
          phaseOrder: 0,
          phaseStatus: null,
          endDate: null,
          estimationPhaseProperties: [],
          color: getRandomColor(),
          attachments: [],
          user: null,
          workStation: null,
        },
      ],
    };
    state.formData = {
      suggestedStartDate: null,
      grantType: null,
      grantName: "",
      grantAmount: 0,
      project: null,
      id: null,
    };
  },
};
