<template>
  <section
    class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-4"
    v-if="modifiedTimeline.length"
  >
    <div
      class="tw-w-full tw-h-full rmd:tw-min-w-[440px] rmd:tw-w-[440px] rmd:!tw-min-h-[198px] tw-flex tw-flex-col tw-gap-4 rmd:tw-px-2 tw-box-border tw-overflow-y-hidden"
    >
      <PropertyCard />
    </div>
    <h3 class="tw-text-left">Market History</h3>
    <section class="tw-flex tw-w-full tw-h-full tw-box-border tw-gap-4">
      <div
        class="tw-w-full tw-h-full tw-bg-[#F1F6FB] tw-rounded-lg tw-p-4 tw-box-border tw-flex tw-flex-col tw-gap-4"
      >
        <div
          class="tw-w-full tw-h-full tw-min-h-[366px] tw-flex tw-flex-col tw-bg-white tw-gap-4 tw-rounded-lg tw-overflow-hidden"
        >
          <div
            class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start tw-gap-2 tw-border-b-solid-custom"
          >
            <Text variant="p">Timeline</Text>
          </div>
          <v-timeline
            class="tw-px-8 tw-box-border tw-overflow-x-auto timeline-ele"
            direction="horizontal"
            truncate-line="start"
            align="start"
            v-if="modifiedTimeline && modifiedTimeline.length"
          >
            <v-timeline-item
              fill-dot
              dot-color="white"
              v-for="(timeline, index) in modifiedTimeline"
              :key="index"
            >
              <template v-slot:icon>
                <img src="@/assets/icons/timeline-icon.svg" />
              </template>
              <v-card class="timeline-card">
                <div
                  class="tw-w-full tw-h-full tw-border-l-solid-custom tw-p-2 tw-box-border tw-flex tw-flex-col tw-justify-start tw-items-start"
                >
                  <Text variant="span">{{
                    timeline?.type && timeline?.type === "sale"
                      ? "Listed for sale"
                      : timeline?.sourceId
                      ? "Sold"
                      : "Listed for let"
                  }}</Text>
                  <Text variant="span">{{
                    CurrencyFilter.formatToCurrency(timeline?.amount)
                  }}</Text>
                  <Text variant="span" textWeight="400">{{
                    appFilters.formatToDate(timeline?.date)
                  }}</Text>
                </div>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
        <div
          v-if="salePropbarData && salePropbarData.length"
          class="tw-w-full tw-h-full tw-flex tw-flex-col tw-rounded-t-lg tw-bg-white tw-rounded-lg tw-overflow-hidden"
        >
          <div
            class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start tw-gap-2"
          >
            <Text variant="p">Market History</Text>
            <div
              class="tw-flex tw-justify-center tw-items-center tw-py-[3px] tw-px-2 tw-bg-[#DEE9FD] tw-rounded-md"
            >
              <Text variant="span" textWeight="400" textColor="#2F42AB"
                >Sale</Text
              >
            </div>
          </div>
          <MarketHistoryTable :data="salePropbarData" />
        </div>
        <div
          v-if="rentPropbarData && rentPropbarData.length"
          class="tw-w-full tw-h-full tw-flex tw-flex-col tw-rounded-t-lg tw-bg-white tw-rounded-lg tw-overflow-hidden"
        >
          <div
            class="tw-w-full tw-px-4 tw-py-5 tw-box-border tw-flex tw-items-center tw-justify-start tw-gap-2"
          >
            <Text variant="p">Market History</Text>
            <div
              class="tw-flex tw-justify-center tw-items-center tw-py-[3px] tw-px-2 tw-bg-[#EBE8FD] tw-rounded-md"
            >
              <Text variant="span" textWeight="400" textColor="#5228B1"
                >Let</Text
              >
            </div>
          </div>
          <MarketHistoryTable :data="rentPropbarData" />
        </div>
      </div>
    </section>
  </section>
  <section v-else class="tw-w-full tw-h-[70dvh] tw-flex tw-flex-col tw-gap-8">
    <div class="empty__content">
      <div class="empty__banner">
        <div class="empty__banner__img">
          <img
            src="../../../../assets/images/market-history-banner.png"
            alt=""
          />
        </div>
        <div class="empty__banner__content">
          <h4>You don't have any market history yet.</h4>
          <p>It is a long established fact that a reader will be distracted</p>
        </div>
        <!-- <div class="empty__banner__footer">
          <v-btn class="button button-purple" width="100%"
            >Market History</v-btn
          >
        </div> -->
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";

import MarketHistoryTable from "@/modules/properties/components/Tables/MarketHistoryTable.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CurrencyFilter from "@/filters/currency.filter";
import appFilters from "@/filters";
import { PROPERTY_STORE } from "@/store/modules/property";
import PropertyCard from "@/modules/properties/components/Cards/PropertyCard.vue";

const store = useStore();

const isLoading = ref(false);
const filters = reactive({
  keywords: "",
});
const modifiedTimeline = ref([]);
const salePropbarData = ref([]);
const rentPropbarData = ref([]);
const propbarData = ref(null);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const sortArrayByDate = (dataArray) => {
  return dataArray.slice().sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
};

const modifyTimeline = async (property) => {
  if (property.saleHistory.length) {
    modifiedTimeline.value = [...property.saleHistory];
    modifiedTimeline.value = modifiedTimeline.value.reverse();
  }
  if (property.listings.length) {
    await property.listings.map((listing) => {
      const newListing = {
        amount: listing.pricing?.price,
        date: listing?.publishedDate,
        listingId: listing?.listingId,
        type: listing?.type,
      };
      modifiedTimeline.value.push(newListing);
    });
  }

  if (modifiedTimeline.value.length && modifiedTimeline.value) {
    modifiedTimeline.value = sortArrayByDate(modifiedTimeline.value);
  }
};

const filterResponse = async (property) => {
  if (property.listings.length) {
    const findSalesListings = property.listings.filter(
      (listing) => listing.type === "sale"
    );
    const findRentListings = property.listings.filter(
      (listing) => listing.type === "rent"
    );

    if (findSalesListings.length) {
      salePropbarData.value = findSalesListings.reverse();
    }
    if (findRentListings.length) {
      rentPropbarData.value = findRentListings.reverse();
    }
  }
};
const initialize = async () => {
  const uprn = propertyDetails.value.localData?.uprn;
  try {
    isLoading.value = true;
    const response = await store.dispatch(
      `${PROPERTY_STORE}/fetchPropertyPropbar`,
      uprn
    );
    if (response) {
      propbarData.value = response;
      await modifyTimeline(response);
      await filterResponse(response);
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await initialize();
});
</script>
<style lang="scss" scoped>
.timeline-card {
  box-shadow: 0px 0px 50px 0px #0000001a;
}
/*Empty Screen */
.empty__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty__banner {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    @include respond(s720) {
      width: 300px;
    }
    .empty__banner__img {
      img {
        @include respond(s720) {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .empty__banner__content {
      margin-top: 46px;
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
      .empty__banner__content__list {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 27px;
        .list__item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .v-icon {
            @include fluidFont(24, 24, 1);
            color: rgba($skyBlue, 1);
          }
          p {
            @include fluidFont(14, 14, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
    .empty__banner__footer {
      margin-top: 36px;
    }
  }
}

/* TimeLine On Mobile */
@media (max-width: 767px) {
  :deep(.timeline-ele) {
    padding: 30px 15px;
    display: block;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: calc(100% - 60px - 84px);
      border: 1px dashed #7467b7;
    }

    .v-timeline-item {
      display: flex;
      width: 53.6%;
      height: 84px;
      position: relative;

      @media (max-width: 340px) {
        width: 54.7%;
      }

      &:nth-child(2n) {
        margin-left: auto;
        flex-direction: row-reverse;

        .v-timeline-divider__dot--size-default {
          margin-left: 1px;
        }

        .v-timeline-divider__before {
          display: none;
        }

        .v-timeline-item__body {
          padding-block-start: 8px;
        }

        .v-timeline-divider {
          margin-top: auto;
          justify-content: flex-start;
        }
      }

      &:nth-child(2n + 1) .v-timeline-item__body {
        padding-block-end: 8px;
      }
    }

    .v-timeline-item__body {
      max-width: 92px;
    }

    .v-timeline-divider {
      height: calc(100% - 8px);
      justify-content: flex-end;
      flex: auto;
    }

    .v-timeline-divider__dot--size-default {
      width: 20px;
      height: 20px;
      margin-left: auto;
    }

    .v-timeline-divider__inner-dot img {
      width: 12px;
      height: 12px;
    }

    .v-timeline-divider__after {
      --v-timeline-line-size-offset: 8px !important;
      inset-inline-end: 1px !important;
      background: #7467b7;
    }

    .typography-span {
      font-size: 11px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
      text-align: left;
    }
  }
}
</style>
