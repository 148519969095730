<template>
  <main
    class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 tw-box-border md:!tw-gap-2"
  >
    <!-- HEADER -->
    <section class="tw-w-full">
      <DocumentsHeader
        :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
        @on-success="onUploadSuccess"
      />
    </section>

    <section class="tw-w-full">
      <DocumentsBreadcrumbs
        :titlePath="state.titlePath"
        @on-view-folder="onViewFolder($event, true)"
      />
    </section>

    <!-- CONTENTS -->
    <section
      class="tw-w-full tw-h-full tw-relative tw-flex tw-gap-4 md:!tw-flex-col-reverse md:!tw-mt-[0] md:!tw-h-auto"
      :class="{
        'md:!tw-flex-col-reverse md:!tw-gap-8':
          !hasContent && !isPersonalAccountWorkStation,
      }"
      v-if="!state.initializing"
    >
      <!-- TABLES -->
      <section
        v-if="hasContent || isPersonalAccountWorkStation"
        class="tw-w-full tw-flex tw-flex-col tw-gap-6 md:!tw-gap-4"
      >
        <DocumentsTable
          :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
          v-show="
            (folderList && folderList.length) || isPersonalAccountWorkStation
          "
          :tableType="documentConstants.FOLDERS"
          @on-success="onSuccess"
          @on-view-folder="onViewFolder"
        />
        <DocumentsTable
          :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
          v-show="(fileList && fileList.length) || isPersonalAccountWorkStation"
          :tableType="documentConstants.FILES"
          @on-success="onSuccess"
          @on-view-folder="onViewFolder"
        />
      </section>

      <!-- EMPTY STATE -->
      <section class="tw-w-full tw-h-full md:!tw-pb-16" v-else>
        <EmptyState
          v-if="getUserWorkStationFieldAccess"
          :iconComponent="addDocumentIcon"
          label="Create a new folder or upload 
files to get started"
        >
          <template #action>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <Button
                  v-bind="props"
                  class="!tw-max-w-[139px]"
                  label="new"
                  variant="secondary"
                />
              </template>
              <AddDocumentMenu @on-handle-action="onHandleAction" />
            </v-menu>
          </template>
        </EmptyState>
        <EmptyState v-else label="No Document Available..." />
      </section>

      <!-- RIGHT SIDEBAR STATUS -->
      <section
        v-if="state.isInMainPage"
        class="tw-pt-10 tw-box-border md:!tw-pt-0"
      >
        <DocumentStatus
          :data="state.documentStatus"
          @on-update-plan="onPlanUpdated"
        />
      </section>

      <v-alert
        v-if="state.alert"
        class="tw-absolute tw-bottom-11 tw-right-0"
        transition="slide-y-transition"
        :title="state.alertConfig.title"
        :text="state.alertConfig.text"
        :type="state.alertType === 'success' ? state.alertType : 'error'"
      >
      </v-alert>
    </section>
    <!-- MODALS -->
    <CreateNewFolderModal
      v-if="modals.newFolderModal"
      @on-close="onCloseNewFolderModal"
      @on-success="onUploadSuccess"
    />
    <FileUploadModal
      v-if="modals.fileUploadModal"
      @on-close="onCloseFileUploadModal"
      @on-success="onUploadSuccess"
    />
    <FolderUploadModal
      v-if="modals.addFolderModal"
      @on-close="onCloseAddFolderModal"
      @on-success="onUploadSuccess"
    />
    <PageRestrictionNoticeModal
      v-if="isShowWorkstationRestrictionModal"
      @on-close="toggleRestrictionModal"
    />
  </main>
</template>
<script setup>
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import AddDocumentIcon from "@/core/components/icons/AddDocumentIcon.vue";
import EmptyState from "@/core/components/common/EmptyState.vue";
import AddDocumentMenu from "@/modules/documents/components/AddDocumentMenu.vue";
import DocumentStatus from "@/modules/documents/components/DocumentStatus.vue";
import DocumentsHeader from "@/modules/documents/components/DocumentsHeader.vue";
import DocumentsTable from "@/modules/documents/components/DocumentsTable.vue";
import documentConstants from "@/modules/documents/contants";
import DocumentsBreadcrumbs from "@/modules/documents/components/DocumentsBreadcrumbs.vue";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import $axios from "@/core/utils/axios-api-config";
import CreateNewFolderModal from "@/modules/documents/components/modal/CreateNewFolderModal.vue";
import FileUploadModal from "@/modules/documents/components/modal/FileUploadModal.vue";
import FolderUploadModal from "@/modules/documents/components/modal/FolderUploadModal.vue";
import { WORKSTATION_SETTING } from "@/store/modules/workstation-setting";
import { WORKSTATION } from "@/store/modules/workstation";

const addDocumentIcon = AddDocumentIcon;

const store = useStore();

const state = reactive({
  isInMainPage: true,

  initializing: false,
  defaultTitlePath: {
    name: "Documents",
  },
  titlePath: [],
  fileTitle: [{ name: "Files" }],
  currentFolderList: [],
  currentFileList: [],
  accumlatedSize: "",

  alert: false,
  alertType: "",
  actionType: "",
  alertConfig: {
    text: "",
    title: "",
  },

  documentStatus: null,
});

const isFetchingSubscriptionDetails = ref(false);

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const fileList = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);
const folderList = computed(
  () => store.getters[`${DOCUMENTS_STORE}/folderList`]
);

const hasContent = computed(() => {
  return fileList.value.length || folderList.value.length;
});

const getWorkstationsSettingDetails = async () => {
  try {
    isFetchingSubscriptionDetails.value = true;
    await store.dispatch(
      `${WORKSTATION_SETTING}/getWorkstationsSettingDetails`,
      {
        userId: user.value.id,
        workstationId: activeUserWorkstation.value?.id,
      }
    );
  } catch (err) {
    console.log();
  } finally {
    isFetchingSubscriptionDetails.value = false;
  }
};

const getUserDocumentCalculatedSize = async (params) => {
  return await store.dispatch(
    `${DOCUMENTS_STORE}/getUserDocumentCalculatedSize`,
    params
  );
};

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};
const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};

const setLoaderFalse = () => {
  setTimeout(() => {
    state.initializing = false;
  }, 300);
};

const getList = async (options = {}) => {
  state.initializing = true;
  const { id: userId } = user.value;
  const params = { userId };
  const { parentId } = options;
  const hasParentParam = parentId || null;
  const documentPromises = [];

  if (Object.keys(options).length) {
    params.options = options;
  }

  try {
    const userDocuments = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocuments`,
      params
    );
    state.currentFolderList = userDocuments.filter(
      (userDocument) =>
        userDocument.isDir === true && userDocument.parentId === hasParentParam
    );

    state.currentFileList = userDocuments.filter(
      (userDocument) =>
        !userDocument.isDir && userDocument.parentId === hasParentParam
    );

    state.currentFileList = state.currentFileList.map((file) => {
      return { ...file, name: file.originalName, attachment: file.name };
    });

    state.currentFolderList.forEach((folderItem) => {
      documentPromises.push(
        getUserDocumentCalculatedSize({
          userId,
          userDocumentId: folderItem.id,
        })
      );
    });

    // Wait for all promises to resolve
    await Promise.all(documentPromises);

    if (documentPromises.length) {
      const documentFolderSizes = await Promise.all(documentPromises);
      state.currentFolderList = state.currentFolderList.map((item) => {
        const findFolder = documentFolderSizes.find((x) => x.id === item.id);

        return { ...item, ...findFolder };
      });
    }
    setFolderList(state.currentFolderList);
    setFileList(state.currentFileList);
  } catch (e) {
    const formError = "Error getting list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    setLoaderFalse();
  }
};

const modals = reactive({
  newFolderModal: false,
  addFolderModal: false,
  fileUploadModal: false,
});

const onOpenNewFolderModal = () => {
  modals.newFolderModal = true;
};

const onCloseNewFolderModal = () => {
  modals.newFolderModal = false;
  getList();
};

const onOpenAddFolderModal = () => {
  modals.addFolderModal = true;
};

const onCloseAddFolderModal = () => {
  modals.addFolderModal = false;
  getList();
};

const onOpenFileUploadModal = () => {
  modals.fileUploadModal = true;
};

const onCloseFileUploadModal = () => {
  modals.fileUploadModal = false;
  getList();
};

const onHandleAction = (name) => {
  if (name === documentConstants.NEW_FOLDER) {
    onOpenNewFolderModal();
  }
  if (name === documentConstants.FOLDER) {
    onOpenAddFolderModal();
  }
  if (name === documentConstants.FILE) {
    onOpenFileUploadModal();
  }
};

const hideAlert = (event) => {
  setTimeout(() => {
    state.alert = false;
  }, 3000);
};

const onUploadSuccess = async (event) => {
  await getDocumentTotalSize();
  state.alertType = "success";
  state.actionType = event;
  state.alert = true;
  state.alertConfig = {
    text:
      state.actionType === documentConstants.FILE
        ? "File uploaded successfully"
        : "Folder created successfully",
    title: state.actionType === documentConstants.FILE ? "Uploaded" : "Created",
  };
};

const onSuccess = async (event) => {
  if (event === "move") {
    await getList();
  }
  state.alertType = event === "notDelete" ? "error" : "success";
  state.actionType = event;
  state.alert = true;
  state.alertConfig = {
    text:
      state.actionType === "edit"
        ? "Edited Successfully"
        : state.actionType === "delete"
        ? "Deleted Successfully"
        : state.actionType === "notDelete"
        ? "You Can't delete property folder"
        : state.actionType === "move"
        ? "Move Successfully"
        : "",
    title: state.actionType === "notDelete" ? "Delete" : "Successful",
  };
  if (state.actionType === "delete") {
    await getDocumentTotalSize();
  }
};

const onViewFolder = async (folder = {}, isBreadcrumbClick = false) => {
  const { id } = folder;
  let params = {};

  if ("id" in folder) {
    params = { parentId: id };
  }

  await store.dispatch(`${DOCUMENTS_STORE}/setOpenedFolder`, folder);

  await getList(params).then(() => {
    if (id) {
      if (isBreadcrumbClick) {
        const foundIndex = state.titlePath.findIndex((item) => item.id === id);

        if (foundIndex !== -1) {
          state.titlePath.splice(foundIndex + 1);
        }
      } else {
        state.titlePath.push(folder);
      }
    } else {
      resetTitlePath();
    }
  });
};

const resetTitlePath = () => {
  state.titlePath = [state.defaultTitlePath];
};

const onPlanUpdated = async () => {
  await getWorkstationsSettingDetails();
};

watch(
  () => state.alert,
  (newValue, oldValue) => {
    if (newValue) {
      hideAlert();
    }
  },
  { deep: true, immediate: true }
);

onBeforeMount(async () => {
  setFolderList([]);
  setFileList([]);

  await store.dispatch(`${DOCUMENTS_STORE}/setIsPropertyDocument`, false);

  await getList();
  resetTitlePath();

  await getWorkstationsSettingDetails();

  await store.dispatch(`${USER_STORE}/initializeUserState`);

  // check successful transaction
  await checkSuccessfulTransaction();
});

const getDocumentTotalSize = async () => {
  const { id: userId } = user.value;
  const params = { userId };

  try {
    const response = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocumentTotalSize`,
      params
    );

    state.documentStatus = {
      documentProgress: response.usedStoragePercentage,
      documentAvailableStorage: response.availableStorage,
    };
  } catch (error) {
    const formError = "Error getting document list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  }
};

const checkSuccessfulTransaction = async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { id: userId } = user.value;

  if (params.session_id) {
    let url = `/payment/${userId}/session?sessionId=${params.session_id}`;

    if (params.is_document) {
      url = `${url}&isDocument=${params.is_document}`;
    }

    await $axios.get(url).then(async (sessionResponse) => {
      if (sessionResponse) {
        // prompt success
      }
    });
  }
};
const isShowWorkstationRestrictionModal = ref(false);
const isPersonalAccountWorkStation = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name === "Default"
);

const toggleRestrictionModal = () => {
  isShowWorkstationRestrictionModal.value =
    !isShowWorkstationRestrictionModal.value;
};
onMounted(async () => {
  state.alert = false;
  await getDocumentTotalSize();
});

const getUserWorkStationFieldAccess = computed(() => {
  if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
    return true;
  } else {
    return store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`];
  }
});
</script>
<style lang="scss" scoped></style>
