<template>
  <div>
    <main
      class="dashboard-sec"
      :class="{ 'md:!tw-pb-16 md:!tw-box-border': isUserWorkstationIsDefault }"
    >
      <!-- Action alert -->
      <div
        v-if="
          userWorkStationStatus === UserVerificationStatusEnum.NOT_ADDED &&
          activeUserWorkstation?.subscription &&
          !isUserWorkstationIsDefault &&
          !isWorkstationLoading
        "
        class="tw-flex tw-border tw-border-[#fa4b0080] tw-mb-5 tw-p-3 tw-rounded-xl tw-border-solid tw-gap-2 tw-items-center tw-bg-[#fa4b001a] tw-text-[#FA4B00] tw-font- tw-capitalize tw-text-xs"
      >
        <img src="../../../assets/icons/alert-box-red.svg" />
        Action Required: Verify your identity to get more Estimates.
        <span
          class="tw-underline tw-cursor-pointer tw-text-[#4B4BFF]"
          @click="onClickVerifyAccount"
          >Get Verified</span
        >
      </div>
      <!-- LOADER -->
      <div
        v-if="isWorkstationLoading"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
      >
        <Loader :show="isWorkstationLoading" />
      </div>

      <!-- BANNER -->
      <div v-else>
        <Card class="!tw-max-h-[unset] tw-w-full !tw-p-5 tw-box-border">
          <template #content>
            <main class="tw-w-full">
              <!-- PROFILE DETAILS BANNER -->
              <section
                v-if="!isUserWorkstationIsDefault"
                class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center rmd:tw-flex-row rmd:tw-justify-between tw-max-w-full md:tw-gap-5"
              >
                <div
                  class="left__block tw-flex tw-flex-col tw-gap-6 md:tw-w-full"
                >
                  <div
                    class="tw-flex tw-items-center tw-gap-5 tw-relative md:tw-w-full md:tw-max-w-full md:tw-gap-3 sm:tw-w-[auto]"
                  >
                    <ProfileThumbnail
                      size="xllg"
                      v-if="userWorkStationImage"
                      :src="userWorkStationImage"
                    />
                    <div
                      v-else
                      class="tw-rounded-lg tw-overflow-hidden tw-max-h-[104px] tw-max-w-[104px] tw-min-h-[104px] tw-min-w-[104px] tw-h-[104px] dm:tw-max-h-[48px] md:tw-max-w-[48px] md:tw-min-h-[48px] md:tw-min-w-[48px] md:tw-h-[48px]"
                    >
                      <UserProfileLogo />
                    </div>
                    <div
                      class="verified__icon tw-absolute tw-left-[90px] sm:!tw-left-[33px] tw-top-0 tw-w-[20px] tw-h-[20px] tw-bg-white tw-rounded tw-flex tw-items-center tw-justify-center"
                      v-if="
                        userWorkStationStatus ===
                        UserVerificationStatusEnum.VERIFIED
                      "
                    >
                      <img src="../../../assets/icons/verified.svg" alt="" />
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-items-start tw-max-w-full"
                    >
                      <h5
                        class="tw-text-[22px] tw-text-[#0C0F4A] tw-font-medium tw-leading-8 tw-text md:tw-text-lg"
                      >
                        {{ activeUserWorkstation?.name }}
                      </h5>
                      <div
                        v-if="user?.userBusinessDetails?.residencyAddressLine"
                        class="tw-flex tw-items-center tw-gap-0 rmd:tw-gap-1 tw-flex-row-reverse sm:tw-flex-wrap sm:tw-flex-col-reverse sm:tw-items-start"
                      >
                        <Text
                          class="tw-flex tw-gap-1 !tw-text-[10px] tw-text-[#0C0F4A80]"
                          textWeight="400"
                          textColor="#0C0F4A"
                          whiteSpace="nowrap"
                          ><img src="@/assets/icons/map.svg" />{{
                            getWorkstationPostcode(
                              user?.userBusinessDetails?.type
                            )
                          }}</Text
                        >
                        <Text
                          class="!tw-text-[10px] tw-text-[#0C0F4A80]"
                          textWeight="400"
                          textColor="#0C0F4A"
                          whiteSpace="nowrap"
                          >{{
                            getWorkstationAddress(
                              user?.userBusinessDetails?.type
                            )
                          }}</Text
                        >
                      </div>
                      <div
                        v-else
                        @click="onClickAddress"
                        class="tw-flex tw-gap-1 tw-items-center tw-cursor-pointer"
                      >
                        <Text textColor="#FFA500" variant="p">+</Text>
                        <Text textColor="#FFA500" variant="span"
                          >Add Address</Text
                        >
                      </div>
                    </div>
                  </div>
                  <div class="view-rating-box">
                    <v-rating
                      :model-value="getAverageOfAllProjectReview"
                      active-color="orange"
                      color="rgba(12, 15, 74, 0.26)"
                      empty-icon="mdi-star"
                      readonly
                    ></v-rating>
                    <p>{{ getAverageOfAllProjectReview }} out of 5</p>
                    <v-btn
                      @click="onClickGotoRatingPage"
                      class="button button-orange"
                      append-icon="mdi-chevron-right"
                      >view Ratings
                      <!-- <template v-slot:append>
                        <v-icon
                          color="white"
                          icon="mdi-chevron-right"
                        ></v-icon> </template
                    > -->
                    </v-btn>
                  </div>
                </div>

                <div
                  class="tw-flex tw-flex-col tw-justify-between tw-items-end tw-relative md:tw-w-full md:tw-max-w-full tw-min-h-[180px] md:tw-min-h-[auto] md:tw-gap-4"
                >
                  <!-- <div
                    v-if="activeUserWorkstation?.subscription"
                    class="free_workstation_box tw-flex tw-items-center tw-gap-1 tw-px-4 tw-py-1 tw-rounded-lg"
                  >
                    <img src="../../../assets/icons/red-exclaimation.svg" />

                    <span
                      class="tw-text-xs tw-font-bold tw-leading-6 tw-text-[#FA4B00] tw-tracking-wide"
                      >{{ userWorkstationStatusByWord }}</span
                    >
                  </div> -->

                  <ProfileVerificationStatusChip
                    v-if="
                      // activeUserWorkstation?.verificationStatus !==
                      //   UserVerificationStatusEnum.VERIFIED &&
                      activeUserWorkstation?.subscription
                    "
                    class="tw-hidden rmd:tw-flex"
                    :variant="userWorkstationStatusByWord"
                  />

                  <!-- for display free workstation -->
                  <div
                    class="tw-flex tw-items-end tw-gap-1 tw-flex-col md:tw-flex-row md:tw-justify-between tw-w-full tw-max-w-full"
                    v-if="
                      userWorkStationStatus ===
                        UserVerificationStatusEnum.NOT_ADDED &&
                      !activeUserWorkstation?.subscription
                    "
                  >
                    <div
                      class="free_workstation_box tw-flex tw-items-center tw-gap-1 tw-px-4 tw-py-1 tw-rounded-lg"
                    >
                      <img src="../../../assets/icons/green-star-circle.svg" />

                      <span
                        class="tw-text-xs tw-font-bold tw-leading-6 tw-text-[#24BD63] tw-tracking-wide tw-whitespace-nowrap"
                        >FREE WORKSTATION</span
                      >
                    </div>
                    <v-btn
                      class="upgrade_btn tw-text-[10px] tw-font-bold tw-leading-6 tw-tracking-[1.25px] tw-opacity-100 tw-capitalize tw-p-0"
                      variant="plain"
                      append-icon="mdi-chevron-right"
                      @click="onClickUpgradePlan"
                      >Upgrade Plan</v-btn
                    >
                  </div>
                  <v-btn
                    class="button button-purple w-full"
                    @click="onClickAddNewProperty"
                  >
                    add a new property
                  </v-btn>
                </div>
              </section>
              <!-- WELCOME BANNER -->
              <section
                v-else
                class="tw-w-full tw-flex tw-flex-col tw-gap-2 rmd:tw-flex-row rmd:tw-items-center rmd:tw-justify-between"
              >
                <div
                  class="tw-flex tw-flex-col tw-gap-4 tw-items-start md:tw-gap-2"
                >
                  <Text
                    class="rlg:!tw-text-[28px]"
                    variant="h6"
                    textWeight="400"
                    >Welcome to your iknowa HQ,
                    <Text class="rlg:!tw-text-[28px]" variant="h6">{{
                      userName
                    }}</Text></Text
                  >
                  <Text
                    class="rlg:!tw-text-sm"
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >This is your personal profile. Create a workstation to get
                    started with the best suited option for you.</Text
                  >
                </div>
                <v-btn
                  class="button button-purple"
                  @click="onClickToggleTradesWorkStation"
                  >CREATE A PROPERTY WORKSTATION
                </v-btn>
              </section>
            </main>
          </template>
        </Card>

        <!-- PROPERTY OWNER DASHBOARD -->
        <!-- v-if="
          !isUserWorkstationIsDefault && activeUserWorkstation && !initialiazing
        " -->
        <section
          v-if="!isUserWorkstationIsDefault"
          class="workstation-dashboard tw-flex tw-flex-col tw-gap-6 tw-w-full tw-h-full"
        >
          <!-- WORKSTATION DETAILS -->
          <div class="tw-relative">
            <!-- <WorkStationVerificationLock
            v-if="
              userWorkStationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION
            "
          /> -->
          </div>
          <div
            class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-gap-6 tw-box-border rlg:tw-grid-cols-2"
          >
            <div
              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 tw-min-h-[340px]"
            >
              <PropertiesWidget />
            </div>
            <div
              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 tw-min-h-full"
            >
              <PreferredContractorsWidget />
            </div>
          </div>
          <div
            class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-gap-6 tw-box-border rlg:tw-grid-cols-2"
          >
            <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
              <ProjectsOverviewWidget />
              <TeamWidget className="!tw-min-h-[275px]" />
            </div>
            <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
              <CalendarEventWidget className="property_calendar" />
            </div>
          </div>
        </section>

        <!-- FREE/DEFAULT DASHBOARD -->
        <section v-else class="dashboard-setting-up__custom">
          <!-- LEFT CONTAINER -->
          <div class="left__container">
            <!-- TRADE PASSPORT STATUS -->
            <v-card class="dashboard_left__card">
              <div class="dashboard_left__card__header">
                <div class="profile-wrap">
                  <div class="content">
                    <h3 class="dashboard-verify-profile__name">
                      {{ propertyListData?.address }}
                    </h3>
                    <div class="dashboard-verify-profile__address">
                      <p>{{ propertyListData?.addressLine1 }}</p>
                      <span>|</span>
                      <div class="location__wrapper">
                        <v-icon icon="mdi-map-marker" />
                        <span>{{ propertyListData?.postcode }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dashboard_left__tags">
                  <div class="dashboard_left__card_tags">
                    <label class="tag-item__points"
                      ><span>1,414</span>&nbsp;Verified Data Points
                    </label>
                  </div>
                  <div class="dashboard_left__card_tags">
                    <label class="tag-item__points">
                      <span> KYP</span> Preview</label
                    >
                  </div>
                </div>
              </div>
              <div v-if="!loading" class="dashboard_left__card__content">
                <div class="speedmeter__img">
                  <!-- <SpeedMeterComponent
                    :handAngle="propertyListData?.days_on_market"
                  /> -->
                  <img src="../../../assets/images/speedometer.png" alt="" />
                </div>
                <div class="speedmeter__text">
                  <div class="speedmeter__tag">
                    <span>{{ propertyListData?.days_on_market }}</span> Poor
                  </div>

                  <p>
                    Here we can have a brief explainer as to the score and the
                    major factors that contribute to it shown below.
                  </p>
                </div>
              </div>

              <CommonLoader v-else :loading="loading" />
              <div class="dashboard_left__card__list">
                <ul>
                  <li class="dashboard_left__card__list__item">
                    <h6 class="left_list__item">Title Type | Number</h6>
                    <p class="right_list__item">
                      {{ propertyListData?.type }}
                    </p>
                  </li>
                  <li class="dashboard_left__card__list__item">
                    <h6 class="left_list__item">Leasehold Years</h6>
                    <p class="right_list__item">
                      Freehold | Years Remaining: N/A
                    </p>
                  </li>
                  <li class="dashboard_left__card__list__item">
                    <h6 class="left_list__item">Energy Efficiency Rating</h6>
                    <p class="right_list__item">75 | C</p>
                  </li>
                  <li class="dashboard_left__card__list__item">
                    <h6 class="left_list__item">Property Type</h6>
                    <p class="right_list__item">Flat</p>
                  </li>
                  <li class="dashboard_left__card__list__item">
                    <h6 class="left_list__item">Floor Area</h6>
                    <p class="right_list__item">39.06 sqm</p>
                  </li>
                  <li class="dashboard_left__card__list__item">
                    <h6 class="left_list__item">Plot Size</h6>
                    <p class="right_list__item">150 sqm</p>
                  </li>
                </ul>
              </div>
              <div class="dashboard_left__card__footer">
                <v-btn
                  class="bright-blue-text"
                  variant="text"
                  @click="onClickToggleTradesWorkStation"
                  append-icon="mdi-chevron-right"
                >
                  Create A Workstation To Explore
                </v-btn>
              </div>
            </v-card>

            <!-- GET YOUR SERVICE PLAN -->
            <div class="service-plan-card">
              <h2>Does Your Property have Air Source heat pump?</h2>
              <p>
                Save with iknowa Heat Pump servicing plan. Providing you with
                peace of mind, ensuring that your heat pump is annually serviced
                and any potential issues are detected early.
              </p>
              <div class="service-plan-card__footer">
                <v-btn
                  class="bright-blue-text"
                  variant="text"
                  append-icon="mdi-chevron-right"
                  @click="onClickOpenServicePlanModal"
                >
                  Get Your Service Plan
                </v-btn>
              </div>
            </div>
          </div>
          <!-- RIGHT CONTAINER -->
          <div class="right__container">
            <!-- PROGRESS DETAILS -->
            <v-col
              v-if="
                !(
                  userBusinessType === BusinessDetailTypeEnum.PROPERTY_OWNER &&
                  userWorkStationStatus === UserVerificationStatusEnum.VERIFIED
                ) &&
                userAllWorkStation &&
                userAllWorkStation.length < 2
              "
              class="dashboard-setting-up__right tw-w-full !tw-p-0"
            >
              <v-card
                class="custom-card"
                :class="{
                  'fixed-height':
                    userWorkStationStatus ===
                    UserVerificationStatusEnum.VERIFIED,
                }"
              >
                <div class="icon-title">
                  <div class="icon-title__inner">
                    <v-icon icon="mdi-star" />
                    <p class="common-title">
                      Setting Up Your iknowa Workstation
                    </p>
                  </div>
                </div>
                <div
                  class="progress-list"
                  v-if="
                    user?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
                    userWorkStationStatus !==
                      UserVerificationStatusEnum.VERIFIED
                  "
                >
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: !isUserWorkstationIsDefault,
                    }"
                    @click="onClickToggleTradesWorkStation"
                  >
                    <div class="progress-list__counter">
                      <span>1</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Create Workstation</p>
                      <span
                        >Explore a property, Raise projects, Invite others to
                        collaborate, etc.</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="progress-list"
                  v-if="
                    userBusinessType ===
                      BusinessDetailTypeEnum.PROPERTY_OWNER &&
                    userWorkStationStatus ===
                      UserVerificationStatusEnum.VERIFIED
                  "
                >
                  <div class="progress-list__item completed">
                    <div class="progress-list__counter">
                      <span>1</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Explore Your Trade Passport</p>
                      <span>Everything under one roof</span>
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: !isUserWorkstationIsDefault,
                    }"
                  >
                    <div class="progress-list__counter">
                      <span>2</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Create a workstation</p>
                      <span>xxx</span>
                    </div>
                  </div>
                  <div class="progress-list__item">
                    <div class="progress-list__counter">
                      <span>3</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Check out new opportunities</p>
                      <span>Earn a new accredited qualification</span>
                    </div>
                  </div>
                  <div class="progress-list__item">
                    <div class="progress-list__counter">
                      <span>4</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Find new jobs</p>
                      <span>xxx</span>
                    </div>
                  </div>
                  <div class="progress-list__item">
                    <div class="progress-list__counter">
                      <span>5</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Take a tour of the dashboard</p>
                      <span>xxx</span>
                    </div>
                  </div>
                </div> -->
              </v-card>
            </v-col>

            <!-- MANAGE WORKSTATIONS -->
            <Card
              class="!tw-max-h-[unset] !tw-min-h-[336px] tw-w-full tw-box-border"
            >
              <template #content>
                <main
                  class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
                >
                  <section
                    class="tw-w-full tw-flex tw-items-center tw-justify-between tw-py-5 tw-px-4 tw-box-border tw-border-b-solid-custom"
                  >
                    <div class="tw-flex tw-items-center tw-gap-4">
                      <Text variant="h6">Workstations</Text>
                    </div>
                  </section>
                  <!-- WORKSTATION FILLED STATE -->
                  <section
                    class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-5 tw-px-4 tw-box-border !tw-max-h-[265px] !tw-min-h-[265px] tw-overflow-auto no-scrollbar"
                    v-if="
                      filteredUserAllWorkStation.length > 0 ||
                      WorkStationInvitation.length > 0
                    "
                  >
                    <div
                      v-if="
                        WorkStationInvitation &&
                        WorkStationInvitation.length > 0
                      "
                      class="tw-flex tw-flex-col tw-gap-4 tw-border-b tw-border-0 tw-border-dashed tw-border-[#C5D2F2] tw-pb-5"
                    >
                      <div
                        class="tw-w-full tw-flex tw-justify-between tw-items-center"
                        v-for="(member, index) in WorkStationInvitation"
                        :key="index"
                      >
                        <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
                          <!-- profile -->
                          <img
                            class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                            v-if="InviteMemberImage"
                            :src="InviteMemberImage"
                          />
                          <WorkStationProfileLogo
                            class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                            v-else
                            :WSName="member?.userWorkstation?.name"
                          />
                          <!-- details -->
                          <div
                            class="tw-w-full tw-flex tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap"
                          >
                            <div class="tw-flex tw-flex-col tw-item-start">
                              <div
                                class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                              >
                                <Text variant="h6">{{
                                  member?.userWorkstation?.name
                                }}</Text>
                                <div
                                  class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                                >
                                  {{ member?.role }}
                                </div>
                                <div
                                  class="tw-flex"
                                  v-for="tag in JSON.parse(member?.tags)"
                                  :key="tag"
                                >
                                  <div
                                    class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                                  >
                                    {{ tag }}
                                  </div>
                                </div>
                                <!-- <SignInChip /> -->
                              </div>
                              <div class="tw-flex tw-items-start tw-flex-col">
                                <div
                                  class="tw-flex tw-items-start tw-flex-col tw-gap-2"
                                >
                                  <Text
                                    variant="span"
                                    textWeight="600"
                                    textColor="rgba(12, 15, 74, 0.5)"
                                    >{{ member?.user?.email }}</Text
                                  >
                                  <!-- <Text
                          variant="span"
                          textWeight="600"
                          textColor="#4B4BFF"
                          >+2 more</Text
                        > -->
                                </div>
                                <Text
                                  v-if="member?.message"
                                  variant="span"
                                  textWeight="600"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >{{ member?.message }}</Text
                                >

                                <Text
                                  class="!tw-text-[10px] !tw-leading-none !tw-text-darkBlue/50"
                                  textWeight="400"
                                  >Last signed in:
                                  {{
                                    member?.user?.lastLoginAt
                                      ? formatDate(
                                          member?.user?.lastLoginAt,
                                          "fullDate"
                                        )
                                      : "NA"
                                  }}</Text
                                >
                              </div>
                            </div>

                            <div
                              class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                              v-if="
                                member?.invitationStatus ===
                                MemberInvitationStatusEnum.PENDING
                              "
                            >
                              <v-btn
                                class="tw-px-[8px] tw-py-[6px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                                @click="onClickRejectWSInvitation(member?.id)"
                              >
                                <InfoIconWhite />
                                <span class="tw-ml-[3px]">Reject</span>
                              </v-btn>
                              <v-btn
                                :loading="
                                  acceptInvitaionLoader &&
                                  acceptInvitationWorkstationId ===
                                    member?.token
                                "
                                class="tw-px-[8px] tw-py-[6px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#1FB27A] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                                @click="
                                  onClickAcceptWSInvitation(member?.token)
                                "
                              >
                                <CheckIconWhite />
                                <span class="tw-ml-[3px]">Accept</span>
                              </v-btn>
                              <!-- <AcceptChip /> -->
                              <!-- <MyWorkstationChip /> -->
                            </div>
                            <div
                              class="tw-flex tw-items-center tw-gap-2"
                              v-if="
                                member?.invitationStatus ===
                                MemberInvitationStatusEnum.ACCEPTED
                              "
                            >
                              <v-btn
                                class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                                @click="
                                  onWorkStationClick(
                                    member?.userWorkstation?.id
                                  )
                                "
                              >
                                launch <LunchIcon class="tw-ml-2" />
                              </v-btn>
                              <v-btn
                                class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                                @click="onClickRejectWSInvitation(member?.id)"
                              >
                                Delete
                                <TrashIcon
                                  class="tw-ml-2"
                                  fill="#FFF"
                                  fillOpacity="1"
                                />
                              </v-btn>
                              <div class="tw-flex tw-items-center tw-gap-2">
                                <DeleteActionChip
                                  @click="onClickRejectWSInvitation(member?.id)"
                                />
                              </div>
                              <!-- <MyWorkstationChip /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- User Workstation -->
                    <div
                      class="tw-max-h-[350px] tw-w-full tw-flex tw-flex-col tw-gap-3 tw-justify-between"
                    >
                      <div
                        class="tw-w-full tw-flex tw-items-center"
                        v-for="(member, index) in filteredUserAllWorkStation"
                        :key="index"
                      >
                        <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
                          <!-- profile -->
                          <img
                            v-if="member?.profileImage"
                            :src="`https://storage.googleapis.com/idgc-b10/${member?.profileImage}`"
                            class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                          />
                          <WorkStationProfileLogo
                            class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                            v-else
                            :WSName="member?.name"
                          />
                          <!-- details -->
                          <div
                            class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap tw-w-[calc(100%_-_64px)]"
                          >
                            <div class="tw-flex tw-flex-col tw-item-start">
                              <div
                                class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                              >
                                <Text variant="h6">{{ member?.name }}</Text>
                                <div
                                  v-if="member?.role"
                                  class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                                >
                                  {{ member?.role }}
                                </div>
                                <div
                                  v-else
                                  class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                                >
                                  Admin
                                </div>
                                <div v-if="member?.tags">
                                  <div
                                    class="tw-flex"
                                    v-for="tag in JSON.parse(member?.tags)"
                                    :key="tag"
                                  >
                                    <div
                                      class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                                    >
                                      {{ tag }}
                                    </div>
                                  </div>
                                </div>
                                <!-- <SignInChip /> -->
                              </div>
                              <div class="tw-flex tw-items-start tw-flex-col">
                                <div
                                  class="tw-flex tw-items-start tw-flex-col tw-gap-2"
                                >
                                  <Text
                                    variant="span"
                                    textWeight="600"
                                    textColor="rgba(12, 15, 74, 0.5)"
                                    >{{
                                      processedEmail(member?.user?.email)
                                    }}</Text
                                  >
                                  <!-- <Text
                          variant="span"
                          textWeight="600"
                          textColor="#4B4BFF"
                          >+2 more</Text
                        > -->
                                </div>
                                <Text
                                  v-if="member?.message"
                                  variant="span"
                                  textWeight="600"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >{{ member?.message }}</Text
                                >

                                <Text
                                  class="!tw-text-[10px] !tw-leading-none !tw-text-darkBlue/50"
                                  textWeight="400"
                                  >Last signed in:
                                  {{
                                    member?.user?.lastLoginAt
                                      ? formatDate(
                                          member?.user?.lastLoginAt,
                                          "fullDate"
                                        )
                                      : "NA"
                                  }}</Text
                                >
                              </div>
                            </div>

                            <div
                              class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                            >
                              <v-btn
                                class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                                @click="onWorkStationClick(member?.id)"
                              >
                                launch <LunchIcon class="tw-ml-2" />
                              </v-btn>
                              <v-btn
                                :loading="
                                  deleteInvitedWorkstationLoader &&
                                  deleteInvitedWorkstationId ===
                                    member?.memberId
                                "
                                v-if="member?.isInvited"
                                class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                                @click="
                                  onClickRejectWSInvitation(member?.memberId)
                                "
                              >
                                Delete
                                <TrashIcon
                                  class="tw-ml-2"
                                  fill="#FFF"
                                  fillOpacity="1"
                                />
                              </v-btn>
                              <!-- <AcceptChip /> -->
                              <!-- <MyWorkstationChip /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- WORKSTATION EMPTY STATE -->
                  <section
                    class="tw-w-full tw-h-60 !tw-max-h-[265px] !tw-min-h-[245px]"
                    v-else
                  >
                    <div
                      class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4"
                    >
                      <h3
                        class="tw-text-center tw-max-w-[90%] tw-text-[#0C0F4A]"
                      >
                        Welcome to iknowa!
                      </h3>
                      <p
                        class="tw-text-center tw-max-w-[90%] tw-text-[#0c0f4a80]"
                      >
                        Get started by estimating live jobs and enhancing your
                        skills to unlock access to courses. Create a workstation
                        to begin trading.
                      </p>
                      <Button
                        class="!tw-w-[auto]"
                        variant="accent"
                        label="CREATE A pROPERTY WORKSTATION "
                        @click="onClickToggleTradesWorkStation"
                      ></Button>
                    </div>
                  </section>
                </main>
              </template>
            </Card>

            <!-- MANAGE EMAILS -->
            <Card
              class="!tw-max-h-[unset] !tw-min-h-[336px] tw-w-full tw-box-border"
            >
              <template #content>
                <main
                  class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
                >
                  <section
                    class="tw-w-full tw-flex tw-items-center tw-justify-between tw-py-5 tw-px-4 tw-box-border tw-border-b-solid-custom"
                  >
                    <div
                      class="email_title tw-flex tw-items-center tw-gap-4 tw-justify-between tw-w-full tw-max-w-full"
                    >
                      <Text variant="h6">Manage Emails</Text>
                    </div>
                    <AddEmailActionChip @click="toggleAddEmailModal(true)" />
                  </section>
                  <section
                    class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-py-5 tw-px-4 tw-box-border !tw-max-h-[255px] !tw-min-h-[255px]"
                  >
                    <div
                      class="tw-w-full tw-flex tw-items-start tw-justify-between"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="h6">{{
                          processedEmail(userEmail)
                        }}</Text>
                      </div>
                    </div>
                    <div
                      class="tw-w-full tw-flex tw-items-start tw-justify-between"
                      v-for="email in manageEmailList"
                      :key="email.id"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <Text variant="h6">{{ email?.aliasEmail }}</Text>
                        <ApproveChip
                          v-if="email?.invitationStatus === 'accepted'"
                        />
                        <PendingChip
                          v-if="email?.invitationStatus === 'pending'"
                        />
                      </div>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <DeleteActionChip @click="onClickDeleteEmail(email)" />
                      </div>
                    </div>
                  </section>
                </main>
              </template>
            </Card>
          </div>
        </section>
      </div>
    </main>
    <AddEmailModal
      v-if="isShowAddEmailModal"
      @on-close="toggleAddEmailModal($event)"
      @onClickAdd="onClickAddEmail"
    />
    <EmailConformModal
      v-if="isShowAddEmailConformModal"
      :modalType="emailConfirmModalType"
      :manageEmail="manageEmail"
      @on-close="toggleAddEmailConfirmModal"
    />

    <PropertyOwnerWorkStationModal
      v-if="isShowPropertyOwnerWorkStationModal"
      @onClickCloseTab="onClickClosePOWorkstationModal"
    />
    <PropertyOwnerSubscriptionModal
      v-if="isShowPropertyOwnerSubscriptionModal"
      :SelectWorkstationType="true"
      @closeSelectWorkstationTypeModal="onClickCloseButton"
    />
  </div>
  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :onClickCloseTab="onClickCloseModel"
  />

  <!-- <KnowYourPropertyComponentModal
    v-if="user.loginAttempt === 1 && isShowknowpropertyModal"
    @onClickCloseWelcomeModal="onClickCloseWelcomeModal"
  /> -->
  <!-- <UpgradePremiumWorkstationModal
    v-if="isShowUpgradePremiumModal"
    @closeUpgradePremiumModal="closeUpgradePremiumModal"
  /> -->
  <PropertyOwnerSubscriptionModal
    v-if="isShowUpgradePremiumModal"
    @closeSubscriptionModal="closeUpgradePremiumModal"
    @closeSelectWorkstationTypeModal="closeUpgradePremiumModal"
  />
  <AddAddressModal
    v-if="openedModel === modelName.ADDRESSMODAL"
    @on-close="onClickCloseModel"
  />
  <PropertyServicePlanModal
    v-if="isOpenServicePlanModal"
    @on-close="onClickOpenServicePlanModal"
    @on-action="onServicePlanAction"
  />
  <WelcomePropertyOwnerPopup
    v-if="user.loginAttempt === 1 && isShowWelcomePropertyOwnerModal"
    @on-close="onClickCloseWelcomePropertyModal"
  />
</template>

<script lang="ts">
import { computed, onBeforeMount, ref, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
// import SpeedMeterComponent from "@/modules/dashboard/components/SpeedMeterComponent.vue";
import { VUE_APP_PROPERTY_DATA_API } from "@/config";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
// import KnowYourPropertyComponentModal from "@/modules/dashboard/components/KnowYourPropertyComponent.vue";
import { getCombineSameProjectReview, parseJSON } from "@/core/utils/common";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { PROJECT_STORE } from "@/store/modules/project";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
  MemberInvitationStatusEnum,
} from "@/core/enums/RolesEnum";
import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
// import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import PropertyOwnerWorkStationModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerWorkStationModal.vue";
// import WorkStationVerificationLock from "@/core/components/WorkStationVerificationLock.vue";
import PropertyOwnerSubscriptionModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerSubscriptionModal.vue";
import WelcomePropertyOwnerPopup from "@/modules/owner-onboarding/components/WelcomePropertyOwnerPopup.vue";

// WIDGETS
import PreferredContractorsWidget from "@/modules/dashboard/components/Widgets/PreferredContractorsWidget.vue";
import PropertiesWidget from "@/modules/dashboard/components/Widgets/PropertiesWidget.vue";
import ProjectsOverviewWidget from "@/modules/dashboard/components/Widgets/ProjectsOverviewWidget.vue";
import TeamWidget from "@/modules/dashboard/components/Widgets/TeamWidget.vue";
import CalendarEventWidget from "@/modules/dashboard/components/Widgets/CalendarEventWidget.vue";
import AddEmailActionChip from "./Chips/AddEmailActionChip.vue";
import AddEmailModal from "@/modules/dashboard/components/Modals/AddEmailModal.vue";
import ApproveChip from "@/modules/dashboard/components/Chips/ApproveChip.vue";
import DeleteActionChip from "@/modules/dashboard/components/Chips/DeleteActionChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import EmailConformModal from "./Modals/EmailConformModal.vue";
import manageEmailService from "@/core/services/manage-email.service";
import { PROPERTY_STORE } from "@/store/modules/property";
// import UpgradePremiumWorkstationModal from "@/modules/workstation/propertyowner/pages/UpgradePremiumWorkstationModal.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
// import MyWorkstationChip from "./Chips/MyWorkstationChip.vue";
import LunchIcon from "@/core/components/icons/LunchIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import Loader from "@/core/components/common/Loader.vue";
import AddAddressModal from "@/modules/dashboard/components/Modals/AddAddressModal.vue";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { VIEW_RATING_ROUTE } from "@/modules/view-rating/routes";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import {
  CreateProjectRatingPayloadWithId,
  reviewRatingState,
} from "@/core/models/project-rating";
import { UserWorkstationVerificationType } from "@/core/enums/RolesEnum";
import ProfileVerificationStatusChip from "./Chips/ProfileVerificationStatusChip.vue";
import PropertyServicePlanModal from "@/modules/dashboard/components/Modals/PropertyServicePlanModal.vue";
import { PROPERTY_TYPE } from "@/modules/properties/constants";
import { ADD_PROPERTY_ROUTE } from "@/modules/properties/routes";

export default {
  components: {
    // SpeedMeterComponent,
    UserProfileLogo,
    WorkStationProfileLogo,
    // KnowYourPropertyComponentModal,
    Text,
    Card,
    Button,
    ProfileThumbnail,
    // Postcode,
    WorkstationVerificationModal,
    PropertyOwnerWorkStationModal,
    PropertiesWidget,
    PreferredContractorsWidget,
    ProjectsOverviewWidget,
    TeamWidget,
    CalendarEventWidget,
    PropertyOwnerSubscriptionModal,
    AddEmailModal,
    EmailConformModal,
    AddEmailActionChip,
    ApproveChip,
    PendingChip,
    DeleteActionChip,
    // UpgradePremiumWorkstationModal,
    // MyWorkstationChip,
    LunchIcon,
    TrashIcon,
    Loader,
    AddAddressModal,
    ProfileVerificationStatusChip,
    PropertyServicePlanModal,
    WelcomePropertyOwnerPopup,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const progressValue = ref("50%");
    const propertyListData = ref([]) as any;
    const loading = ref(false);
    const isWorkstationEmpty = ref(false);
    const WorkStationInvitation = ref();
    const InviteMemberImage = ref();
    const modelName = {
      VERIFYMODAL: "verifyModal",
      ADDRESSMODAL: "addressModal",
    };
    const openedModel = ref("");
    const isShowUpgradePremiumModal = ref(false);
    const isShowPropertyOwnerWorkStationModal = ref(false);
    const isShowPropertyOwnerSubscriptionModal = ref(false);
    const isShowknowpropertyModal = ref(false);
    const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
    const userFullName = computed(() => {
      return `${user.value?.firstName} ${user.value?.lastName}`;
    });
    const isShowAddEmailModal = ref(false);
    const isShowAddEmailConformModal = ref(false);
    const manageEmailList: any = ref([]);
    const emailConfirmModalType = ref("");
    const manageEmail = ref() as any;
    const statusModal = { PAYMENT: "payment", KYC: "kyc" };
    const currentStatusModalName = ref("");
    const currentModalStatus = ref("");
    const isShowWelcomePropertyOwnerModal = ref(false);
    onUnmounted(() => {
      store.commit(
        `${WORKSTATION}/setPropertyOwnerWorkStationModelState`,
        false
      );
    });

    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );

    const filteredUserAllWorkStation = computed(() =>
      userAllWorkStation.value.filter(
        (workstation: any) => workstation.name !== "Default"
      )
    );

    const WorkStationSiltVerificationStatus = computed(
      () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
    );

    const isWorkstationLoading = computed(
      () => store.getters[`${WORKSTATION}/loadingWorkstation`]
    );

    const onClickToggleTradesWorkStation = () => {
      isShowPropertyOwnerWorkStationModal.value = true;
      store.commit(
        `${WORKSTATION}/setPropertyOwnerWorkStationModelState`,
        !isShowCreatePropertyOwnerWorkStationModal.value
      );
    };

    const isShowCreatePropertyOwnerWorkStationModal = computed(
      () =>
        store.getters[`${WORKSTATION}/getPropertyOwnerWorkStationModelState`]
    );
    const onClickCloseWelcomeModal = () => {
      isShowknowpropertyModal.value = false;
      localStorage.setItem("isShowknowpropertyPopup", JSON.stringify(true));
    };
    const route = useRoute();
    const redirectEscrowPayment = () => {
      if (
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
        route.query.result &&
        route.query.paymentInitiationId
      ) {
        const data = localStorage.getItem("escrowPayment") as any;
        if (data) {
          const details = JSON.parse(data);
          store.dispatch(
            `${PROJECT_STORE}/setProjectRedirectTabName`,
            "escrow-account"
          );
          router.push({
            path: `/project-details/${details?.url}`,
            query: {
              result: route.query.result,
              paymentInitiationId: route.query.paymentInitiationId,
            },
          });
          // localStorage.removeItem("escrowPayment");
        }
      }
    };

    const getPreferredContractorsConnection = async () => {
      try {
        let params = {
          status: "accepted",
        };
        await store.dispatch(
          `${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`,
          {
            userId: user.value?.id,
            params: params,
          }
        );
      } catch (err) {
        console.log();
      }
    };
    const getAllProjectReviewList = computed<
      reviewRatingState["allProjectReviewList"]
    >(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);
    const combineSameProjectReview = computed(() =>
      getCombineSameProjectReview(getAllProjectReviewList.value)
    );
    const getAverageOfAllProjectReview = computed(() => {
      const totalAvg = combineSameProjectReview.value.reduce(
        (totalRating: number, review: any) => {
          totalRating += review.totalAvg || 0;
          return totalRating;
        },
        0
      );
      return totalAvg
        ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) /
            100
        : totalAvg;
    });

    const getCheckoutUserKYC = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.silt_user_id) return;
        const soloTraderVerificationData = await store.dispatch(
          `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
          {
            id: params.silt_user_id,
            verificationType: UserWorkstationVerificationType.KYC,
          }
        );
        console.log(soloTraderVerificationData);
        await store.dispatch(
          `${WORKSTATION}/saveWorkstationVerificationData`,
          soloTraderVerificationData
        );

        let status = UserVerificationStatusEnum.PENDING_VERIFICATION;
        if (soloTraderVerificationData.status === "SUCCESS") {
          status = UserVerificationStatusEnum.VERIFIED;
        }

        const data = {
          userId: user.value?.id,
          userWorkstationId: activeUserWorkstation.value?.id,
          formData: {
            verificationStatus: status,
          },
        };
        await store.dispatch(
          `${WORKSTATION}/updateUserWorkstationStatus`,
          data
        );

        currentStatusModalName.value = statusModal.KYC;
        currentModalStatus.value = soloTraderVerificationData?.status;
        openedModel.value = "";
        await store.dispatch(`${USER_STORE}/initializeUserState`);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          currentStatusModalName.value = statusModal.KYC;
          openedModel.value = "";
          currentModalStatus.value = "ERROR";
          return;
        }
      }
    };

    onBeforeMount(async () => {
      redirectEscrowPayment();
      getWorkStationInvitation();
      getCheckoutUserKYC();
      getPreferredContractorsConnection();
      await store.dispatch(
        `${REVIEW_RATING_STORE}/getAllProjectReviewList`,
        ProjectRatingTypeEnum.TRADER_OWNER
      );
      await store.dispatch(
        `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
        user.value.id
      );
      setManageEmailList();
      isShowknowpropertyModal.value = parseJSON(
        localStorage.getItem("isShowknowpropertyPopup")
      )
        ? false
        : true;

      isShowWelcomePropertyOwnerModal.value = parseJSON(
        localStorage.getItem("isShowWelcomePropertyOwnerModal")
      )
        ? true
        : false;
      try {
        loading.value = true;
        // const response = await axios.get(
        //   `https://api.propertydata.co.uk/sourced-properties?key=${VUE_APP_PROPERTY_DATA_API}&list=repossessed-properties&postcode=${user.value?.postcode}`
        // );
        // if (response.status === 200) {
        // propertyListData.value = response.data.properties[0];
        propertyListData.value = {
          id: "R142978070",
          address: "9 Cornwallis Avenue",
          addressLine1: "Folkestone, Kent, UK",
          postcode: "CT19 5JA",
          type: " Leasehold | LH2RYU",
          bedrooms: 3,
          price: 2250000,
          sqf: 1895,
          days_on_market: 47,
          sstc: 0,
          lat: "51.49305000",
          lng: "-0.15286000",
          distance_to: "0.73",
          highest_offer: null,
        };

        await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});

        // }

        // addressListfromPostcode.value = response.data.results;
      } catch (error) {
        console.log("error", error);
      } finally {
        loading.value = false;
      }
    });

    const onClickSelectWorkstation = () => {
      router.push("/select-workstation");
    };
    const onClickUpdateProfile = () => {
      router.push("/profile");
    };

    const userName = computed(() => {
      return `${user.value?.firstName}`;
    });

    const userEmail = computed(() => {
      return `${user.value?.email}`;
    });

    const userWorkStationImage = computed(
      () => store.getters[`${USER_STORE}/userWorkStationImage`]
    );

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const getWorkstationAddress = (type: string) => {
      if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
        return user.value?.userBusinessDetails?.businessAddress;
      }
      return user.value?.userBusinessDetails?.residencyAddressLine;
    };

    const getWorkstationPostcode = (type: string) => {
      if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
        return user.value?.userBusinessDetails?.businessPostcode;
      }
      return `${user.value?.userBusinessDetails?.residencyPostcode},`;
    };

    const userWorkStationStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const userWorkstationStatusByWord = computed(() => {
      if (
        userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED
      ) {
        return "PO-UNVERIFIED";
      }
      if (userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED) {
        return "PO-VERIFIED";
      }
      if (
        userWorkStationStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        return "PO-PENDING";
      }
      return "";
    });

    const userBusinessType = computed(
      () => user.value?.userBusinessDetails?.type
    );

    // NOTE: Must be isUserProfileVerifiable
    const isUserProfileVerified = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );

    const isUserTaxDetailsAdded = computed(
      () =>
        user.value?.userBusinessDetails?.taxReferenceNumber &&
        user.value?.userBusinessDetails?.ukVatNumber
    );

    const workstationSetupProgress = computed(() => {
      if (
        !isUserWorkstationIsDefault.value &&
        isUserProfileVerified.value &&
        isUserTaxDetailsAdded.value
      ) {
        return 66;
      }
      if (!isUserWorkstationIsDefault.value) {
        return 33;
      }
      return 0;
    });

    const isUserWorkstationIsDefault = computed(
      () =>
        activeUserWorkstation.value?.name === "Default" &&
        activeUserWorkstation.value?.status === "active"
    );

    const acceptInvitaionLoader = ref(false);
    const acceptInvitationWorkstationId = ref<null | string>(null);
    const onClickAcceptWSInvitation = async (token: string) => {
      if (
        user.value?.userKYCVerificationStatus ===
        UserVerificationStatusEnum.VERIFIED
      ) {
        try {
          acceptInvitaionLoader.value = true;
          acceptInvitationWorkstationId.value = token;
          await store.dispatch(`${USER_STORE}/acceptWorkStationInvitation`, {
            userId: user.value?.id,
            key: token,
          });
          getWorkStationInvitation();
          await store.dispatch(
            `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
            user.value.id
          );
        } catch (err) {
          console.log();
        } finally {
          acceptInvitaionLoader.value = false;
          acceptInvitationWorkstationId.value = null;
        }
      } else {
        openedModel.value = "verifyModal";
      }
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    };

    const deleteInvitedWorkstationLoader = ref(false);
    const deleteInvitedWorkstationId = ref<null | number>(null);
    const onClickRejectWSInvitation = async (id: number) => {
      try {
        deleteInvitedWorkstationLoader.value = true;
        deleteInvitedWorkstationId.value = id;
        const response = await store.dispatch(
          `${MEMBER_STORE}/deleteMemberDetails`,
          {
            userId: user.value?.id,
            userMemberId: id,
          }
        );
        getWorkStationInvitation();
        await store.dispatch(
          `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
          user.value.id
        );
      } catch (err) {
        console.log();
      } finally {
        deleteInvitedWorkstationLoader.value = false;
        deleteInvitedWorkstationId.value = null;
      }
    };

    const getWorkStationInvitation = async () => {
      try {
        const response = await store.dispatch(
          `${USER_STORE}/getWorkStationInvitation`,
          user.value?.id
        );
        WorkStationInvitation.value = response;
        const image = getImageApiUrl(
          response[0]?.userWorkstation?.profileImage,
          true
        );
        const encoded: any = await $axios.get(image);
        InviteMemberImage.value = encoded?.publicUrl;
      } catch (err) {
        console.log();
      }
    };

    const onClickClosePOWorkstationModal = () => {
      isShowPropertyOwnerWorkStationModal.value = false;
    };

    const freePlan = computed(() => {
      return (
        userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED &&
        !activeUserWorkstation.value?.subscription
      );
    });

    const properties = computed(
      () => store.getters[`${PROPERTY_STORE}/properties`]
    );
    const onClickAddNewProperty = () => {
      if (freePlan.value && properties.value.length >= 1) {
        onClickUpgradePlan();
      } else {
        // router.push("/properties");
        router.push({
          name: ADD_PROPERTY_ROUTE,
          params: { type: PROPERTY_TYPE },
        });
      }
    };

    const onClickVerifyAccount = () => {
      isShowPropertyOwnerSubscriptionModal.value = true;
    };

    const onClickCloseButton = async () => {
      isShowPropertyOwnerSubscriptionModal.value = false;
      getWorkStationInvitation();
      await store.dispatch(
        `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
        user.value.id
      );
      setManageEmailList();
    };
    const toggleAddEmailModal = (event: boolean) => {
      isShowAddEmailModal.value = event;
    };
    const toggleAddEmailConfirmModal = (status = false) => {
      isShowAddEmailConformModal.value = !isShowAddEmailConformModal.value;
      if (status) {
        manageEmailList.value = manageEmailList.value.filter(
          (data: any) => data.id !== manageEmail.value?.id
        );
        manageEmail.value = "";
      }
    };

    const onClickUpgradePlan = () => {
      isShowUpgradePremiumModal.value = true;
    };

    const closeUpgradePremiumModal = () => {
      isShowUpgradePremiumModal.value = false;
    };

    const setManageEmailList = async () => {
      try {
        const response: any = await manageEmailService.getEmailList(
          user.value?.id
        );
        manageEmailList.value = response?.aliases;
      } catch (error) {
        console.error("get email list error", error);
      }
    };
    const onClickDeleteEmail = (data: any) => {
      manageEmail.value = data;
      emailConfirmModalType.value = "delete";
      toggleAddEmailConfirmModal();
    };
    const onClickAddEmail = (addEmailData: object) => {
      manageEmailList.value.push(addEmailData);
      isShowAddEmailModal.value = false;
      emailConfirmModalType.value = "success";
      toggleAddEmailConfirmModal();
    };

    const onClickAddress = () => {
      // if (user.value?.address) return;
      openedModel.value = modelName.ADDRESSMODAL;
    };

    const onWorkStationClick = async (
      workstationId: number,
      resetData = true
    ) => {
      try {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, true);
        if (!workstationId) return;
        if (resetData) {
          await router.push({ name: DASHBOARD_ROUTE });
          await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
            userId: user.value?.id,
            userWorkstationId: workstationId,
          });
        }
        const workstationVerification = await store.dispatch(
          `${WORKSTATION}/getWorkstationVerificationData`,
          workstationId
        );
        if (
          workstationVerification &&
          workstationVerification?.response?.id &&
          ["KYC", "KYB"].includes(workstationVerification?.verificationType)
        ) {
          await store.dispatch(
            `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
            {
              id: workstationVerification.response.id,
              verificationType: workstationVerification?.verificationType,
            }
          );
        } else {
          await store.dispatch(
            `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
            null
          );
        }
      } catch (error) {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
        console.log();
      } finally {
        if (resetData) {
          await store.dispatch(`${USER_STORE}/initializeUserState`);
          await store.dispatch(`${USER_STORE}/getAccountStatuses`);

          await store.dispatch(
            `${USER_STORE}/setUserCertificates`,
            user.value?.id
          );
          await store.dispatch(
            `${USER_STORE}/setUserInsurances`,
            user.value?.id
          );
          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
          await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
        }
      }
    };

    const formatDate = (dateString: any, format: string) => {
      const date = new Date(dateString);

      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      switch (format) {
        case "fullDate": {
          const day = date.getUTCDate();
          const month = date.getUTCMonth();
          const year = date.getUTCFullYear();
          return `${day} ${monthsOfYear[month]}, ${year}`;
        }
        default: {
          throw new Error("Invalid format type");
        }
      }
    };

    const processedEmail = (email: any) => {
      if (email.startsWith("iknowa.alias.")) {
        return email.replace("iknowa.alias.", "");
      }
      return email;
    };
    const onClickGotoRatingPage = () => {
      router.push({ name: VIEW_RATING_ROUTE });
    };
    const isOpenServicePlanModal = ref(false);
    const onClickOpenServicePlanModal = () => {
      isOpenServicePlanModal.value = !isOpenServicePlanModal.value;
    };
    const onServicePlanAction = (actionType: string) => {
      isOpenServicePlanModal.value = false;
      if (actionType === "workstation") {
        onClickToggleTradesWorkStation();
      }
    };
    const onClickCloseWelcomePropertyModal = () => {
      isShowWelcomePropertyOwnerModal.value = false;
      localStorage.removeItem("isShowWelcomePropertyOwnerModal");
    };
    return {
      onClickToggleTradesWorkStation,
      isShowCreatePropertyOwnerWorkStationModal,
      user,
      userImage,
      userFullName,
      progressValue,
      VUE_APP_PROPERTY_DATA_API,
      propertyListData,
      loading,
      onClickSelectWorkstation,
      onClickUpdateProfile,
      isShowknowpropertyModal,
      onClickCloseWelcomeModal,
      isUserWorkstationIsDefault,
      userName,
      userWorkStationImage,
      activeUserWorkstation,
      getWorkstationAddress,
      BusinessDetailTypeEnum,
      getWorkstationPostcode,
      userWorkstationStatusByWord,
      UserVerificationStatusEnum,
      userWorkStationStatus,
      userBusinessType,
      workstationSetupProgress,
      isUserProfileVerified,
      isUserTaxDetailsAdded,
      isWorkstationEmpty,
      WorkStationInvitation,
      InviteMemberImage,
      MemberInvitationStatusEnum,
      onClickAcceptWSInvitation,
      onClickRejectWSInvitation,
      modelName,
      openedModel,
      onClickCloseModel,
      userEmail,
      onClickClosePOWorkstationModal,
      isShowPropertyOwnerWorkStationModal,
      onClickAddNewProperty,
      onClickVerifyAccount,
      isShowPropertyOwnerSubscriptionModal,
      onClickCloseButton,
      isShowAddEmailModal,
      onClickAddEmail,
      toggleAddEmailConfirmModal,
      isShowAddEmailConformModal,
      emailConfirmModalType,
      manageEmail,
      manageEmailList,
      onClickDeleteEmail,
      toggleAddEmailModal,
      onClickUpgradePlan,
      isShowUpgradePremiumModal,
      closeUpgradePremiumModal,
      userAllWorkStation,
      formatDate,
      onWorkStationClick,
      processedEmail,
      isWorkstationLoading,
      UserRolesEnum,
      filteredUserAllWorkStation,
      onClickAddress,
      onClickGotoRatingPage,
      getAverageOfAllProjectReview,
      UserWorkstationVerificationType,
      statusModal,
      currentStatusModalName,
      currentModalStatus,
      WorkStationSiltVerificationStatus,
      onClickOpenServicePlanModal,
      isOpenServicePlanModal,
      onServicePlanAction,
      acceptInvitaionLoader,
      deleteInvitedWorkstationLoader,
      deleteInvitedWorkstationId,
      acceptInvitationWorkstationId,
      isShowWelcomePropertyOwnerModal,
      onClickCloseWelcomePropertyModal,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/dashboard/styles/propertyownerdashboard.scss";
.profile-logo {
  border-radius: 10px;
  background-color: #4a92ef;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ws-profile-logo {
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-rating-box {
  padding: 12px 16px;
  background-color: #f3f3f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  flex-wrap: wrap;
  .v-rating {
    :deep(.v-rating__wrapper) {
      .v-rating__item {
        .v-btn {
          width: auto;
          height: auto;
        }
      }
    }
  }
  p {
    @include fluidFont(16, 16, 1.2);
    font-weight: 700;
    letter-spacing: 0.12px;
    color: rgba($blueDark, 1);
  }
  .v-btn {
    padding: 5px 8px;
    min-height: auto;
    display: flex;
    text-transform: capitalize;
    height: auto;
  }
}

.button-purple {
  letter-spacing: 1.25px;
}
</style>
