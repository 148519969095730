<template>
  <div class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto">
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img">
        <img
          src="../../../assets/images/jobs-empty.png"
          alt=""
          class="tw-w-full"
        />
      </div>
      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any jobs yet!</h4>
        <p>
          Start exploring new opportunities and find the perfect job to match
          your skills and schedule.
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any jobs yet!</h4>
        <p>All job listings will be displayed here.</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          v-if="getUserWorkStationFieldAccess"
          class="button button-purple"
          width="100%"
          >Find Jobs</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const getUserWorkStationFieldAccess = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const isUserProprtyOwner = computed(
  () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
);
</script>

<style lang="scss" scoped>
/*Empty Screen */
.empty__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty__banner {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    @include respond(sm) {
      width: 100%;
    }
    // .empty__banner__img {
    //   img {
    //     @include respond(s720) {
    //       width: 100%;
    //       max-width: 100%;
    //     }
    //   }
    // }
    .empty__banner__content {
      margin-top: 46px;
      @include respond(sm) {
        margin: 0;
      }
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
      .empty__banner__content__list {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 27px;
        .list__item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .v-icon {
            @include fluidFont(24, 24, 1);
            color: rgba($skyBlue, 1);
          }
          p {
            @include fluidFont(14, 14, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
    .empty__banner__footer {
      margin-top: 36px;
      @include respond(sm) {
        margin-top: 16px;
      }
    }
  }
}
</style>
