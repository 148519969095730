<template>
  <div
    v-if="!loading"
    class="main_project_preview !tw-mt-0"
    @click.stop="addComments"
    ref="canvas"
    id="canvas_id"
  >
    <div class="left_block">
      <div class="top_links_main mobile__wrapper">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            @click="onClickBackToSearchResults"
            >Back to Search results
          </v-btn>
        </div>

        <EmergencyChip
          v-if="ProjectData?.type === ProjectTypesEnum.EMERGENCY"
        />
        <StandardChip v-if="ProjectData?.type === ProjectTypesEnum.STANDARD" />
        <RoutineChip v-if="ProjectData?.type === ProjectTypesEnum.ROUTINE" />
      </div>
      <!-- Team Members -->
      <div class="proj_components list_block">
        <h4>Team Members</h4>
        <div class="list_block__item">
          <template v-if="ProjectData?.invitedMembersAndRoles?.length">
            <v-list
              v-for="(member, index) in ProjectData?.invitedMembersAndRoles"
              :key="index"
            >
              <v-list-item
                :prepend-avatar="member?.prependAvatar"
                :subtitle="member?.role"
                :title="member?.email"
              >
              </v-list-item>
            </v-list>
          </template>

          <v-btn
            class="button button-purple-border"
            @click="onClickInviteTeamButton"
          >
            invite team member to project
          </v-btn>
        </div>

        <div class="status__buttons">
          <v-btn
            @click="redirectVideoConsultation"
            class="button mt-2"
            color="#7467B7"
            v-if="
              ProjectData?.type === ProjectTypesEnum.VIDEO_CONSULTATION &&
              isShowVideoButton(ProjectData?.projectJobStatus?.id)
            "
          >
            Video Consultation
          </v-btn>
          <v-btn
            class="button button-orange"
            @click="onClickSiteVisit"
            :loading="isShowLoaderOnSaveButton"
            v-if="
              ProjectData?.projectJobStatus?.id ===
              ProjectJobStatusEnum.DRAFT_PROJECT
            "
          >
            post live project
          </v-btn>
          <v-btn
            class="button button-purple-border"
            @click="onClickSaveDraftProject"
            :loading="isShowLoaderOnSaveButton"
            v-if="
              ProjectData?.projectJobStatus?.id ===
              ProjectJobStatusEnum.DRAFT_PROJECT
            "
          >
            save project for later
          </v-btn>
        </div>
      </div>
      <!-- Project Overview -->
      <div class="proj_components list_block project_overview">
        <h4>Project Overview</h4>
        <div class="list_block__item">
          <div class="list_block__item__wrapper">
            <div class="list_block__item__wrapper__left">Job Type</div>
            <div class="list_block__item__wrapper__right">
              {{ projectDetails?.type }}
            </div>
          </div>
          <div class="list_block__item__wrapper">
            <div class="list_block__item__wrapper__left">Timeline</div>
            <div class="list_block__item__wrapper__right">
              {{ projectDetails?.endDate }}
            </div>
          </div>
          <div class="list_block__item__wrapper">
            <div class="list_block__item__wrapper__left">Site Visit</div>
            <div class="list_block__item__wrapper__right">
              {{ ProjectData?.siteVisitStatus ? "Enabled" : "Disabled" }}
            </div>
          </div>
        </div>
        <div class="overview__detail">
          <v-btn
            v-if="isShowProjectRatingButton"
            class="button rating-btn button-purple-border"
            @click="toggleRatingModal(null)"
            >Rate your Experience</v-btn
          >
          <div
            v-for="overview in projectOverviewStatus.default"
            :key="overview.id"
            :class="{ status_complete: overview.completed }"
            class="proj_loop overview__detail__item"
          >
            <div class="overview__detail__icon">
              <v-icon>
                <img src="@/core/uni-assets/svgs/house.svg" alt="" />
              </v-icon>
              <div class="custom__border"></div>
            </div>
            <div class="overview__detail__text">
              <h5>{{ overview.label }}</h5>
              <p>{{ overview.description }}</p>
            </div>
          </div>

          <div
            v-if="!showProjectOverviewDialog"
            class="bottom_icon"
            @click="showProjectOverviewDialog = true"
          >
            <v-icon
              icon="
               mdi-chevron-down
              "
            ></v-icon>
          </div>
          <v-expand-transition>
            <div v-show="showProjectOverviewDialog">
              <div
                v-for="overview in projectOverviewStatus.expanded"
                :key="overview.id"
                :class="{ status_complete: overview.completed }"
                class="proj_loop overview__detail__item"
              >
                <div class="overview__detail__icon">
                  <v-icon>
                    <img src="@/core/uni-assets/svgs/house.svg" alt="" />
                  </v-icon>
                  <div class="custom__border"></div>
                </div>
                <div class="overview__detail__text">
                  <h5>{{ overview.label }}</h5>
                  <p>
                    {{ overview.description }}
                  </p>
                </div>
              </div>
            </div>
          </v-expand-transition>
          <div
            v-if="showProjectOverviewDialog"
            class="bottom_icon"
            @click="showProjectOverviewDialog = false"
          >
            <v-icon
              icon="
               mdi-chevron-up
              "
            ></v-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="right_block">
      <div class="top_links_main desktop__wrapper">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            @click="onClickBackToSearchResults"
            >Back to Search results
          </v-btn>
        </div>

        <EmergencyChip
          v-if="ProjectData?.type === ProjectTypesEnum.EMERGENCY"
        />
        <StandardChip v-if="ProjectData?.type === ProjectTypesEnum.STANDARD" />
        <RoutineChip v-if="ProjectData?.type === ProjectTypesEnum.ROUTINE" />
        <VideoConsultationChip
          v-if="ProjectData?.type === ProjectTypesEnum.VIDEO_CONSULTATION"
        />
      </div>

      <!-- Start project notice -->
      <AlertCard
        class="tw-mt-8 md:!tw-mt-2 md:!tw-mb-4"
        v-if="estimateDetails && estimatePhasesMessage"
      >
        <template #content>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-items-start">
            <Text variant="h6">To Start Project</Text>

            <div v-html="estimatePhasesMessage"></div>
            <Button
              label="Deposit Payment"
              class="!tw-w-[auto] !tw-mt-4"
              @click="redirectToEscrowAccount"
            />
          </div>
        </template>
      </AlertCard>

      <!-- Project Notes -->
      <div class="project_info__wrapper">
        <template v-if="!isEditProjectNoteSection">
          <v-card class="project_info">
            <div class="card_content">
              <div class="card_content__header">
                <div class="card_content__title">
                  <h5
                    id="project_name"
                    data-comment-element="true"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented('project_name'),
                    }"
                  >
                    {{ ProjectData?.name }}
                  </h5>
                  <div class="card_content__title_info">
                    <!-- <div
                      class="raised"
                      data-comment-element="true"
                      id="project_review_item_icon_commerical"
                      :class="{
                        comment_hover:
                          isShowDashboard.type === 'comment' &&
                          isAlreadyCommented(
                            'project_review_item_icon_commerical'
                          ),
                      }"
                    >
                      <v-icon class="pointer_events_none">
                        <img
                          class="pointer_events_none"
                          src="../../../assets/icons/user.svg"
                          alt=""
                        />
                      </v-icon>
                      <p class="pointer_events_none">Commercial</p>
                    </div> -->
                    <div
                      class="project_id"
                      id="project_id_container"
                      :class="{
                        comment_hover:
                          isShowDashboard.type === 'comment' &&
                          isAlreadyCommented('project_id_container'),
                      }"
                      data-comment-element="true"
                    >
                      <p class="pointer_events_none">Project ID:</p>
                      <span class="pointer_events_none">{{
                        ProjectData?.refId
                      }}</span>
                    </div>
                  </div>
                </div>

                <v-icon
                  class="custom_edit"
                  icon="mdi-pencil"
                  @click.stop="updateProjectNameNote(ProjectData)"
                ></v-icon>
              </div>
              <div class="card_content__footer">
                <h6
                  id="project_notes_title"
                  data-comment-element="true"
                  :class="{
                    comment_hover:
                      isShowDashboard.type === 'comment' &&
                      isAlreadyCommented('project_notes_title'),
                  }"
                >
                  Project Notes
                </h6>
                <p
                  v-html="ProjectData?.description"
                  id="project_html_description"
                  data-comment-element="true"
                  :class="{
                    comment_hover:
                      isShowDashboard.type === 'comment' &&
                      isAlreadyCommented('project_html_description'),
                  }"
                ></p>
              </div>
            </div>
          </v-card>
        </template>
        <template v-else>
          <v-card class="edit_project_info">
            <div class="title">
              <h6>Project Title</h6>
              <v-text-field
                :disabled="
                  projectDetails?.projectFrom === ProjectFrom.RECOMMENDATION
                "
                density="compact"
                placeholder="enter title"
                v-model="projectTitle"
                variant="outlined"
              ></v-text-field>
            </div>
            <div class="description">
              <h6>Project Notes</h6>
              <div class="custom_editor">
                <quill-editor
                  v-model:value="state.content"
                  :options="state.editorOption"
                  :disabled="state.disabled"
                  class="editor_ql"
                />
              </div>
            </div>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                @click="onCancelProjectNotes"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="onClickSaveProjectNotes"
                :loading="isLoadingProjectNotes"
                prepend-icon="mdi-content-save"
              >
                save
              </v-btn>
            </div>
          </v-card>
        </template>
      </div>

      <!-- Specialism -->

      <!-- new specialism -->
      <div class="specialism__wrapper">
        <div class="header">
          <div class="title">
            <h4>Specialism</h4>
          </div>
          <v-icon
            class="custom_edit"
            v-if="!isEditSpecialismSection"
            icon="mdi-pencil"
            @click.stop="updateSpecialismSection()"
          ></v-icon>
        </div>
        <div class="specialism__wrapper__content">
          <div v-if="!ProjectData?.subCategories?.length">No Specialism</div>
          <div class="specialism-card-list">
            <div
              class="specialism-card-list__item"
              v-for="subCategory in ProjectData?.subCategories"
              :key="subCategory.id"
            >
              <v-btn
                variant="text"
                aria-label="Delete"
                class="specialism-card-list__item-delete"
                prepend-icon="mdi-delete"
                v-if="isEditSpecialismSection"
                @click="onClickDeleteCategoryButton(subCategory?.id)"
              ></v-btn>

              <div class="specialism-card-list__item-author">
                <div
                  class="specialism-card-list__item-author-header"
                  :class="specialismCategoryClass(subCategory?.generatedBy)"
                >
                  <div class="speicalism__icon">
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'krystal'
                      "
                      :src="require('@/assets/icons/krystal.svg')"
                      alt="krystalimg"
                    />
                    <img
                      v-if="
                        specialismCategoryClass(subCategory?.generatedBy) ===
                        'property'
                      "
                      :src="require('@/assets/icons/house.svg')"
                      alt="houseimage"
                    />
                  </div>
                  <h6>{{ subCategory?.generatedBy }}</h6>
                  <v-icon icon="mdi-information-variant-circle-outline" />
                </div>
                <div class="specialism-card-list__item-author-content">
                  <div class="category_icon">
                    <img
                      v-if="subCategory?.category?.iconName"
                      :src="
                        require('@/assets/category-icons/' +
                          subCategory?.category?.iconName +
                          '.svg')
                      "
                      alt="Image"
                    />
                  </div>
                  <div class="category_text">
                    <h5>{{ subCategory?.label }}</h5>
                    <p>{{ subCategory?.category?.poLabel }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isEditSpecialismSection" class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            variant="text"
            @click="onClickAddMoreSpecialism"
          >
            Add more Specialism
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              @click="onClickCancelSpecialismButton"
              variant="outlined"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickSaveSpecialismButton"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Project Properties -->
      <div class="properties_wrapper">
        <div class="header">
          <div class="title">
            <h4
              id="project_properties_title"
              data-comment-element="true"
              :class="{
                comment_hover:
                  isShowDashboard.type === 'comment' &&
                  isAlreadyCommented('project_properties_title'),
              }"
            >
              Properties
              <span class="count__property pointer_events_none">{{
                ProjectData?.projectProperties?.length
              }}</span>
            </h4>
            <p
              class="desktop__wrapper"
              id="project_properties_description"
              data-comment-element="true"
              :class="{
                comment_hover:
                  isShowDashboard.type === 'comment' &&
                  isAlreadyCommented('project_properties_description'),
              }"
            >
              This job is required across multiple properties, please select any
              you would like to combine into this estimate.
            </p>
          </div>

          <v-icon
            class="custom_edit"
            v-if="!isEditPropertiesSection"
            icon="mdi-pencil"
            @click.stop="updatePropertiesSection()"
          ></v-icon>
        </div>
        <div class="properties_wrapper__content">
          <div
            class="properties_wrapper__left no-scrollbar"
            :class="{ 'tw-pt-3': isEditPropertiesSection }"
          >
            <div
              class="properties_wrapper__info"
              v-for="property in ProjectData?.projectProperties"
              :key="property?.id"
            >
              <v-icon
                class="delete__icon"
                v-if="isEditPropertiesSection"
                icon="mdi-delete"
                @click.stop="onClickRemoveProperty(property)"
              ></v-icon>

              <div class="properties_wrapper__info__left">
                <div
                  class="location"
                  :id="`project_property_prop_location_${property?.id}`"
                  :class="{
                    comment_hover:
                      isShowDashboard.type === 'comment' &&
                      isAlreadyCommented(
                        `project_property_prop_location_${property?.id}`
                      ),
                  }"
                  data-comment-element="true"
                >
                  <v-icon
                    icon="mdi mdi-map-marker"
                    class="pointer_events_none"
                  ></v-icon>
                  {{ property?.property?.postcode }}
                </div>

                <div
                  class="prop_name"
                  :id="`project_property_prop_name_${property?.id}`"
                  :class="{
                    comment_hover:
                      isShowDashboard.type === 'comment' &&
                      isAlreadyCommented(
                        `project_property_prop_name_${property?.id}`
                      ),
                  }"
                  data-comment-element="true"
                >
                  <v-icon class="pointer_events_none">
                    <img src="../../../assets/icons/house.svg" alt="" />
                  </v-icon>
                  <h6 class="pointer_events_none">
                    {{ property?.nickName }}
                  </h6>
                </div>

                <div class="property__info">
                  <div
                    class="property__info__item"
                    :id="`project_property_prop_type_${property?.id}`"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented(
                          `project_property_prop_type_${property?.id}`
                        ),
                    }"
                    data-comment-element="true"
                  >
                    {{ property?.property?.propertyType || "NA" }}
                  </div>
                  <div class="separator"></div>
                  <div
                    class="property__info__item"
                    :id="`project_property_prop_beds_${property?.id}`"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented(
                          `project_property_prop_beds_${property?.id}`
                        ),
                    }"
                    data-comment-element="true"
                  >
                    <span class="pointer_events_none">{{
                      property?.property?.beds || "0"
                    }}</span>
                    bedroom
                  </div>
                  <div class="separator"></div>
                  <div
                    class="property__info__item"
                    :id="`project_property_prop_size_${property?.id}`"
                    :class="{
                      comment_hover:
                        isShowDashboard.type === 'comment' &&
                        isAlreadyCommented(
                          `project_property_prop_size_${property?.id}`
                        ),
                    }"
                    data-comment-element="true"
                  >
                    <span class="pointer_events_none">{{
                      property?.property?.propertySize || "0"
                    }}</span>
                    sqm
                  </div>
                </div>

                <div
                  class="property_type"
                  :id="`project_property_prop_main_type_${property?.id}`"
                  :class="{
                    comment_hover:
                      isShowDashboard.type === 'comment' &&
                      isAlreadyCommented(
                        `project_property_prop_main_type_${property?.id}`
                      ),
                  }"
                  data-comment-element="true"
                >
                  <v-icon
                    icon="mdi mdi-account-circle"
                    class="pointer_events_none"
                  ></v-icon
                  >{{ property?.property?.propertyCategory || "NA" }}
                </div>
              </div>
              <!-- <div class="properties_wrapper__info__right">
                <div class="property_image">
                  <img
                    src="../../../assets/images/property-sample-image.jpg"
                    alt=""
                  />
                </div>
                <div v-if="!isEditPropertiesSection" class="prop_count">
                  {{ index + 1 }}
                </div>
              </div> -->
            </div>
          </div>
          <div class="properties_wrapper__right">
            <div
              class="map__wrapper"
              id="map_properties"
              v-if="addresses && addresses.length"
            >
              <!-- <AddressMapComponent
                class="pointer_events_none"
                :latlng="[53.5809811, -0.6393335]"
                :addresses="addresses"
              /> -->
              <PropertyMapModal
                :properties="addresses"
                className="tw-w-full tw-h-[186px] rmd:tw-h-[380px] tw-rounded-[10px] tw-overflow-hidden"
              />
            </div>
          </div>
        </div>
        <div v-if="isEditPropertiesSection" class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            @click="onClickTogglePropertyListModal"
            variant="text"
          >
            Add more Property
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              :loading="isLoadingOnCancel"
              @click="onClickCancelButton"
              variant="outlined"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickSaveButton"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Documents / Attachments -->
      <div
        v-if="!loadingSelectProperty"
        class="documents_attachment"
        :class="{ 'is-empty': !projectAttachments.length }"
      >
        <div class="title">
          <h3
            id="project_documents_attachment_name"
            :class="{
              comment_hover:
                isShowDashboard.type === 'comment' &&
                isAlreadyCommented('project_documents_attachment_name'),
            }"
            data-comment-element="true"
          >
            Documents / Attachments
          </h3>
          <v-select
            v-if="projectAttachments.length"
            v-model="propertyId"
            label="Select property"
            :items="projectAttachments"
            item-title="address"
            item-value="propertyId"
            multiple
            variant="outlined"
            density="compact"
            class="select_prop_dropdown"
            @update:modelValue="changePropertyId"
            :menu-props="{ contentClass: 'custom__text__selector' }"
          >
          </v-select>
          <!-- <v-icon
            class="custom_edit"
            v-if="!projectAttachments.length"
            icon="mdi-pencil"
          ></v-icon> -->
        </div>

        <v-expansion-panels v-model="panel" multiple class="accordion__wrapper">
          <v-expansion-panel
            v-for="(property, index) in projectAttachments"
            :key="index"
          >
            <v-expansion-panel-title>
              <v-icon class="house__icon">
                <img src="../../../assets/icons/house.svg" alt="" />
              </v-icon>
              <h6
                :id="`project_property_attachments_doc_label_${property.propertyId}`"
                data-comment-element="true"
                :class="{
                  comment_hover:
                    isShowDashboard.type === 'comment' &&
                    isAlreadyCommented(
                      `project_property_attachments_doc_label_${property.propertyId}`
                    ),
                }"
              >
                {{ property?.address }}
              </h6>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  class="custom_edit"
                  v-if="expanded && !isEditAttachmentsSection"
                  icon="mdi-pencil"
                  @click="updateAttachmentsSection(index)"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-chevron-down"
                  class="bottom__chevron"
                ></v-icon>
              </template>
            </v-expansion-panel-title>

            <div class="group__cards" v-if="isShowGroupCard(index)">
              <div class="group__card__text">
                <v-expansion-panel-text>
                  <div
                    class="accordion_inner_title"
                    v-for="category in getFilterCategory(property.categories)"
                    :key="category.id"
                  >
                    <h3
                      :id="`attachments_item_categories_name_${property.propertyId}_${category.id}`"
                      :class="{
                        comment_hover:
                          isShowDashboard.type === 'comment' &&
                          isAlreadyCommented(
                            `attachments_item_categories_name_${property.propertyId}_${category.id}`
                          ),
                      }"
                      data-comment-element="true"
                    >
                      {{ category?.name }}
                    </h3>
                    <div
                      class="doc_main"
                      :id="`attachments_item_categories_content_${property.propertyId}_${category.id}`"
                      :class="{
                        comment_hover:
                          isShowDashboard.type === 'comment' &&
                          isAlreadyCommented(
                            `attachments_item_categories_content_${property.propertyId}_${category.id}`
                          ),
                      }"
                      data-comment-element="true"
                    >
                      <div
                        class="doc_main__list"
                        v-for="attachment in category.attachments"
                        :key="attachment.id"
                      >
                        <div
                          class="property__img__name"
                          @click.stop="
                            onClickOpenNewTab(attachment.attachmentUrl)
                          "
                        >
                          <v-icon icon="mdi mdi-image-outline"></v-icon>
                          <span>{{ attachment?.originalName }}</span>
                        </div>
                        <div
                          v-if="isEditAttachmentsSection"
                          @click="
                            onClickRemoveAttachment(
                              attachment,
                              property,
                              category?.name,
                              index,
                              category?.id
                            )
                          "
                        >
                          <v-icon
                            icon="mdi-close"
                            class="pointer_events_none"
                          ></v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!isEditAttachmentsSection"
                    class="property__images"
                  >
                    <div
                      v-for="(attachment, idx) in getFilterImages(
                        property.categories
                      )"
                      :key="attachment.id"
                      class="property__item__images"
                    >
                      <img
                        :src="attachment?.attachmentUrl"
                        @click="openCarousel(idx, property.categories)"
                      />
                    </div>
                  </div>
                  <div v-if="isEditAttachmentsSection" class="edit-mode-button">
                    <v-btn
                      class="button button-purple-border"
                      @click="onClickToggleAttachmentModal"
                    >
                      Upload More
                    </v-btn>
                    <div class="edit__buttons">
                      <v-btn
                        class="button button-white"
                        variant="outlined"
                        :loading="isLoadingOnAttachmentCancel"
                        @click="onCancelAttachments"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="button button-orange"
                        size="large"
                        block
                        :loading="isLoadingRemoveAttachments"
                        @click="onClickSaveButtonAttachments"
                      >
                        save
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </div>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
        <div>
          <v-btn
            class="button button-purple-border tw-mt-5"
            @click="onClickToggleAttachmentModal"
            v-if="!projectAttachments.length"
          >
            Upload Attachments
          </v-btn>
        </div>
      </div>
      <!-- LOADER -->
      <div
        v-else
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[500px]"
      >
        <Loader :show="loadingSelectProperty" />
      </div>
      <div
        class="tooltip_wrapper"
        ref="clickOutside"
        v-if="
          ['jobDetails', 'projectDetails'].includes(type) &&
          isShowDashboard.type == 'comment'
        "
      >
        <ToolTipComment
          v-for="(val, index) in projectComments"
          :key="val.id"
          :index="index"
          :commentElement="val"
          :projectId="ProjectData.id"
          :activeComment="activeComment"
          :commentsLength="projectComments.length"
          :userImagesMap="userImagesMap"
          @toggleActiveComment="toggleActiveComment"
          @updateCommentRoomStatus="updateCommentRoomStatus"
          @nextComment="nextComment"
        />
      </div>
    </div>

    <!-- PropertyListModal -->
    <commonDialog
      v-model="isShowPropertyListModal"
      :isShowHeader="false"
      :isShowBackButton="false"
      width="1010px"
      height="auto"
      max-width="100%"
      persistent
      :className="['edit__propertyList__modal']"
    >
      <template v-slot:body>
        <v-icon
          class="close__icon"
          icon="mdi-close"
          @click="onClickTogglePropertyListModal"
        ></v-icon>

        <div class="custom__modal__heading">
          <h3>
            Properties
            <span class="count__property">{{
              ProjectData?.projectProperties?.length
            }}</span>
          </h3>
          <p>
            This job is required across multiple properties, please select any
            you would like to combine into this estimate.
          </p>
        </div>
        <div class="modal___content">
          <div class="property__cards">
            <div class="courses__card__list">
              <v-card
                class="courses__card"
                :class="{ 'selected-card': isSelected(property?.id) }"
                @click="toggleSelection(property?.id)"
                v-for="(property, index) in propertiesList"
                :key="index"
              >
                <!-- <div class="courses__card_top_title">
                  <v-icon>
                    <img src="../../../assets/icons/house.svg" alt="" />
                  </v-icon>
                  <div class="course__property_name">
                    <h4>
                      {{ property.nickname || "NA" }}
                    </h4>
                  </div>
                </div> -->
                <div class="courses__card__img">
                  <img
                    class=""
                    cover
                    :src="
                      require('@/assets/images/solar-pv-installation-hero.jpg')
                    "
                  />
                </div>
                <div class="courses__card__prefix">
                  <div class="left__prefix">
                    <v-icon>
                      <img src="../../../assets/icons/user.svg" alt="" />
                    </v-icon>
                    <p>{{ getPropertyType(property) }}</p>
                  </div>
                  <div class="right__prefix">
                    <v-icon icon="mdi-map-marker"></v-icon>
                    <p>{{ property?.postcode }}</p>
                  </div>
                </div>
                <div class="courses__card__text">
                  <h5 class="courses__card__title">
                    {{ property?.address }}
                  </h5>
                </div>
              </v-card>
            </div>
            <div class="load__wrapper dssda">
              <label
                v-if="isPaginationAvailable"
                @click="onLoadMore"
                class="yellow-text"
              >
                Load More Properties<v-icon icon="mdi-chevron-right"
              /></label>
            </div>
          </div>

          <div class="footer__button">
            <v-btn
              class="button button-purple-border"
              @click="onClickTogglePropertyListModal"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickPropertyModalSave"
            >
              save
            </v-btn>
          </div>
        </div>
      </template>
    </commonDialog>

    <!-- AttachmentModal -->
    <commonDialog
      v-model="isShowAttachmentModal"
      :isShowHeader="false"
      :isShowBackButton="false"
      width="832px"
      max-width="100%"
      persistent
      :className="['property__upload__modal ']"
    >
      <template v-slot:body>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onClickToggleAttachmentModal"
        ></v-icon>
        <div class="modal__content">
          <div class="left_upload_img">
            <h4>Upload images and files.</h4>

            <div class="upload__area">
              <div class="uploade-wrapper">
                <v-file-input
                  class="upload_file"
                  label="Upload"
                  append-inner-icon="mdi-image-plus"
                  accept="image/png, image/jpeg, image/jpg"
                  variant="solo-filled"
                  v-model="selectedFile"
                  :class="{ 'added-img': imageUrl }"
                  @change="onFileChange"
                  @click:clear="clearImageUrl"
                  multiple
                >
                  <!-- <template v-slot:append-inner>
                    <v-icon>mdi-image-plus</v-icon>
                  </template> -->
                  <!-- append-icon="mdi-upload" -->
                </v-file-input>
                <v-img
                  class="uploaded-img"
                  :width="200"
                  aspect-ratio="16/9"
                  cover
                  :src="imageUrl"
                ></v-img>
                <v-icon icon="mdi mdi-file-image-box"></v-icon>
                <p class="error_msg" v-if="errorMessage">
                  {{ errorMessage }}
                </p>
              </div>
              <div class="upload__text">
                <p>
                  <strong>Drag and drop documents, or</strong>
                  <span>Browse</span>
                </p>
                <p class="">
                  Attachments such as images, design ideas and documents help
                  better understand the project and provide increasingly
                  accurate estimates.
                </p>
              </div>
            </div>
            <div class="selected-info">
              <v-btn
                aria-label="Refresh"
                class="button button-orange"
                @click="addPropertyImage"
                :disabled="!uploadedImages"
                >{{ attachmentDataList.length ? "ADD ANOTHER" : "add" }}</v-btn
              >
            </div>
          </div>
          <div class="image__upload__wrapper">
            <div class="tw-w-full" v-if="attachmentDataList.length">
              <h5>Uploaded Files</h5>
              <div class="uploaded_details_wrapper">
                <div
                  class="show_uploaded_details"
                  v-for="(attachment, index) in attachmentDataList"
                  :key="index"
                >
                  <v-img
                    class="uploaded-img"
                    :width="156"
                    :height="88"
                    aspect-ratio="16/9"
                    cover
                    :src="attachment?.uploadedImages?.url"
                  ></v-img>
                  <div class="prop_img_details">
                    <h5>{{ attachment?.uploadedImages?.name }}</h5>
                    <v-btn
                      @click="
                        removeImageFromAttachments(
                          index,
                          attachment?.uploadedImages?.attachmentId
                        )
                      "
                      variant="text"
                      class="button button-lightGray"
                    >
                      <img src="../../../assets/icons/delete.svg" alt="" />
                    </v-btn>
                  </div>

                  <div class="upload__list">
                    <v-select
                      v-model="attachment.propertyId"
                      label="Select property"
                      :items="userSelectedProperties"
                      item-title="address"
                      item-value="id"
                      multiple
                      variant="outlined"
                      density="compact"
                      class="selectprop_dropdown"
                      :menu-props="{ contentClass: 'text__selector' }"
                    >
                    </v-select>
                    <v-text-field
                      density="compact"
                      placeholder="E.g. Public liability"
                      variant="outlined"
                      v-model="attachment.userCategoryName"
                      class="v-text-custom selectprop_dropdown"
                      :menu-props="{ contentClass: 'text__selector' }"
                    >
                      <v-icon
                        icon="mdi-chevron-down"
                        :id="`menu-activator-${index}`"
                        class="cursor-pointer"
                      ></v-icon>
                    </v-text-field>
                  </div>
                  <v-menu
                    transition="slide-y-reverse-transition"
                    class="slider-menu-active pro_review_drop"
                    :activator="`#menu-activator-${index}`"
                    :active="true"
                    :menu-props="{ contentClass: 'pro_review_drop' }"
                  >
                    <v-list
                      class="dropdown_selector"
                      :menu-props="{ contentClass: 'text__selector' }"
                    >
                      <v-list-item
                        v-for="(item, index) in userCategory"
                        :key="index"
                      >
                        <v-list-item-title
                          class="cursor-pointer"
                          @click="attachment.userCategoryName = item.name"
                          >{{ item.name }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </div>
            <div v-else class="blank_project">
              <p>Your added project attachments will show up here</p>
            </div>
          </div>
        </div>

        <div class="footer-buttons">
          <v-btn
            class="button button-purple-border"
            @click="onClickToggleAttachmentModal"
            variant="outlined"
          >
            cancel
          </v-btn>
          <v-btn
            class="button button-orange"
            :loading="isLoadingUploadAttachments"
            @click="onClickSaveButtonAttachmentsModal"
          >
            save
          </v-btn>
        </div>
      </template>
    </commonDialog>

    <AddCommentComponent
      v-if="activeAddComment && activeAddComment.length"
      :commentData="activeAddComment[0]"
      @onComment="onComment"
    />
    <InviteTeamMember
      @onCloseModal="onCloseModal"
      :projectId="ProjectData?.id"
      v-if="iShowInviteTeamMemberModal"
    />

    <SpecialismMultiSelectModel
      v-if="isShowSpecialismModal"
      :isHistorical="false"
      :projectId="ProjectData.id"
      :selectedSpecialism="ProjectData?.subCategories"
      :isLoading="isLoadingForSaveSpecialism"
      @onClickCloseTab="onClickCloseTab"
      @onClickSaveButtonClick="onClickSaveButtonClick"
    />
  </div>
  <!-- LOADER -->
  <div
    v-else
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[500px]"
  >
    <Loader :show="loading" />
  </div>
  <!-- CarouselDialog -->
  <v-dialog
    v-model="isCarouselDialog"
    height="auto"
    max-width="600"
    class="project__carousal__modal"
    :show-arrows="false"
  >
    <v-card>
      <template v-slot:actions>
        <v-btn
          icon="mdi-close"
          @click="isCarouselDialog = false"
          class="d-flex"
        ></v-btn>
        <v-carousel
          hide-delimiter-background
          :show-arrows="carouselImageList?.length > 1"
          v-model="currentIndex"
        >
          <v-carousel-item
            v-for="attachment in carouselImageList"
            :key="attachment.id"
          >
            <v-img
              cover
              :src="attachment?.attachmentUrl"
              height="100%"
              width="100%"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </template>
    </v-card>
  </v-dialog>
  <ChatCommentsComponent
    :isShowDashboard="isShowDashboard"
    :activeComment="activeComment"
    :projectComments="projectComments"
    :projectId="ProjectData?.id"
    :userImagesMap="userImagesMap"
    @openMessageDashboard="openMessageDashboard"
    @toggleActiveComment="toggleActiveComment"
    @updateCommentRoomStatus="updateCommentRoomStatus"
    v-if="isShowCommentSection"
  />

  <v-dialog
    width="420px"
    v-model="isPostLoadingModal"
    class="modal_box action-modal map-bg mod-map-modal"
    persistent
  >
    <div class="modal_wrapper">
      <main class="main">
        <div>
          <p class="switching-text" v-html="switchingText"></p>
          <div class="loading-dots">
            <img
              class=""
              cover
              :src="require('@/assets/images/' + switchingImage)"
            />
          </div>
          <v-stepper class="onboard-stepper" v-model="currentSelectedTab.name">
            <v-stepper-header class="onboard-stepper__header">
              <v-stepper-item
                class="onboard-stepper__item"
                v-for="(step, index) in tabListItems"
                :key="index"
                :value="step.name"
              ></v-stepper-item>
            </v-stepper-header>
          </v-stepper>
          <v-btn
            class="button button-purple"
            @click="onClickContinuePostProject"
            block
            v-if="currentSelectedTab.name === tabListItems[2].name"
          >
            continue
          </v-btn>
        </div>
      </main>
    </div>
  </v-dialog>
  <ProjectRateModal
    v-if="isShowReviewModal && projectDetails?.id"
    :type="ProjectRatingTypeEnum.PROPERTY_OWNER"
    :ratingData="projectRatingData"
    @on-close="toggleRatingModal"
    :projectId="projectDetails?.id"
    reviewType="project"
  />
  <SiteVisitModel
    @onClickBack="onClickSiteVisit"
    @onClickPostProject="onClickPostLiveProject"
    v-if="isShowSiteVisitModal"
    :projectId="ProjectData?.id"
  />
</template>
<script lang="ts">
import {
  ref,
  onMounted,
  computed,
  inject,
  onBeforeUnmount,
  reactive,
} from "vue";
import { useStore } from "vuex";
import { PROJECT, PROJECT_STORE } from "@/store/modules/project";
import Loader from "@/core/components/common/Loader.vue";
import {
  ProjectJobStatusEnum,
  ProjectStatusType,
  ProjectTypesEnum,
  ProjectRatingTypeEnum,
  ProjectFrom,
} from "@/core/enums/ProjectsEnum";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import ChatCommentsComponent from "@/core/components/ChatCommentsComponent.vue";
import ToolTipComment from "@/core/components/ToolTipComment.vue";
import AddCommentComponent from "@/core/components/AddCommentComponent.vue";
import { getCurrentInstance } from "vue";
import commentsService from "@/core/services/comments.service";
import { USER_STORE } from "@/store/modules/user";
import { quillEditor } from "vue3-quill";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { getProperties } from "@/core/services/properties.service";
import { CHATMESSAGE } from "@/store/modules/chat-message";
import Text from "@/core/components/ui/general/Text.vue";
import AlertCard from "@/core/components/common/AlertCard.vue";
import Button from "@/core/components/ui/general/Button.vue";
import ProjectService from "@/core/services/project.service";
import { useRouter } from "vue-router";
import InviteTeamMember from "./InviteTeamMember.vue";
import { PROJECT_ROUTE } from "../routes";
import { useDisplay } from "vuetify";
import ProjectRateModal from "@/core/components/modals/ProjectRateModal.vue";
import { ProjectPhaseType } from "@/core/enums/ProjectsEnum";
import { EscrowStatusEnum } from "@/core/enums/estimateEnum";
import {
  AgreeTermsConsumerDisclosure,
  DurationsType,
  EstimatePhaseStatus,
  PropertySideStatus,
} from "@/core/enums/estimateEnum";
import tradeEstimate from "@/store/modules/trade-estimate";
import tradeEstimateService from "@/core/services/trade-estimate.service";
// import SpecialismModel from "@/modules/project/components/SpecialismModel.vue";
import SpecialismMultiSelectModel from "@/modules/project/modal/SpecialismMultiSelectModel.vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import VideoConsultationChip from "@/core/uni-components/Chips/VideoConsultationChip.vue";
import $axios from "@/core/utils/axios-api-config";
import { getImageApiUrl, parseJSON } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import DailyIframe from "@daily-co/daily-js";
import projectRatingService from "@/core/services/project-rating.service";
import { LatLngExpression } from "leaflet";
import SiteVisitModel from "./SiteVisitModel.vue";
import PropertyMapModal from "@/modules/properties/components/Modals/PropertyMapModal.vue";
export default {
  name: "ProjectReviewComponent",
  props: {
    projectRefId: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  components: {
    Loader,
    // AddressMapComponent,
    PropertyMapModal,
    ChatCommentsComponent,
    ToolTipComment,
    AddCommentComponent,
    quillEditor,
    CommonDialog,
    Text,
    AlertCard,
    Button,
    InviteTeamMember,
    // SpecialismModel,
    SpecialismMultiSelectModel,
    EmergencyChip,
    StandardChip,
    RoutineChip,
    VideoConsultationChip,
    // Overview,
    ProjectRateModal,
    SiteVisitModel,
  },

  setup(props: any, ctx: any) {
    const store = useStore();
    const isCarouselDialog = ref(false);
    const currentIndex = ref(0);
    const ProjectData = ref();
    const projectDataProperties = ref();
    const projectAttachments = ref([]) as any;
    const loading = ref(false);
    const loadingSelectProperty = ref(false);
    const addresses: any = ref([]);
    const isEditProjectNoteSection = ref(false);
    const isEditPropertiesSection = ref(false);
    const isEditSpecialismSection = ref(false);
    const isEditAttachmentsSection = ref(false);
    const isLoadingRemoveAttachments = ref(false);
    const isShowAttachmentModal = ref(false);
    const projectTitle = ref("");
    const isShowPropertyListModal = ref(false);
    const isFetchingPropertiesLoading = ref(false);
    const propertiesList = ref([]) as any;
    const panel = ref([]) as any;
    const imageUrl = ref("");
    const errorMessage = ref("");
    const uploadedImages = ref(null) as any;
    const attachmentDataList = ref([]) as any;
    const userSelectedProperties = ref([]) as any;
    const isLoadingUpdatePropriety = ref(false);
    const showProjectOverviewDialog = ref(false);
    const isLoadingProjectNotes = ref(false);
    const isLoadingProjectData = ref(false);
    const attachmentsUpdateList = ref([]) as any;
    const carouselImageList = ref();
    const availableCategoryList = ref();
    const cloneAttachmentDataList = ref([]) as any;
    const isLoadingUploadAttachments = ref(false);
    const isNewPropertyAdded = ref(false);
    const removeAttachmentsIds = ref([]) as any;
    const clickOutside = ref(null) as any;

    const isShowSpecialismModal = ref(false);

    const isLoadingForSaveSpecialism = ref(false);

    const iShowInviteTeamMemberModal = ref(false);

    const router = useRouter();

    const isShowLoaderOnSaveButton = ref(false);
    const isShowLoaderOnSaveButtonForDraft = ref(false);
    const vuetify = useDisplay();
    const projectStatus = ref(null) as any;
    const isShowSiteVisitModal = ref(false);
    const userCategory = [
      {
        name: "Floorplan",
      },
      {
        name: "Garden",
      },
      {
        name: "Materials",
      },
      {
        name: "Maps",
      },
    ];
    const SERVICE_CHARGE = 0.03;
    const PHASE_FIRST_CHARGE = 5;
    const MATERIALS = "Materials";
    const paginationData = ref({
      currentPage: 0,
      totalPages: 0,
      totalPropertiesCount: 0,
    });
    const isShowDashboard = ref({
      isActive: true,
      type: "chat",
    });
    const propertyId = ref();
    const isPostLoadingModal = ref(false);
    const switchingImage = ref("postlive-project-stage1.png");
    const internalInstance = getCurrentInstance();
    const tabListItems = [
      {
        tab: "step1",
        name: "Applying your project dispute protection...",
      },
      {
        tab: "step2",
        name: "Posting Project to 1,735 trades companies....",
      },
      {
        tab: "step3",
        name: "Your Project has successfully been posted!",
      },
    ];
    const inviteMemberList = ref([
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Sam Johnson",
        subtitle: `samjohnson@iknowa.com`,
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Sam Johnson",
        subtitle: "samjohnson@iknowa.com",
      },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Sam Johnson",
        subtitle: "samjohnson@iknowa.com",
      },
    ]);
    const switchingText = ref(
      `Applying your project <br> <strong>dispute protection...</strong>`
    );
    const currentSelectedTab = ref(tabListItems[0]);
    const activeAddComment: any = ref([]);

    const isMobileView = computed(() => vuetify.mdAndDown.value);

    const deletedProject = ref([]) as any;

    const isLoadingOnCancel = ref(false);

    const isLoadingOnAttachmentCancel = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const canvas: any = ref(null);
    const projectComments: any = ref([]);

    const selectedFile = ref([]);
    const activeComment: any = ref({
      elementId: null,
    });
    const pusher = inject("pusher") as any;
    const subscribeCommentRoomsChannelList = ref([]) as any;

    /* methods */
    const redirectToEscrowAccount = async () => {
      await store.dispatch(
        `${PROJECT_STORE}/setProjectRedirectTabName`,
        "escrow-account"
      );
    };

    const onComment = async (comment: any) => {
      try {
        const payload = {
          projectId: ProjectData.value?.id,
          elementId: activeAddComment.value[0].elementId,
          workStationId: ProjectData.value?.userWorkstation?.id,
        };
        const response: any = await commentsService.createUserCommentsRoom(
          payload
        );
        const userCommentRoomId = response?.id;
        const commentPayload = {
          comment,
          userId: user.value.id,
          commentRoomId: userCommentRoomId,
        };
        await commentsService.createUserComments(commentPayload);
      } catch (error) {
        console.log(error);
      } finally {
        getAllCommentsRoomByProjectId();
      }
      activeAddComment.value = [];
    };

    const addComments = (e: any) => {
      if (!isAlreadyCommented(e?.target?.id)) return;
      if (isShowDashboard.value.type !== "comment") return;
      if (activeAddComment.value && activeAddComment.value.length > 0) {
        activeAddComment.value = [];
        return;
      }

      if (e?.target?.id && e?.target.hasAttribute("data-comment-element")) {
        activeComment.value.elementId = null;
        const canvasId = document.getElementById("canvas_id");
        const rect = canvasId?.getBoundingClientRect();
        const mainElement: any = document.getElementById("main_layout_content");
        if (rect) {
          activeAddComment.value = [
            {
              elementId: e?.target?.id,
              styles: {
                x: isMobileView.value ? e.clientX - 70 : e.clientX - 345,
                y: e.clientY + mainElement.scrollTop - 175,
              },
            },
          ];
        }
      }
    };
    const setProjectData = async () => {
      try {
        // isLoadingProjectData.value = true;
        const projectDataResponse = await store.dispatch(
          `${PROJECT_STORE}/getProjectData`,
          {
            projectRefId: props.projectRefId,
          }
        );
        availableCategoryList.value = await store.dispatch(
          `${PROJECT_STORE}/getProjectCategoryList`,
          projectDataResponse.id
        );
        ProjectData.value = projectDataResponse;
        store.dispatch(`${PROJECT}/projectDetails`, {
          inviteMembers: ProjectData.value?.invitedMembersAndRoles,
        });

        await getPropertyStatus(ProjectData.value.id);

        projectAttachments.value = projectDataResponse?.projectAttachments;

        selectedIndices.value = projectDataResponse?.projectProperties.map(
          (property: any) => property?.property?.id
        );
        ProjectData.value?.projectProperties.map((item: any) => {
          const existingPropertyIndex = userSelectedProperties.value.findIndex(
            (property: any) => property.id === item.property.id
          );
          if (existingPropertyIndex === -1)
            userSelectedProperties.value.push(item.property);
        });
        attachmentDataList.value =
          projectDataResponse?.projectAttachmentProperties.map(
            (propertyItems: any) => ({
              uploadedImages: {
                url: propertyItems[0]?.attachmentUrl,
                name: propertyItems[0]?.projectAttechment?.originalName,
                attachment: propertyItems[0]?.projectAttechment?.attachment,
                attachmentId: propertyItems[0]?.projectAttechment?.id,
                id: propertyItems[0]?.id,
              },
              propertyId: propertyItems.map(
                (property: any) => property.property.id
              ),
              userCategoryName:
                propertyItems[0]?.projectAttachmentsCategory?.value,
            })
          );
        cloneAttachmentDataList.value = JSON.parse(
          JSON.stringify(attachmentDataList.value)
        );
      } catch (error) {
        console.log();
      } finally {
        // isLoadingProjectData.value = false;
      }
    };
    const getProjectData = async () => {
      try {
        loading.value = true;
        await setProjectData();
        // const propertiesResponse = await store.dispatch(
        //   `${PROJECT_STORE}/getProjectDataProperties`,
        //   {
        //     projectRefId: props.projectRefId,
        //   }
        // );
        projectDataProperties.value = ProjectData.value.projectProperties;

        let addressList: any = [];
        // projectDataProperties.value &&
        //   projectDataProperties.value.map((item: any) => {
        //     if (item?.property?.addressData) {
        //       const address = parseJSON(item.property.addressData);
        //       addressList.push({
        //         address: item.property.address,
        //         uprn: item.property.uprn,
        //         latlang: getLatLng(address),
        //         id: item.property.id,
        //         postcode: item.property.postcode,
        //       });
        //     }
        //   });
        addressList = projectDataProperties.value.map((item: any) => {
          return item?.property;
        });
        addresses.value = addressList;
        console.log("addressssss", addresses.value);
      } catch (error) {
        console.log("error:", error);
      } finally {
        loading.value = false;
      }
    };
    const getLatLng = (addressData: string): LatLngExpression | undefined => {
      if (!addressData) return undefined;

      try {
        const unParsedData = parseJSON(addressData);
        const latlng: LatLngExpression = [
          unParsedData.LAT ? unParsedData.LAT : unParsedData.lat,
          unParsedData.LNG ? unParsedData.LNG : unParsedData.lng,
        ];
        return latlng;
      } catch (error) {
        console.error("Invalid address data:", error);
        return undefined;
      }
    };
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      const formattedHours = hours < 10 ? "0" + hours : hours;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

      return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
    };

    const openCarousel = (idx: any = 0, categoryData: any) => {
      isCarouselDialog.value = true;
      carouselImageList.value = getFilterImages(categoryData);
      currentIndex.value = idx;
    };
    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickSaveDraftProject = async () => {
      try {
        isShowLoaderOnSaveButtonForDraft.value = true;

        await ProjectService.updateProject(
          {
            projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
            inviteMembers: projectDetails.value?.inviteMembers,
          },
          ProjectData.value.id
        );
        displayToast("Your Project Added In Draft Projects.", "success");
        router.push({ name: PROJECT_STORE });
      } catch (error) {
        console.log();
      } finally {
        isShowLoaderOnSaveButtonForDraft.value = false;
      }
    };

    const onClickEditProject = async () => {
      ctx.emit("onClickeditProject", {
        projectRefId: ProjectData.value?.refId,
        data: true,
      });
    };

    const delay = (ms: any) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const onClickPostLiveProject = async () => {
      try {
        onClickSiteVisit();
        isShowLoaderOnSaveButton.value = true;
        isPostLoadingModal.value = true;

        // First step: Wait for 1 second
        await delay(1000);
        switchingText.value =
          "Applying your project <br> <strong>dispute protection...</strong>";
        switchingImage.value = "postlive-project-stage1.png";
        currentSelectedTab.value = tabListItems[0];

        // Second step: Wait for 2 seconds
        await delay(2000);
        switchingText.value =
          "Posting your project to <strong>346 </strong>trade businesses...";
        switchingImage.value = "postlive-project-stage2.png";
        currentSelectedTab.value = tabListItems[1];

        // Third step: Wait for 5 seconds
        await delay(5000);
        switchingText.value =
          "Amazing job...🚀 Your project is now</strong> LIVE </strong>on iknowa!";
        switchingImage.value = "postlive-project-stage3.png";
        currentSelectedTab.value = tabListItems[2];
        // const createProject = await store.dispatch(
        //   `${PROJECT_STORE}/updateProject`,
        //   {
        //     projectId: props.projectRefId,
        //     data: {
        //       projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
        //     },
        //   }
        // );
        await ProjectService.updateProject(
          {
            projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
            inviteMembers: projectDetails.value?.inviteMembers,
          },
          ProjectData.value.id
        );

        await store.dispatch(
          `${PROJECT_STORE}/setProjectRedirectTabName`,
          "active-project"
        );
      } catch (error) {
        console.log("error : ", error);
      } finally {
        isShowLoaderOnSaveButton.value = false;
      }
    };

    const onClickContinuePostProject = () => {
      displayToast("Your Project Added In Active Projects.", "success");
      isPostLoadingModal.value = false;
      router.push({ name: PROJECT_STORE });
    };
    const changePropertyId = async (value: any) => {
      if (value.length > 0) {
        try {
          loadingSelectProperty.value = true;
          let selectedProperties = await store.dispatch(
            `${PROJECT_STORE}/getProjectDataSelectedProperties`,
            {
              projectRefId: props.projectRefId,
              propertyIds: value,
            }
          );
          projectAttachments.value = selectedProperties;
        } catch (error) {
          console.log("error", error);
        } finally {
          loadingSelectProperty.value = false;
        }
      } else {
        projectAttachments.value = ProjectData.value?.projectAttachments;
      }
    };
    const openMessageDashboard = (type: any) => {
      store.dispatch(`${CHATMESSAGE}/setCurrentMessageType`, type);
      if (type == "chat") {
        isShowDashboard.value.type = "chat";
      } else {
        isShowDashboard.value.type = "comment";
      }
      isShowDashboard.value.isActive = true;
    };

    const getStylesByElementById = (elementId: string) => {
      const element = document.getElementById(elementId);
      const elementRect = element?.getBoundingClientRect();
      const mainElement: any = document.getElementById("main_layout_content");
      return elementRect
        ? {
            x: elementRect?.x + 20 - (window.innerWidth > 1279 ? 300 + 50 : 20),
            y:
              elementRect?.y +
              mainElement.scrollTop -
              (window.innerWidth > 541 || window.innerWidth < 350 ? 175 : 150),
          }
        : null;
    };
    const setCommentPosition = () => {
      projectComments.value.forEach((comment: any) => {
        comment.style = getStylesByElementById(comment.elementId);
      });
    };
    const getAllCommentsRoomByProjectId = async () => {
      try {
        const response: any =
          await commentsService.getPropertyOwnerCommentsRoomByProjectId(
            ProjectData.value?.id
          );
        getUserWorkStationProfileImage(activeUserWorkstation);
        if (response) {
          const commentStyles: any = [];
          response?.map((item: any) => {
            getUserWorkStationProfileImage(item.userWorkstation);
            commentStyles.push({
              ...item,
              style: getStylesByElementById(item.elementId),
            });
          });
          projectComments.value = commentStyles;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const filterAttachments = computed(
      () =>
        projectAttachments.value?.map((attachment: any) => ({
          ...attachment,
          categories: Object.values(attachment?.categories)?.map(
            (item: any) => item
          ),
        })) || []
    );

    const imageArray = computed(() => {
      let uniqueUrls = new Set();
      return (
        filterAttachments.value?.flatMap((attachment: any) => {
          return attachment.categories
            .filter((category: any) => {
              if (!uniqueUrls.has(category.attachmentUrl)) {
                uniqueUrls.add(category.attachmentUrl);
                return true;
              }
              return false;
            })
            ?.map((category: any) => ({ src: category.attachmentUrl }));
        }) || []
      );
    });

    const toggleActiveComment = (elementId: any) => {
      activeComment.value.elementId = elementId;
      scrollIntoViewOfScreen(elementId);
    };

    const updateCommentRoomStatus = async (
      commentRoomId: number,
      commentStatus: string
    ) => {
      try {
        await commentsService.updateUserCommentRoomStatus(commentRoomId, {
          status: commentStatus,
        });
        activeComment.value.elementId = null;
        getAllCommentsRoomByProjectId();
      } catch (error) {
        console.log("error", error);
      }
    };

    const nextComment = (idx: number, dir: string) => {
      const len = projectComments.value.length;
      if (idx < len && dir === "right") {
        activeComment.value.elementId = projectComments.value[idx]["elementId"];
      }
      if (idx >= 0 && dir === "left") {
        activeComment.value.elementId = projectComments.value[idx]["elementId"];
      }
      scrollIntoViewOfScreen(activeComment.value.elementId);
    };
    const scrollIntoViewOfScreen = (elementId: any) => {
      if (!elementId) return;
      const element = document.getElementById(elementId);
      const elementRect: any = element?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const distanceToBottom = viewportHeight - elementRect?.bottom;
      const distanceToTop = elementRect?.top || 0;

      if (distanceToBottom < 0 && element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      if (distanceToTop < 0 && element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const subscribeProjectCommentRoom = () => {
      const roomChannel = pusher?.subscribe(
        `project-comments-room-${ProjectData.value?.id}`
      );
      roomChannel?.bind("new.commentRoom", async (commentRoomData: any) => {
        if (commentRoomData) {
          await getAllCommentsRoomByProjectId();
        }
      });
      subscribeCommentRoomsChannelList.value.push(roomChannel);
    };

    const closeProjectCommentRoom = () => {
      const roomChannel = pusher?.subscribe(
        `project-comments-close-room-${ProjectData.value?.id}`
      );
      roomChannel?.bind("close.commentRoom", (commentRoomId: any) => {
        if (commentRoomId) {
          projectComments.value = projectComments.value.filter(
            (data: any) => data.id !== commentRoomId
          );
        }
      });
      subscribeCommentRoomsChannelList.value.push(roomChannel);
    };
    const unbindSubscribedCommentsRoomsChannels = () => {
      if (subscribeCommentRoomsChannelList.value.length) {
        subscribeCommentRoomsChannelList.value.forEach((channel: any) => {
          // Unbind all events from channel
          channel?.unbind();
          pusher?.unsubscribe(channel.name);
        });
        subscribeCommentRoomsChannelList.value.length = [];
      }
    };
    const selectedIndices = ref([]) as any;

    const isSelected = (index: number) => {
      return selectedIndices.value.includes(index);
    };

    const toggleSelection = (index: number) => {
      const selectedIndex = selectedIndices.value.indexOf(index);
      if (selectedIndex === -1) {
        selectedIndices.value.push(index);
      } else {
        selectedIndices.value.splice(selectedIndex, 1);
      }
    };

    const getPropertyType = (property: any) => {
      return property?.propertyType
        ? property?.propertyType?.trim()
        : typeof property?.epcData === "string"
        ? JSON.parse(property?.epcData)
          ? JSON.parse(property?.epcData)["property-type"]
          : "NA"
        : property?.epcData
        ? property?.epcData["property-type"]
        : "NA";
    };

    const getPropertiesListing = async (page: number, limit: number) => {
      try {
        const response = await getProperties({
          page: page,
          limit: limit,
          search: "",
        });

        if (response) {
          paginationData.value.currentPage = response.page;
          paginationData.value.totalPages = response.totalPages;
          paginationData.value.totalPropertiesCount = response.totalCount;
          isFetchingPropertiesLoading.value = false;
        }

        if (response?.data?.length) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error);
        isFetchingPropertiesLoading.value = false;
      }
    };

    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Description here",
        modules: {},
      },
      disabled: false,
    });

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (file.type.includes("image")) {
            // Check image size limit (200MB)
            if (file.size <= 200 * 1024 * 1024) {
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
              };
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
              // Handle error message or display notification
            }
          } else if (file.type.includes("video")) {
            // Check video size limit (20MB)
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              // Process video file
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
              // Handle error message or display notification
            }
          } else {
            // Allow other file types without size validation
            imageUrl.value = URL.createObjectURL(file);
            uploadedImages.value = {
              url: imageUrl.value,
              name: file.name,
              imageData: file,
            };
          }
        });
      } else {
        console.error("Error: No file selected.");
      }
    };

    const addPropertyImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        propertyId: null,
        userCategoryName: null,
      });
      uploadedImages.value = null;
      isNewPropertyAdded.value = true;
      selectedFile.value = [];
      imageUrl.value = "";
    };

    const isAttachmentDataValid = computed(() => {
      if (!attachmentDataList.value || attachmentDataList.value.length === 0) {
        return false;
      }

      return attachmentDataList.value.every(
        (attachment: { propertyId: null; userCategoryName: null }) =>
          attachment.propertyId !== null && attachment.userCategoryName !== null
      );
    });

    const removeImageFromAttachments = (index: number, attachmentId: any) => {
      removeAttachmentsIds.value.push(attachmentId);
      attachmentDataList.value.splice(index, 1);
      cloneAttachmentDataList.value.forEach((attachment: any) => {
        if (attachment.uploadedImages.attachmentId === attachmentId) {
          attachment["isDeleted"] = true;
        }
      });
    };

    const onCancelProjectNotes = () => {
      isEditProjectNoteSection.value = false;
    };

    const onClickSaveProjectNotes = async () => {
      try {
        isLoadingProjectNotes.value = true;
        await store.dispatch(`${PROJECT_STORE}/updateProject`, {
          projectId: ProjectData.value?.id,
          data: {
            description: state.content,
            name: projectTitle.value,
          },
        });
        displayToast("Update successfully.", "success");
      } catch {
        console.log();
      } finally {
        await setProjectData();
        isEditProjectNoteSection.value = false;
        isLoadingProjectNotes.value = false;
      }
    };
    const onClickCancelSpecialismButton = async () => {
      isEditSpecialismSection.value = false;
      isLoadingProjectData.value = false;
      await setProjectData();
    };
    const onClickCancelButton = async () => {
      try {
        isLoadingOnCancel.value = true;
        await setProjectData();
        isEditPropertiesSection.value = false;
      } catch (e) {
        console.log("Caught exception", e);
      } finally {
        isLoadingOnCancel.value = false;
        deletedProject.value = [];
      }
    };

    const onClickSaveSpecialismButton = async () => {
      isLoadingProjectData.value = true;
      let selectedCategoryFinal = ProjectData.value?.subCategories.map(
        (subCategory: any) => {
          return subCategory.id;
        }
      );

      await store.dispatch(`${PROJECT_STORE}/updateSubSpecialismCategory`, {
        projectId: ProjectData.value?.id,
        data: {
          categoryData: selectedCategoryFinal,
        },
      });

      onClickCancelSpecialismButton();
    };

    const onClickSaveButton = async () => {
      isLoadingProjectData.value = true;
      await updateProperties(deletedProject.value);
      await setProjectData();
      isEditPropertiesSection.value = false;
      isLoadingProjectData.value = false;
      deletedProject.value = [];
    };

    const onClickTogglePropertyListModal = () => {
      isShowPropertyListModal.value = !isShowPropertyListModal.value;
    };

    const onClickRemoveSpecialism = (projectSpecialism: any) => {
      updateSpecialism([
        {
          categoryId: projectSpecialism?.category?.id,
          isDeleted: true,
        },
      ]);
    };

    const onClickRemoveProperty = async (ProjectDetails: any) => {
      deletedProject.value.push({
        id: ProjectDetails?.id,
        propertyId: ProjectDetails?.property?.id,
        isDeleted: true,
      });
      ProjectData.value.projectProperties =
        ProjectData.value.projectProperties.filter((property: any) => {
          return property?.id !== ProjectDetails?.id;
        });
    };
    const onClickPropertyModalSave = async () => {
      const projectDefaultPropertyIds =
        ProjectData.value?.projectProperties.map(
          (property: any) => property?.property?.id
        );
      const newSelectedPropertyData = selectedIndices.value
        .filter(
          (propertyId: any) => !projectDefaultPropertyIds.includes(propertyId)
        )
        .map((propertyId: any) => ({ propertyId }));
      const removePropertyData = ProjectData.value?.projectProperties
        .filter(
          (detail: any) => !selectedIndices.value.includes(detail.property.id)
        )
        .map((detail: any) => ({
          id: detail?.id,
          propertyId: detail?.property?.id,
          isDeleted: true,
        }));
      await updateProperties([
        ...removePropertyData,
        ...newSelectedPropertyData,
      ]);
      await setProjectData();
      onClickTogglePropertyListModal();
    };

    const updateSpecialism = async (specialism: any) => {
      try {
        isLoadingUpdatePropriety.value = true;
        if (specialism.length && ProjectData.value?.id)
          await store.dispatch(`${PROJECT_STORE}/updateSpecialism`, {
            projectId: ProjectData.value?.id,
            specialism,
          });
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoadingUpdatePropriety.value = false;
      }
    };

    const updateProperties = async (propertiesData: any) => {
      try {
        isLoadingUpdatePropriety.value = true;
        if (propertiesData.length && ProjectData.value?.id)
          await store.dispatch(`${PROJECT_STORE}/updateProject`, {
            projectId: ProjectData.value?.id,
            data: { propertiesData },
          });
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoadingUpdatePropriety.value = false;
      }
    };

    const updateSpecialismSection = () => {
      isEditSpecialismSection.value = true;
    };

    const updatePropertiesSection = () => {
      isEditPropertiesSection.value = true;
    };

    const updateAttachmentsSection = (index: number) => {
      panel.value.push(index);
      isEditAttachmentsSection.value = true;
    };

    const onClickSaveButtonAttachments = async () => {
      try {
        isLoadingRemoveAttachments.value = true;
        await store.dispatch(`${PROJECT_STORE}/updateProject`, {
          projectId: ProjectData.value?.id,
          data: {
            attachments: attachmentsUpdateList.value,
          },
        });
        attachmentsUpdateList.value = null;
        await setProjectData();
        isEditAttachmentsSection.value = false;
      } catch {
        console.log();
      } finally {
        isLoadingRemoveAttachments.value = false;
      }
    };

    const onCancelAttachments = async () => {
      try {
        isLoadingOnAttachmentCancel.value = true;
        await setProjectData();
        isEditAttachmentsSection.value = false;
      } catch (e) {
        console.log("Error :", e);
      } finally {
        isLoadingOnAttachmentCancel.value = false;
      }
    };

    const onClickToggleAttachmentModal = () => {
      isShowAttachmentModal.value = !isShowAttachmentModal.value;
      isNewPropertyAdded.value = false;
    };

    const onClickRemoveAttachment = (
      attachment: any,
      category: any,
      categoryName: any,
      attachmentIndex: any,
      categoryId: number
    ) => {
      projectAttachments.value[attachmentIndex].categories[categoryName] =
        projectAttachments.value[attachmentIndex].categories[
          categoryName
        ].filter((category: any) => {
          return category?.id !== attachment?.id;
        });

      attachmentsUpdateList.value.push({
        id: attachment?.id,
        propertyIds: [
          {
            id: category?.id,
            propertyId: category?.propertyId,
            isDeleted: true,
          },
        ],
        attechmentCategoryId: attachment?.categoryId,
        isDeleted: false,
      });
    };

    const updateProjectNameNote = (ProjectDetails: any) => {
      isEditProjectNoteSection.value = true;
      projectTitle.value = ProjectDetails?.name;
      state.content = ProjectDetails?.description;
    };

    const onClickSaveButtonAttachmentsModal = async () => {
      try {
        isLoadingUploadAttachments.value = true;

        //new attachment add functionality
        const newAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => attachment?.uploadedImages.imageData
        );
        const filterAttachmentDataList = attachmentDataList.value.filter(
          (attachment: any) => !attachment?.uploadedImages.imageData
        );
        // check if new category add then upload that

        for (const attachment of attachmentDataList.value) {
          const existCategory = availableCategoryList.value.find(
            (category: any) => category.label === attachment.userCategoryName
          );
          if (!existCategory) {
            const response = await store.dispatch(
              `${PROJECT_STORE}/attachmentCategory`,
              {
                label: attachment.userCategoryName,
                value: attachment.userCategoryName,
              }
            );
            availableCategoryList.value.push(response);
          }
        }

        let newCreatedAttachment = [];
        if (newAttachmentDataList.length) {
          const formData = new FormData();
          newAttachmentDataList.forEach((attachment: any) => {
            if (attachment.uploadedImages.imageData) {
              formData.append(`files`, attachment.uploadedImages.imageData);
            }
          });

          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          newCreatedAttachment = uploadMultipleImage.map(
            (item: any, index: number) => ({
              publicUrl: item.publicUrl,
              attachment: item.attachment,
              originalName: item.originalName,
              propertyIds: newAttachmentDataList[index].propertyId.map(
                (id: any) => ({ propertyId: id, isDeleted: false })
              ),
              attechmentCategoryId: availableCategoryList.value.find(
                (category: any) =>
                  category.label ===
                  newAttachmentDataList[index].userCategoryName
              ).id,
              isDeleted: false,
            })
          );
        }

        const finalDataAttachment: any = [];
        const projectDefaultPropertyIds =
          ProjectData.value?.projectProperties.map(
            (property: any) => property?.property?.id
          );
        cloneAttachmentDataList.value.forEach((attachment: any, index: any) => {
          let attachmentData;
          if (attachment.isDeleted) {
            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              attechmentCategoryId: attachment?.uploadedImages?.attachmentId,
              propertyIds: attachment.propertyId.map((id: any) => ({
                id: attachment?.uploadedImages?.id,
                propertyId: id,
                isDeleted: true,
              })),
              isDeleted: attachment.isDeleted,
            };
          } else {
            const propertyIds: any = [];
            projectDefaultPropertyIds.forEach((propertyId: number) => {
              if (
                attachment.propertyId.includes(propertyId) &&
                filterAttachmentDataList[index].propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  id: attachment?.uploadedImages?.id,
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                !attachment.propertyId.includes(propertyId) &&
                filterAttachmentDataList[index].propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  propertyId: propertyId,
                  isDeleted: false,
                });
              } else if (
                attachment.propertyId.includes(propertyId) &&
                !filterAttachmentDataList[index].propertyId.includes(propertyId)
              ) {
                propertyIds.push({
                  id: attachment?.uploadedImages?.id,
                  propertyId: propertyId,
                  isDeleted: true,
                });
              }
            });

            attachmentData = {
              id: attachment?.uploadedImages?.attachmentId,
              attachment: attachment?.uploadedImages?.attachment,
              originalName: attachment?.uploadedImages?.name,
              attechmentCategoryId: availableCategoryList.value.find(
                (category: any) =>
                  category.label ===
                  filterAttachmentDataList[index].userCategoryName
              ).id,
              propertyIds: propertyIds,
            };
          }
          finalDataAttachment.push(attachmentData);
        });
        await store.dispatch(`${PROJECT_STORE}/updateProject`, {
          projectId: ProjectData.value?.id,
          data: {
            attachments: [...newCreatedAttachment, ...finalDataAttachment],
          },
        });

        await setProjectData();
        isEditAttachmentsSection.value = false;
      } catch (error) {
        console.log();
      } finally {
        isLoadingUploadAttachments.value = false;
        onClickToggleAttachmentModal();
      }
    };

    const normalizeData = () => {
      const addressList: any = [];
      propertiesList?.value?.length &&
        propertiesList?.value.map((item: any) => {
          let latlang = null;

          if (item.addressData) {
            const address =
              typeof item.addressData === "string"
                ? JSON.parse(item.addressData)
                : item.addressData;

            latlang = address?.latlng;

            addressList.push({
              address: item.address,
              uprn: item.uprn,
              latlang,
              id: item.id,
              postcode: item.postcode,
            });
          }
        });
      addresses.value = addressList;
    };

    const isPaginationAvailable = computed(() => {
      const { totalPropertiesCount } = paginationData.value;
      return totalPropertiesCount !== propertiesList.value.length;
    });

    const onLoadMore = async () => {
      const { currentPage, totalPages, totalPropertiesCount } =
        paginationData.value;
      isFetchingPropertiesLoading.value = true;
      if (isPaginationAvailable.value) {
        const data = await getPropertiesListing(
          currentPage,
          totalPropertiesCount + 6
        );
        propertiesList.value = [...data];
        isFetchingPropertiesLoading.value = false;
        normalizeData();
      }
    };

    const getFilterCategory: any = (category: any) => {
      const categoryData = Object.entries(category).map((data: any) => ({
        name: data[0],
        id: data[1][0]?.categoryId,
        attachments: data[1],
      }));
      return categoryData;
    };

    const getFilterImages: any = (category: any) => {
      const filterImages: any = [];
      return Object.values(category)
        .flat()
        .filter((category: any) => {
          if (!filterImages.includes(category.attachmentUrl)) {
            filterImages.push(category.attachmentUrl);
            return true;
          }
          return false;
        });
    };

    const isShowGroupCard = (index: number) => {
      return panel.value.includes(index);
    };

    const getCommentedElementIds: any = computed(
      () =>
        projectComments.value.map((comment: any) => comment?.elementId) || []
    );

    const isAlreadyCommented = (elementId: any) =>
      !getCommentedElementIds.value.includes(elementId);

    const clickOutsideHandler = (event: any) => {
      if (!clickOutside.value?.contains(event?.target))
        activeComment.value.elementId = null;
    };

    const onCloseModal = async () => {
      try {
        loading.value = true;
        iShowInviteTeamMemberModal.value = false;
        const projectDataResponse = await store.dispatch(
          `${PROJECT_STORE}/getProjectData`,
          {
            projectRefId: props.projectRefId,
          }
        );
        ProjectData.value = projectDataResponse;
      } catch (error) {
        console.log("error", error);
      } finally {
        loading.value = false;
      }
    };

    const onClickInviteTeamButton = () => {
      iShowInviteTeamMemberModal.value = true;
    };

    const onClickBackToSearchResults = () => {
      router.push({ name: PROJECT_ROUTE });
    };
    const getPropertyStatus = async (projectId: number) => {
      projectStatus.value = await tradeEstimateService.getPropertyStatus(
        projectId
      );
      let stopLoop = false;
      if (projectStatus.value) {
        if (
          [
            ProjectJobStatusEnum.DRAFT_PROJECT,
            ProjectJobStatusEnum.CANCELLED,
          ].includes(projectDetails.value.projectJobStatus?.id)
        ) {
          return projectOverviewStatusList.value;
        }

        projectOverviewStatusList.value.forEach((status) => {
          if (stopLoop) return;
          status.completed = true;
          if (status.statusCode === projectStatus.value) stopLoop = true;
        });
      }
    };
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const userImagesMap = ref({}) as any;

    const keyExists = (key: number) => {
      return key in userImagesMap.value;
    };
    const addUserImage = (id: number, imageUrl: any) => {
      if (!keyExists(id)) {
        userImagesMap.value[id] = imageUrl;
        return true;
      } else {
        return false;
      }
    };

    const getUserWorkStationProfileImage = async (userDetails: any) => {
      if (userDetails) {
        const profileImage = userDetails.profileImage;
        if (!keyExists(userDetails.id)) {
          if (!profileImage) {
            addUserImage(userDetails.id, null);
            return null;
          }
          const imageUrl = getImageApiUrl(profileImage, true);
          try {
            const encoded: any = await $axios.get(imageUrl);
            addUserImage(userDetails.id, encoded.publicUrl);
            return encoded.publicUrl;
          } catch (error) {
            return null;
          }
        }
      }
    };

    const onClickSaveButtonClick = async (selectedCategory: any) => {
      try {
        isLoadingForSaveSpecialism.value = true;
        const projectSubCategories = ProjectData.value?.subCategories.map(
          (subCategory: any) => {
            return subCategory.id;
          }
        );

        const removedSubCategories = projectSubCategories.filter(
          (subCategoryId: number) => {
            return !selectedCategory.includes(subCategoryId);
          }
        );

        if (removedSubCategories.length) {
          ProjectData.value.subCategories =
            ProjectData.value.subCategories.filter((subCategory: any) => {
              return !removedSubCategories.includes(subCategory.id);
            });
        }

        const newAddedSubCategoriesIds = selectedCategory.filter(
          (subCategoryId: number) => {
            return !projectSubCategories.includes(subCategoryId);
          }
        );

        if (newAddedSubCategoriesIds.length) {
          const newAddedSubCategoriesData = await store.dispatch(
            `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
            {
              subCategoriesIds: newAddedSubCategoriesIds,
            }
          );

          newAddedSubCategoriesData.forEach((subCategory: any) => {
            ProjectData.value?.subCategories.push(subCategory);
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingForSaveSpecialism.value = false;
        isShowSpecialismModal.value = false;
      }
    };

    /** mounted*/
    onMounted(async () => {
      await getProjectData();
      if (projectStatus.value == ProjectJobStatusEnum.COMPLETED) {
        await checkIsUserRatedProject();
      }
      const data = await getPropertiesListing(1, 6);
      propertiesList.value = data;
      getAllCommentsRoomByProjectId();
      subscribeProjectCommentRoom();
      closeProjectCommentRoom();
      document
        ?.getElementById("main_layout_content")
        ?.addEventListener("scroll", setCommentPosition);
      window.addEventListener("resize", setCommentPosition);
      document.addEventListener("transitionend", setCommentPosition);
      document.addEventListener("click", clickOutsideHandler, true);

      // const routeData = router.resolve({
      //   name: "project-details",
      //   params: { refId: ProjectData.value.refId },
      // });
      // window.open(window.location.href, "_blank");
    });

    onBeforeUnmount(() => {
      unbindSubscribedCommentsRoomsChannels();
      document
        ?.getElementById("main_layout_content")
        ?.removeEventListener("scroll", setCommentPosition);
      window.removeEventListener("resize", setCommentPosition);
      document.removeEventListener("transitionend", setCommentPosition);
      document.removeEventListener("click", clickOutsideHandler, true);
      store.dispatch(`${CHATMESSAGE}/setCurrentMessageType`, "chat");
    });
    const getProjectEstimateDataWithEscrow = computed(
      () => store.getters[`${PROJECT_STORE}/getProjectEstimateDataWithEscrow`]
    );

    const projectOverviewStatusList = ref([
      {
        id: 1,
        label: "Project Live On iknowa ",
        description:
          "Your project is now visible on iknowa. Contractors can begin to view and bid on your project.",
        isShowDefault: true,
        statusCode: 1,
        completed: false,
      },
      {
        id: 2,
        label: "Estimates Received",
        description:
          "You have received estimates from contractors. Review the bids and select your preferred contractors.",
        isShowDefault: true,
        statusCode: 2,
        completed: false,
      },
      {
        id: 3,
        label: "Contractors Approved",
        description:
          "You have approved the contractors for your project. They will start work as per the agreed schedule.",
        isShowDefault: true,
        statusCode: 3,
        completed: false,
      },
      {
        id: 4,
        label: "Secured Project Escrow Account ",
        description:
          "The project's escrow account is now secured. Funds will be released upon completion of milestones.",
        isShowDefault: false,
        statusCode: 4,
        completed: false,
      },
      {
        id: 5,
        label: "Project Underway",
        description:
          "The project is now in progress. Contractors are actively working to complete the tasks according to the schedule.",
        isShowDefault: false,
        statusCode: 5,
        completed: false,
      },
      {
        id: 6,
        label: "Completion",
        description:
          "Your project is now complete. Please review the final work and close out the project.",
        isShowDefault: false,
        statusCode: 6,
        completed: false,
      },
    ]);

    const projectOverviewStatus = computed(() => ({
      default: projectOverviewStatusList.value.filter(
        (status) => status.isShowDefault
      ),
      expanded: projectOverviewStatusList.value.filter(
        (status) => !status.isShowDefault
      ),
    }));

    const estimateDetails = computed(
      () =>
        store.getters[`${PROJECT_STORE}/getProjectEstimateDataWithEscrow`]
          ?.estimationPhase
    );
    const selectedPhases = computed(() => {
      const phases = estimateDetails.value?.filter((phase: any) =>
        [EscrowStatusEnum.NOT_STARTED_POKED].includes(phase?.escrowStatus?.id)
      );

      return phases;
    });

    const filterPhases = computed(() => {
      return selectedPhases.value?.map((phase: any) => {
        return {
          ...phase,
          totalAmount: totalPhaseAmount(phase.subTotal, phase.vat, phase.isOld),
        };
      });
    });
    const projectDetails = computed(
      () => store.getters[`${PROJECT_STORE}/projectDetails`]
    );

    const totalPhaseAmount = (
      amount: number,
      vat: number,
      isFirstPhase: boolean
    ) => {
      let sum = 0;

      sum += amount + amount * vat;
      sum += sum * SERVICE_CHARGE;
      if (isFirstPhase) {
        sum += PHASE_FIRST_CHARGE;
      }
      return sum;
    };

    const onClickAddMoreSpecialism = () => {
      isShowSpecialismModal.value = true;
    };

    const onClickCloseTab = () => {
      isShowSpecialismModal.value = false;
      setProjectData();
    };

    const onClickDeleteCategoryButton = (subCategoryId: number) => {
      ProjectData.value.subCategories = ProjectData.value.subCategories.filter(
        (subCategory: any) => {
          return subCategory.id !== subCategoryId;
        }
      );
    };

    const onClickOpenNewTab = (attachment: any) => {
      try {
        window.open(attachment);
      } catch (e) {
        console.log(e);
      }
    };

    const clearImageUrl = () => {
      imageUrl.value = "";
    };

    const estimatePhasesMessage = computed(() => {
      if (!filterPhases.value?.length) {
        return null;
      }

      const phaseDetails = filterPhases.value
        ?.map(
          (phase: any) =>
            `${
              phase?.phaseType === ProjectPhaseType.MATERIAL
                ? MATERIALS
                : phase.name
            } (£${phase.totalAmount.toFixed(2)})`
        )
        .join(" & ");
      const totalAmount = filterPhases.value
        ?.reduce((sum: any, phase: any) => sum + phase.totalAmount, 0)
        .toFixed(2);

      return `
    <p><strong>Required Deposit (£${totalAmount}) </strong></p>
    <p>
      Deposits required for
      <strong>${phaseDetails}</strong> to
      kick-off the project.
    </p>
  `;
    });

    const getProjectRedirectTabName = computed(
      () => store.getters[`${PROJECT_STORE}/getProjectRedirectTabName`]
    );
    const isShowCommentSection = computed(() => {
      return (
        ["jobDetails", "projectDetails"].includes(props.type) &&
        getProjectRedirectTabName.value === "project-overview" &&
        ![
          ProjectJobStatusEnum.CANCELLED,
          ProjectJobStatusEnum.DRAFT_PROJECT,
        ].includes(projectDetails.value?.projectJobStatus?.id)
      );
    });
    const specialismCategoryClass = (val: string) => {
      if (val.toLowerCase() === "krystal") {
        return "krystal";
      }
      if (val.toLowerCase() === "property") {
        return "property";
      }
      if (val.toLowerCase() === "trade") {
        return "trade";
      }
    };

    const redirectVideoConsultation = async () => {
      await store.dispatch(
        `${PROJECT_STORE}/setProjectRedirectTabName`,
        "video consultation"
      );
    };

    const isShowVideoButton = (projectId: any) => {
      return [ProjectJobStatusEnum.IN_PROGRESS].includes(
        projectDetails.value?.projectJobStatus?.id
      );
    };
    const projectRatingData = ref();
    const isShowReviewModal = ref(false);

    const toggleRatingModal = (ratingData: any) => {
      isShowReviewModal.value = !isShowReviewModal.value;
      if (ratingData) {
        projectRatingData.value = ratingData;
      }
    };
    const checkIsUserRatedProject = async () => {
      try {
        projectRatingData.value = await projectRatingService.getProjectRating(
          projectDetails.value.id
        );
        if (!projectRatingData.value) {
          toggleRatingModal(null);
        }
      } catch (error) {
        console.log("get project rating", error);
      }
    };
    const isShowProjectRatingButton = computed(
      () =>
        projectRatingData.value &&
        !parseJSON(projectRatingData.value.ratingStatusList)?.length &&
        projectStatus.value == ProjectJobStatusEnum.COMPLETED
    );

    const onClickSiteVisit = async () => {
      isShowSiteVisitModal.value = !isShowSiteVisitModal.value;
    };
    return {
      onClickAddMoreSpecialism,
      estimateDetails,
      isShowSpecialismModal,
      projectOverviewStatus,
      selectedPhases,
      estimatePhasesMessage,
      projectDetails,
      isShowLoaderOnSaveButtonForDraft,
      isShowGroupCard,
      isCarouselDialog,
      currentIndex,
      imageArray,
      openCarousel,
      isShowDashboard,
      openMessageDashboard,
      ProjectData,
      filterAttachments,
      formatDate,
      projectDataProperties,
      propertyId,
      changePropertyId,
      loading,
      onClickSaveDraftProject,
      ProjectStatusType,
      onClickEditProject,
      addresses,
      isPostLoadingModal,
      currentSelectedTab,
      switchingText,
      onClickPostLiveProject,
      tabListItems,
      switchingImage,
      addComments,
      activeAddComment,
      canvas,
      onComment,
      projectComments,
      activeComment,
      toggleActiveComment,
      updateCommentRoomStatus,
      nextComment,
      inviteMemberList,
      updateProjectNameNote,
      isEditProjectNoteSection,
      projectTitle,
      state,
      onCancelProjectNotes,
      onClickSaveProjectNotes,
      isEditPropertiesSection,
      updatePropertiesSection,
      onClickCancelButton,
      onClickRemoveProperty,
      isShowPropertyListModal,
      onClickTogglePropertyListModal,
      isFetchingPropertiesLoading,
      propertiesList,
      isSelected,
      selectedIndices,
      toggleSelection,
      getPropertyType,
      updateAttachmentsSection,
      panel,
      isEditAttachmentsSection,
      isLoadingRemoveAttachments,
      onClickSaveButtonAttachments,
      onCancelAttachments,
      onClickRemoveAttachment,
      loadingSelectProperty,
      isShowAttachmentModal,
      onClickToggleAttachmentModal,
      imageUrl,
      onFileChange,
      errorMessage,
      uploadedImages,
      addPropertyImage,
      attachmentDataList,
      isAttachmentDataValid,
      removeImageFromAttachments,
      userSelectedProperties,
      userCategory,
      onClickPropertyModalSave,
      onClickSaveButton,
      isPaginationAvailable,
      onLoadMore,
      isLoadingUpdatePropriety,
      showProjectOverviewDialog,
      isLoadingProjectNotes,
      isLoadingProjectData,
      projectAttachments,
      getFilterCategory,
      getFilterImages,
      attachmentsUpdateList,
      carouselImageList,
      isLoadingUploadAttachments,
      onClickSaveButtonAttachmentsModal,
      removeAttachmentsIds,
      isAlreadyCommented,
      getCommentedElementIds,
      clickOutside,
      isEditSpecialismSection,
      updateSpecialismSection,
      onClickRemoveSpecialism,
      onClickCancelSpecialismButton,
      onClickSaveSpecialismButton,
      redirectToEscrowAccount,
      isShowLoaderOnSaveButton,
      onCloseModal,
      iShowInviteTeamMemberModal,
      onClickInviteTeamButton,
      onClickBackToSearchResults,
      onClickCloseTab,
      onClickDeleteCategoryButton,
      onClickOpenNewTab,
      ProjectJobStatusEnum,
      ProjectTypesEnum,
      isShowCommentSection,
      isLoadingOnCancel,
      clearImageUrl,
      isLoadingOnAttachmentCancel,
      selectedFile,
      userImagesMap,
      onClickSaveButtonClick,
      isLoadingForSaveSpecialism,
      specialismCategoryClass,
      redirectVideoConsultation,
      isShowVideoButton,
      isShowProjectRatingButton,
      toggleRatingModal,
      isShowReviewModal,
      ProjectRatingTypeEnum,
      projectRatingData,
      onClickContinuePostProject,
      ProjectFrom,
      isShowSiteVisitModal,
      onClickSiteVisit,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/projectReview.scss";
:deep(.onboard-stepper) {
  box-shadow: none !important;
  overflow: unset !important;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  .v-stepper-header {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    gap: 0.5rem;
    overflow: unset;
    padding-top: 16px;
    // margin-top: 30px;
  }
  .v-stepper-item {
    padding: 0;
    flex: 1 0 0%;
    flex-direction: column;
    align-items: center;

    .v-avatar {
      background-color: rgba(#ffa500, 0.5);
      @include fluidFont(0, 0, 1);
      width: 100% !important;
      border-radius: 10px;
      height: 0.375rem !important;
      margin: 0;
    }

    &--selected {
      .v-avatar {
        background-color: #ffa500 !important;
      }
    }
  }
}
:deep(.prop_img_details) {
  :deep(.v-btn) {
    :deep(.v-btn__overlay),
    :deep(.v-btn__underlay) {
      display: none;
    }
  }
}
</style>
