<template>
  <div class="map-container tw-w-full tw-h-full tw-max-h-full">
    <div ref="map" class="map tw-w-full tw-h-full"></div>

    <div
      ref="sidebar"
      :class="['map__content', { 'sidebar-hidden': !isSidebarVisible }]"
    >
      <div class="left__block">
        <div class="data_layer__details" v-if="selectedSolarLayer?.description">
          <div class="data_layer__header">
            <h6>{{ selectedSolarLayer.label }}</h6>
            <v-icon icon="mdi-close" class="close_icon" />
          </div>
          <div class="data_layer__description">
            <p>
              {{ selectedSolarLayer?.description }}
            </p>
          </div>
          <div class="data_layer__footer" v-if="solarLayer?.palette">
            <div
              :style="{
                background: `linear-gradient(to right, ${solarLayer.palette.colors
                  .map((hex) => '#' + hex)
                  .join(', ')})`,
              }"
              class="modal__weather"
            ></div>
            <div class="modal__temperature">
              <span class="temp_start">{{ solarLayer?.palette?.min }}</span>
              <span class="temp_end">{{ solarLayer?.palette?.max }}</span>
            </div>
          </div>
        </div>
        <div class="slider__wrapper" v-if="showCustomRange">
          <v-slider
            class="data__slider"
            v-model="panelCustomIndex"
            min="0"
            step="1"
            color="#000"
            :max="overlays.length - 1"
            @update:modelValue="updateCustomSlider"
            :label="
              selectedSolarLayer?.tiffId === 'monthlyFluxUrl'
                ? monthNames[panelCustomIndex]
                : generateHourlyValues[panelCustomIndex]
            "
          ></v-slider>
        </div>
      </div>

      <div class="right__block">
        <div class="map-settings">
          <v-icon @click="onFullScreenRequest">
            <img src="../../../assets/icons/zoom.svg" alt="" />
          </v-icon>

          <v-icon icon="mdi-plus" @click="onZoomIn" />
          <v-icon icon="mdi-minus" @click="onZoomOut" />
          <v-icon
            v-if="!loading && googleMap"
            icon="mdi-map-marker-outline"
            @click="centerMapOnLocation"
          ></v-icon>

          <v-icon
            class="toggle-button"
            :icon="isSidebarVisible ? 'mdi-chevron-right' : 'mdi-chevron-left'"
            @click="toggleSidebar"
          ></v-icon>
        </div>
        <div class="custom-column">
          <div class="custom__column">
            <div class="sidebar-content">
              <!-- Add your sidebar content here -->
              <div class="content-header">
                <h5>Data <strong>Layers</strong></h5>
                <v-icon
                  icon="mdi-close"
                  class="close_icon"
                  @click="toggleSidebar"
                />
              </div>
              <div class="content-wrapper">
                <div class="content-selector">
                  <div class="content-item">
                    <v-switch
                      class="map__switch"
                      color="#0B5FFF"
                      v-model="isShowSolarPanels"
                      :disabled="loading"
                      @change="showSolarDetails"
                    ></v-switch>
                    <span> Solar panels </span>
                  </div>

                  <div class="content-item">
                    <v-switch
                      class="map__switch"
                      color="#0B5FFF"
                      v-model="isShowSegment"
                      :disabled="loading"
                      @change="toggleSolarSegments"
                    ></v-switch>
                    <span> Show Segment </span>
                  </div>

                  <div class="content-item">
                    <v-switch
                      class="map__switch"
                      color="#0B5FFF"
                      v-model="isShowVersion2"
                      :disabled="loading"
                      @change="toggleSolarVersion"
                    ></v-switch>
                    <span> Switch to Version2 </span>
                  </div>

                  <div class="content-item">
                    <v-switch
                      class="map__switch"
                      color="#0B5FFF"
                      v-model="isShowBoundary"
                      :disabled="loading"
                      @change="showPropertyPolygon"
                    ></v-switch>
                    <span> Show Boundary </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar-content">
              <div class="content-wrapper">
                <div class="content-selector">
                  <v-radio-group
                    v-model="selectedSolarLayer"
                    @change="showSolarLayers(true)"
                  >
                    <template v-for="layer of dataLayerList" :key="layer.id">
                      <template v-if="layer.tiffId !== 'rgbUrl'">
                        <div class="content-item">
                          <v-switch
                            class="map__switch"
                            color="#0B5FFF"
                            v-model="selectedSolarLayer"
                            :value="layer"
                            :disabled="loading"
                          ></v-switch>
                          <span> {{ layer.label }} </span>
                        </div>
                      </template>
                    </template>
                  </v-radio-group>

                  <template
                    v-for="polygon of specialPolygonList"
                    :key="polygon.id"
                  >
                    <div class="content-item">
                      <v-switch
                        @change="showSpecialPolygon"
                        class="map__switch"
                        color="#0B5FFF"
                        v-model="selectedSpecialPolygon"
                        :value="polygon"
                        :disabled="loading"
                      ></v-switch>
                      <span> {{ polygon.label }} </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <!-- solar controls -->
          <div class="solar_controls">
            <div class="sidebar-content">
              <div class="content-wrapper">
                <div class="accordion-wrapper" v-if="isShowSolarPanels">
                  <v-expansion-panels variant="accordion">
                    <v-expansion-panel title="Solar Panel Control">
                      <v-expansion-panel-text>
                        <div class="panel-control-wrapper">
                          <div class="panel-control-list">
                            <span>Panels count </span>
                            <span>{{ panelIndex }} panels</span>
                          </div>
                          <v-slider
                            v-model="panelIndex"
                            min="0"
                            step="1"
                            color="#0C0F4A"
                            :max="
                              propertyMapDetails?.solarPotential?.solarPanels
                                ?.length
                            "
                            @update:modelValue="addSolarPanels"
                          ></v-slider>
                        </div>
                        <div class="panel-size-wrapper">
                          <v-form>
                            <v-text-field
                              suffix="meter"
                              v-model="panelWidth"
                              :rules="[numberRules]"
                              type="number"
                              step="0.01"
                              variant="outlined"
                              class="input_field"
                            ></v-text-field>
                            <v-text-field
                              variant="outlined"
                              suffix="meter"
                              v-model="panelHeight"
                              :rules="[numberRules]"
                              type="number"
                              step="0.01"
                              class="input_field"
                            ></v-text-field>
                          </v-form>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </div>
          <div class="custom_layer__wrapper">
            <!-- Custom Data Layers -->
            <div class="custom_data_layers">
              <div class="sidebar-content">
                <!-- Add your sidebar content here -->
                <div class="content-header">
                  <h5>Custom Data<strong>Layers</strong></h5>
                  <v-btn
                    class="button button-orange"
                    width="auto"
                    @click="toggleLayerModal"
                    :disabled="loading"
                    >Add New</v-btn
                  >
                </div>
                <div class="content-wrapper">
                  <div class="content-selector">
                    <span
                      class="content-item"
                      v-for="layer in propertyLayers"
                      :key="layer?.id"
                    >
                      <v-icon
                        v-if="layer.selected"
                        icon="mdi-close"
                        @click="toggleConfirmationModel(layer)"
                        class="cursor-pointer"
                      ></v-icon>
                      <v-checkbox
                        v-model="layer.selected"
                        color="#0c0f4a"
                        hide-details
                        inset
                        @change="selectCustomLayer(layer, layer.selected)"
                      ></v-checkbox>
                      <span> {{ layer?.name }} </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
        <div class="bg__overlay" v-if="isOpenLayerModal">
          <div class="layer__wrapper">
            <div class="layer__header">
              <h5>Save new layer</h5>
            </div>
            <div class="layer__body">
              <div v-if="!isSaveNewLayer">
                <label>Custom data layers name</label>
                <v-text-field
                  v-model="layerName"
                  type="text"
                  variant="outlined"
                  class="input_field"
                  placeholder="Shape 144"
                ></v-text-field>
              </div>

              <div class="layers__list" v-else>
                <div v-if="propertyLayers.length > 0">
                  <v-select
                    :items="propertyLayers"
                    v-model="selectedLayerId"
                    placeholder="Please select Layer"
                    variant="outlined"
                    item-title="name"
                    item-value="id"
                    class="input_field_selector"
                    :menu-props="{ contentClass: 'layer_selector' }"
                    required
                    :rules="[(v) => !!v || 'Layer is required']"
                  ></v-select>
                </div>
                <div v-else>
                  <input
                    type="text"
                    v-model="layerName"
                    placeholder="Enter Layer Name"
                  />
                </div>
              </div>
            </div>

            <div class="layer__footer">
              <v-btn
                class="button button-purple-border"
                width="auto"
                @click="toggleLayerModal"
                >close</v-btn
              >
              <v-btn
                class="button button-orange"
                width="auto"
                @click="saveNewLayer"
                >save</v-btn
              >
            </div>
          </div>
        </div>

        <!-- Modal For Confirmation -->
        <div class="bg__overlay" v-if="isOpenConfirmationModel">
          <div class="layer__wrapper">
            <div class="layer__header">
              <h5>Delete layer</h5>
            </div>
            <div class="layer__body">
              <div v-if="!isSaveNewLayer">
                <label>Are You Sure Want to Delete?</label>
              </div>
            </div>

            <div class="layer__footer">
              <v-btn
                class="button button-purple-border"
                width="auto"
                @click="toggleConfirmationModel(null)"
                >close</v-btn
              >
              <v-btn
                class="button button-orange"
                width="auto"
                @click="deleteLayer(layer)"
                >Delete</v-btn
              >
            </div>
          </div>
        </div>
        <div class="layer__details">
          <div v-if="selectMetaDataLayer">
            <!-- Add your sidebar content here -->
            <div class="content-header">
              <h5>
                {{
                  selectMetaDataLayer?.type === PropertyMapLayerType.SHAPES
                    ? "Shape Details"
                    : "Measure Details"
                }}
              </h5>

              <v-icon
                icon="mdi-close"
                class="close_icon"
                @click="selectMetaDataLayer = null"
              />
            </div>
            <div class="content-wrapper no-scroll">
              <div
                class="inner__wrapper no-scroll"
                v-if="selectMetaDataLayer?.type === PropertyMapLayerType.SHAPES"
              >
                <div class="detail__content">
                  <span>Total Area: </span>
                  <span>{{ selectMetaDataLayer?.area }} sqm</span>
                </div>
                <div class="detail__content">
                  <span>Total Perimeter: : </span>
                  <span>{{ selectMetaDataLayer?.perimeter }} sqm</span>
                </div>
              </div>
              <div v-else>
                <div>
                  <span>Total Length: </span>
                  <span>{{ selectMetaDataLayer?.distance }} m</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="shape__btn" v-if="isMapLoaded">
        <v-btn
          :class="['toggle-button disabled', { active: isShowMeasure }]"
          @click="startMeasurement"
        >
          <v-icon>
            <img src="../../../assets/icons/measure.svg" alt="" /> </v-icon
          ><span>Measure</span></v-btn
        >
        <v-btn
          @click="startDrawingShapes"
          :class="['toggle-button disabled', { active: isShowShapes }]"
        >
          <v-icon>
            <img src="../../../assets/icons/shape.svg" alt="" />
          </v-icon>
          <span>Add Shape</span></v-btn
        >

        <v-btn
          v-if="polyLines.length || polygonsData.length"
          class="button button-orange"
          @click="toggleSaveLayer"
          >Save
        </v-btn>
        <v-btn
          v-if="polyLines.length || polygonsData.length"
          class="button button-purple-border"
          @click="resetLayer"
          >Cancel
        </v-btn>
      </div>
    </div>

    <div class="bg__overlay" v-if="loading">
      <CommonLoader :loading="loading" color="white" class="loader" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, computed, getCurrentInstance } from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import markerImage from "@/assets/images/custom-marker.png";
import { PROPERTY_STORE } from "@/store/modules/property";
import { useStore } from "vuex";
import CommonLoader from "../CommonLoader.vue";
import { debounce } from "lodash";
import { watch } from "vue";
import { fromArrayBuffer } from "geotiff";
import * as geoKeysToProj4 from "geotiff-geokeys-to-proj4";
import proj4 from "proj4";
import { useSolarLayer } from "@/core/utils/map-data-layer.ts";
import {
  createPalette,
  getRandomColor,
  panelsPalette,
  normalize,
  rgbToColor,
  displayToastMessage,
  parseJSON,
} from "@/core/utils/common";
import { toRaw } from "vue";
import { nextTick } from "vue";
import { PropertyMapLayerType } from "@/core/enums/PropertiesEnum";
import createGoogleMapsLoader from "@/core/utils/google-map-loader";
import propertyService from "@/core/services/property.service";
import axios from "axios";
import moment from "moment";
const sidebar = ref(null);
const props = defineProps({
  propertyId: {
    type: Number,
  },
  addressData: {
    type: Object,
  },
});

const store = useStore();
const map = ref(null);
const loading = ref(false);
const googleMap = ref(null);
const isSidebarVisible = ref(true);
const isShowSolarPanels = ref(true);
const isShowBoundary = ref(true);
const panelIndex = ref(0);
const panelCustomIndex = ref(0);
const showCustomRange = ref(false);
const wantGoogleResponse = ref(false);
let solarSegments = ref([]);
let solarSegments_V2 = ref([]);
let rotated_segment = ref([]);
const panelWidth = ref("1.045");
const panelHeight = ref("1.875");
const isShowSegment = ref(true);
const isShowVersion2 = ref(false);
const isShowMeasure = ref(false);
const isShowShapes = ref(false);
let solarPanels = [];
const markers = ref([]);
const polyLines = ref([]);
const polyGonInstances = ref([]);
const cachedData = ref([]);

const polygonsData = ref([]);
const mapMarkers = ref([]);
const displayMapMarkers = ref([]);
const overlayMarkers = ref([]);
const isFullScreen = ref(false);
const propertyMapDetails = ref(null);
const drawingManager = ref(null);
const layerName = ref("");
const selectedLayerId = ref(null);
const propertyLayers = ref([]);
const selectMetaDataLayer = ref(null);
const isOpenConfirmationModel = ref(false);
const selectedSolarLayer = ref();
const solarDataLayerUrlList = ref(null);
const solarLayer = ref();
const overlays = ref([]);
const specialPolyGonInstanceArr = ref([]);
const selectedSpecialPolygon = ref();
const specialPolygonMarker = ref([]);
const solarLayerInterval = ref();
const dataLayerList = [
  { id: 1, label: "RGB Layer", tiffId: "rgbUrl", description: "" },
  {
    id: 2,
    label: "Digital Surface Model(DSM)",
    tiffId: "dsmUrl",
    description: `Elevation data that represents the topography of Earth's surface, including natural and built features. Values are in metres above sea level. Invalid locations, or areas where we don't have data, are stored as -9999.`,
  },
  {
    id: 3,
    label: "Building mask",
    tiffId: "maskUrl",
    description: `The building mask image: one bit per pixel saying whether that pixel is considered to
              be part of a rooftop or not.`,
  },
  {
    id: 4,
    label: "Annual flux",
    tiffId: "annualFluxUrl",
    description: ` The annual flux map (annual sunlight on roofs) of the region. Values are kWh/kW/year.
              This is unmasked flux: flux is computed for every location, not just building
              rooftops. Invalid locations are stored as -9999: locations outside our coverage area
              will be invalid, and a few locations inside the coverage area, where we were unable to
              calculate flux, will also be invalid.`,
  },
  {
    id: 5,
    label: "Monthly flux",
    tiffId: "monthlyFluxUrl",
    description: `The monthly flux map (sunlight on roofs, broken down by month) of the region. Values
              are kWh/kW/year. The GeoTIFF imagery file pointed to by this URL will contain twelve
              bands, corresponding to January...December, in order.`,
  },
  {
    id: 6,
    label: "Hourly shade",
    tiffId: "hourlyShadeUrls",
    description: ` Twelve URLs for hourly shade, corresponding to January...December, in order. Each
              GeoTIFF imagery file will contain 24 bands, corresponding to the 24 hours of the day.
              Each pixel is a 32 bit integer, corresponding to the (up to) 31 days of that month; a
              1 bit means that the corresponding location is able to see the sun at that day, of
              that hour, of that month. Invalid locations are stored as -9999 (since this is
              negative, it has bit 31 set, and no valid value could have bit 31 set as that would
              correspond to the 32nd day of the month).`,
  },
];
const specialPolygonList = [
  {
    id: 1,
    label: "Building Preservation ",
    polygonId: "building_preservation_notices_polygons",
    fillColor: "#bbc1be",
    borderColor: "#606060",
  },
  {
    id: 2,
    label: "Conservation Areas ",
    polygonId: "conservation_areas",
    fillColor: "#e4afb5",
    strokeColor: "#9b5b62",
  },
  {
    id: 3,
    label: "Properties with Certificate of Immunity ",
    polygonId: "certificate_of_immunity_polygons",
    fillColor: "#b6897a",
    strokeColor: "#875e52",
  },
  {
    id: 4,
    label: "Flood Risk ",
    polygonId: "flood_risk_polygon",
    fillColor: "#e15554",
    strokeColor: "#ab4648",
  },
  {
    id: 5,
    label: "Listed Building Polygon ",
    polygonId: "listed_building_polygons",
    fillColor: "#a2b58d",
    strokeColor: "#54683d",
  },
];
const internalInstance = getCurrentInstance();

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const generateHourlyValues = computed(() => {
  const timeSlots = [];
  const startTime = moment().startOf("day");

  for (let i = 0; i < 24; i++) {
    timeSlots.push(startTime.format("h A"));
    startTime.add(1, "hour");
  }

  return timeSlots;
});

const toggleSidebar = () => {
  isSidebarVisible.value = !isSidebarVisible.value;

  if (!isSidebarVisible.value) {
    resetLayer();
  }
};

const numberRules = (value) => {
  const pattern = /^\d*\.?\d*$/;
  return pattern.test(value) || "Only numeric values are allowed";
};
const isOpenLayerModal = ref(false);
const isSaveNewLayer = ref(false);

const loader = createGoogleMapsLoader();

const isMapLoaded = computed(() => {
  return googleMap.value ? "true" : false;
});

const addMarker = (location) => {
  return new window.google.maps.Marker({
    position: location,
    icon: markerImage,
    map: googleMap.value,
  });
};

const drawPolyline = () => {
  const coordinates = [];
  mapMarkers.value.forEach((marker) => {
    const position = marker.getPosition();
    coordinates.push({
      lat: position.lat(),
      lng: position.lng(),
    });
  });

  const polyline = new window.google.maps.Polyline({
    path: coordinates,
    strokeColor: "#FFA500",
    strokeOpacity: 1,
    strokeWeight: 4,
    zIndex: 2,
  });
  polyline.setMap(googleMap.value);

  if (polyline) {
    const path = polyline.getPath();
    const distance = google.maps.geometry.spherical.computeLength(path);

    polyLines.value.push({
      coordinates,
      distance: distance.toFixed(2),
      data: polyline,
      // markers: mapMarkers,
    });
  }
};

const removeMarkers = () => {
  mapMarkers.value.forEach((marker) => toRaw(marker).setMap(null));
  mapMarkers.value = [];
};

const coordinates = ref([]);
const initializeMap = () => {
  const mapOptions = {
    center: {
      lat: props.addressData.LAT
        ? props.addressData.LAT
        : props.addressData.lat,
      lng: props.addressData.LNG
        ? props.addressData.LNG
        : props.addressData.lng,
    },
    mapTypeId: "satellite",
    zoom: 19,
    tilt: 0,
    rotateControl: false,
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    fullscreenControlOptions: {
      position: window.google.maps.ControlPosition.TOP_LEFT,
    },
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_CENTER,
    },
  };

  googleMap.value = new window.google.maps.Map(map.value, mapOptions);

  nextTick(() => {
    const sidebarDiv = sidebar.value;
    googleMap.value.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      sidebarDiv
    );
  });
};
const loadMap = () => {
  googleMap.value.addListener("click", (event) => {
    if (mapMarkers.value.length < 2 && isShowMeasure.value) {
      const marker = addMarker(event.latLng);
      mapMarkers.value.push(marker);
      if (mapMarkers.value.length === 2) {
        drawPolyline();
        const [marker1, marker2] = mapMarkers.value;
        const position1 = marker1.getPosition();
        const position2 = marker2.getPosition();
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          position1,
          position2
        );
        let distanceText;
        if (distance < 1000) {
          distanceText = `${distance.toFixed(2)} m`;
        } else {
          distanceText = `${(distance / 1000).toFixed(2)} km`;
        }
        const overlay = addOverlayLayer(position1, position2, distanceText);
        overlayMarkers.value.push(overlay);

        if (polyLines.value.length) {
          const len = polyLines.value.length;
          const polyline = polyLines.value[len - 1];
          polyline.coordinates.forEach((position) => {
            const marker = addMarker(position);
            displayMapMarkers.value.push(marker);
          });
        }

        removeMarkers();
      }
    }
  });

  drawingManager.value = new window.google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.POLYGON,

    drawingControl: false,
    polygonOptions: {
      fillColor: "#FFA50080",
      strokeColor: "#FFA500",
      strokeOpacity: 1,
      strokeWeight: 4,
      zIndex: 2,
      editable: true,
    },
  });

  window.google.maps.event.addListener(
    drawingManager.value,
    "polygoncomplete",
    (polygon) => {
      if (isShowShapes.value) {
        const path = polygon.getPath();
        const coordinates = [];
        for (let i = 0; i < path.getLength(); i++) {
          let p1 = path.getAt(i);
          let p2 = path.getAt((i + 1) % path.getLength());
          let distance = google.maps.geometry.spherical.computeDistanceBetween(
            p1,
            p2
          );

          let distanceText;
          if (distance < 1000) {
            distanceText = `${distance.toFixed(2)} m`;
          } else {
            distanceText = `${(distance / 1000).toFixed(2)} km`;
          }

          const overlay = addOverlayLayer(p1, p2, distanceText);
          overlayMarkers.value.push(overlay);

          const latLng = path.getAt(i);
          coordinates.push({ lat: latLng.lat(), lng: latLng.lng() });
        }

        const area = google.maps.geometry.spherical.computeArea(path);
        const perimeter = google.maps.geometry.spherical.computeLength(path);
        polygonsData.value.push({
          coordinates: coordinates,
          area: area.toFixed(2),
          perimeter: perimeter.toFixed(2),
          data: polygon,
          markers: [],
        });
      }
    }
  );

  showPropertyPolygon();
  showSolarDetails();
};

const showPolyLines = (layer, coordinates, isChecked) => {
  if (!coordinates.length) return;

  if (isChecked) {
    coordinates?.forEach((points) => {
      const marker = addMarker(points);
      layer.markers.push(marker);
    });

    const [marker1, marker2] = coordinates;

    const polyline = new window.google.maps.Polyline({
      path: coordinates,
      strokeColor: "#FFA500",
      strokeOpacity: 1,
      strokeWeight: 5,
      zIndex: 2,
    });
    polyline.setMap(googleMap.value);
    layer.polyLines.push(polyline);

    const path = polyline.getPath();
    const distance = google.maps.geometry.spherical.computeLength(path);

    let distanceText;
    if (distance < 1000) {
      distanceText = `${distance.toFixed(2)} m`;
    } else {
      distanceText = `${(distance / 1000).toFixed(2)} km`;
    }

    const overlay = addOverlayLayer(marker1, marker2, distanceText);
    layer.overlayMarkers.push(overlay);
  } else {
    // clear unchecked data
    layer.polyLines.forEach((polyline) => toRaw(polyline).setMap(null));
    layer.polyLines = [];
    layer.markers.forEach((marker) => toRaw(marker).setMap(null));
    layer.markers = [];
    layer.overlayMarkers.forEach((overlay) => {
      overlay.onRemove();
      toRaw(overlay).setMap(null);
    });
    layer.overlayMarkers = [];
  }
};
const showPolyGons = (layer, coordinates, isChecked) => {
  if (!coordinates.length) return;
  if (isChecked) {
    coordinates.forEach((points, index) => {
      const marker = addMarker(points);
      layer.markers.push(marker);
      const point1 = coordinates[index];
      const point2 = coordinates[(index + 1) % coordinates.length];
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        point1,
        point2
      );
      let distanceText;
      if (distance < 1000) {
        distanceText = `${distance.toFixed(2)} m`;
      } else {
        distanceText = `${(distance / 1000).toFixed(2)} km`;
      }
      const overlay = addOverlayLayer(point1, point2, distanceText);
      layer.overlayMarkers.push(overlay);
    });

    const polygon = new google.maps.Polygon({
      paths: coordinates,
      strokeColor: "#FFA500",
      strokeOpacity: 1,
      strokeWeight: 4,
      fillColor: "#FFA50080",
      fillOpacity: 0.9,
      zIndex: 2,
      editable: false,
      draggable: false,
    });

    polygon.setMap(googleMap.value);

    google.maps.event.addListener(polygon, "click", function (event) {
      const path = polygon.getPath();
      const area = google.maps.geometry.spherical.computeArea(path);
      const perimeter = google.maps.geometry.spherical.computeLength(path);
      selectMetaDataLayer.value = {
        type: PropertyMapLayerType.SHAPES,
        area: area.toFixed(2),
        perimeter: perimeter.toFixed(2),
      };
    });

    layer.polyGons.push(polygon);
  } else {
    // clear unchecked data
    layer.polyGons.forEach((polyGon) => toRaw(polyGon).setMap(null));
    layer.polyGons = [];
    layer.markers.forEach((marker) => toRaw(marker).setMap(null));
    layer.markers = [];
    layer.overlayMarkers.forEach((overlay) => {
      overlay.onRemove();
      toRaw(overlay).setMap(null);
    });
    layer.overlayMarkers = [];
  }
};

const showPropertyPolygon = () => {
  if (isShowBoundary.value && coordinates.value?.length) {
    for (const polygon of coordinates.value) {
      const polygonCoordsMapped = [];
      polygon.polygon_points[0][0].forEach((points) => {
        // const marker = addMarker({ lat: points[1], lng: points[0] });
        // toRaw(markers.value).push(marker);
        polygonCoordsMapped.push({
          lat: parseFloat(points[1]),
          lng: parseFloat(points[0]),
        });
      });
      const polygonIns = new window.google.maps.Polygon({
        paths: polygonCoordsMapped,
        strokeColor: "#FFA500",
        strokeOpacity: 1,
        strokeWeight: 4,
        fillColor: "#FFA50080",
        fillOpacity: 0.9,
        zIndex: 2,
      });
      polyGonInstances.value.push(polygonIns);
      toRaw(polygonIns).setMap(googleMap.value);
    }
  } else {
    markers.value.forEach((marker) => toRaw(marker).setMap(null));
    markers.value = [];
    polyGonInstances.value.forEach((polygon) => toRaw(polygon).setMap(null));
    polyGonInstances.value = [];
  }
};
const centerMapOnLocation = () => {
  let zoomInterval;
  const location = new window.google.maps.LatLng(
    props.addressData.LAT ? props.addressData.LAT : props.addressData.lat,
    props.addressData.LNG ? props.addressData.LNG : props.addressData.lng
  );
  clearInterval(zoomInterval);
  let currentZoom = googleMap.value?.getZoom();
  zoomInterval = setInterval(function () {
    if (currentZoom <= 19) {
      currentZoom++;
      googleMap.value?.setZoom(currentZoom);
    } else {
      clearInterval(zoomInterval);
    }
  }, 100);

  googleMap.value?.setCenter(location);
};
const toggleSolarVersion = async () => {
  solarPanels?.map((panel, i) => {
    return toRaw(panel).setMap(null);
  });
  solarPanels = [];

  let payload = {
    latitude: props.addressData.LAT
      ? props.addressData.LAT
      : props.addressData.lat,
    longitude: props.addressData.LNG
      ? props.addressData.LNG
      : props.addressData.lng,
    width: panelWidth.value,
    height: panelHeight.value,
    response_version: "v1",
  };
  if (isShowVersion2.value) {
    payload["response_version"] = "v2";
    if (isShowSegment.value) {
      solarSegments.value.map((segment) => {
        toRaw(segment).setMap(null);
      });
    } else
      solarSegments_V2.value.map((segment) => {
        toRaw(segment).setMap(null);
      });
  }

  await getPropertiesMapData(payload);
  showSolarDetails();
};

const addSolarPanels = () => {
  let panelConfig = null;
  let configId = panelIndex.value;
  if (propertyMapDetails.value?.solarPotential?.solarPanelConfigs) {
    panelConfig =
      propertyMapDetails.value?.solarPotential?.solarPanelConfigs[configId];
  }
  solarPanels?.map((panel, i) => {
    return toRaw(panel).setMap(
      configId && i < configId ? googleMap.value : null
    );
  });
};

const startDrawingShapes = () => {
  isShowMeasure.value = false;
  isShowShapes.value = !isShowShapes.value;
  toggleDrawingManagerData();
};
const toggleDrawingManagerData = () => {
  if (isShowShapes.value) drawingManager.value.setMap(googleMap.value);
  else {
    drawingManager.value.setMap(null);
  }
};
const startMeasurement = () => {
  isShowShapes.value = false;
  toggleDrawingManagerData();
  isShowMeasure.value = !isShowMeasure.value;
};
const showSolarDetails = () => {
  //check condition
  if (!isShowSolarPanels.value) {
    solarPanels?.map((panel, i) => {
      return toRaw(panel).setMap(null);
    });
    solarPanels = [];
    return;
  }

  solarPanels?.map((panel) => toRaw(panel).setMap(null));
  solarPanels = [];

  // Create the solar panels on the map.
  const palette = createPalette(panelsPalette).map(rgbToColor);
  const minEnergy =
    propertyMapDetails.value?.solarPotential.solarPanels?.slice(-1)[0]
      ?.yearlyEnergyDcKwh;
  const maxEnergy =
    propertyMapDetails.value?.solarPotential.solarPanels[0]?.yearlyEnergyDcKwh;
  solarPanels = propertyMapDetails.value?.solarPotential?.solarPanels?.map(
    (panel, index) => {
      const [w, h] = [
        propertyMapDetails.value?.solarPotential?.panelWidthMeters / 2,
        propertyMapDetails.value?.solarPotential?.panelHeightMeters / 2,
      ];
      const points = [
        { x: +w, y: +h }, // top right
        { x: +w, y: -h }, // bottom right
        { x: -w, y: -h }, // bottom left
        { x: -w, y: +h }, // top left
        { x: +w, y: +h }, // top right
      ];
      const orientation = panel.orientation == "PORTRAIT" ? 90 : 0;
      const azimuth =
        propertyMapDetails.value?.solarPotential?.roofSegmentStats[
          panel.segmentIndex
        ].azimuthDegrees;
      const colorIndex = Math.round(
        normalize(panel.yearlyEnergyDcKwh, maxEnergy, minEnergy) * 255
      );
      const googlePoly = new window.google.maps.Polygon({
        paths: points.map(({ x, y }) =>
          window.google.maps.geometry.spherical.computeOffset(
            { lat: panel.center.latitude, lng: panel.center.longitude },
            Math.sqrt(x * x + y * y),
            Math.atan2(y, x) * (180 / Math.PI) +
              orientation +
              (wantGoogleResponse.value ? azimuth : 0)
          )
        ),
        strokeColor: "#B0BEC5",
        strokeOpacity: 0.9,
        strokeWeight: 1,
        fillColor: palette[colorIndex],
        fillOpacity: 0.9,
        zIndex: 2,
      });

      return googlePoly;
    }
  );

  let panelConfig = null;
  let configId = panelIndex.value;
  if (propertyMapDetails.value?.solarPotential?.solarPanelConfigs) {
    panelConfig =
      propertyMapDetails.value?.solarPotential?.solarPanelConfigs[configId];
  }

  solarPanels?.map((panel, i) => {
    return toRaw(panel).setMap(
      configId && i < configId ? googleMap.value : null
    );
  });

  let solarPanelsBox = [];
  solarPanelsBox?.map((panel) => {
    return toRaw(panel).setMap(null);
  });

  solarPanelsBox?.map((panel, i) => {
    return toRaw(panel).setMap(
      panelConfig && i < panelConfig.panelsCount ? googleMap.value : null
    );
  });

  solarSegments.value?.map((segment) => {
    toRaw(segment).setMap(null);
  });
  solarSegments.value = [];

  if (
    propertyMapDetails.value?.segment_data?.polygon_data &&
    !isShowVersion2.value
  ) {
    solarSegments.value =
      propertyMapDetails.value?.segment_data?.polygon_data?.map((data) => {
        let coordinates = data?.coordinates[0];
        let formattedCoordinates = coordinates?.map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));

        // Create the polygon
        let polygon = new window.google.maps.Polygon({
          paths: formattedCoordinates,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        return polygon;
      });
  }
  solarSegments_V2.value?.map((segment) => {
    toRaw(segment).setMap(null);
  });
  solarSegments_V2.value = [];
  if (
    propertyMapDetails.value?.segment_data?.polygon_data_V2 &&
    isShowVersion2.value
  ) {
    solarSegments_V2.value =
      propertyMapDetails.value?.segment_data?.polygon_data_V2?.map((data) => {
        let coordinates = data?.coordinates[0];
        let formattedCoordinates = coordinates?.map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));

        // Create the polygon
        let polygon = new window.google.maps.Polygon({
          paths: formattedCoordinates,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        return polygon;
      });

    solarSegments_V2.value.map((segment) => {
      toRaw(segment).setMap(googleMap.value);
    });
  }

  rotated_segment.value?.map((segment) => {
    toRaw(segment).setMap(null);
  });
  rotated_segment.value = [];
  if (propertyMapDetails.value?.segment_data?.rotated_segment) {
    rotated_segment.value =
      propertyMapDetails.value?.segment_data?.rotated_segment?.map((data) => {
        let coordinates = data?.coordinates[0];
        let formattedCoordinates = coordinates?.map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));
        let color = getRandomColor();
        // Create the polygon
        let polygon = new window.google.maps.Polygon({
          paths: formattedCoordinates,
          strokeColor: color,
          strokeOpacity: 2.5,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0,
        });
        return polygon;
      });
  }
  toggleSolarSegments();
};
const toggleSolarSegments = () => {
  if (!isShowSegment.value) {
    solarSegments.value.map((segment) => {
      toRaw(segment).setMap(null);
    });
    rotated_segment.value.map((segment) => {
      toRaw(segment).setMap(null);
    });
    solarSegments_V2.value.map((segment) => {
      toRaw(segment).setMap(null);
    });
  } else {
    solarSegments.value.map((segment) => {
      toRaw(segment).setMap(googleMap.value);
    });
    rotated_segment.value.map((segment) => {
      toRaw(segment).setMap(googleMap.value);
    });

    if (isShowVersion2.value) {
      solarSegments_V2.value.map((segment) => {
        toRaw(segment).setMap(googleMap.value);
      });
    }
  }
};

const getPropertiesMapData = async (payload) => {
  try {
    loading.value = true;
    const cacheKey = JSON.stringify(payload);
    const cachedData = getCacheData(cacheKey);

    if (cachedData) {
      propertyMapDetails.value = cachedData;
      panelIndex.value = Math.floor(
        propertyMapDetails.value?.solarPotential?.solarPanels?.length / 2
      );
      return;
    }
    const data = await store.dispatch(
      `${PROPERTY_STORE}/fetchPolygonCoordinates`,
      payload
    );
    propertyMapDetails.value = data;
    setCacheData(cacheKey, data);

    panelIndex.value = Math.floor(
      propertyMapDetails.value?.solarPotential?.solarPanels?.length / 2
    );
  } catch (error) {
    console.error("Invalid Latitude and Longitude", error);
    return undefined;
  } finally {
    loading.value = false;
  }
};

const getPropertyPolygonData = async () => {
  const uprnId = props.addressData.UPRN
    ? props.addressData.UPRN
    : props.addressData.uprn;
  const classCode = props.addressData?.titleList[0]?.title_class || "";
  try {
    loading.value = true;
    const { data } = await store.dispatch(
      `${PROPERTY_STORE}/fetchPropertyPolygon`,
      { uprnId, classCode }
    );

    if (data?.result?.polygon_data?.length) {
      coordinates.value = data.result.polygon_data;
    }
  } catch (error) {
    isShowBoundary.value = false;
    console.log();
  } finally {
    loading.value = false;
  }
};
const getPropertyMapLayers = async () => {
  try {
    let response = await store.dispatch(
      `${PROPERTY_STORE}/getPropertyLayers`,
      props.propertyId
    );
    if (response) {
      if (
        propertyLayers.value.length &&
        propertyLayers.value.length !== response.length
      ) {
        //new layer added
        const lastLayer = response.pop();
        propertyLayers.value.push(lastLayer);
      } else {
        response = response.map((data) => {
          return {
            ...data,
            selected: false,
            polyLines: [],
            polyGons: [],
            markers: [],
            overlayMarkers: [],
          };
        });
        propertyLayers.value = response;
      }
    }
  } catch (error) {
    console.log();
  }
};
const debouncedShowSolarDetails = debounce(async () => {
  const payload = {
    latitude: props.addressData.LAT
      ? props.addressData.LAT
      : props.addressData.lat,
    longitude: props.addressData.LNG
      ? props.addressData.LNG
      : props.addressData.lng,
    width: panelWidth.value,
    height: panelHeight.value,
    response_version: "v1",
  };
  await getPropertiesMapData(payload);
  wantGoogleResponse.value = false;
  showSolarDetails();
}, 350);

const selectCustomLayer = (layer, isChecked) => {
  if (!layer.propertyMapLayerInfo.length) return;
  layer.propertyMapLayerInfo.map((data) => {
    const cord = parseJSON(data.coordinates);
    if (data.type === PropertyMapLayerType.MEASURE)
      showPolyLines(layer, cord, isChecked);
    else showPolyGons(layer, cord, isChecked);
  });
};

watch([panelWidth, panelHeight], debouncedShowSolarDetails);

const onZoomIn = () => {
  if (googleMap.value) googleMap.value.setZoom(googleMap.value.getZoom() + 1);
};
const onZoomOut = () => {
  if (googleMap.value) googleMap.value.setZoom(googleMap.value.getZoom() - 1);
};
const onFullScreenRequest = () => {
  document.addEventListener("keydown", (event) => {
    if (isFullScreen.value && event.key === "Escape") {
      exitFullscreen();
      isFullScreen.value = false;
      return;
    }
  });

  if (isFullScreen.value) {
    exitFullscreen();
  } else {
    enterFullscreen();
  }
  isFullScreen.value = !isFullScreen.value;
};
const enterFullscreen = () => {
  const mapContainer = map.value;
  if (mapContainer.requestFullscreen) {
    mapContainer.requestFullscreen();
  } else if (mapContainer.mozRequestFullScreen) {
    // Firefox
    mapContainer.mozRequestFullScreen();
  } else if (mapContainer.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    mapContainer.webkitRequestFullscreen();
  } else if (mapContainer.msRequestFullscreen) {
    // IE/Edge
    mapContainer.msRequestFullscreen();
  }
};

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    // Chrome, Safari and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    // IE/Edge
    document.msExitFullscreen();
  }
};

const saveNewLayer = async () => {
  if (propertyLayers.value.length === 0) {
    isSaveNewLayer.value = false;
  }
  if (!isSaveNewLayer.value) {
    if (!layerName.value.trim()) return;
    const payload = {
      name: layerName.value,
      latLang: {
        lat: props.addressData.LAT
          ? props.addressData.LAT
          : props.addressData.lat,
        lng: props.addressData.LNG
          ? props.addressData.LNG
          : props.addressData.lng,
      },
      propertyId: props.propertyId,
    };
    const property = await store.dispatch(
      `${PROPERTY_STORE}/createPropertyNewLayer`,
      payload
    );

    if (propertyLayers.value.length === 0) {
      const layerDetails = [];
      polyLines.value.map((line) => {
        const record = {
          propertyLayerId: property.data.id,
          type: PropertyMapLayerType.MEASURE,
          coordinates: line.coordinates,
          meta: {
            distance: line.distance,
          },
        };
        layerDetails.push(record);
      });
      polygonsData.value.map((polyGon) => {
        const record = {
          propertyLayerId: property.data.id,
          type: PropertyMapLayerType.SHAPES,
          coordinates: polyGon.coordinates,
          meta: {
            perimeter: polyGon.perimeter,
            area: polyGon.area,
          },
        };
        layerDetails.push(record);
      });

      await store.dispatch(`${PROPERTY_STORE}/createPropertyLayerData`, {
        layerDetails,
      });

      propertyLayers.value.forEach((layer) => {
        if (layer.id === selectedLayerId.value) selectCustomLayer(layer, false);
      });

      resetLayer();
    }
    layerName.value = "";
    await getPropertyMapLayers();
  } else {
    const layerDetails = [];
    polyLines.value.map((line) => {
      const record = {
        propertyLayerId: selectedLayerId.value,
        type: PropertyMapLayerType.MEASURE,
        coordinates: line.coordinates,
        meta: {
          distance: line.distance,
        },
      };
      layerDetails.push(record);
    });

    polygonsData.value.map((polyGon) => {
      const record = {
        propertyLayerId: selectedLayerId.value,
        type: PropertyMapLayerType.SHAPES,
        coordinates: polyGon.coordinates,
        meta: {
          perimeter: polyGon.perimeter,
          area: polyGon.area,
        },
      };
      layerDetails.push(record);
    });

    await store.dispatch(`${PROPERTY_STORE}/createPropertyLayerData`, {
      layerDetails,
    });

    propertyLayers.value.forEach((layer) => {
      if (layer.id === selectedLayerId.value) selectCustomLayer(layer, false);
    });

    let response = await store.dispatch(
      `${PROPERTY_STORE}/getPropertyLayerData`,
      selectedLayerId.value
    );

    propertyLayers.value = propertyLayers.value.map((layer) => {
      if (layer.id === selectedLayerId.value) {
        return {
          ...layer,
          propertyMapLayerInfo: response.propertyMapLayerInfo,
          selected: false,
          polyLines: [],
          polyGons: [],
          markers: [],
          overlayMarkers: [],
        };
      }
      return layer;
    });

    resetLayer();
  }

  toggleLayerModal();
};
const toggleLayerModal = () => {
  isOpenLayerModal.value = !isOpenLayerModal.value;
  isSaveNewLayer.value = false;
  selectedLayerId.value = null;
};
const toggleSaveLayer = () => {
  isOpenLayerModal.value = true;
  isSaveNewLayer.value = true;
};
const resetLayer = () => {
  if (polygonsData.value.length) {
    polygonsData.value.forEach((polygon) => toRaw(polygon.data).setMap(null));
    polygonsData.value.forEach((polygon) => {
      polygon.markers.forEach((marker) => toRaw(marker).setMap(null));
    });
  }
  if (polyLines.value.length) {
    polyLines.value.forEach((line) => toRaw(line.data).setMap(null));
    polygonsData.value.forEach((line) => {
      line.markers.forEach((marker) => toRaw(marker).setMap(null));
    });
  }
  polygonsData.value = [];
  polyLines.value = [];
  isShowMeasure.value = false;
  isShowShapes.value = false;
  toggleDrawingManagerData();
  selectMetaDataLayer.value = null;

  removeMarkers();

  displayMapMarkers.value.forEach((marker) => toRaw(marker).setMap(null));
  displayMapMarkers.value = [];

  overlayMarkers.value.forEach((overlay) => {
    overlay.onRemove();

    overlay.setMap(null);
  });
  overlayMarkers.value = [];
};
const selectedDeleteLayer = ref(null);
const toggleConfirmationModel = (layer) => {
  selectedDeleteLayer.value = layer;
  isOpenConfirmationModel.value = !isOpenConfirmationModel.value;
};
const deleteLayer = async () => {
  isOpenConfirmationModel.value = true;
  await store.dispatch(
    `${PROPERTY_STORE}/deletePropertyLayer`,
    selectedDeleteLayer.value.id
  );
  propertyLayers.value.forEach((layer) => {
    if (layer.id === selectedDeleteLayer.value.id)
      selectCustomLayer(layer, false);
  });
  propertyLayers.value = propertyLayers.value.filter(
    (layer) => layer.id !== selectedDeleteLayer.value.id
  );
  toggleConfirmationModel(null);
};

const getCacheData = (key) => {
  const cacheItem = cachedData.value.find((item) => item.key === key);
  return cacheItem ? cacheItem.data : null;
};
const setCacheData = (key, data) => {
  const existingIndex = cachedData.value.findIndex((item) => item.key === key);
  if (existingIndex !== -1) {
    cachedData.value[existingIndex].data = data;
  } else {
    cachedData.value.push({ key, data });
  }
};
const addOverlayLayer = (position1, position2, text) => {
  const overlay = new google.maps.OverlayView();

  overlay.onAdd = function () {
    const div = document.createElement("div");

    div.style.position = "absolute";
    div.style.background = "white";
    div.style.padding = "5px";
    div.style.borderRadius = "3px";
    div.style.fontWeight = "bold";
    div.style.color = "black";
    div.style.whiteSpace = "nowrap";
    div.innerHTML = text;
    this.div = div;
    const panes = this.getPanes();
    panes.overlayLayer.appendChild(div);
  };

  overlay.draw = function () {
    // Convert lat/lng to pixel position
    const overlayProjection = this.getProjection();
    const startPixel = overlayProjection.fromLatLngToDivPixel(position1);
    const endPixel = overlayProjection.fromLatLngToDivPixel(position2);

    // Calculate the midpoint
    const midX = (startPixel.x + endPixel.x) / 2;
    const midY = (startPixel.y + endPixel.y) / 2;

    // Calculate the angle between the two points
    const deltaX = endPixel.x - startPixel.x;
    const deltaY = endPixel.y - startPixel.y;
    let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    if (angle < 0) {
      angle += 360;
    }
    const div = this.div;
    const divWidth = div.offsetWidth;
    const divHeight = div.offsetHeight;

    // Adjust label position
    div.style.left = `${midX - divWidth / 2}px`;
    div.style.top = `${midY - divHeight / 2}px`;
    div.style.zIndex = "200";

    // Rotate the label based on the angle
    div.style.transform = `rotate(${angle}deg)`;
    div.style.transformOrigin = "center center";
  };

  overlay.onRemove = function () {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
    this.setMap(null);
    this.div = null;
    this.map = null;
    this.panes = null;
    this.projection = null;
  };

  overlay.setMap(googleMap.value);
  return overlay;
};

const setSolarDataLayerUrlList = async () => {
  try {
    if (!propertyMapDetails.value?.boundingBox) return;
    loading.value = true;
    const ne = propertyMapDetails.value?.boundingBox?.ne;
    const sw = propertyMapDetails.value?.boundingBox?.sw;
    const diameter = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(ne.latitude, ne.longitude),
      new google.maps.LatLng(sw.latitude, sw.longitude)
    );

    const radius = Math.ceil(diameter / 2);
    solarDataLayerUrlList.value = await propertyService.getDataLayerUrls({
      latitude:
        propertyMapDetails.value?.center?.latitude || props.addressData.lat,
      longitude:
        propertyMapDetails.value?.center?.longitude || props.addressData.lng,
      radius_meters: radius,
    });
  } catch (error) {
    console.log("get solar layer error", error);
  } finally {
    loading.value = false;
  }
};

const getFilterSolarLayerIds = computed(() => {
  const datalistLayerNames = dataLayerList.map((layer) => layer.tiffId);
  const transformedData = [];
  if (solarDataLayerUrlList.value)
    for (const [key, value] of Object.entries(solarDataLayerUrlList.value)) {
      if (datalistLayerNames.includes(key)) {
        if (Array.isArray(value)) {
          transformedData[key] = value.map((url) => url.split("id=")[1]);
        } else if (typeof value === "string") {
          transformedData[key] = value.split("id=")[1];
        }
      }
    }
  return transformedData;
});
const showTiffLayerOnMap = async () => {
  try {
    loading.value = true;

    const tiffLayerUrl =
      getFilterSolarLayerIds.value[selectedSolarLayer.value?.tiffId];
    if (!tiffLayerUrl) return;

    solarLayer.value = await useSolarLayer().getLayer(
      selectedSolarLayer.value.tiffId,
      getFilterSolarLayerIds.value
    );

    const month = selectedSolarLayer.value.tiffId == "hourlyShadeUrls" ? 3 : 0;
    const day = 14;
    overlays.value = solarLayer.value
      .render(true, month, day)
      .map(
        (canvas) =>
          new window.google.maps.GroundOverlay(
            canvas.toDataURL(),
            solarLayer.value.bounds
          )
      );

    if (
      !["monthlyFluxUrl", "hourlyShadeUrls"].includes(
        selectedSolarLayer.value.tiffId
      )
    ) {
      overlays.value[0].setMap(googleMap.value);
    } else {
      showCustomRange.value = true;
      overlays.value[0].setMap(googleMap.value);
      let index = 0;
      solarLayerInterval.value = setInterval(() => {
        index = panelCustomIndex.value;
        overlays.value[index]?.setMap(googleMap.value);
        index = index === overlays.value.length - 1 ? 0 : index + 1;
        panelCustomIndex.value = index;
      }, 1000);
    }
  } catch (e) {
    console.log("error", e);
  } finally {
    loading.value = false;
    centerMapOnLocation();
  }
};

const clearSolarDataLayer = () => {
  showCustomRange.value = false;
  panelCustomIndex.value = 0;
  clearInterval(solarLayerInterval.value);

  if (overlays.value?.length) {
    overlays.value.map((overlay) => overlay.setMap(null));
  }
  overlays.value = [];

  if (!selectedSolarLayer.value?.tiffId) {
    selectedSolarLayer.value = dataLayerList[0];
  }
};

const showSolarLayers = async (isShowToast) => {
  try {
    if (isShowToast && !solarDataLayerUrlList.value) {
      displayToastMessage(
        internalInstance,
        `No data available for ${
          selectedSpecialPolygon.value?.label || "selected solar data layer"
        }.`,
        "info"
      );
    }

    clearSolarDataLayer();
    clearSpecialPolygonLayers();
    selectedSpecialPolygon.value = null;
    await showTiffLayerOnMap();
  } catch (error) {
    console.log("get tiff layer error", error);
  }
};

const updateCustomSlider = () => {
  overlays.value[panelCustomIndex.value].setMap(googleMap.value);
};

const clearSpecialPolygonLayers = () => {
  specialPolygonMarker.value.forEach((marker) => {
    toRaw(marker).setMap(null);
  });
  specialPolygonMarker.value = [];

  if (specialPolyGonInstanceArr.value.length) {
    specialPolyGonInstanceArr.value.forEach((polygon) => {
      toRaw(polygon).setMap(null);
    });
    specialPolyGonInstanceArr.value = [];
  }
};

const showSpecialPolygon = async () => {
  try {
    if (!selectedSpecialPolygon.value) {
      showSolarLayers(false);
      return;
    }
    selectedSolarLayer.value = null;
    clearSolarDataLayer();
    clearSpecialPolygonLayers();
    await showTiffLayerOnMap();
    loading.value = true;
    if (!selectedSpecialPolygon.value?.polygonId) return;
    const cacheData = getCacheData(selectedSpecialPolygon.value.polygonId);
    const response = cacheData
      ? cacheData
      : await propertyService.getSpecialPolygon({
          uprn: props.addressData.uprn,
          polygonType: selectedSpecialPolygon.value.polygonId,
        });
    setCacheData(selectedSpecialPolygon.value.polygonId, response);
    const polygonsData = response.data.results;
    const bounds = new window.google.maps.LatLngBounds();

    if (polygonsData.length) {
      for (const polygon of polygonsData) {
        const polygonCoordsMapped = [];
        const points = polygon.polygon_points[0][0];
        points.forEach((point, index) => {
          if (index % 3 === 0) {
            const latLng = {
              lat: parseFloat(point[1]),
              lng: parseFloat(point[0]),
            };
            // const marker = addMarker(latLng);
            // toRaw(specialPolygonMarker.value).push(marker);
            polygonCoordsMapped.push(latLng);
            bounds.extend(latLng);
          }
        });
        const newPolygon = new window.google.maps.Polygon({
          paths: polygonCoordsMapped,
          strokeColor: selectedSpecialPolygon.value?.strokeColor || "#A30000",
          strokeOpacity: 1,
          strokeWeight: 3,
          fillColor: selectedSpecialPolygon.value?.fillColor || "#FF2E2E",
          fillOpacity: 0.7,
          zIndex: 2,
        });

        toRaw(specialPolyGonInstanceArr.value).push(newPolygon);
        newPolygon.setMap(googleMap.value);
      }
      googleMap.value.fitBounds(bounds);
    } else {
      displayToastMessage(
        internalInstance,
        `No data available for ${
          selectedSpecialPolygon.value?.label || "selected special polygon"
        }.`,
        "info"
      );
      centerMapOnLocation();
    }
  } catch (error) {
    console.log("error while displaying special polygons", error);
    displayToastMessage(
      internalInstance,
      `No data available for ${
        selectedSpecialPolygon.value?.label || "selected special polygon"
      }.`,
      "info"
    );
  } finally {
    loading.value = false;
  }
};
onMounted(async () => {
  try {
    const payload = {
      latitude: props.addressData.LAT
        ? props.addressData.LAT
        : props.addressData.lat,
      longitude: props.addressData.LNG
        ? props.addressData.LNG
        : props.addressData.lng,
      width: panelWidth.value,
      height: panelHeight.value,
      response_version: "v1",
    };

    await loader.load();
    initializeMap();
    await getPropertyPolygonData();
    await getPropertiesMapData(payload);
    await getPropertyMapLayers();
    await setSolarDataLayerUrlList();
    loadMap();

    await showSolarLayers(false);
  } catch (error) {
    console.log("Failed to load Google Maps API or fetch user location", error);
  }
});

onUnmounted(() => {
  // Remove event listeners and clear map
  if (googleMap.value) {
    window.google.maps.event.clearInstanceListeners(googleMap.value);
    googleMap.value = null;

    clearInterval(solarLayerInterval.value);
    showCustomRange.value = false;
    panelCustomIndex.value = 0;
  }
});
</script>
<style lang="scss" scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "Mulish", sans-serif !important;
  .map {
    width: 100%;
    height: 100%;
  }
  .map__content {
    padding: 20px;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: unset !important;
    .left__block {
      position: absolute;
      left: 20px;
      top: 20px;
      .data_layer__details {
        padding: 10px;
        box-shadow: 0px 0px 4px 2px #0000000f;
        border-radius: 8px;
        width: 195px;
        height: 100%;
        min-height: 150px;
        background-color: rgba($white, 1);
        display: flex;
        gap: 10px;
        flex-direction: column;
        .data_layer__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba($black, 0.1);
          h6 {
            @include fluidFont(12, 12, 1.2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            letter-spacing: 0.15px;
          }
        }
        .data_layer__description {
          p {
            @include fluidFont(10, 10, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.12px;
            text-align: left;
          }
        }
        .data_layer__footer {
          .modal__weather {
            height: 7px;
            width: 100%;
            background: linear-gradient(
              90deg,
              #3e52b0 0%,
              #7ecff7 24.01%,
              #71be6c 49.01%,
              #fadf81 76.01%,
              #e7473b 100%
            );
          }
          .modal__temperature {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              @include fluidFont(10, 10, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
            }
          }
        }
      }
    }
  }
  .right__block {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 20px;
    top: 20px;

    .map-settings {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 30px;
      .v-icon {
        width: 27px;
        height: 27px;
        min-width: 27px;
        max-width: 27px;
        background-color: rgba($white, 1);
        box-shadow: 0px 0px 27px 0px #0c0f4a1a;
        border-radius: 6px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .hide-map-settings {
      right: 0;
      left: auto;
      position: absolute;
    }
    .custom-column {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      max-width: 100%;
      .custom__column {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .sidebar-content {
          height: auto;
          max-height: 400px;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 10;
          transition: transform 0.3s ease;
          background-color: rgba($white, 1);
          border-radius: 8px;
          overflow-x: hidden;
          overflow-y: auto;
          width: 100%;
          max-width: 100%;
          position: relative;
          font-family: "Mulish", sans-serif !important;
        }
      }
    }
  }
}

.sidebar-content {
  height: 100%;
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: rgba($cardBorder, 1);
    border-radius: 8px;
    h5 {
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      font-family: "Mulish", sans-serif !important;
      text-align: left;
    }
    .button {
      min-height: auto;
      height: auto;
      padding: 4px 10px;
      border-radius: 4px;
      &:hover {
        :deep(.v-btn__content) {
          color: rgba($orange, 1);
        }
      }
      :deep(.v-btn__content) {
        @include fluidFont(10, 10, 1.2);
        color: rgba($blueDark, 1);
        font-weight: 400;
        text-transform: capitalize;
        letter-spacing: normal;
      }
    }
    .v-icon {
      color: rgba($blueDark, 1);
      @include fluidFont(14, 14, 1.2);
    }
    .custom__chip {
      @include fluidFont(10, 10, 1.2);
      font-weight: 400;
      color: rgba($white, 1);
      background-color: rgba($orange, 1);
      padding: 4px 10px;
      border-radius: 4px;
    }
  }
  .content-wrapper {
    text-align: right;
    max-height: 200px;
    overflow: auto;
    .content-selector {
      .content-item {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 8px 16px;
        border-bottom: 1px solid rgba($blueDark, 0.1);
        text-align: left;
        .v-icon {
          @include fluidFont(14, 14, 1);
        }
        .v-input {
          :deep(.v-input__control) {
            .v-selection-control {
              min-height: auto;
              .v-selection-control__wrapper {
                min-height: auto;
                width: auto;
                height: auto;
                .v-switch__track {
                  height: 14px;
                  min-width: 26px;
                  padding: 0;
                  opacity: 1;
                }
                .v-selection-control__input {
                  width: 14px;
                  height: 14px;
                  .v-icon {
                    @include fluidFont(14, 14, 1);
                  }
                  .v-switch__thumb {
                    width: 11px;
                    height: 12px;
                    background-color: rgba($white, 1) !important;
                    left: 5px;
                    right: auto;
                  }
                }
              }
            }
            .v-selection-control--dirty {
              .v-selection-control__wrapper {
                .v-selection-control__input {
                  .v-switch__thumb {
                    left: -4px;
                  }
                }
              }
            }
          }
        }
        span {
          color: rgba($blueDark, 1);
          @include fluidFont(12, 12, 1.3);
          display: inline-block;
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .accordion-wrapper {
      :deep(.v-expansion-panels) {
        .v-expansion-panel {
          .v-expansion-panel__shadow {
            box-shadow: none;
          }
          .v-expansion-panel-title {
            @include fluidFont(12, 12, 1.2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            padding: 16px;
            min-height: auto;
            .v-expansion-panel-title__overlay {
              display: none;
            }
          }
          .v-expansion-panel-text {
            padding: 0 16px 16px;
            .v-expansion-panel-text__wrapper {
              padding: 0;
              .panel-control-wrapper {
                .panel-control-list {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  span {
                    @include fluidFont(12, 12, 1.3);
                    font-weight: 500;
                    color: rgba($blueDark, 1);
                  }
                }
              }
              .panel-size-wrapper {
                margin-top: 24px;
                .v-form {
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                  align-items: flex-start;
                  .input_field {
                    width: 100%;
                    max-width: 100%;
                    .v-input__control {
                      .v-field {
                        padding: 0;
                        border-radius: 8px;
                        border: 1px solid rgba($buttonText, 0.4);
                        background-color: transparent;
                        .v-field__field {
                          .v-field__input {
                            column-gap: 7px;
                            padding: 8px 16px;
                            min-height: auto;
                            @include fluidFont(12, 12, 1.3);
                            font-weight: 500;
                            color: rgba($blueDark, 1);
                            &::placeholder {
                              color: rgba($blueDark, 1);
                              @include fluidFont(12, 12, 1.3);
                              font-weight: 500;
                            }
                          }
                          .v-text-field__suffix {
                            padding: 8px 16px;
                            min-height: auto;
                            @include fluidFont(12, 12, 1.3);
                            font-weight: 500;
                            color: rgba($blueDark, 1);
                          }
                        }
                        .v-field__outline {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidebar-hidden {
  justify-content: end;
  .overlay_bg {
    display: none;
  }
  .custom-column {
    display: none;
  }
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}
.tilt-button {
  position: absolute;
  top: 60%;
  left: 9px;
  padding: 10px;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 5;
  height: 40px;
  width: 40px;
}

.layer__wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba($white, 1);
  box-shadow: 0px 0px 4px 2px #0000000f;
  border-radius: 4px;
  width: 225px;
  z-index: 10;
  .layer__header {
    text-align: left;
    width: 100%;
    max-width: 100%;
    padding: 8px 10px;
    border-bottom: 1px solid rgba($blueDark, 0.1);
    h5 {
      @include fluidFont(12, 12, 1.3);
      font-weight: 600;
      letter-spacing: 0.15px;
      color: rgba($blueDark, 1);
    }
  }
  .layer__body {
    width: 100%;
    max-width: 100%;
    text-align: left;
    padding: 8px 10px;
    label {
      @include fluidFont(10, 10, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.12px;
      display: inline-block;
      margin-bottom: 4px;
    }
    .input_field {
      margin: 0;
      --v-input-control-height: auto;
      :deep(.v-input__control) {
        .v-field {
          border: 1px solid rgba($blueDark, 0.2);
          border-radius: 4px;
          padding: 6px 12px;
          .v-field__field {
            .v-field__input {
              background-color: transparent;
              border: 0;
              border-radius: 0;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              min-height: auto;
              padding: 0;
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
    }
  }
  .layer__footer {
    padding: 8px 10px;
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    .button {
      padding: 4px 12px;
      min-height: auto;
      height: auto;
      border-radius: 5px;
      :deep(.v-btn__content) {
        @include fluidFont(10, 10, 1.2);
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}
.shape__btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  gap: 15px;
  align-items: center;
  .v-btn {
    width: 46px;
    height: 46px;
    border-radius: 4px;
    border: 1px solid rgba($blueDark, 0.1);
    box-shadow: none;
    padding: 0;
    :deep(.v-btn__content) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      span {
        @include fluidFont(6, 6, 1.2);
        color: rgba($blueDark, 1);
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: normal;
      }
    }
  }
  .button-orange,
  .button-purple-border {
    width: auto;
    height: auto;
    min-height: auto;
    padding: 8px 10px;
    :deep(.v-btn__content) {
      @include fluidFont(12, 12, 1.2);
      text-transform: capitalize;
      font-weight: 500;
    }
  }
}

.layer__details {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: rgba($white, 1);
  width: 230px;
  border-radius: 4px;
  margin: 0;
  .content-header {
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba($blueDark, 0.1);
    h5 {
      @include fluidFont(12, 12, 1.2);
      font-weight: 600;
      color: rgba($blueDark, 1);
    }
  }
  .content-wrapper {
    padding: 7px 10px;
    .inner__wrapper {
      .detail__content {
        display: flex;
        gap: 2px;
        align-items: center;
        span {
          @include fluidFont(10, 10, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.12px;
        }
      }
    }
  }
}

.layers__list {
  .v-select {
    --v-input-control-height: auto;
    :deep(.v-input__control) {
      .v-field {
        padding: 8px 12px;
        border: 1px solid rgba($blueDark, 0.2);
        align-items: center;
        .v-field__field {
          .v-field__input {
            padding: 0;
            min-height: auto;
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.12px;
            top: -5px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.12px;
            }
            .v-select__selection {
              .v-select__selection-text {
                display: inline-block;
                width: 100%;
                max-width: 100%;
                position: absolute;
                top: -3px;
              }
            }
          }
        }
        .v-field__outline {
          display: none;
        }
        .v-field__append-inner {
          .v-icon {
            @include fluidFont(16, 16, 1);
          }
        }
      }
    }
  }
}
.toggle-button.disabled {
  background-color: rgba($white, 0.4);
}
.toggle-button.disabled.active {
  background-color: rgba($orange, 1);
  .v-btn__content {
    .v-icon {
      img {
        filter: invert(100%) sepia(60%) saturate(7228%) hue-rotate(179deg)
          brightness(124%) contrast(115%);
      }
    }
    span {
      color: rgba($white, 1);
    }
  }
}
.bg__overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($blueDark, 0.3);
  z-index: 10;
}
.custom_layer__wrapper {
  .custom_data_layers {
    z-index: 1;
    border-radius: 8px;
  }
}
.slider__wrapper {
  padding: 10px;
  background-color: rgba($white, 1);
  border-radius: 8px;
  margin-top: 10px;
  .data__slider {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
    margin: 0;
    :deep(.v-input__prepend) {
      margin-inline-end: 0;
      width: 25px;
      .v-label {
        margin: 0;
        @include fluidFont(9, 9, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
        letter-spacing: 0.12px;
      }
    }
    :deep(.v-input__control) {
      width: 100%;
      max-width: 100%;
      .v-slider__container {
        .v-slider-thumb {
          .v-slider-thumb__surface {
            background-color: rgba($orange, 1);
            border-radius: 2px;
            width: 11px;
            height: 11px;
            box-shadow: none;
          }
        }
        .v-slider-track {
          .v-slider-track__background {
            background-color: rgba($blueDark, 0.12) !important;
            height: 3px !important;
          }
          .v-slider-track__fill {
            background-color: rgba($blueDark, 0.12) !important;
            height: 3px !important;
          }
        }
      }
    }
  }
}
</style>
