<template>
  <commonDialog
    v-if="isShowPropertyOwnerWS"
    :HeaderTittle="currentSelectedTab?.header"
    :title="currentSelectedTab?.title"
    :description="currentSelectedTab?.description"
    :onClickBackButton="onClickBackButton"
    :className="['propertyowner_workstation_modal']"
  >
    <template v-slot:body>
      <div v-if="currentSelectedTab?.id == 3" class="modal__inner__body">
        <TermsAndCondition />
        <!-- <div class="tw-flex tw-flex-col tw-gap-3 tw-mt-6">
          <Text class="rmd:!tw-text-lg" variant="h6"
            >General Platform Usage</Text
          >
          <Text
            variant="p"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            class="tw-max-w-[82%]"
            >Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.</Text
          >
        </div> -->
        <div class="tw-mt-10">
          <v-checkbox class="checkbox__btn" v-model="acceptAggreement">
            <template v-slot:label>
              <v-icon v-if="acceptAggreement" color="#faa500">
                mdi-check-circle
              </v-icon>
              <v-icon v-else> mdi-circle-outline </v-icon>
              <p class="tw-flex tw-items-center tw-gap-1">
                I accept & agree to the
                <span class="yellow-text">Terms of Service</span>
              </p>
            </template>
          </v-checkbox>
          <Button
            class="!tw-w-auto tw-mt-6"
            label="NEXT"
            @click="onClickTermAndConditionNext"
            :isActive="acceptAggreement"
            :isLoading="isWorkStationCreated"
          ></Button>
        </div>
      </div>
      <div
        v-if="currentSelectedTab?.id == 2"
        class="modal__carousel__content md:!tw-h-[auto] md:!tw-mt-4"
      >
        <div
          class="onboarding-steps-content__upload tw-gap-8 !tw-h-auto md:!tw-gap-0"
        >
          <div
            class="uploade-wrapper !tw-mt-0 !tw-mb-0 !tw-w-[150px] !tw-h-[150px] !tw-min-w-[150px] !tw-min-h-[150px] md:!tw-w-[120px] md:!tw-h-[120px] md:!tw-min-w-[120px] md:!tw-min-h-[120px] md:!tw-my-[25px] md:!tw-mx-auto md:!tw-mt-0"
          >
            <v-file-input
              class="upload_file md:!tw-w-[120px] md:!tw-h-[120px]"
              append-inner-icon="mdi-image-plus"
              variant="solo-filled"
              :class="{ 'added-img': imageUrl }"
              @change="onFileChange"
              @click:clear="clearImageUrl"
            >
            </v-file-input>
            <v-img
              class="uploaded-img"
              :width="200"
              aspect-ratio="16/9"
              cover
              :src="imageUrl"
            ></v-img>
          </div>
          <div class="next_step_btns">
            <v-btn
              class="button button-orange w-full traking"
              :disabled="isContinueDisabled"
              :loading="isImageUpload"
              @click.enter="onClickSaveImage"
            >
              save
            </v-btn>
            <div class="skip__wrapper">
              <span>No Image ?</span>
              <v-btn
                class="skip__btn"
                variant="plain"
                @click.enter="onClickSaveImage"
                append-icon="mdi-chevron-right"
              >
                skip for now
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="currentSelectedTab?.id == 1"
        class="modal__carousel__content md:tw-gap-12 md:!tw-h-full"
      >
        <div class="custom__modal__sub__content">
          <label>Name</label>
          <v-form :rules="validation" ref="tradingNameRef">
            <v-text-field
              :rules="validation.tradingName"
              density="compact"
              placeholder="ie. Sparks Buildings"
              v-model="tradingName"
              variant="outlined"
              class="c-input rounded-full"
            ></v-text-field>
          </v-form>
        </div>
        <v-btn
          class="button button-orange w-full"
          :disabled="isContinueNameDisabled"
          :isLoading="isWorkStationCreated"
          @click.enter="onClickContinue"
        >
          continue
        </v-btn>
      </div>
    </template>
    <template v-slot:footer>
      <v-stepper class="onboard-stepper" v-model="currentSelectedTab.id">
        <v-stepper-header class="onboard-stepper__header">
          <v-stepper-item
            class="onboard-stepper__item"
            v-for="step in modelDetails"
            :key="step?.id"
            :class="{ done: currentSelectedTab.id > step?.id }"
            :title="step?.tab"
            :value="step?.id"
          ></v-stepper-item>
        </v-stepper-header>
      </v-stepper>
    </template>
  </commonDialog>
  <commonDialog
    v-if="isShowSuccessFullModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    persistent
    :className="['solo-trader__last__step__box', 'workstation_create_modal']"
  >
    <template v-slot:body>
      <div
        class="custom__modal__heading tw-flex tw-flex-col tw-justify-center tw-items-center"
      >
        <div>
          <div class="header-title">
            Nice job,
            <span class="tw-ml-1"> {{ activeUserWorkstation?.name }}.</span>
          </div>
          <div class="header-sub-title">
            You have successfully created your Property Workstation.
          </div>
        </div>

        <div class="custom__modal__img">
          <img
            src="../../../../assets/images/ws-creation-success-image.png"
            alt="Create Workstation"
          />
        </div>
        <p class="description explore__desc">
          Time to unlock your property superpower!
        </p>
      </div>
      <v-btn
        class="button button-orange !tw-mt-[16px] rmd:!tw-mt-[24px]"
        size="large"
        block
        @click="onClickPropertyOwnerDashboard"
      >
        Explore 🚀
      </v-btn>
    </template>
  </commonDialog>
  <KnowYourPropertyComponentModal
    v-if="isShowknowpropertyModal"
    @onClickCloseWelcomeModal="onClickCloseWelcomeModal"
  />
  <CreateWorkstationPreviewModal
    v-if="isShowWSPreviewModal"
    @onClickClosePreviewButton="onClickClosePreviewButton"
  />
</template>

<script lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useRouter } from "vue-router";
import { countryinfo } from "@/core/utils/countryinfo";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL } from "@/config";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import KnowYourPropertyComponentModal from "@/modules/dashboard/components/KnowYourPropertyComponent.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CheckBlue from "@/core/components/icons/CheckBlue.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  BusinessDetailTypeEnum,
  WorkstationAccountTypeEnum,
} from "@/core/enums/RolesEnum";
import CreateWorkstationPreviewModal from "@/modules/workstation/propertyowner/pages/CreateWorkstationPreviewModal.vue";
import TermsAndCondition from "@/core/components/common/TermsAndCondition.vue";
import { PROPERTY_STORE } from "@/store/modules/property";

export default {
  components: {
    CommonDialog,
    KnowYourPropertyComponentModal,
    Button,
    // Text,
    // CheckBlue,
    CreateWorkstationPreviewModal,
    TermsAndCondition,
  },
  setup(props: any, ctx: any) {
    const router = useRouter();
    const store = useStore();
    const model = ref(0);
    const formRef = ref(null) as any;
    const isShowAddressData = ref(false);
    const isShowPhoneData = ref(false);
    const countryCode = ref(null) as any;
    const userPhone = ref("");
    const imageUrl = ref("");
    let imageData = ref("");
    const tradingName = ref("");
    const isWorkStationCreated = ref(false);
    const isImageUpload = ref(false);
    const isPhoneNumberSend = ref(false);
    const isActiveNextButton = ref(false);
    const isShowknowpropertyModal = ref(false);
    const acceptAggreement = ref(false);
    const isShowSuccessFullModal = ref(false);
    const isShowPropertyOwnerWS = ref(true);
    const createdWorkstation = ref(null) as any;
    const isShowWSPreviewModal = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const validation = ref({
      phone: [
        (v: string) => !!v || "Phone number is required",
        (v: string) => /^\+\d{1,3}\s?/.test(v) || "Please select country code.",
        (v: string) =>
          /^\+\d{1,3}\s?\d{10,14}$/.test(v) || "Invalid phone number.",
      ],
      tradingName: [(v: any) => !!v || "Trading name is required"],
    });
    const modelDetails = ref([
      {
        id: 1,
        tab: "Workstation Name",
        name: "Workstation Name",
        header: "Workstation Name",
        title: "How do you refer to your Workstation?",
        description: `Please let us know your preferred Workstation name`,
      },
      {
        id: 2,
        tab: "Workstation Logo",
        name: "Workstation Logo",
        header: "Upload Logo",
        title: "Upload Workstation Logo",
        description: `Please upload a logo that represents your business or an image that clearly shows your face.`,
      },
      {
        id: 3,
        tab: "Terms & Conditions",
        name: "Terms & Conditions",
        header: "Terms & Conditions",
        title: "Terms & Conditions",
        description: `Please read through and agree to the terms & conditions to access your iknowa platform.`,
      },
    ]);

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const currentSelectedTab = ref(modelDetails.value[0]);
    onBeforeMount(() => {
      countryCode.value = countryinfo.find(
        (country: any) => country.code === "GB"
      );
      userPhone.value = countryCode.value.dial_code + " " + userPhone.value;
    });
    const onClickBackButton = () => {
      const currentTabIndex = modelDetails.value.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      if (currentTabIndex === 0) {
        ctx.emit("onClickCloseTab");
      } else {
        currentSelectedTab.value = modelDetails.value[currentTabIndex - 1];
      }
    };

    const onClickContine = async () => {
      currentSelectedTab.value.header = "Phone Number";
      currentSelectedTab.value.title = "Submit Your Phone Number";
      currentSelectedTab.value.description =
        "Never miss a job that matches your requirements.";
      isShowPhoneData.value = true;
    };

    const handleCountryCode = () => {
      if (userPhone.value) {
        userPhone.value = "";
      }
      userPhone.value = countryCode.value + " " + userPhone.value;
    };

    const onClickCompleteOnboarding = async () => {
      try {
        isPhoneNumberSend.value = true;
        const isFormValid = await formRef.value.validate();
        if (!isFormValid.valid) return;
        await store.dispatch(`${USER_STORE}/verifyPhoneNumber`, {
          contactNo: userPhone.value,
        });
        await store.dispatch(`${USER_STORE}/updateUser`, {
          userId: user.value.id,
          userData: { hasAccountSetup: true },
        });
        isShowknowpropertyModal.value = true;
      } catch (error) {
        console.log("error:", error);
      } finally {
        isPhoneNumberSend.value = false;
      }
    };

    const onClickCloseWelcomeModal = () => {
      isShowknowpropertyModal.value = false;
      router.push("/dashboard");
    };

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];

        imageUrl.value = URL.createObjectURL(file);
        imageData.value = file;
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
    };

    const isContinueDisabled = computed(() => {
      return !imageUrl.value || isImageUpload.value;
    });

    const isContinueNameDisabled = computed(() => {
      return !tradingName.value;
    });

    const onClickSaveImage = async () => {
      currentSelectedTab.value = modelDetails.value[2];
    };

    const onClickContinue = async () => {
      currentSelectedTab.value = modelDetails.value[1];
    };

    const onClickTermAndConditionNext = async () => {
      // if (!imageData.value) return;
      try {
        if (!tradingName.value.length) return;
        const companyName = tradingName.value;
        isWorkStationCreated.value = true;
        await store.dispatch(`${USER_STORE}/updateUser`, {
          userId: user.value?.id,
          userData: { hasBusinessType: true },
        });
        let response = null;
        if (imageData.value) {
          isImageUpload.value = true;
          const formData = new FormData();
          formData.append("file", imageData.value);
          response = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            formData
          );
        }

        createdWorkstation.value = await store.dispatch(
          `${WORKSTATION}/createUserWorkstation`,
          {
            userId: user.value?.id,
            userWorkstation: {
              name: companyName,
              companyName,
              businessType: BusinessDetailTypeEnum.PROPERTY_OWNER,
              workstationAccountType: WorkstationAccountTypeEnum.HOMEOWNER,
            },
          }
        );

        if (response) {
          await store.dispatch(`${WORKSTATION}/updateUserWorkstation`, {
            userId: user.value?.id,
            userWorkstationId: createdWorkstation.value.id,
            payload: {
              profileImage: response?.attachment || null,
            },
          });

          // await store.dispatch(`${USER_STORE}/saveUserProfile`, {
          //   userId: user.value.id,
          //   profile: {
          //     profileImage: response?.attachment,
          //   },
          // });
          // await store.dispatch(
          //   `${USER_STORE}/saveUserImage`,
          //   response?.publicUrl
          // );
        }

        await store.dispatch(
          `${USER_STORE}/saveUserRole`,
          UserRolesEnum.PROPERTY_OWNER
        );
        await store.dispatch(`${USER_STORE}/initializeUserState`);
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
        await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
          userId: user.value?.id,
          userWorkstationId: createdWorkstation.value?.id,
        });
        await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        await store.dispatch(
          `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
          user.value.id
        );
        await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});
        isShowPropertyOwnerWS.value = false;
        isShowSuccessFullModal.value = true;
      } catch (error) {
        console.log("error:", error);
      } finally {
        isImageUpload.value = false;
        isWorkStationCreated.value = false;
      }
    };

    const onClickPropertyOwnerDashboard = () => {
      isShowSuccessFullModal.value = false;
      isShowWSPreviewModal.value = true;
      // router.push("/dashboard");
    };

    const onClickClosePreviewButton = () => {
      isShowWSPreviewModal.value = false;
      router.push("/dashboard");
    };
    const tradingNameRef = ref(null);

    return {
      modelDetails,
      onClickBackButton,
      currentSelectedTab,
      model,
      isShowAddressData,
      onClickContine,
      isShowPhoneData,
      handleCountryCode,
      countryCode,
      userPhone,
      countryinfo,
      onClickCompleteOnboarding,
      imageUrl,
      onFileChange,
      clearImageUrl,
      onClickSaveImage,
      isContinueDisabled,
      isImageUpload,
      isContinueNameDisabled,
      isPhoneNumberSend,
      isActiveNextButton,
      UserRolesEnum,
      user,
      formRef,
      validation,
      isShowknowpropertyModal,
      onClickCloseWelcomeModal,
      tradingName,
      tradingNameRef,
      onClickContinue,
      isWorkStationCreated,
      acceptAggreement,
      onClickTermAndConditionNext,
      isShowSuccessFullModal,
      isShowPropertyOwnerWS,
      onClickPropertyOwnerDashboard,
      createdWorkstation,
      activeUserWorkstation,
      isShowWSPreviewModal,
      onClickClosePreviewButton,
    };
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: block;
}
:deep(.v-stepper-item) {
  width: 100%;
  max-width: 25px;
}
:deep(.v-stepper-item__content) {
  display: none;
}

:deep(.v-stepper-item--selected) {
  max-width: unset;
  .v-stepper-item__content {
    display: inline-block;
  }
}

@media screen and (min-width: 568px) {
  :deep(.v-stepper-item) {
    max-width: unset;
  }
  :deep(.v-stepper-item__content) {
    display: inline-block;
  }
}
:deep .checkbox__btn .v-selection-control__wrapper {
  display: none;
}

:deep .checkbox__btn input[type="radio"]:checked + .v-icon {
  color: #faa500;
}
@import "@/modules/owner-onboarding/styles/OwnerOnboarding.scss";
</style>
