<template>
  <div class="main_project_preview md:!tw-box-border">
    <div class="left_block">
      <v-card class="project_type__overview_card">
        <div class="project_type__overview_card_content">
          <div class="heading">
            <h4 class="title">Project Overview</h4>
          </div>
          <div class="project_type__overview_card_text">
            <div class="project_type__overview_card_text_item">
              <div class="project_type__overview_list">
                <h6>Address</h6>
                <p>{{ propertyDetails?.address }}</p>
              </div>
            </div>
            <div class="project_type__overview_card_text_item">
              <div class="heading">Project Details</div>
              <div class="project_type__overview_list">
                <h6>Project Type</h6>
                <p>{{ propertyDetails?.propertyType }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="inner__content">
          <div class="title">
            <h5>Estimate</h5>
          </div>
          <div class="estimate_item">
            <h6>Start Date</h6>
            <p>{{ suggestedStartDate }}</p>
          </div>
          <div class="estimate_item">
            <h6>Total Phases</h6>
            <p>{{ totalPhaseLength }}</p>
          </div>
          <div class="estimate_item">
            <h6>Total Tasks</h6>
            <p>{{ totalTasks }}</p>
          </div>
          <div class="estimate_item">
            <h6>Duration</h6>
            <p>{{ totalDuration }}</p>
          </div>
          <div class="estimate_item">
            <h6>Total Cost</h6>
            <p>{{ totalCost }}</p>
          </div>
        </div>
        <v-btn
          class="button button-orange"
          @click="onSaveRecord"
          width="100%"
          max-width="100%"
          :loading="isLoadingOnAttachments"
          :disabled="isLoadingOnAttachments"
          >{{
            workflowType === HistoricalWorkFlowTypeEnum.CLIENT
              ? "Submit"
              : "save record"
          }}</v-btn
        >
      </v-card>
    </div>
    <div class="right_block">
      <div class="top_links_main">
        <div class="back_results">
          <v-btn
            prepend-icon="mdi-chevron-left"
            variant="plain"
            :disabled="isLoadingOnAttachments"
            @click="onBack()"
            >Back</v-btn
          >
        </div>
      </div>
      <!-- Project Notes -->
      <div class="project_info__wrapper">
        <template v-if="!isEditProjectNoteSection">
          <v-card class="project_info">
            <div class="card_content">
              <div class="card_content__header">
                <div class="card_content__title">
                  <h5>{{ historicalProjectForm?.title }}</h5>
                </div>
                <v-icon
                  class="custom_edit"
                  icon="mdi-pencil"
                  @click.stop="toggleProjectNameNote()"
                ></v-icon>
              </div>

              <div class="card_content__footer">
                <h6>Project Notes</h6>
                <p v-html="historicalProjectForm?.description"></p>
              </div>
            </div>
          </v-card>
        </template>
        <template v-else>
          <v-card class="edit_project_info">
            <div class="title">
              <h6>Project Title</h6>
            </div>
            <v-text-field
              density="compact"
              placeholder="Enter Title"
              v-model="projectDetails.title"
              variant="outlined"
              class="property_input"
            ></v-text-field>

            <div class="description">
              <h6>Project Notes</h6>
              <div class="custom_editor">
                <quill-editor
                  v-model:value="projectDetails.description"
                  :options="state.editorOption"
                  :disabled="state.disabled"
                  class="editor_ql"
                />
              </div>
            </div>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                @click="toggleProjectNameNote"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="onClickSaveProjectNotes"
                prepend-icon="mdi-content-save"
              >
                save
              </v-btn>
            </div>
          </v-card>
        </template>
      </div>

      <!-- Specialism -->
      <div class="specialism__wrapper">
        <div class="header">
          <div class="title">
            <h4>Specialism</h4>
          </div>
          <v-icon
            class="custom_edit"
            v-if="!isEditSpecialismSection"
            icon="mdi-pencil"
            @click.stop="toggleSpecialismSection"
          ></v-icon>
        </div>
        <div class="specialism__wrapper__content">
          <div v-if="!categories.length">No Specialism</div>
          <div class="specialism-card-list">
            <div
              class="specialism-card-list__item"
              v-for="specialism in categories"
              :key="specialism.id"
            >
              <v-btn
                variant="text"
                aria-label="Delete"
                class="specialism-card-list__item-delete"
                prepend-icon="mdi-delete"
                v-if="isEditSpecialismSection"
                @click="onClickDeleteCategoryButton(specialism.id)"
              ></v-btn>

              <div class="specialism-card-list__item-author">
                <div class="specialism-card-list__item-author-header property">
                  <div class="speicalism__icon">
                    <img src="../../../assets/icons/house.svg" alt="" />
                  </div>
                  <h6>property</h6>
                  <v-icon icon="mdi-information-variant-circle-outline" />
                </div>
                <div class="specialism-card-list__item-author-content">
                  <div class="category_icon">
                    <img
                      v-if="specialism?.category?.iconName"
                      :src="
                        require(`@/assets/category-icons/${specialism?.category?.iconName}.svg`)
                      "
                      alt="Image"
                    />
                  </div>
                  <div class="category_text">
                    <h5>{{ specialism?.category?.tpLabel }}</h5>
                    <p>Rewiring</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isEditSpecialismSection" class="edit-mode-button">
          <v-btn
            class="button button-purple-border"
            variant="text"
            @click="onToggleSpecialismModal"
          >
            Add more Specialism
          </v-btn>
          <div class="edit__buttons">
            <v-btn
              class="button button-white"
              @click="cancelSaveSpecialism"
              variant="outlined"
            >
              cancel
            </v-btn>
            <v-btn
              class="button button-orange"
              :loading="isLoadingProjectData"
              @click="onClickSaveSpecialismButton"
            >
              save
            </v-btn>
          </div>
        </div>
      </div>

      <!-- Selected Contractors -->
      <div
        class="contractors_wrapper"
        v-if="
          historicalProjectForm.projectType ===
          HistoricalProjectTypeEnum.TRADE_VERIFY
        "
      >
        <div class="header">
          <div class="title">
            <h4 v-if="workflowType === HistoricalWorkFlowTypeEnum.CLIENT">
              Client
            </h4>
            <h4 v-else>Selected Contractors</h4>
          </div>
          <v-icon
            class="custom_edit"
            v-if="
              !isEditContractorSection &&
              workflowType !== HistoricalWorkFlowTypeEnum.CLIENT
            "
            icon="mdi-pencil"
            @click.stop="toggleContractorSection"
          ></v-icon>
        </div>
        <div v-if="!isEditContractorSection" class="contractors_content">
          <div
            class="tw-flex tw-gap-4 tw-items-center tw-justify-start trade_card"
          >
            <TradeNetworkCard
              class="trade_card_item"
              v-for="(
                contractor, index
              ) in historicalProjectForm.invitedContractors"
              :key="index"
              :contractor="contractor"
              :workflowType="workflowType"
            />
          </div>
        </div>
        <div v-else class="edit-mode-button">
          <div
            v-if="tradePreferredContractorList.length"
            class="tw-w-full tw-max-w-full"
          >
            <div
              class="tw-flex tw-gap-4 tw-items-center tw-justify-start trade_card trade-network-container tw-flex-wrap tw-w-full tw-max-w-full"
            >
              <TradeNetworkCard
                v-for="(contractor, index) in tradePreferredContractorList"
                :key="index"
                :contractor="contractor"
                class="trade_card_item tw-w-[calc(50%_-_8px)] tw-max-w-full"
                :class="{
                  '!tw-border-[2px] !tw-border-solid !tw-border-[#FFA500]':
                    checkIfContractorSelected(contractor.id),
                }"
                @click="selectContractor(contractor)"
              />
            </div>
          </div>

          <div>
            <div class="edit__buttons">
              <v-btn
                class="button button-white"
                @click="toggleContractorSection"
                variant="outlined"
              >
                cancel
              </v-btn>
              <v-btn
                class="button button-orange"
                @click="onClickSaveContractorButton"
              >
                save
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- Project Properties -->
      <div class="properties_wrapper">
        <div class="header">
          <div class="title">
            <h4>Properties</h4>
          </div>
        </div>
        <div class="properties_wrapper__content">
          <div class="properties_wrapper__left no-scrollbar">
            <div class="properties_wrapper__info">
              <div class="properties_wrapper__info__left">
                <div class="location">
                  <v-icon
                    icon="mdi mdi-map-marker"
                    class="pointer_events_none"
                  ></v-icon>
                  {{ propertyDetails?.postcode }}
                </div>

                <div class="prop_name">
                  <v-icon>
                    <img :src="require('@/assets/icons/house.svg')" alt="" />
                  </v-icon>
                  <h6>{{ propertyDetails?.address }}</h6>
                </div>
                <div class="property__info">
                  <div class="property__info__item">NA</div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">
                      {{
                        propertyDetails?.beds ? propertyDetails?.beds : "NA"
                      }}</span
                    >
                    bedroom
                  </div>
                  <div>|</div>
                  <div class="property__info__item">
                    <span class="pointer_events_none">NA</span>
                    sqm
                  </div>
                </div>
                <div class="property_type">
                  <v-icon
                    icon="mdi mdi-account-circle"
                    class="pointer_events_none"
                  ></v-icon
                  >Commercial
                </div>
              </div>
              <div class="properties_wrapper__info__right">
                <div class="property_image">
                  <img
                    :src="require('@/assets/images/property-sample-image.jpg')"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="properties_wrapper__right">
            <div class="map__container tw-rounded-xl tw-h-full">
              <PropertyMapModal
                :properties="parsedPropertyList"
                className="tw-h-full"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Documents / Attachments -->

      <div v-if="!loadingSelectProperty" class="documents_attachment">
        <div class="title">
          <h3>Documents / Attachments</h3>
          <v-select
            label="Select category"
            :items="getFilterCategory(projectAttachments)"
            item-title="userCategoryName"
            item-value="userCategoryName"
            multiple
            variant="outlined"
            density="compact"
            class="select_prop_dropdown"
            @update:modelValue="changeCategoryId"
            :menu-props="{ contentClass: 'custom__text__selector' }"
          >
          </v-select>
        </div>

        <v-expansion-panels v-model="panel" multiple class="accordion__wrapper">
          <v-expansion-panel
            v-for="(attachmentCategory, index) in cloneAttachmentDataList"
            :key="index"
          >
            <v-expansion-panel-title>
              <v-icon class="house__icon">
                <img src="../../../assets/icons/house.svg" alt="" />
              </v-icon>
              <h6>
                {{ attachmentCategory?.userCategoryName }}
              </h6>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  class="custom_edit"
                  v-if="expanded && !isEditAttachmentsSection.flag"
                  icon="mdi-pencil"
                  @click="updateAttachmentsSection(index)"
                ></v-icon>
                <v-icon
                  v-else
                  icon="mdi-chevron-down"
                  class="bottom__chevron"
                ></v-icon>
              </template>
            </v-expansion-panel-title>

            <div class="group__cards" v-if="isShowGroupCard(index)">
              <div class="group__card__text">
                <v-expansion-panel-text>
                  <div class="accordion_inner_title">
                    <div class="doc_main">
                      <div
                        class="doc_main__list"
                        v-for="attachment in attachmentCategory.attachments"
                        :key="attachment.id"
                      >
                        <!-- @click.stop="
                            onClickOpenNewTab(attachment.uploadedImages?.url)
                          " -->
                        <div class="property__img__name">
                          <v-icon icon="mdi mdi-image-outline"></v-icon>
                          <span>{{ attachment?.uploadedImages?.name }}</span>
                        </div>

                        <div
                          v-if="
                            isEditAttachmentsSection.flag &&
                            isEditAttachmentsSection.index === index
                          "
                          @click="
                            onClickRemoveAttachment(
                              attachment?.uploadedImages?.id
                            )
                          "
                        >
                          <v-icon
                            icon="mdi-close"
                            class="pointer_events_none"
                          ></v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      !(
                        isEditAttachmentsSection.flag &&
                        isEditAttachmentsSection.index === index
                      )
                    "
                    class="property__images"
                  >
                    <div
                      v-for="attachment in attachmentCategory.attachments?.slice(
                        0,
                        3
                      )"
                      :key="attachment.id"
                      class="property__item__images"
                    >
                      <img
                        :src="attachment?.uploadedImages?.url"
                        @click="openCarousel(index)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      isEditAttachmentsSection.flag &&
                      isEditAttachmentsSection.index == index
                    "
                    class="edit-mode-button"
                  >
                    <v-btn
                      class="button button-purple-border"
                      @click="onClickToggleAttachmentModal"
                    >
                      Upload More
                    </v-btn>
                    <div class="edit__buttons">
                      <v-btn
                        class="button button-white"
                        variant="outlined"
                        @click="onCancelAttachments"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="button button-orange"
                        size="large"
                        block
                        @click="onClickSaveButtonAttachments"
                      >
                        save
                      </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </div>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <CommonLoader
        class="common__loader__center"
        v-else
        :loading="loadingSelectProperty"
      />
    </div>

    <PropertySpecialismModal
      v-if="isShowSpecialismModal"
      :isReview="true"
      @onClickCloseTab="onClickCloseTab"
      @onClickSaveSpecialism="onClickSaveSpecialism"
    />

    <UploadProjectAttachmentsModal
      v-if="isShowProjectAttachmentUploadModal"
      :isPropertyOwner="true"
      :projectAttachments="projectAttachments"
      @on-close="onClickToggleAttachmentModal"
    />
    <!-- CarouselDialog -->
    <v-dialog
      v-model="isCarouselDialog"
      height="auto"
      max-width="600"
      class="project__carousal__modal"
      :show-arrows="false"
    >
      <v-card>
        <template v-slot:actions>
          <v-btn
            icon="mdi-close"
            @click="isCarouselDialog = false"
            class="d-flex"
          ></v-btn>
          <v-carousel
            hide-delimiter-background
            show-arrows="hover"
            v-model="currentIndex"
          >
            <v-carousel-item
              v-for="attachment in carouselImageList"
              :key="attachment.id"
            >
              <v-img
                cover
                :src="attachment?.url"
                height="100%"
                width="100%"
              ></v-img>
            </v-carousel-item>
          </v-carousel>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup>
import { computed, reactive, ref, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import TradeNetworkCard from "@/modules/properties/components/Cards/TradeNetworkCard.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { quillEditor } from "vue3-quill";
import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import PropertySpecialismModal from "@/modules/Specialism/components/PropertySpecialismModal.vue";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { USER_STORE } from "@/store/modules/user";
import UploadProjectAttachmentsModal from "./Modals/UploadProjectAttachmentsModal.vue";
import appFilters from "@/filters";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import {
  HistoricalProjectTypeEnum,
  HistoricalWorkFlowTypeEnum,
  ProjectSpecialismGeneratedEnum,
} from "@/core/enums/ProjectsEnum";
import SpecialismMultiSelectModel from "@/modules/Specialism/components/SpecialismMultiSelectModel.vue";
import PropertyMapModal from "./Modals/PropertyMapModal.vue";
const store = useStore();

const emits = defineEmits(["on-back", "on-save-record", "on-edit-details"]);
const props = defineProps({
  propertyDetails: {
    type: Object,
  },
  historicalProjectForm: {
    type: Object,
  },
  workflowType: { type: String, default: HistoricalWorkFlowTypeEnum.PROPERTY },
});
const isShowDescriptionEditor = ref(false);

const toggleShowDescriptionEditor = () => {
  isShowDescriptionEditor.value = !isShowDescriptionEditor.value;
};

const invitedContractors = computed(
  () => store.getters[`${PROJECT_STORE}/invitedContractors`]
);

const parsedPropertyList = computed(() => {
  if (props.propertyDetails) {
    return [props.propertyDetails];
  }
  return [];
});
const onBack = () => {
  emits("on-back");
};

const onSaveRecord = async () => {
  if (projectAttachments.value.length) {
    isLoadingOnAttachments.value = true;

    try {
      const formData = new FormData();
      projectAttachments.value.forEach((attachment) => {
        formData.append(`files`, attachment.uploadedImages.imageData);
      });
      const uploadMultipleImage = await store.dispatch(
        `${USER_STORE}/uploadMultipleImage`,
        formData
      );

      const attachmentCategoryData = [];

      for (const attachment of cloneAttachmentDataList.value) {
        const existCategory = attachmentCategoryData.find(
          (category) => category.label === attachment.userCategoryName
        );
        if (existCategory) {
          attachmentCategoryData.push(existCategory);
        } else {
          const response = await store.dispatch(
            `${PROJECT_STORE}/attachmentCategory`,
            {
              label: attachment.userCategoryName,
              value: attachment.userCategoryName,
            }
          );
          attachmentCategoryData.push(response);
        }
      }

      const attachments = uploadMultipleImage.map((item, index) => ({
        publicUrl: item.publicUrl,
        attachment: item.attachment,
        originalName: item.originalName,
        propertyIds: [
          {
            propertyId: props.propertyDetails?.id,
            isDeleted: false,
          },
        ],
        attachmentCategoryId: attachmentCategoryData[index]?.id,
        isDeleted: false,
      }));

      emits("on-save-record", attachments);
    } catch (error) {
      console.log();
    } finally {
      isLoadingOnAttachments.value = false;
    }
  }
};

const toggleProjectNameNote = () => {
  isEditProjectNoteSection.value = !isEditProjectNoteSection.value;
};
const projectDetails = ref({
  title: props.historicalProjectForm.title,
  description: props.historicalProjectForm.description,
});
const state = reactive({
  content: "",
  _content: "",
  editorOption: {
    placeholder: "Description here",
    modules: {},
  },
  disabled: false,
});
const isEditProjectNoteSection = ref(false);
const loading = ref(false);
const addresses = ref(null);
const specialismCategories = ref([]);
const specialismDeleteCategories = ref([]);
const cloneCategories = ref([]);

const onClickSaveProjectNotes = () => {
  emits("on-edit-details", projectDetails.value);
  toggleProjectNameNote();
};
const setPropertyAddress = () => {
  const addressList = [];
  const projectDataProperties = [props.propertyDetails];
  projectDataProperties &&
    projectDataProperties.map((item) => {
      let latlang = null;

      if (item.addressData) {
        const address =
          typeof item.addressData === "string"
            ? JSON.parse(item.addressData)
            : item.addressData;
        const lat = address?.lat ? address?.lat : address?.LAT;
        const lng = address?.lng ? address?.lng : address?.LNG;
        if (lat && lng) {
          latlang = [lat, lng];
          addressList.push({
            address: item.address,
            uprn: item.uprn,
            latlang,
            id: item.id,
            postcode: item.postcode,
          });
        }
      }
    });
  addresses.value = addressList;
};

const isEditSpecialismSection = ref(false);
const isEditContractorSection = ref(false);
const isEditAttachmentsSection = ref({
  flag: false,
  index: -1,
});
const isShowSpecialismModal = ref(false);
const isLoadingOnAttachments = ref(false);
const isShowProjectAttachmentUploadModal = ref(false);

const selectedContractors = ref([]);
const panel = ref([]);
// documents
const categoryId = ref(null);
const loadingSelectProperty = ref(false);
const isCarouselDialog = ref(false);
const currentIndex = ref(0);
const categories = ref([]);
const specialisms = computed(
  () => store.getters[`${PROJECT_STORE}/projectSpecialisms`]
);
const carouselImageList = ref([]);
const toggleSpecialismSection = () => {
  isEditSpecialismSection.value = !isEditSpecialismSection.value;
};
const toggleContractorSection = () => {
  isEditContractorSection.value = !isEditContractorSection.value;
};

const cancelSaveSpecialism = () => {
  categories.value = cloneCategories.value;
  store.dispatch(`${PROJECT_STORE}/setProjectSpecialisms`, categories.value);
  toggleSpecialismSection();
};
const projectAttachments = computed(
  () => store.getters[`${PROJECT_STORE}/projectAttachments`]
);
const onToggleSpecialismModal = () => {
  isShowSpecialismModal.value = !isShowSpecialismModal.value;
};
const onClickCloseTab = () => {
  onToggleSpecialismModal();
};
const onClickSaveSpecialism = async (payload) => {
  cloneCategories.value = JSON.parse(JSON.stringify(categories.value));
  specialismCategories.value = payload;

  const newAddedSubCategoriesData = await store.dispatch(
    `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
    {
      subCategoriesIds: specialismCategories.value,
      generatedBySpecialism: ProjectSpecialismGeneratedEnum.PROPERTY,
    }
  );
  categories.value = newAddedSubCategoriesData;
};
const onClickSaveSpecialismButton = async () => {
  try {
    store.dispatch(`${PROJECT_STORE}/setProjectSpecialisms`, categories.value);
    cloneCategories.value = JSON.parse(JSON.stringify(categories.value));
  } catch (error) {
    console.log();
  } finally {
    specialismCategories.value = [];
    specialismDeleteCategories.value = [];
    toggleSpecialismSection();
  }
};
const onClickSaveContractorButton = () => {
  toggleContractorSection();
};
const onClickDeleteCategoryButton = (categoryId) => {
  // store.dispatch(`${PROJECT_STORE}/removeProjectSpecialism`, {
  //   id: categoryId,
  // });
  specialismDeleteCategories.value.push(categoryId);
  categories.value = categories.value.filter((specialism) => {
    return specialism.id != categoryId;
  });
};

const onClickToggleAttachmentModal = (payload) => {
  isShowProjectAttachmentUploadModal.value =
    !isShowProjectAttachmentUploadModal.value;
  attachmentsData.value = projectAttachments.value;
  cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
};

const toggleEditAttachmentsSection = () => {
  isEditAttachmentsSection.value.flag = !isEditAttachmentsSection.value.flag;
};

const openCarousel = (idx = 0) => {
  isCarouselDialog.value = true;
  const carouselImages = [];
  cloneAttachmentDataList.value.map((data) => {
    data.attachments.map((attachment) => {
      carouselImages.push({ url: attachment?.uploadedImages?.url });
    });
    carouselImageList.value = carouselImages;
  });
  currentIndex.value = idx;
};
const onCancelAttachments = async () => {
  toggleEditAttachmentsSection();
  cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
};
const tradePreferredContractorList = computed(() => {
  return store.getters[`${PREFERRED_CONTRACTORS}/getPreferredContractorsList`];
});
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const initContractorList = async () => {
  await store.dispatch(`${PREFERRED_CONTRACTORS}/fetchPreferredContractors`, {
    userId: user.value.id,
  });
};
const checkIfContractorSelected = (id) => {
  return selectedContractors.value.some((contractor) => contractor.id === id);
};

const selectContractor = (event) => {
  const isExist = checkIfContractorSelected(event.id);

  // if (isExist) {
  //   selectedContractors.value = selectedContractors.value.filter(
  //     (contractor) => contractor.id !== event.id
  //   );
  // } else {
  //   if (!selectedContractors.value.length)
  //     selectedContractors.value.push(event);
  // }
  selectedContractors.value = [];
  selectedContractors.value.push(event);
  emits("on-select-contractor", selectedContractors.value);
};
const isShowGroupCard = (index) => {
  return panel.value.includes(index);
};

// Documents
const isEditPropertiesSection = ref(false);
const cloneAttachmentDataList = ref([]);
const attachmentsData = ref([]);
const updatePropertiesSection = () => {
  isEditPropertiesSection.value = true;
};
const updateAttachmentsSection = (index) => {
  isEditAttachmentsSection.value.flag = true;
  isEditAttachmentsSection.value.index = index;
  panel.value.push(index);
};
const getFilterCategory = (attachments) => {
  const grouped = attachments.reduce((acc, item) => {
    const { userCategoryName, uploadedImages, propertyId } = item;

    if (!acc[userCategoryName]) {
      acc[userCategoryName] = [];
    }

    acc[userCategoryName].push({ uploadedImages, propertyId });

    return acc;
  }, {});

  return Object.keys(grouped).map((key) => ({
    userCategoryName: key,
    attachments: grouped[key],
  }));
};

const onClickSaveButtonAttachments = async () => {
  await store.dispatch(
    `${PROJECT_STORE}/setProjectAttachments`,
    attachmentsData.value
  );
  cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
  toggleEditAttachmentsSection();
};
const onClickRemoveAttachment = (imgId) => {
  cloneAttachmentDataList.value = cloneAttachmentDataList.value.map(
    (category) => {
      return {
        userCategoryName: category.userCategoryName,
        attachments: category.attachments.filter(
          (attachment) => attachment.uploadedImages.id !== imgId
        ),
      };
    }
  );

  attachmentsData.value = attachmentsData.value.filter(
    (attachment) => attachment.uploadedImages.id !== imgId
  );
};
const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];

  return estimates;
});
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const suggestedStartDate = computed(() => {
  let startDate = "";

  // isReadOnly means PO received estimate else TP estimate form
  if (props.isReadOnly) {
    startDate = appFilters.formatToDate(
      props.selectedEstimate?.suggestedStartDate,
      "DD MMM YYYY"
    );
  } else {
    startDate = appFilters.formatToDate(
      formData.value.suggestedStartDate,
      "DD MMM YYYY"
    );
  }
  return startDate;
});
const totalPhaseLength = computed(() => {
  return estimateForm.value?.projectStages?.length || 0;
});
const totalTasks = computed(() => {
  let sum = 0;
  estimateForm.value?.projectStages.forEach((phase) => {
    sum += phase.projectStageTasks.length;
  });
  return sum;
});
const totalDuration = computed(() => {
  let totalDays = 0;
  totalDays = estimateForm.value.projectStages.reduce((sum, stage) => {
    if (stage.totalDuration) {
      const duration = parseFloat(stage.totalDuration);
      if (stage.durationType === 1) {
        return sum + duration;
      } else if (stage.durationType === 2) {
        return sum + duration * 7;
      }
    }

    return sum;
  }, 0);

  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
});
const changeCategoryId = (categories) => {
  if (categories.length > 0) {
    const filterData = projectAttachments.value.filter((attachement) =>
      categories.includes(attachement.userCategoryName)
    );

    cloneAttachmentDataList.value = getFilterCategory(filterData);
  } else {
    cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
  }
};
const totalSubTotal = computed(() => {
  const sum = estimateForm.value.projectStages.reduce((sum, phase) => {
    const roundedSubTotal = parseFloat(phase.subTotal);
    return sum + roundedSubTotal;
  }, 0);
  return Math.round(sum * 100) / 100;
});
const totalVat = computed(() => {
  const sum = estimateForm.value.projectStages.reduce((sum, phase, index) => {
    const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
    return sum + roundedTotalVat;
  }, 0);
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03;
  let totalWithServiceFee = estimateForm.value.projectStages.reduce(
    (sum, phase, index) => {
      const roundedTotalWithServiceFee = parseFloat(
        phase.subTotal * serviceFeePercentage
      );
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});
const totalCost = computed(() => {
  return CurrencyFilter.formatToCurrency(
    totalSubTotal.value + totalVat.value + serviceCharges.value
  );
});

onMounted(async () => {
  setPropertyAddress();
  await initContractorList();
  cloneAttachmentDataList.value = getFilterCategory(projectAttachments.value);
  attachmentsData.value = projectAttachments.value;
  categories.value = specialisms.value;
  cloneCategories.value = JSON.parse(JSON.stringify(categories.value));
});
</script>
<style lang="scss" scoped>
@import "@/modules/properties/styles/propertyReview.scss";
</style>
