<template>
  <CalenderView v-if="isShowCalender" />
  <div v-if="!isShowCalender && detailsItems" class="tab__area__wrapper">
    <label class="blue-text" @click="onClickBackCoursesButton"
      ><v-icon icon="mdi-chevron-left"></v-icon>Back to Courses
    </label>
    <div class="tab__area__blocks">
      <div class="tab__area__left__block tw-flex tw-flex-col tw-gap-4">
        <h4 class="tab__area__title">
          {{ detailsItems?.name }}
        </h4>
        <div
          class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-w-full rlg:tw-flex-row rlg:tw-items-center rlg:tw-justify-between"
          v-if="isEnrollRoute"
        >
          <EnrolledChipState :statusLabel="lastCourseStatus?.label" />
          <div
            class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-gap-2 rlg:tw-gap-4 rlg:tw-items-center rlg:tw-flex-row"
          >
            <div class="tw-flex tw-items-center tw-gap-2">
              <MapMarkerYellowIcon />
              <Text variant="span" textWeight="500">{{
                selectedEnrolledCourseScheduleAddress
              }}</Text>
            </div>
            <div class="tw-flex tw-items-center tw-gap-2">
              <MapMarkerBlueIcon />
              <Text variant="span" textColor="#264FD5" textWeight="400"
                >Online & In-Person</Text
              >
            </div>
          </div>
        </div>
        <div class="tab__area__buttons mobile">
          <!-- <v-btn class="button button-purple-border" block size="large">
            register interest for course
          </v-btn> -->
          <v-btn
            class="button button-orange !tw-mt-0"
            block
            size="large"
            @click="goToClender"
            :disabled="isSportDisable || !isEligible"
          >
            secure my spot
          </v-btn>
          <Text
            class="tw-mt-2"
            textAlign="center"
            lineHeight="18px"
            v-if="!isSportDisable && !isEligible"
            variant="p"
            textColor="red"
            >You are not eligible for this course. Please check course
            eligibility to continue.</Text
          >
        </div>
        <div class="tab__area__img !tw-mt-0">
          <img
            class="align-end text-white"
            height="234"
            cover
            :src="require('@/assets/images/course/' + detailsItems?.thumbnail)"
          />
        </div>
        <div class="tab__area__content">
          <h5 class="tab__area__content__title">About</h5>
          <p class="tab__area__content__description">
            {{ detailsItems?.about }}
          </p>
        </div>
        <div class="tab__area__content">
          <h5 class="tab__area__content__title">Summary</h5>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <Text
              variant="p"
              v-for="(summary, index) in detailsItems?.summary"
              :key="index"
              >{{ summary.title }} -
              <Text
                variant="p"
                textWeight="400"
                textColor="rgba(12, 15, 74, 0.8)"
                >{{ summary?.description }}</Text
              ></Text
            >
          </div>
        </div>
        <div class="tab__area__content">
          <h5 class="tab__area__content__title">
            After completing this course you will be able to
          </h5>
          <ul>
            <li
              class="tab__area__content__description"
              v-for="(aim, index) in detailsItems?.aims"
              :key="index"
            >
              <v-icon icon="mdi-check-circle-outline"></v-icon> {{ aim }}
            </li>
          </ul>
        </div>
        <div class="tab__area__cards">
          <h5 class="tab__area__content__title">Course Details</h5>
          <div class="tab__area__cards__list">
            <v-card
              v-for="(courseDetail, index) in detailsItems?.courseDetails"
              :key="index"
              class="tab__area__card__item"
            >
              <div class="courses__card__prefix">
                <span class="left__prefix"
                  >{{ courseDetail?.dayCount }}
                  {{ courseDetail?.dayCount > 1 ? "Days" : "Day" }}</span
                >
                <p class="right__prefix">
                  <v-icon icon="mdi-map-marker"></v-icon>
                  {{ courseDetail?.locationType }}
                </p>
              </div>
              <div class="tab__area__cards__title">
                <img class="open-logo" src="../../../assets/images/badge.svg" />

                {{ courseDetail?.name }}
              </div>
              <p class="tab__area__cards__description">
                {{ courseDetail?.description }}
              </p>
            </v-card>
          </div>
        </div>
        <div
          class="tab__area__buttons desktop tw-flex !tw-flex-col !tw-items-center !tw-gap-2 !tw-justify-center !tw-w-full"
        >
          <!-- <v-btn class="button button-purple-border" block size="large">
            register interest for course
          </v-btn> -->
          <v-btn
            v-if="
              getUserWorkStationFieldAccess && !isPersonalAccountWorkStation
            "
            class="button button-orange !tw-w-full"
            block
            size="large"
            @click="goToClender"
            :disabled="isSportDisable || !isEligible"
          >
            secure my spot
          </v-btn>
          <Button
            label="create workstation"
            @click="onCreateWorkstation"
            v-if="isPersonalAccountWorkStation"
          ></Button>
          <Text
            textAlign="center"
            lineHeight="18px"
            v-if="!isSportDisable && !isEligible"
            variant="p"
            textColor="red"
            >You are not eligible for this course. Please check course
            eligibility to continue.</Text
          >
        </div>
      </div>
      <div
        class="tab__area__right__block"
        v-if="variableName == 'enroll' && getUserWorkStationFieldAccess"
      >
        <v-card class="tab__area__right__block__card">
          <div class="enroll__content">
            <h4 class="title">You’re signed up to this course</h4>
            <!-- <p class="description">
              Lorem ipsum dolor sit amet consectetur. Tincidunt vitae habitasse
              adipiscing.
            </p> -->
          </div>
          <div
            v-for="status in filterCourseStatusList"
            :key="status.id"
            :class="[
              { completed: status.completed && status.isShow },
              { proj_loop: status.isShow },
            ]"
          >
            <div v-if="status.isShow">
              <div class="icon_abs">
                <v-icon icon="mdi mdi-home-circle"></v-icon>
              </div>
              <div class="proj_loop_box">
                <div class="title">{{ status.label }}</div>
                <p>
                  {{ status.info }}
                </p>
              </div>
            </div>
          </div>
          <v-btn
            class="download__btn button button-purple-border"
            v-if="getSelectedCourse?.result == '1'"
            @click="onClickDownloadCertificate"
            prepend-icon="mdi-download"
            >Download Certificate</v-btn
          >
          <!-- <div
            v-if="
              getSelectedCourse.value?.enrollmentStatus === '4' &&
              getSelectedCourse.value.result
            "
          >
            <p v-if="getSelectedCourse.value.result === '1'">
              Congratulations! Download your certificate now.
            </p>
            <p v-if="getSelectedCourse.value.result === '2'">
              Don't lose hope. You can try again in the future.
            </p>
          </div> -->

          <v-btn
            class="button button-orange next_button"
            block
            :disabled="isSportDisable"
          >
            NEXT
          </v-btn>
        </v-card>
      </div>
      <div
        class="tab__area__right__block"
        v-else-if="getUserWorkStationFieldAccess"
      >
        <v-card class="tab__area__right__block__card">
          <div class="content">
            <h4 class="title">Sign Up To This Course Today</h4>
            <h6 class="subtitle">Entry requirements</h6>
            <p class="description">
              To be eligible for this course, learners must have all the
              prerequisites.
            </p>
            <label class="yellow-text" @click="toggleElegibilityModal"
              >Check eligibility<v-icon icon="mdi-chevron-right"></v-icon
            ></label>
          </div>
          <div class="tab__area__list">
            <h6 class="title">Summary</h6>
            <ul>
              <!-- <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/duration-filled-icon.svg"
                  />
                </div>
                <p>Duration 10 days</p>
              </li> -->
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/mic-filled-icon.svg"
                  />
                </div>
                <p>English</p>
              </li>
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-building-filled-icon.svg"
                  />
                </div>
                <p>In Person & E-Learning Lessons</p>
              </li>
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-location-icon.svg"
                  />
                </div>
                <p>Locations Nationwide</p>
              </li>
              <li>
                <div class="tab__area__list__icon">
                  <img
                    class="open-logo"
                    src="../../../assets/icons/yellow-certificate-icon.svg"
                  />
                </div>
                <p>Certification on completion</p>
              </li>
            </ul>
          </div>
          <div class="tab__area__price">
            <ul>
              <li>
                <div class="tab__area__item">Course fee</div>
                <div class="tw-flex tw-items-center tw-gap-2">
                  <Text
                    class="tw-line-through"
                    variant="p"
                    textColor="#7E82A2"
                    textWeight="600"
                    >£{{
                      detailsItems?.title ===
                      "Solar PV, Battery Storage & EV Charging Level 3"
                        ? "1,837"
                        : "1,558"
                    }}</Text
                  >
                  <Text variant="p" textColor="#FFA500">FREE</Text>
                </div>
              </li>
              <li>
                <div class="tab__area__item">Booking fee (inc. VAT)</div>
                <div class="tw-flex tw-items-center tw-gap-2">
                  <Text
                    class="tw-line-through"
                    variant="p"
                    textColor="#7E82A2"
                    textWeight="600"
                    >£9.99</Text
                  >
                  <Text variant="p" textColor="#FFA500">FREE</Text>
                </div>
              </li>
            </ul>
          </div>

          <v-checkbox
            v-if="!isPersonalAccountWorkStation"
            v-model="tcOptIn"
            class="tab__area__checkbox"
          >
            <template v-slot:label>
              <p>
                <span class="blue-text remove-padding">
                  I have read and agree with the
                </span>
                <span @click="openTermsServiceModal" class="yellow-text">
                  Terms of Service & Privacy Policy</span
                >
                <!-- <span class="">Select checkbox to continue</span> -->
              </p>
            </template>
          </v-checkbox>

          <v-checkbox
            v-if="!isPersonalAccountWorkStation"
            v-model="marketingOptIn"
            class="tab__area__checkbox remove-padding"
          >
            <template v-slot:label>
              <p>
                <span class="blue-text remove-padding">
                  I have read and agree with the
                </span>
                <span class="yellow-text">Cancellation Policy</span>
              </p>
            </template>
          </v-checkbox>
          <!-- <span class="">Select checkbox to continue</span> -->

          <!-- <div class="courses__card__users">
            <ul class="courses__card__users__img">
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
              <li>
                <img src="../../../assets/images/sample-avatar.jpg" alt="" />
              </li>
            </ul>
            <p>+11 More Enrolled</p>
          </div> -->
          <v-btn
            v-if="!isPersonalAccountWorkStation"
            class="button button-orange"
            size="large"
            block
            @click="goToClender"
            :disabled="isSportDisable || !isEligible"
          >
            secure my spot
          </v-btn>
          <Button
            label="create workstation"
            class="!tw-mt-8"
            @click="onCreateWorkstation"
            v-else
          ></Button>
          <Text
            class="tw-mt-2"
            textAlign="center"
            lineHeight="18px"
            v-if="!isSportDisable && !isEligible"
            variant="p"
            textColor="red"
            >You are not eligible for this course. Please check course
            eligibility to continue.</Text
          >
          <!-- <v-btn class="button button-purple-border" size="large" block>
            register interest for course
          </v-btn> -->
        </v-card>
      </div>
    </div>

    <!-- MODALS -->
    <CheckElegibilityModal
      v-if="eligibilityModal"
      @on-close="toggleElegibilityModal"
    />
    <EntryRequirementsModal
      v-if="entryRequirementsModal"
      @on-close="toggleElegibilityModal"
    />
    <CoursesTermsConditionModal
      v-if="termsServiceModal"
      @closeTermsAndConditionModal="closeTermsAndConditionModal"
    />
    <PageRestrictionNoticeModal
      v-if="isShowWorkstationRestrictionModal"
      @on-close="toggleRestrictionModal"
    />
  </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { COURSES_STORE } from "@/store/modules/courses";
import { useRouter, useRoute } from "vue-router";
import CalenderView from "@/modules/courses/components/CalenderView.vue";
import CoursesTermsConditionModal from "@/modules/courses/components/CoursesTermsConditionModal.vue";
import Text from "../../../core/components/ui/general/Text.vue";
import EnrolledChipState from "./EnrolledChipState.vue";
import CheckElegibilityModal from "./CheckElegibilityModal.vue";
import MapMarkerYellowIcon from "@/core/components/icons/MapMarkerYellowIcon.vue";
import MapMarkerBlueIcon from "@/core/components/icons/MapMarkerBlueIcon.vue";
import { airSourceHeatPumpData, greenSkillsData } from "../data/courseDetails";
import { stringify } from "postcss";
import { getImageApiUrl } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import moment from "moment";
import { WORKSTATION } from "@/store/modules/workstation";
import PageRestrictionNoticeModal from "@/core/components/modals/PageRestrictionNoticeModal.vue";
import EntryRequirementsModal from "@/modules/courses/components/EntryRequirementsModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";

export default {
  name: "CoursesDetailsPage",
  components: {
    CalenderView,
    CheckElegibilityModal,
    Text,
    EnrolledChipState,
    MapMarkerYellowIcon,
    MapMarkerBlueIcon,
    CoursesTermsConditionModal,
    PageRestrictionNoticeModal,
    EntryRequirementsModal,
    Button,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const tcOptIn = ref(false);
    const marketingOptIn = ref(false);
    const isShowCalender = ref(false);
    const onClickBackCoursesButton = () => {
      router.push("/courses");
    };
    const detailsItems = ref(null) as any;
    const courseEnrollmentStatus = ref(null) as any;
    const variableName = ref();
    const loading = ref(false);
    const termsServiceModal = ref(false);
    const eligibilityModal = ref(false);
    const entryRequirementsModal = ref(false);
    const eligibility = ref(null) as any;
    const selectedEnrolledCourseScheduleAddress = ref(null) as any;

    variableName.value = router.currentRoute?.value?.params?.variableName;
    const courseStatusItems = [
      {
        id: 1,
        label: "Pre - Enrolled",
        info: " Your pre-enrollment is confirmed. Stay tuned for further updates.",
        completed: false,
        isShow: true,
      },
      {
        id: 2,
        label: "Enrolled",
        info: "Congratulations! You are successfully enrolled in the program.",
        completed: false,
        isShow: true,
      },
      {
        id: 3,
        label: "Rejected",
        info: "We regret to inform you that your application has been rejected.",
        completed: false,
        isShow: true,
      },
      {
        id: 4,
        label: "Waitlist",
        info: "You are on the course waitlist.",
        completed: false,
        isShow: true,
      },
      {
        id: 5,
        label: "Awaiting Results",
        info: "Your application is under review. Please await the results.",
        completed: false,
        isShow: true,
      },
      {
        id: 6,
        label: "Results Awarded",
        info: "",
        completed: false,
        isShow: true,
      },
    ];

    const closeTermsAndConditionModal = () => {
      termsServiceModal.value = false;
    };

    const openTermsServiceModal = () => {
      termsServiceModal.value = true;
    };

    const isSportDisable = computed(() => {
      return !(marketingOptIn.value && tcOptIn.value);
    });

    const isEligible = computed(() => {
      if (eligibility.value) {
        return eligibility.value.isEligible;
      }
      return false;
    });

    const getCheckElegibility = async () => {
      const courseId = route?.params?.courseId;
      try {
        let response = await store.dispatch(
          `${COURSES_STORE}/checkElegibility`,
          courseId
        );
        console.log("getCheckElegibility", response);

        if (response.data) {
          eligibility.value = response;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getSelectedCourse = computed(
      () => store.getters[`${COURSES_STORE}/courseDetails`]
    );
    const filterCourseStatusList = computed(() => {
      const courseStatusList = JSON.parse(JSON.stringify(courseStatusItems));
      if (getSelectedCourse.value?.result === "1") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[5].completed = true;
        courseStatusList[5].info =
          " Congratulations! Download your certificate now.";

        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
        courseStatusList[4].isShow = false;
      } else if (getSelectedCourse.value?.result === "2") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[5].completed = true;
        courseStatusList[5].info =
          " Don't lose hope. You can try again in the future.";

        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
        courseStatusList[4].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "1") {
        courseStatusList[0].completed = true;
        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "2") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[2].isShow = false;
        courseStatusList[3].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "3") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[2].completed = true;
        courseStatusList[3].isShow = false;
        courseStatusList[4].isShow = false;
        courseStatusList[5].isShow = false;
      } else if (getSelectedCourse.value?.enrollmentStatus === "4") {
        courseStatusList[0].completed = true;
        courseStatusList[1].completed = true;
        courseStatusList[3].completed = true;
        courseStatusList[2].isShow = false;
        courseStatusList[4].isShow = false;
        courseStatusList[5].isShow = false;
      }

      if (
        getSelectedCourse.value?.userCourseSchedules?.length &&
        moment(
          getSelectedCourse.value?.userCourseSchedules[0]?.courseSchedule
            ?.startDate
        ).isBefore(moment()) &&
        !["1", "3", "4"].includes(getSelectedCourse.value?.enrollmentStatus)
      ) {
        courseStatusList[4].completed = true;
      }

      return courseStatusList;
    });
    const lastCourseStatus = computed(() =>
      filterCourseStatusList.value.findLast((course: any) => course.completed)
    );
    const onClickDownloadCertificate = async () => {
      try {
        const fileURLString = getSelectedCourse.value.certificateAttachment;
        const url = getImageApiUrl(fileURLString);
        const imageUrl: any = await $axios.get(url);
        if (imageUrl?.publicUrl) window.open(imageUrl.publicUrl);
      } catch (error) {
        console.log("error", error);
      }
    };

    const isEnrollRoute = computed(() => {
      return route?.params?.variableName === "enroll";
    });

    const goToClender = () => {
      if (isEligible.value) {
        isShowCalender.value = true;
      }
      return;
    };

    // const onOpenEntryRequirementsModal = () => {
    //   entryRequirementsModal.value = true;
    // };

    // const onCloseEntryRequirementsModal = () => {
    //   entryRequirementsModal.value = false;
    // };

    const toggleElegibilityModal = () => {
      if (isPersonalAccountWorkStation.value) {
        entryRequirementsModal.value = !entryRequirementsModal.value;
        return;
      }
      eligibilityModal.value = !eligibilityModal.value;
    };

    const getCourseData = async () => {
      let coursesWithExtraInfo = [] as any;
      const selectedCourseId = route.params?.courseId;
      try {
        loading.value = true;
        // if user did refresh the page it will call the getCourses endpoint else it will retain the stored datavalue);
        if (!getSelectedCourse.value) {
          // onClickBackCoursesButton();
          let response = await store.dispatch(`${COURSES_STORE}/getCourses`);
          if (response.data) {
            coursesWithExtraInfo = response.data.map((course: any) => {
              if (course.id === 1) {
                return {
                  ...course,
                  ...airSourceHeatPumpData,
                };
              }
              if (course.id === 2) {
                return {
                  ...course,
                  ...greenSkillsData,
                };
              }
            });

            if (coursesWithExtraInfo.length) {
              detailsItems.value = coursesWithExtraInfo.find(
                (course: any) => course.id.toString() === selectedCourseId
              );
              if (detailsItems.value?.userCourses?.length)
                await store.dispatch(
                  `${COURSES_STORE}/saveCourseDetails`,
                  detailsItems.value?.userCourses[0]
                );
            }
          }
        } else {
          if (!isEnrollRoute.value) {
            detailsItems.value = getSelectedCourse.value;
          } else {
            detailsItems.value = getSelectedCourse.value.course;
            courseEnrollmentStatus.value = getSelectedCourse.value;
            selectedEnrolledCourseScheduleAddress.value =
              courseEnrollmentStatus.value?.userCourseSchedules[0]?.courseSchedule.address;
          }
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        loading.value = false;
      }
    };
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );

    const isShowWorkstationRestrictionModal = ref(false);
    const isPersonalAccountWorkStation = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.name ===
        "Default"
    );

    const toggleRestrictionModal = () => {
      isShowWorkstationRestrictionModal.value =
        !isShowWorkstationRestrictionModal.value;
    };

    const onCreateWorkstation = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: true,
      });
      router.push({ name: DASHBOARD_ROUTE });
    };

    onBeforeMount(async () => {
      getCourseData();
      if (!isEnrollRoute.value) {
        getCheckElegibility();
      }
    });

    onBeforeUnmount(() => {
      detailsItems.value = null;
      eligibility.value = null;
    });
    return {
      onClickBackCoursesButton,
      detailsItems,
      tcOptIn,
      marketingOptIn,
      isShowCalender,
      goToClender,
      isSportDisable,
      variableName,
      eligibilityModal,
      toggleElegibilityModal,
      isEligible,
      isEnrollRoute,
      selectedEnrolledCourseScheduleAddress,
      onClickDownloadCertificate,
      filterCourseStatusList,
      getSelectedCourse,
      lastCourseStatus,
      termsServiceModal,
      closeTermsAndConditionModal,
      openTermsServiceModal,
      getUserWorkStationFieldAccess,
      toggleRestrictionModal,
      isPersonalAccountWorkStation,
      isShowWorkstationRestrictionModal,

      entryRequirementsModal,
      onCreateWorkstation,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/courses/styles/courses.scss";
.completed {
  border-left: 1px dashed #faa500 !important;

  .icon_abs i,
  .proj_loop_box .title,
  .proj_loop_box p {
    color: #faa500 !important;
  }
}

.download__btn {
  display: flex;
  width: 100%;
}
.next_button {
}
</style>
